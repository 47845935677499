"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_input_item',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  components: {
    linkaddress: _linkaddress.default
  },
  data: function data() {
    return {
      value: '',
      defaults: {},
      configData: {}
    };
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    linkUrl: function linkUrl(e) {
      this.configData.val = e;
      this.$emit('getConfig', {
        name: 'c_input_item',
        values: this.configData.val
      });
    },
    getLink: function getLink(title) {
      if (title !== '链接' && title !== '更多链接') {
        return;
      }
      this.$refs.linkaddres.dialogVisible = true;
    }
  }
};