"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _uploadPicture = _interopRequireDefault(require("@/components/base/uploadPicture"));
var _c_checked_tab = _interopRequireDefault(require("../mobileConfigRight/c_checked_tab.vue"));
var _c_txt_tab = _interopRequireDefault(require("../mobileConfigRight/c_txt_tab.vue"));
var _c_title = _interopRequireDefault(require("../mobileConfigRight/c_title.vue"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
var _c_menu_list = _interopRequireDefault(require("@/views/pagediy/components/mobileConfigRight/c_menu_list"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'pageTitle',
  components: {
    C_menu_list: _c_menu_list.default,
    uploadPictures: _uploadPicture.default,
    checkedTab: _c_checked_tab.default,
    linkaddress: _linkaddress.default,
    txtTab: _c_txt_tab.default,
    cTitle: _c_title.default
  },
  data: function data() {
    return {
      bgType: 'bgPic',
      actived: 0,
      // 默认初始化数据禁止修改
      defaultConfig: {
        titleCent: {
          tabTitle: '基础设置'
        },
        titleColor: {
          tabTitle: '颜色设置'
        },
        setUp: {
          tabVal: '0',
          cname: '页面设置'
        },
        swiperBgConfig: {
          tabTitle: '版块设置',
          tips: '建议：图片尺寸750*332px；鼠标拖拽版块可调整图片顺序',
          title: '建议：图片尺寸750*332px；鼠标拖拽版块可调整图片顺序',
          maxList: 1000,
          list: [{
            img: '',
            info: [{
              title: '标题',
              value: '今日推荐',
              tips: '选填，不超过8个字',
              max: 8
            }, {
              title: '链接',
              value: '',
              tips: '请选择链接',
              max: 100
            }]
          }]
        }
      },
      title: '',
      //页面标题
      name: '',
      //模板名称
      isShow: true,
      bgColor: false,
      bgPic: false,
      tabVal: '0',
      colorPicker: '#f5f5f5',
      //背景色
      isChoice: '单选',
      bgPicUrl: '',
      //背景图
      returnAddress: '',
      // 返回地址
      titleColor: '#000000',
      // 标题颜色
      titleBgColor: '#fff',
      //顶部颜色
      textPosition: 0,
      //文本位置
      picList: ['icon-dantu', 'icon-pingpu', 'icon-datu'],
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapState)({
    titleTxt: function titleTxt(state) {
      return state.mobildConfig.pageTitle || '首页';
    },
    nameTxt: function nameTxt(state) {
      return state.mobildConfig.pageName || '模板';
    }
  })),
  watch: {
    nameTxt: function nameTxt(val) {
      //直接赋值给本地data中的属性，就不会报错啦
      this.name = val;
    }
  },
  created: function created() {
    var state = this.$store.state.mobildConfig;
    this.title = state.pageTitle || '首页';
    this.name = state.pageName || '模板';
    this.isShow = state.pageShow ? true : false;
    this.bgColor = state.pageColor ? true : false;
    this.bgPic = state.pagePic ? true : false;
    this.defaultConfig.swiperBgConfig = state.swiperBgConfig;
    this.bgType = state.bgType;
    this.colorPicker = state.pageColorPicker;
    this.tabVal = state.pageTabVal;
    this.bgPicUrl = state.pagePicUrl;
    this.returnAddress = state.returnAddress;
    this.titleColor = state.titleColor;
    this.titleBgColor = state.titleBgColor;
  },
  methods: {
    // 重置
    resetBgA: function resetBgA() {
      this.titleBgColor = '#fff';
      this.$store.commit('mobildConfig/UPPTitleBgColor', this.titleBgColor);
    },
    getConfig: function getConfig(e) {
      this.actived = e.values;
    },
    getLink: function getLink() {
      this.$refs.linkaddres.dialogVisible = true;
    },
    //返回链接
    linkUrl: function linkUrl(e) {
      this.returnAddress = e;
      this.$store.commit('mobildConfig/UPPReturnAddress', this.returnAddress);
    },
    // 点击图文封面
    modalPicTap: function modalPicTap() {
      var _this = this;
      this.$modalUpload(function (img) {
        _this.bgPicUrl = img[0].sattDir;
        _this.$store.commit('mobildConfig/UPPICURL', _this.bgPicUrl);
      });
    },
    bindDelete: function bindDelete() {
      this.bgPicUrl = '';
    },
    //标题背景颜色
    titleBgPickerTap: function titleBgPickerTap(colorPicker) {
      this.$store.commit('mobildConfig/UPPTitleBgColor', colorPicker);
    },
    //背景设置类型
    setBgType: function setBgType(bgType) {
      this.$store.commit('mobildConfig/UPBGTYPE', bgType);
    },
    //背景轮播设置
    setSwiperBgConfig: function setSwiperBgConfig() {
      this.$store.commit('mobildConfig/UPSWIPERBGConfig', this.defaultConfig.swiperBgConfig);
    },
    //标题颜色
    handleChangeTitle: function handleChangeTitle(colorPicker) {
      this.$store.commit('mobildConfig/UPPTitleColor', colorPicker);
    },
    //背景色
    colorPickerTap: function colorPickerTap(colorPicker) {
      this.$store.commit('mobildConfig/UPPICKER', colorPicker);
    },
    radioTap: function radioTap(val) {
      this.$store.commit('mobildConfig/UPRADIO', val);
    },
    // 标题
    changVal: function changVal(val) {
      this.$store.commit('mobildConfig/UPTITLE', val);
    },
    //模板名称
    changName: function changName(val) {
      this.$store.commit('mobildConfig/nameUpdata', val);
    },
    //是否选背景色
    bgColorTap: function bgColorTap(val) {
      this.$store.commit('mobildConfig/UPCOLOR', val);
    },
    //是否选背景图
    bgPicTap: function bgPicTap(val) {
      this.$store.commit('mobildConfig/UPPIC', val);
    }
  }
};