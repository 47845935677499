var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
          },
          [
            _c(
              "el-tabs",
              {
                directives: [
                  {
                    name: "hasPermi",
                    rawName: "v-hasPermi",
                    value: [
                      "platform:system:agreement:user:info",
                      "platform:system:agreement:merincomming:info",
                      "platform:system:agreement:userprivacy:info",
                      "platform:system:agreement:useraccountcancel:info",
                      "platform:system:agreement:useraccountcancelnotice:info",
                      "platform:system:agreement:aboutus:info",
                      "platform:system:agreement:intelligent:info",
                      "platform:system:agreement:platfromrule:info",
                      "platform:system:agreement:coupon:agreement:info",
                    ],
                    expression:
                      "[\n          'platform:system:agreement:user:info',\n          'platform:system:agreement:merincomming:info',\n          'platform:system:agreement:userprivacy:info',\n          'platform:system:agreement:useraccountcancel:info',\n          'platform:system:agreement:useraccountcancelnotice:info',\n          'platform:system:agreement:aboutus:info',\n          'platform:system:agreement:intelligent:info',\n          'platform:system:agreement:platfromrule:info',\n          'platform:system:agreement:coupon:agreement:info',\n        ]",
                  },
                ],
                attrs: { "tab-position": "left" },
                on: { "tab-click": _vm.tabStatus },
                model: {
                  value: _vm.agreementValue,
                  callback: function ($$v) {
                    _vm.agreementValue = $$v
                  },
                  expression: "agreementValue",
                },
              },
              _vm._l(_vm.tabList, function (item, index) {
                return _c(
                  "el-tab-pane",
                  { key: index, attrs: { label: item.title, name: item.info } },
                  [
                    _c("div", { staticClass: "content" }, [
                      _c("div", { staticClass: "phoneBox" }, [
                        _c("div", {
                          staticClass: "fontBox",
                          domProps: {
                            innerHTML: _vm._s(_vm.formValidate.agreement),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ueditor" },
                        [
                          _c("div", { staticClass: "font" }, [
                            _c("span", { staticClass: "gang" }),
                            _vm._v(" " + _vm._s(item.title)),
                          ]),
                          _vm._v(" "),
                          _c("Tinymce", {
                            key: _vm.keyIndex,
                            model: {
                              value: _vm.formValidate.agreement,
                              callback: function ($$v) {
                                _vm.$set(_vm.formValidate, "agreement", $$v)
                              },
                              expression: "formValidate.agreement",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: [
                          "platform:system:agreement:user:save",
                          "platform:system:agreement:merincomming:save",
                          "platform:system:agreement:userprivacy:save",
                          "platform:system:agreement:useraccountcancel:save",
                          "platform:system:agreement:useraccountcancelnotice:save",
                          "platform:system:agreement:aboutus:save",
                          "platform:system:agreement:intelligent:save",
                          "platform:system:agreement:platfromrule:save",
                          "platform:system:agreement:coupon:agreement:save",
                        ],
                        expression:
                          "[\n            'platform:system:agreement:user:save',\n            'platform:system:agreement:merincomming:save',\n            'platform:system:agreement:userprivacy:save',\n            'platform:system:agreement:useraccountcancel:save',\n            'platform:system:agreement:useraccountcancelnotice:save',\n            'platform:system:agreement:aboutus:save',\n            'platform:system:agreement:intelligent:save',\n            'platform:system:agreement:platfromrule:save',\n            'platform:system:agreement:coupon:agreement:save',\n          ]",
                      },
                    ],
                    staticClass: "button",
                    attrs: { type: "primary" },
                    on: { click: _vm.submenus },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("systemSetting.agreement.index.5u0z71qgsu00")
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }