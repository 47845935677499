"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var obj = {
  name: '',
  grade: 1,
  icon: '',
  backImage: '',
  id: null,
  backColor: '',
  experience: ''
};
var _default = exports.default = {
  name: 'CreatGrade',
  props: {
    user: Object,
    levelNumData: {
      type: Array,
      default: []
    }
  },
  watch: {
    levelNumData: {
      handler: function handler(val) {
        this.levelNumDataNew = val;
      },
      immediate: false,
      deep: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formValidate: Object.assign({}, obj),
      loading: false,
      rules: {
        name: [{
          required: true,
          message: this.$t('user.level.creatLevel.5tqrkhbnbss0'),
          trigger: 'blur'
        }],
        grade: [{
          required: true,
          message: this.$t('user.level.creatLevel.5tqrkhbncmk0'),
          trigger: 'blur'
        }, {
          type: 'number',
          message: this.$t('user.level.creatLevel.5tqrkhbnco00')
        }],
        backColor: [{
          required: true,
          message: this.$t('user.level.creatLevel.5tqrkhbncpk0'),
          trigger: 'blur'
        }],
        discount: [{
          message: this.$t('user.level.creatLevel.5tqrkhbncs00'),
          trigger: 'blur'
        }],
        experience: [{
          required: true,
          message: this.$t('user.level.creatLevel.5tqrkhbnctc0'),
          trigger: 'blur'
        }, {
          type: 'number',
          message: this.$t('user.level.creatLevel.5tqrkhbncuw0')
        }],
        icon: [{
          required: true,
          message: this.$t('user.level.creatLevel.5tqrkhbncw80'),
          trigger: 'change'
        }],
        backImage: [{
          required: true,
          message: this.$t('user.level.creatLevel.5tqrkhbncxk0'),
          trigger: 'change'
        }]
      },
      levelNum: [],
      levelNumDataNew: this.levelNumData
      //levelData: []
    };
  },
  mounted: function mounted() {
    for (var i = 0; i < 100; i++) {
      this.levelNum.push({
        val: i,
        name: i
      });
    }
  },
  computed: {
    levelData: function levelData() {
      var _this2 = this;
      this.levelNum.map(function (i) {
        if (_this2.levelNumDataNew.some(function (item) {
          return item === i.val;
        })) {
          i.disabled = true;
        } else {
          i.disabled = false;
        }
      });
      return this.levelNum;
    }
  },
  methods: {
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === '1' && num === 'icon') {
          _this.formValidate.icon = img[0].sattDir;
          this.$set(_this.user, 'icon', _this.formValidate.icon);
        } else {
          _this.formValidate.backImage = img[0].sattDir;
          this.$set(_this.user, 'backImage', _this.formValidate.backImage);
        }
        this.$set(_this.user, 'isShow', false);
      }, tit, 'user');
    },
    info: function info(id) {
      var _this3 = this;
      this.loading = true;
      (0, _user.levelInfoApi)({
        id: id
      }).then(function (res) {
        _this3.formValidate = res;
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
      // this.user = Object.assign({}, '')
    },
    submitForm: (0, _validate.Debounce)(function (formName) {
      var _this4 = this;
      this.$refs.user.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          var data = {
            discount: _this4.user.discount,
            experience: _this4.user.experience,
            grade: _this4.user.grade,
            icon: _this4.user.icon,
            id: _this4.user.id,
            isShow: _this4.user.isShow,
            name: _this4.user.name,
            backColor: _this4.user.backColor,
            backImage: _this4.user.backImage
          };
          _this4.user.id ? (0, _user.levelUpdateApi)(data).then(function (res) {
            _this4.$message.success(_this4.$t('user.level.creatLevel.5tqrkhbncyw0'));
            _this4.loading = false;
            _this4.handleClose();
            _this4.formValidate = Object.assign({}, obj);
            _this4.$parent.getList();
          }).catch(function () {
            _this4.loading = false;
          }) : (0, _user.levelSaveApi)(_this4.user).then(function (res) {
            _this4.$message.success(_this4.$t('user.level.creatLevel.5tqrkhbnd000'));
            _this4.loading = false;
            _this4.handleClose();
            _this4.formValidate = Object.assign({}, obj);
            _this4.$parent.getList();
          }).catch(function () {
            _this4.loading = false;
            _this4.formValidate = Object.assign({}, obj);
          });
        } else {
          return false;
        }
      });
    }),
    resetForm: function resetForm(formName) {
      this.dialogVisible = false;
      this[formName] = {};
      //  this.$nextTick(() => {
      //   this.$refs.formData.resetFields();
      // })
    }
  }
};