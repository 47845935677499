var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("checked-tab", {
        attrs: { configObj: _vm.defaultConfig, configNme: "setUp" },
        on: { getConfig: _vm.getConfig },
      }),
      _vm._v(" "),
      _vm.actived === 0
        ? _c(
            "div",
            [
              _c("c-title", {
                attrs: { configObj: _vm.defaultConfig, configNme: "titleCent" },
              }),
              _vm._v(" "),
              this.$route.query.type !== 2
                ? _c("div", { staticClass: "c_row-item" }, [
                    _c("div", { staticClass: "label" }, [_vm._v("模板名称")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "slider-box" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            placeholder: "选填不超过15个字",
                            maxlength: "15",
                          },
                          on: { change: _vm.changName },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "c_row-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("页面标题")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "slider-box" },
                  [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        placeholder: "选填不超过30个字",
                        maxlength: "30",
                      },
                      on: { change: _vm.changVal },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt20" },
                [
                  _c("txt-tab", {
                    attrs: {
                      configObj: _vm.defaultConfig,
                      configNme: "textPosition",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actived === 1
        ? _c(
            "div",
            [
              _c("c-title", {
                attrs: {
                  configObj: _vm.defaultConfig,
                  configNme: "titleColor",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "c_row-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("顶部颜色")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "slider-box acea-row row-between row-middle" },
                  [
                    _c("el-color-picker", {
                      on: {
                        change: function ($event) {
                          return _vm.titleBgPickerTap(_vm.titleBgColor)
                        },
                      },
                      model: {
                        value: _vm.titleBgColor,
                        callback: function ($$v) {
                          _vm.titleBgColor = $$v
                        },
                        expression: "titleBgColor",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "widthIpt",
                      attrs: { size: "small" },
                      model: {
                        value: _vm.titleBgColor,
                        callback: function ($$v) {
                          _vm.titleBgColor = $$v
                        },
                        expression: "titleBgColor",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { on: { click: _vm.resetBgA } }, [
                      _vm._v("重置"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c_row-item" }, [
                _c("div", { staticClass: "label" }, [_vm._v("标题颜色")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "slider-box acea-row row-between row-middle" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.handleChangeTitle(_vm.titleColor)
                          },
                        },
                        model: {
                          value: _vm.titleColor,
                          callback: function ($$v) {
                            _vm.titleColor = $$v
                          },
                          expression: "titleColor",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "#ffffff" } }, [
                          _vm._v("白色"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "#000000" } }, [
                          _vm._v("黑色"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "c_row-item acea-row row-top" }, [
                _c("div", { staticClass: "label" }, [_vm._v("背景设置")]),
                _vm._v(" "),
                _c("div", { staticClass: "slider-box" }, [
                  _c(
                    "div",
                    { staticClass: "acea-row row-between row-top color" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.bgColorTap },
                          model: {
                            value: _vm.bgColor,
                            callback: function ($$v) {
                              _vm.bgColor = $$v
                            },
                            expression: "bgColor",
                          },
                        },
                        [_vm._v("背景色")]
                      ),
                      _vm._v(" "),
                      _c("el-color-picker", {
                        on: {
                          change: function ($event) {
                            return _vm.colorPickerTap(_vm.colorPicker)
                          },
                        },
                        model: {
                          value: _vm.colorPicker,
                          callback: function ($$v) {
                            _vm.colorPicker = $$v
                          },
                          expression: "colorPicker",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "65%" },
                        attrs: { size: "small" },
                        model: {
                          value: _vm.colorPicker,
                          callback: function ($$v) {
                            _vm.colorPicker = $$v
                          },
                          expression: "colorPicker",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "acea-row row-between row-top color" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticStyle: { "margin-top": "6px" },
                          on: { change: _vm.setBgType },
                          model: {
                            value: _vm.bgType,
                            callback: function ($$v) {
                              _vm.bgType = $$v
                            },
                            expression: "bgType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "bgPic" } }, [
                            _vm._v("背景图"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "bgSwiper" } }, [
                            _vm._v("背景轮播"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "page_bg" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { type: "button" },
                              on: { change: _vm.radioTap },
                              model: {
                                value: _vm.tabVal,
                                callback: function ($$v) {
                                  _vm.tabVal = $$v
                                },
                                expression: "tabVal",
                              },
                            },
                            _vm._l(_vm.picList, function (item, index) {
                              return _c(
                                "el-radio",
                                { key: index, attrs: { label: index } },
                                [
                                  _c("span", {
                                    staticClass: "iconfont",
                                    class: item,
                                  }),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.bgType === "bgPic"
                    ? _c("div", [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("建议尺寸：690 * 240px"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "boxs",
                            on: { click: _vm.modalPicTap },
                          },
                          [
                            _vm.bgPicUrl
                              ? _c("img", {
                                  attrs: { src: _vm.bgPicUrl, alt: "" },
                                })
                              : _c("div", { staticClass: "upload-box" }, [
                                  _c("i", {
                                    staticClass: "iconfont icon-tianjia1",
                                  }),
                                ]),
                            _vm._v(" "),
                            _vm.bgPicUrl
                              ? _c("div", { staticClass: "replace" }, [
                                  _vm._v("更换图片"),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bgType === "bgSwiper" && _vm.actived === 1
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "acea-row row-right",
                  staticStyle: { "padding-right": "10px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.setSwiperBgConfig },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("c_menu_list", {
                attrs: {
                  configNme: "swiperBgConfig",
                  configObj: _vm.defaultConfig,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("linkaddress", { ref: "linkaddres", on: { linkUrl: _vm.linkUrl } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }