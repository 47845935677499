"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var schedule = _interopRequireWildcard(require("@/api/schedule.js"));
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'CompanyList',
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      loading: false,
      dialogVisible: false,
      editId: 0,
      tableFrom: {
        page: 1,
        limit: 20,
        total: 0
      }
    };
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['platform:schedule:job:log:list'])) this.getjobLogList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //  获取定时任务日志分页列表
    getjobLogList: function getjobLogList() {
      var _this = this;
      this.loading = true;
      schedule.jobLogList({
        page: this.tableFrom.page,
        limit: this.tableFrom.limit
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = res;
      }).catch(function () {
        _this.loading = false;
      });
    },
    // 分页
    pageChange: function pageChange(e) {
      this.tableFrom.page = e;
      this.getjobLogList();
    },
    handleSizeChange: function handleSizeChange(e) {
      this.tableFrom.limit = e;
      this.getjobLogList();
    }
  }
};