"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemGroup = require("@/api/systemGroup");
var _clipboard = _interopRequireDefault(require("clipboard"));
var _systemConfig = require("@/api/systemConfig");
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'index',
  data: function data() {
    return {
      iframeUrl: '',
      menuList: [],
      menuInfo: {},
      typeName: '',
      currentPage: 'home',
      modelBanner: [],
      urlbg: require('@/assets/imgs/user_bg.png'),
      //  indexTab: [],
      dataList: [],
      addUrlStatus: true,
      infoStatus: false,
      showStatus: false,
      shows: 0,
      indextTabMenu: [],
      tabActive: 0,
      cate: false,
      tip: false,
      mockGoods: false,
      mockGoodsImg: require('@/assets/theme/goodsList1.png'),
      showTabNav: true,
      cateArr: [{
        img: require('@/assets/imgs/moren.png'),
        tit: this.$t('pagediy.viewDesign.index.5tromlc5epk0')
      }, {
        img: require('@/assets/imgs/youxuan.png'),
        tit: this.$t('pagediy.viewDesign.index.5tromlc5eqs0')
      }, {
        img: require('@/assets/imgs/haowu.png'),
        tit: this.$t('pagediy.viewDesign.index.5tromlc5es80')
      }, {
        img: require('@/assets/imgs/shengxian.png'),
        tit: this.$t('pagediy.viewDesign.index.5tromlc5eto0')
      }],
      cateImg: '',
      active: 1,
      active1: 0,
      disabled: false,
      radio: true,
      // logoUrl: '',
      newsInfo: '',
      listActive: 0,
      tabList: [this.$t('pagediy.viewDesign.index.5tromlc5evg0'), this.$t('pagediy.viewDesign.index.5tromlc5ewg0')],
      itemIndex: 0,
      navigationList: [],
      //底部导航
      navigationListTab: [],
      //底部导航左侧展示
      isCustom: 0
    };
  },
  components: {
    linkaddress: _linkaddress.default
  },
  created: function created() {
    this.iframeUrl = "https://app.beta.java.jiefanghui.net?type=iframeVisualizing";
  },
  mounted: function mounted() {
    //监听子页面给当前页面传值
    window.addEventListener('message', this.handleMessage, false);
    if ((0, _permission.checkPermi)(['platform:page:layout:index'])) this.designList();
    if ((0, _permission.checkPermi)(['platform:page:layout:bottom:navigation'])) this.getBottomNavigation();
    this.$set(this, 'typeName', '');
    this.$set(this, 'tip', true);
    this.$nextTick(function () {
      var _this2 = this;
      var clipboard = new _clipboard.default('.copy-data');
      clipboard.on('success', function () {
        _this2.$message.success(_this2.$t('pagediy.viewDesign.index.5tromlc5exk0'));
      });
    });
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //删除底部菜单中的配置项
    handleDelMenu: function handleDelMenu(item, index) {
      this.navigationList.splice(index, 1);
      this.navigationListTab.splice(index, 1);
    },
    addBox: function addBox() {
      if (this.typeName == 'bottomNavigation') {
        var indexMenu = JSON.parse(JSON.stringify(this.navigationList[0]));
        indexMenu.id = null;
        indexMenu.name = '';
        indexMenu.link = '';
        indexMenu.checked = '';
        indexMenu.unchecked = '';
        this.navigationList.push(indexMenu);
      } else if (this.menuList.length >= 10 && this.typeName == 'indexMenu') {
        this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5ez00'));
      } else if (this.typeName == 'indexTabNav' && this.menuList.length >= 4) {
        this.addUrlStatus = false;
        this.infoStatus = true;
        this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5f0g0'));
      } else {
        var _indexMenu = JSON.parse(JSON.stringify(this.menuList[0]));
        _indexMenu.id = null;
        _indexMenu.name = '';
        _indexMenu.url = '';
        _indexMenu.info = '';
        _indexMenu.pic = '';
        this.menuList.push(_indexMenu);
      }
    },
    //获取底部导航
    getBottomNavigation: function getBottomNavigation() {
      var _this3 = this;
      (0, _systemGroup.getBottomNavigationApi)().then(function (res) {
        _this3.navigationList = res.bottomNavigationList.reverse();
        var data = res.bottomNavigationList.filter(function (item) {
          return item.status;
        });
        _this3.navigationListTab = data;
        _this3.isCustom = Number(res.isCustom);
      });
    },
    // 获取列表值；
    designList: function designList() {
      var _this4 = this;
      (0, _systemGroup.designListApi)().then(function (res) {
        for (var key in res) {
          if (res[key] instanceof Array) res[key].reverse();
        }
        _this4.menuInfo = res;
        // this.$set(this, 'logoUrl', res.indexLogo);
        var newArr = [];
        var indexMenu = res.indexMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var indexBanner = res.indexBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        var userMenu = res.userMenu.filter(function (item, index, arr) {
          return item.status == true;
        });
        var indexNews = res.indexNews;
        var userBanner = res.userBanner.filter(function (item, index, arr) {
          return item.status == true;
        });
        newArr.push(indexMenu, indexBanner, userMenu, indexNews, userBanner);
        _this4.dataList = newArr;
        _this4.$set(_this4, 'newsInfo', indexNews[0] ? indexNews[0].title : _this4.$t('pagediy.viewDesign.index.5tromlc5f1o0'));
      });
    },
    //
    handleMessage: function handleMessage(event) {
      this.typeName = event;
      switch (event) {
        case 'bottomNavigation':
          this.shows = 8;
          break;
        case 'indexMenu':
          this.menuList = this.menuInfo.indexMenu;
          this.shows = 3;
          this.mockGoods = false;
          break;
        case 'indexBanner':
          this.menuList = this.menuInfo.indexBanner;
          this.shows = 1;
          this.mockGoods = false;
          break;
        case 'userMenu':
          this.menuList = this.menuInfo.userMenu;
          this.shows = 6;
          this.mockGoods = false;
          break;
        case 'indexNews':
          this.menuList = this.menuInfo.indexNews;
          this.shows = 2;
          this.mockGoods = false;
          break;
        case 'userBanner':
          this.menuList = this.menuInfo.userBanner;
          this.shows = 5;
          this.mockGoods = false;
          break;
        case 'indexTabNav':
          this.menuList = this.indextTabMenu;
          this.shows = 4;
          this.mockGoods = false;
          break;
        case 'goodsMock':
          this.mockGoods = true;
          this.typeName = '';
          this.tip = false;
          this.shows = 7;
          break;
      }
    },
    switchNav: function switchNav(index) {
      this.tabActive = index;
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (tit === '1' && num === 'duo') {
          _this.menuList[i].pic = img[0].sattDir;
        }
        if (tit === '2') {
          _this.navigationList[i].checked = img[0].sattDir;
        }
        if (tit === '3') {
          _this.navigationList[i].unchecked = img[0].sattDir;
        }
      }, tit, 'content', true);
    },
    // 删除
    bindDelete: function bindDelete(item, index) {
      this.menuList.splice(index, 1);
    },
    saveConfig: (0, _validate.Debounce)(function () {
      switch (this.typeName) {
        case 'indexMenu':
          this.saveData('indexMenu', '/admin/platform/page/layout/index/menu/save');
          break;
        case 'indexBanner':
          this.saveData('indexBanner', '/admin/platform/page/layout/index/banner/save');
          break;
        case 'userMenu':
          this.saveData('userMenu', '/admin/platform/page/layout/user/menu/save');
          break;
        case 'indexNews':
          this.saveData('indexNews', '/admin/platform/page/layout/index/news/save');
          break;
        case 'userBanner':
          this.saveData('userBanner', '/admin/platform/page/layout/user/banner/save');
          break;
        case 'indexTabNav':
          this.saveData('indexTable', '/admin/platform/page/layout/index/table/save');
          break;
        case 'bottomNavigation':
          this.saveData('bottomNavigation', '/admin/platform/page/layout/bottom/navigation/save');
          break;
      }
    }),
    saveData: function saveData(param, url) {
      var _this5 = this;
      var tArr = this.menuList.filter(function (item, index, arr) {
        return item.status === true;
      });
      var navigationList = this.navigationList.filter(function (item, index, arr) {
        return item.status === true;
      });
      var data = {};
      if (param === 'bottomNavigation') {
        data = {
          bottomNavigationList: this.changeIndex(this.navigationList),
          isCustom: this.isCustom
        };
        if (navigationList.length < 4) return this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5f2w0'));
      } else {
        if (param === 'indexMenu' && tArr.length < 5) return this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5f400'));
        if (param === 'indexTabNav' && tArr.length < 2) return this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5f5c0'));
        if (param === 'indexNews' && tArr.length < 1) return this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5f740'));
        data = _defineProperty({}, param, this.changeIndex(this.menuList));
      }
      (0, _systemGroup.SaveDataApi)(data, url).then(function (res) {
        _this5.$message.success(_this5.$t('pagediy.viewDesign.index.5tromlc5f9c0'));
        if (param === 'bottomNavigation') {
          _this5.getBottomNavigation();
        } else {
          _this5.designList();
        }
      });
    },
    changeIndex: function changeIndex(array) {
      array.map(function (item, index) {
        item.sort = index;
      });
      return array;
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragEnter: function handleDragEnter(e, item, data, name) {
      var _newItems;
      e.dataTransfer.effectAllowed = 'move';
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(data);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      (_newItems = newItems).splice.apply(_newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      newItems = newItems.filter(function (item) {
        return item.status;
      });
      if (name === 'menuList') {
        this.menuList = newItems;
      } else {
        this.navigationList = newItems;
        this.navigationListTab = newItems;
      }
    },
    showCurrent: function showCurrent(name) {
      this.currentPage = name;
      this.$set(this, 'typeName', '');
      this.$set(this, 'tip', true);
      this.$set(this, 'cate', false);
    },
    showTip: function showTip() {
      this.$message.warning(this.$t('pagediy.viewDesign.index.5tromlc5faw0'));
    },
    cateNav: function cateNav() {
      this.currentPage = 'cate';
      this.$set(this, 'typeName', '');
      this.$set(this, 'cate', true);
      this.$set(this, 'mockGoods', false);
      this.getConfig();
    },
    switchTab: function switchTab(index) {
      this.active = index;
      switch (index) {
        case 1:
          this.cateImg = require('@/assets/imgs/moren.png');
          break;
        case 2:
          this.cateImg = require('@/assets/imgs/youxuan.png');
          break;
        case 3:
          this.cateImg = require('@/assets/imgs/haowu.png');
          break;
        case 4:
          this.cateImg = require('@/assets/imgs/shengxian.png');
          break;
      }
    },
    switchKind: function switchKind(index) {
      this.active1 = index;
      switch (index) {
        case '0':
          this.$set(this, 'mockGoodsImg', require('@/assets/theme/goodsList1.png'));
          this.$set(this, 'showTabNav', true);
          break;
        case '1':
          this.$set(this, 'mockGoodsImg', require('@/assets/theme/goodsList2.png'));
          this.$set(this, 'showTabNav', false);
          break;
        case '2':
          this.$set(this, 'mockGoodsImg', require('@/assets/theme/goodsList3.png'));
          this.$set(this, 'showTabNav', false);
          break;
      }
    },
    save: (0, _validate.Debounce)(function () {
      var _this6 = this;
      var data = {
        category_page_config: this.active,
        is_show_category: this.radio
      };
      (0, _systemGroup.SaveDataApi)(data, '/admin/page/layout/category/config/save').then(function (res) {
        _this6.$message.success(_this6.$t('pagediy.viewDesign.index.5tromlc5f9c0'));
      });
    }),
    getConfig: function getConfig() {
      var _this7 = this;
      (0, _systemGroup.getDataApi)().then(function (res) {
        _this7.$set(_this7, 'active', res.categoryConfig);
        _this7.$set(_this7, 'radio', res.isShowCategory === 'true' ? true : false);
        _this7.switchTab(_this7.active);
      });
    },
    kindSave: (0, _validate.Debounce)(function () {
      var _this8 = this;
      var data = {
        key: 'homePageSaleListStyle',
        value: Number(this.active1) + 1
      };
      (0, _systemConfig.configSaveUniq)(data).then(function (res) {
        _this8.$message.success(_this8.$t('pagediy.viewDesign.index.5tromlc5f9c0'));
      });
    }),
    kindGet: function kindGet() {
      var _this9 = this;
      (0, _systemConfig.configGetUniq)({
        key: 'homePageSaleListStyle'
      }).then(function (res) {
        var conque = res - 1;
        _this9.$set(_this9, 'active1', conque.toString());
        if (res == '1') {
          _this9.$set(_this9, 'mockGoodsImg', require('@/assets/theme/goodsList1.png')); //showTabNav
          _this9.$set(_this9, 'showTabNav', true);
        } else if (res == '2') {
          _this9.$set(_this9, 'mockGoodsImg', require('@/assets/theme/goodsList2.png'));
          _this9.$set(_this9, 'showTabNav', false);
        } else if (res == '3') {
          _this9.$set(_this9, 'mockGoodsImg', require('@/assets/theme/goodsList3.png'));
          _this9.$set(_this9, 'showTabNav', false);
        }
      });
    },
    getLink: function getLink(index) {
      this.itemIndex = index;
      this.$refs.linkaddres.dialogVisible = true;
    },
    ProductNavTab: function ProductNavTab(index) {
      this.listActive = index;
      if (index === 1) {
        this.showCurrent('user');
      } else {
        this.showCurrent('home');
      }
    },
    linkUrl: function linkUrl(e) {
      if (this.typeName == 'bottomNavigation') {
        this.navigationList[this.itemIndex].link = e;
      } else {
        this.menuList[this.itemIndex].url = e;
      }
    }
  }
};