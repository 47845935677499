"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _systemSetting = require("@/api/systemSetting");
var _auth = require("@/utils/auth");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_upload_video',
  computed: _objectSpread({}, (0, _vuex.mapState)({
    tabVal: function tabVal(state) {
      return state.admin.mobildConfig.searchConfig.data.tabVal;
    }
  })),
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      }
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  methods: {
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var formData = new FormData();
      var data = {
        model: 'product',
        pid: 0
      };
      formData.append('multipart', param.file);
      this.uploadPic(formData, data);
    },
    uploadPic: function uploadPic(formData, data) {
      var _this = this;
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _systemSetting.fileFileApi)(formData, data).then(function (res) {
        loading.close();
        _this.$message.success('上传成功');
        _this.$emit('getConfig', {
          name: 'video',
          values: res.url
        });
        _this.configData.uploadVideo.url = res.url;
      }).catch(function (res) {
        loading.close();
      });
    }
  }
};