"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _index = _interopRequireDefault(require("../mobileConfigRight/index.js"));
var _index2 = _interopRequireDefault(require("../rightBtn/index.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_home_coupon',
  componentsName: 'home_coupon',
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  props: {
    activeIndex: {
      type: null
    },
    num: {
      type: null
    },
    index: {
      type: null
    }
  },
  data: function data() {
    return {
      configObj: {},
      rCom: [{
        components: _index.default.c_checked_tab,
        configNme: 'setUp'
      }],
      // automatic: [
      //  {
      //    components: toolCom.c_merchantName,
      //    configNme: 'activeValueMer',
      //  },
      // ],
      tableFrom: {
        page: 1,
        limit: 500,
        category: ''
      }
    };
  },
  watch: {
    num: function num(nVal) {
      var value = JSON.parse(JSON.stringify(this.$store.state.mobildConfig.defaultArray[nVal]));
      this.configObj = value;
    },
    configObj: {
      handler: function handler(nVal, oVal) {
        this.$store.commit('mobildConfig/UPDATEARR', {
          num: this.num,
          val: nVal
        });
      },
      deep: true
    },
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        var arr = [this.rCom[0]];
        if (nVal == 0) {
          var tempArr = [{
            components: _index.default.c_title,
            configNme: 'tabConfig'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'tabConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'logoConfig'
          }, {
            components: _index.default.c_upload_img,
            configNme: 'logoConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'titleConfig'
          }, {
            components: _index.default.c_input_item,
            configNme: 'titleConfig'
          }, {
            components: _index.default.c_input_item,
            configNme: 'linkConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'numConfig'
          }, {
            components: _index.default.c_input_number,
            configNme: 'numConfig'
          },
          // 新建优惠券
          {
            components: _index.default.c_coupon,
            configNme: 'activeValueMer'
          }];
          this.rCom = arr.concat(tempArr);
        } else {
          var _tempArr = [{
            components: _index.default.c_title,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'themeColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'titleColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'moreColor'
          }, {
            components: _index.default.c_title,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'downConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'contentConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'lrConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'mbConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_select,
            configNme: 'activeValueMer'
          }];
          this.rCom = arr.concat(_tempArr);
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        var value = JSON.parse(JSON.stringify(_this.$store.state.mobildConfig.defaultArray[_this.num]));
        _this.configObj = value;
      }
    });
    this.getProList();
  },
  created: function created() {},
  methods: _objectSpread(_objectSpread({
    getConfig: function getConfig(data) {
      if (data.name && data.name === 'c_input_item') this.configObj.linkConfig.val = data.values;
    },
    handleSubmit: function handleSubmit(name) {
      var obj = {};
      obj.activeIndex = this.activeIndex;
      obj.data = this.configObj;
      this.add(obj);
    }
  }, (0, _vuex.mapMutations)({
    add: 'admin/mobildConfig/UPDATEARR'
  })), {}, {
    //选择tab获取商品列表
    getProList: function getProList(data) {
      var _this2 = this;
      (0, _marketing.canSendList)(this.tableFrom).then(function (res) {
        //  console.log('res',res)
        _this2.configObj.activeValueMer.goodsList = res.list;
        // console.log('this.configObj.activeValueMer.goodsList ',this.configObj.activeValueMer.goodsList )
      });
    }
  })
};