"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var merchant = _interopRequireWildcard(require("@/api/merchant"));
var _creatMerchant = _interopRequireDefault(require("./creatMerchant"));
var _vuex = require("vuex");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'MerchantList',
  components: {
    creatMerchant: _creatMerchant.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      fromList: this.$constants.fromList,
      isChecked: false,
      listLoading: false,
      headeNum: [{
        count: '',
        type: '1',
        title: this.$t('merchant.list.index.5thgzrmtdos0')
      }, {
        count: '',
        type: '0',
        title: this.$t('merchant.list.index.5thgzrmtdqo0')
      }],
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        dateLimit: '',
        isSwitch: '1',
        keywords: '',
        isSelf: '',
        categoryId: '',
        typeId: ''
      },
      autoUpdate: true,
      timeVal: [],
      merId: 0,
      keyNum: 0,
      isDisabled: false,
      indexKey: 0
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['merchantClassify', 'merchantType'])),
  mounted: function mounted() {
    if (!this.merchantClassify.length) this.$store.dispatch('merchant/getMerchantClassify');
    if (!this.merchantType.length) this.$store.dispatch('merchant/getMerchantType');
    if ((0, _permission.checkPermi)(['platform:merchant:list:header:num'])) this.getHeadNum();
    if ((0, _permission.checkPermi)(['platform:merchant:page:list'])) this.getList('');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    /**
     *  选择时间
     */
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.tableData.data = [];
      this.getList('');
      this.getHeadNum();
    },
    /**
     *  具体日期
     */
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = this.timeVal ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList('');
      this.getHeadNum();
    },
    /**
     *  获取开启商户数
     */
    getHeadNum: function getHeadNum() {
      var _this2 = this;
      var data = _objectSpread({}, this.tableFrom);
      delete data.page;
      delete data.limit;
      delete data.isSwitch;
      merchant.merHeaderNumApi(data).then(function (res) {
        _this2.headeNum[0]['count'] = res.openNum;
        _this2.headeNum[1]['count'] = res.closeNum;
      }).catch(function (res) {});
    },
    /**
     *  列表
     */
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      merchant.merchantListApi({
        page: this.tableFrom.page,
        limit: this.tableFrom.limit,
        dateLimit: this.tableFrom.dateLimit,
        isSwitch: this.tableFrom.isSwitch,
        keywords: encodeURIComponent(this.tableFrom.keywords),
        isSelf: this.tableFrom.isSelf,
        categoryId: this.tableFrom.categoryId,
        typeId: this.tableFrom.typeId
      }).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
        _this3.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
      this.getHeadNum();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
      this.getHeadNum();
    },
    /**
     *  修改状态
     */
    onchangeIsShow: function onchangeIsShow(row) {
      var _this4 = this;
      if (this.tableFrom.isSwitch === '0') return;
      var title = !row.isRecommend ? this.$t('merchant.list.index.5thgzrmtduk0') : this.$t('merchant.list.index.5thgzrmtdww0');
      this.$modalSure(title).then(function () {
        merchant.merchantSwitchApi(row.id).then(function (res) {
          row.isRecommend = !row.isRecommend;
          _this4.$message.success(_this4.$t('merchant.list.index.5thgzrmtdz40'));
        });
      });
    },
    /**
     *  开启关闭
     */
    onchangeIsClose: function onchangeIsClose(row) {
      var _this5 = this;
      !row.isSwitch ? merchant.merchantOpenApi(row.id).then(function () {
        _this5.$message.success(_this5.$t('merchant.list.index.5thgzrmte1c0'));
        _this5.tableFrom.isSwitch = '1';
        _this5.getHeadNum();
        _this5.getList('');
      }) : merchant.merchantCloseApi(row.id).then(function () {
        _this5.$message.success(_this5.$t('merchant.list.index.5thgzrmte780'));
        _this5.tableFrom.isSwitch = '0';
        _this5.getHeadNum();
        _this5.getList('');
      });
    },
    getChange: function getChange() {
      this.getHeadNum();
      this.getList(1);
      this.dialogVisible = false;
    },
    closeModel: function closeModel() {
      this.dialogVisible = false;
    },
    /**
     *  添加
     */
    onAdd: function onAdd() {
      this.dialogVisible = true;
      this.isDisabled = false;
      this.indexKey = Math.random();
      this.merId = 0;
    },
    /**
     *  {{$t('merchant.list.index.5thgzrmtdbo0')}}
     */
    onEdit: function onEdit(id, n) {
      this.dialogVisible = true;
      this.merId = id;
      this.indexKey = Math.random();
      n ? this.isDisabled = true : this.isDisabled = false;
    },
    /**
     *  {{$t('merchant.list.index.5thgzrmtdfg0')}}
     */
    handleUpdatePhone: function handleUpdatePhone(row, num) {
      this.merId = row.id;
      var _this = this;
      this.$modalParserFrom(this.$t('merchant.list.index.5thgzrmte9o0'), this.$t('merchant.list.index.5thgzrmte9o0'), 1, {
        phone: row.phone
      }, function (formValue) {
        _this.submit(formValue, num);
      }, this.keyNum += 2);
    },
    /**
     *  设置复制次数
     */
    handleTimes: function handleTimes(row, num) {
      this.merId = row.id;
      var nums = row.copyProductNum;
      var _this = this;
      this.$modalParserFrom(this.$t('merchant.list.index.5thgzrmteck0'), this.$t('merchant.list.index.5thgzrmteck0'), 1, {
        copyProductNum: nums || 0
      }, function (formValue) {
        _this.submit(formValue, num);
      }, this.keyNum += 1);
    },
    // 修改密码表单
    onPassword: function onPassword(id) {
      var _this6 = this;
      this.merId = id;
      this.$modalSure(this.$t('merchant.list.index.5thgzrmtefs0')).then(function () {
        merchant.merRsetPasswordApi(id).then(function (res) {
          _this6.$modalSure("\u91CD\u7F6E\u6210\u529F\uFF0C\u65B0\u5BC6\u78BC\u662F".concat(res, ",\u95DC\u9589\u5F8C\u5BC6\u78BC\u7121\u6CD5\u518D\u6B21\u67E5\u770B\u8ACB\u59A5\u5584\u4FDD\u7BA1"), false);
        });
      });
    },
    submit: function submit(formValue, num) {
      var _this7 = this;
      if (num === 1) {
        var data = {
          id: this.merId,
          phone: formValue.phone
        };
        merchant.merchantupdatePhoneApi(data).then(function (res) {
          _this7.$message.success(_this7.$t('merchant.list.index.5thgzrmtelw0'));
          _this7.$msgbox.close();
        }).catch(function () {
          _this7.loading = false;
        });
      } else {
        var _data = {
          id: this.merId,
          type: formValue.type,
          num: formValue.num
        };
        merchant.merchantCopyNumApi(_data).then(function (res) {
          _this7.$message.success(_this7.$t('merchant.list.index.5thgzrmtelw0'));
          _this7.$msgbox.close();
          _this7.getList(1);
        }).catch(function () {
          _this7.loading = false;
        });
      }
    }
  }
};