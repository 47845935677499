var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "demo-ruleForm",
      attrs: { model: _vm.ruleForm, "label-width": "100px" },
    },
    [
      _c(
        "el-form-item",
        [
          _c("el-alert", {
            attrs: {
              title: _vm.$t("user.list.level.5tqnj8qxffc0"),
              type: "warning",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form-item",
        {
          attrs: {
            label: _vm.$t("user.list.level.5tqnj8qxftw0"),
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                clearable: "",
                placeholder: _vm.$t("user.list.level.5tqnj8qxfwc0"),
              },
              on: { change: _vm.currentSel },
              model: {
                value: _vm.ruleForm.levelId,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleForm, "levelId", $$v)
                },
                expression: "ruleForm.levelId",
              },
            },
            _vm._l(_vm.levelList, function (item) {
              return _c("el-option", {
                key: item.grade,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      (_vm.grade == "" ? false : _vm.grade < _vm.levelInfo.gradeLevel)
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("user.list.level.5tqnj8qxfyg0"),
                "label-width": "100px",
              },
            },
            [
              _c("el-switch", {
                model: {
                  value: _vm.ruleForm.isSub,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "isSub", $$v)
                  },
                  expression: "ruleForm.isSub",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.resetForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("user.list.level.5tqnj8qxfzw0")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("user.list.level.5tqnj8qxg1k0")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }