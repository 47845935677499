"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  data: function data() {
    return {
      showStatus: false,
      type: 1,
      form: {
        type: '0',
        price: '',
        discount: '',
        activity_stock: ''
      }
    };
  },
  methods: {
    onClose: function onClose() {
      this.showStatus = false;
      setTimeout(this.form = {
        type: '0',
        price: '',
        discount: '',
        activity_stock: ''
      }, 500);
    },
    confirmSet: function confirmSet() {
      this.$emit('onChange', this.form, this.type);
      this.onClose();
    }
  }
};