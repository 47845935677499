"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_group',
  cname: '拼团',
  icon: 't-icon-zujian-miaosha',
  configName: 'c_home_group',
  type: 1,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'group',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        margin: this.configObj.mbConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + '10px' + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //图片背景
    boxBgStyle: function boxBgStyle() {
      return {
        backgroundImage: "url('https://api.admin.merchant.jiefanghui.xbdzz.cn/crmebimage/public/activitystyle/2023/05/30/5fb66919d78c4c2892fee5900915fba5bepulyen1m.png'),linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      };
    },
    //图片样式
    contentStyle: function contentStyle() {
      return {
        'border-radius': this.configObj.contentStyle.val + 'px'
      };
    },
    //内容边距
    contentConfig: function contentConfig() {
      return [{
        'margin-right': this.configObj.contentConfig.val ? this.configObj.contentConfig.val + 'px' : '0'
      }];
    },
    //标题颜色
    titleColor: function titleColor() {
      return {
        color: this.configObj.titleColor.color[0].item
      };
    },
    //名称颜色
    nameColor: function nameColor() {
      return {
        color: this.configObj.nameColor.color[0].item
      };
    },
    //价格颜色
    priceColor: function priceColor() {
      return {
        color: this.configObj.priceColor.color[0].item
      };
    },
    //内容间距
    gridGap: function gridGap() {
      return {
        'grid-gap': this.configObj.contentConfig.val + 'px'
      };
    },
    //商品名称
    nameShow: function nameShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(0) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    //商品价格
    priceShow: function priceShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(1) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      defaultConfig: {
        name: 'group',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '拼团'
        },
        tabConfig: {
          title: '展示样式',
          tabTitle: '布局设置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '样式1',
            icon: 'icon-yangshiyi'
          }, {
            val: '样式2',
            icon: 'icon-yangshier'
          }]
        },
        logoConfig: {
          isShow: 1,
          tabTitle: '图标设置',
          title: '上传图标',
          tips: '建议：124px*32px',
          url: localStorage.getItem('mediaDomain') + '/crmebimage/presets/groupTitle.png'
        },
        titleConfig: {
          tabTitle: '标题设置',
          title: '标题内容',
          val: '邀请你的好友来参与',
          place: '请输入标题',
          isShow: 1,
          max: 15
        },
        linkConfig: {
          title: '更多链接',
          val: '/pages/activity/goods_group/index',
          place: '请选择链接',
          isShow: 1,
          max: 100
        },
        //显示内容
        typeConfig: {
          tabTitle: '显示内容',
          name: 'rowsNum',
          title: '展示信息',
          activeValue: [0, 1],
          list: [{
            val: '商品名称'
          }, {
            val: '商品价格'
          }]
        },
        // 背景颜色
        bgColor: {
          tabTitle: '颜色设置',
          title: '背景颜色',
          color: [{
            item: '#FF2200'
          }, {
            item: '#FFFFFF'
          }],
          default: [{
            item: '#FF2200'
          }, {
            item: '#FFFFFF'
          }]
        },
        nameColor: {
          title: '商品名称颜色',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        titleColor: {
          title: '标题颜色',
          color: [{
            item: '#fff'
          }],
          default: [{
            item: '#fff'
          }]
        },
        priceColor: {
          title: '价格颜色',
          color: [{
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }]
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 7,
          min: 0,
          max: 30
        },
        contentStyle: {
          title: '图片圆角',
          name: 'contentStyle',
          val: 5,
          min: 0,
          max: 30
        },
        // 上间距
        upConfig: {
          tabTitle: '边距设置',
          title: '上边距',
          val: 10,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          title: '下边距',
          val: 10,
          min: 0
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 25
        },
        mbConfig: {
          title: '顶部间距',
          val: 10,
          min: 0
        },
        contentConfig: {
          title: '内容间距',
          val: 10,
          min: 0,
          max: 30
        }
      },
      configObj: null,
      src: '',
      listStyle: 0,
      spikeList: [{
        img: '',
        name: '小米家用电饭煲小米家用电饭煲',
        price: '234'
      }, {
        img: '',
        name: '小米家用电饭煲小米家用电饭煲',
        price: '177',
        ot_price: '300'
      }, {
        img: '',
        name: '小米家用电饭煲小米家用电饭煲',
        price: '177',
        ot_price: '300'
      }, {
        img: '',
        name: '小米家用电饭煲小米家用电饭煲',
        price: '234',
        ot_price: '300'
      }]
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        var pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(pageData);
      }
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.listStyle = this.configObj.tabConfig.tabVal;
        this.src = this.configObj.logoConfig.url ? this.configObj.logoConfig.url : localStorage.getItem('mediaDomain') + '/crmebimage/presets/groupTitle.png';
      }
    }
  }
};