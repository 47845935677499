"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/echartsNew/index"));
var _index2 = require("@/utils/index");
var _dashboard = require("@/api/dashboard");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  components: {
    echartsNew: _index.default
  },
  data: function data() {
    return {
      grid: {
        xl: 4,
        lg: 8,
        md: 12,
        sm: 6,
        xs: 24
      },
      nav_list: [{
        bgColor: '#EF9C20',
        icon: 'icon-yonghuguanli',
        title: '用户管理',
        url: '/user/index',
        perms: 'platform:statistics:home:index'
      }, {
        bgColor: '#1890FF',
        icon: 'icon-shangpinguanli',
        title: '商品管理',
        url: '/product/list',
        perms: 'platform:product:page:list'
      }, {
        bgColor: '#4BCAD5',
        icon: 'icon-shanghuguanli',
        title: '商户管理',
        url: '/merchant/list',
        perms: 'platform:merchant:page:list'
      }, {
        bgColor: '#A277FF',
        icon: 'icon-a-dingdanguanli1',
        title: '订单管理',
        url: '/order/list',
        perms: 'platform:order:page:list'
      },
      // {
      //   bgColor: '#1BBE6B',
      //   icon: 'icon-xitongshezhi',
      //   title: '系统设置',
      //   url: '/operation/setting',
      //   perms: 'platform:system:config:check',
      // },
      {
        bgColor: '#1890FF',
        icon: 'icon-fenxiaoshezhi',
        title: '分销设置',
        url: '/distribution/distributionconfig',
        perms: 'platform:retail:store:config:get'
      }, {
        bgColor: '#A277FF',
        icon: 'icon-caiwuguanli',
        title: '财务管理',
        url: '/finance/closingSetting',
        perms: 'platform:retail:store:config:get'
      }, {
        bgColor: '#EF9C20',
        icon: 'icon-yihaotong',
        title: '一号通',
        url: '/operation/onePass/index',
        perms: 'platform:one:pass:send:code'
      }, {
        bgColor: '#4BCAD5',
        icon: 'icon-qiandaopeizhi',
        title: '签到配置',
        url: '/marketing/sign/config',
        perms: 'platform:sign:get:config'
      }],
      statisticData: [{
        title: '待审核商品数量',
        num: 0,
        path: '/product/list'
      }, {
        title: '待核销订单数量',
        num: 0,
        path: '/order/list'
      }, {
        title: '待发货订单数量',
        num: 0,
        path: '/order/list'
      }, {
        title: '在售商品数量',
        num: 0,
        path: '/product/list'
      }, {
        title: '待退款订单数量',
        num: 0,
        path: '/order/refund'
      }],
      optionData: {},
      applyNum: 0,
      style: {
        height: '250px'
      }
    };
  },
  computed: {
    //鉴权处理
    permList: function permList() {
      var _this = this;
      var arr = [];
      this.nav_list.forEach(function (item) {
        if (_this.checkPermi([item.perms])) {
          arr.push(item);
        }
      });
      return arr;
    }
    // businessList: function () {
    //   let arr = [];
    //   this.statisticData.forEach((item) => {
    //     //if (this.checkPermi(item.perms)) {
    //     arr.push(item);
    //     //}
    //   });
    //   return arr;
    // },
  },
  mounted: function mounted() {
    // this.getbusinessData();
  },
  methods: {
    convertToCamelCase: _index2.convertToCamelCase,
    checkPermi: _permission.checkPermi,
    navigatorTo: function navigatorTo(path) {
      this.$router.push(path);
    } // getbusinessData() {
    //   businessData().then((res) => {
    //     this.statisticData[0].num = res.notShippingOrderNum; //待审核商品数量
    //     this.statisticData[1].num = res.awaitAuditProductNum; //待核销订单数量
    //     this.statisticData[2].num = res.notShippingOrderNum; //待发货订单数量
    //     this.statisticData[3].num = res.onSaleProductNum; //在售商品数量
    //     this.statisticData[4].num = res.refundingOrderNum; //待退款订单数量
    //   });
    // },
  }
};