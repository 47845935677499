"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _merchant = require("@/api/merchant");
var _vuex = require("vuex");
var _store = _interopRequireDefault(require("@/store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'GoodList',
  props: {
    commodityType: {
      type: String,
      default: ''
    },
    handleNum: {
      type: String,
      default: ''
    },
    checked: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      templateRadio: 0,
      props: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: false,
        emitPath: false,
        multiple: false
      },
      merProps: {
        value: 'id',
        label: 'name',
        children: 'merchantList',
        expandTrigger: 'hover',
        //checkStrictly: true,
        emitPath: false,
        multiple: true
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 10,
        categoryId: '',
        name: '',
        isShow: '',
        merIds: '',
        merStars: ''
      },
      merIds: [],
      name: '',
      categoryList: [],
      imgList: [],
      multipleSelection: [],
      checkedPage: [],
      isChecked: false,
      isIndex: 0,
      checkBox: [],
      merSelect: [],
      checkedIds: [] // 订单当前页选中的数据
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    if (!_store.default.getters.adminProductClassify.length) _store.default.dispatch('product/getAdminProductClassify');
    this.$nextTick(function () {
      _this.categoryList = _store.default.getters.adminProductClassify;
    });
    this.getMerchantList();
    this.getList();
    if (this.checked.length) {
      var _this$checked = _toArray(this.checked),
        arr2 = _this$checked.slice(0);
      this.checkBox = arr2;
      this.checkedIds = arr2.map(function (item) {
        return item.id;
      });
    }
  },
  methods: {
    onInput: function onInput(e) {
      this.$forceUpdate();
    },
    // 商户列表
    getMerchantList: function getMerchantList() {
      var _this2 = this;
      (0, _merchant.merCategoryListApi)().then(function (res) {
        _this2.merSelect = res;
      });
    },
    changeType: function changeType(v) {
      this.isChecked = v;
      var index = this.checkedPage.indexOf(this.tableFrom.page);
      this.isIndex = index;
      this.checkedPage.push(this.tableFrom.page);
      if (index > -1) {
        this.checkedPage.splice(index, 1);
      }
      this.syncCheckedId(v);
    },
    changeOne: function changeOne(v, order) {
      if (v) {
        var index = this.checkedIds.indexOf(order.id);
        if (index === -1) {
          this.checkedIds.push(order.id);
          this.checkBox.push(order);
        }
      } else {
        var _index = this.checkedIds.indexOf(order.id);
        if (_index > -1) {
          this.checkedIds.splice(_index, 1);
          this.checkBox.splice(_index, 1);
        }
      }
    },
    syncCheckedId: function syncCheckedId(o) {
      var _this3 = this;
      var ids = this.tableData.data.map(function (v) {
        return v.id;
      });
      if (o) {
        this.tableData.data.forEach(function (item) {
          var index = _this3.checkedIds.indexOf(item.id);
          if (index === -1) {
            _this3.checkedIds.push(item.id);
            _this3.checkBox.push(item);
          }
        });
      } else {
        this.tableData.data.forEach(function (item) {
          var index = _this3.checkedIds.indexOf(item.id);
          if (index > -1) {
            _this3.checkedIds.splice(index, 1);
            _this3.checkBox.splice(index, 1);
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var tables = [];
      val.map(function (item) {
        tables.push({
          src: item.image,
          id: item.id
        });
      });
      this.multipleSelection = tables;
    },
    ok: function ok() {
      this.$emit('getStoreItem', this.checkBox);
    },
    getTemplateRow: function getTemplateRow(row) {
      this.$emit('getStoreItem', row);
    },
    getList: function getList(num) {
      var _this4 = this;
      this.checkedIds = [];
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.name = encodeURIComponent(this.name);
      this.tableFrom.merIds = this.merIds.toString();
      if (this.commodityType == 'seckill') {
        this.tableFrom.isSecKill = 1;
      }
      console.log(this.tableFrom, '123456');
      (0, _product.productActivityListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        // res.list.map((item) => {
        //   this.imgList.push(item.image)
        // })
        _this4.tableData.data.forEach(function (item, index) {
          _this4.checked.forEach(function (element) {
            if (Number(item.id) === Number(element.id)) {
              _this4.$nextTick(function () {
                _this4.$refs.multipleTable.toggleRowSelection(item, true);
              });
            } else if (item.id == element.productId) {
              _this4.$nextTick(function () {
                _this4.checkedIds.push(item.id);
              });
            }
          });
        });
        console.log(_this4.tableData.data, '12456', _this4.checked);
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
        _this4.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};