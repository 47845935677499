"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var merchant = _interopRequireWildcard(require("@/api/merchant"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'index',
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    merIdChecked: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    merIdChecked: function merIdChecked(n) {
      this.merIds = n;
    }
  },
  data: function data() {
    return {
      merProps: {
        value: 'id',
        label: 'name',
        children: 'merchantList',
        expandTrigger: 'hover',
        emitPath: false,
        multiple: true
      },
      merSelect: [],
      merIds: null
    };
  },
  mounted: function mounted() {
    this.merIds = this.merIdChecked;
    this.getMerList();
  },
  methods: {
    // 列表
    getMerList: function getMerList() {
      var _this = this;
      merchant.merCategoryListApi().then(function (res) {
        _this.merSelect = res;
      });
    },
    onChangeMerId: function onChangeMerId() {
      this.$emit('getMerId', this.merIds);
    }
  }
};