"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'c_foot',
  props: {
    configObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    configNme: {
      type: String,
      default: ''
    },
    index: {
      type: null
    }
  },
  components: {
    linkaddress: _linkaddress.default,
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      configData: {},
      modalPic: false,
      itemIndex: 0,
      itemChildIndex: 0,
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      },
      defaults: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.defaults = _this2.configObj;
      _this2.configData = _this2.configObj[_this2.configNme];
    });
  },
  methods: {
    linkUrl: function linkUrl(e) {
      this.configData.list[this.itemIndex].link = e;
    },
    getLink: function getLink(index) {
      this.itemIndex = index;
      this.$refs.linkaddres.dialogVisible = true;
    },
    // 点击图文封面
    modalPicTap: function modalPicTap(index, name) {
      var _this = this;
      _this.$modalUpload(function (img) {
        if (name === 'checked') {
          _this.configData.list[index].checked = img[0].sattDir;
        } else {
          _this.configData.list[index].unchecked = img[0].sattDir;
        }
      });
    },
    // 添加模块
    addMenu: function addMenu() {
      var obj = {
        checked: '',
        unchecked: '',
        name: '自定义',
        link: ''
      };
      this.configData.list.push(obj);
    },
    deleteMenu: function deleteMenu(index) {
      var _this3 = this;
      this.$confirm('是否确定删除该菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.configData.list.splice(index, 1);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
};