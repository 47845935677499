var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "editPram",
          attrs: { model: _vm.editPram, "label-width": "130px" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("product.category.editDialogConfig.nameFormItem"),
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: _vm.$t(
                      "product.category.editDialogConfig.nameRequiredMessage"
                    ),
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  maxlength: _vm.biztype.value === 1 ? 8 : 20,
                  placeholder: _vm.$t(
                    "product.category.editDialogConfig.nameFormItem"
                  ),
                },
                model: {
                  value: _vm.editPram.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value !== 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "product.category.editDialogConfig.pidFormItem"
                    ),
                  },
                },
                [
                  _c("el-cascader", {
                    ref: "cascader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: _vm.isCreate === 1,
                      options: _vm.allTreeList,
                      filterable: "",
                      props: _vm.categoryProps,
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.editPram.pid,
                      callback: function ($$v) {
                        _vm.$set(_vm.editPram, "pid", $$v)
                      },
                      expression: "editPram.pid",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("product.category.editDialogConfig.iconFormItem"),
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "upLoadPicBox",
                  on: {
                    click: function ($event) {
                      return _vm.modalPicTap("1")
                    },
                  },
                },
                [
                  _vm.editPram.icon
                    ? _c("div", { staticClass: "pictrue" }, [
                        _c("img", { attrs: { src: _vm.editPram.icon } }),
                      ])
                    : _c("div", { staticClass: "upLoad" }, [
                        _c("i", {
                          staticClass: "el-icon-camera cameraIconfont",
                        }),
                      ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("product.category.editDialogConfig.sortFormItem"),
              },
            },
            [
              _c("el-input-number", {
                attrs: { min: 0 },
                model: {
                  value: _vm.editPram.sort,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.editPram,
                      "sort",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "editPram.sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.biztype.value !== 1 &&
          _vm.biztype.value !== 3 &&
          _vm.biztype.value !== 5
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t(
                      "product.category.editDialogConfig.extraFormItem"
                    ),
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$t(
                        "product.category.editDialogConfig.extraFormItem"
                      ),
                    },
                    model: {
                      value: _vm.editPram.extra,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.editPram,
                          "extra",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "editPram.extra",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "debounceClick",
                      rawName: "v-debounceClick",
                      value: function () {
                        _vm.handlerSubmit("editPram")
                      },
                      expression:
                        "\n          () => {\n            handlerSubmit('editPram');\n          }\n        ",
                    },
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:product:category:add",
                        "platform:product:category:update",
                        "platform:category:update",
                        "platform:category:save",
                      ],
                      expression:
                        "[\n          'platform:product:category:add',\n          'platform:product:category:update',\n          'platform:category:update',\n          'platform:category:save',\n        ]",
                    },
                  ],
                  attrs: { type: "primary", loading: _vm.loadingBtn },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("operateBtn.confirm")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(" " + _vm._s(_vm.$t("operateBtn.cancel"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }