"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  shortcuts: [{
    text: '本月',
    onClick: function onClick(picker) {
      picker.$emit('pick', [new Date(), new Date()]);
    }
  }, {
    text: '今年至今',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date(new Date().getFullYear(), 0);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近六个月',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setMonth(start.getMonth() - 6);
      picker.$emit('pick', [start, end]);
    }
  }]
};