"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _utils = require("@/utils");
var _finance = require("@/api/finance");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// internationalization
//import detailsFrom from '@/views/order/orderDetail'
// 权限判断函数
var _default = exports.default = {
  filters: {
    transactionTypeFilter: function transactionTypeFilter(status) {
      var statusMap = {
        pay_order: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbs680'),
        refund_order: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbs8c0'),
        recharge_user: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbs9w0'),
        yue_pay: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbsbg0'),
        merchant_collect: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbscw0'),
        brokerage: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbsek0'),
        system: (0, _utils.getLocaleI18n)('finance.journalAccount.capitalFlow.index.5u0yqynbsg00')
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      isSpreadList: [{
        label: '',
        name: '全部'
      }, {
        label: 0,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg2')
      }, {
        label: 1,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg3')
      }, {
        label: 2,
        name: '混合模式'
      }],
      exportLoading: false,
      orderId: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        orderNo: '',
        dateLimit: '',
        isSpread: '',
        page: 1,
        limit: 20
      },
      timeVal: [],
      fromList: this.$constants.fromList,
      selectionList: [],
      ids: '',
      tableFromLog: {
        page: 1,
        limit: 10
      },
      tableDataLog: {
        data: [],
        total: 0
      },
      LogLoading: false,
      dialogVisible: false,
      evaluationStatusList: [{
        value: 1,
        label: this.$t('finance.journalAccount.capitalFlow.index.5u0yqynbshg0')
      }, {
        value: 0,
        label: this.$t('finance.journalAccount.capitalFlow.index.5u0yqynbsk00')
      }],
      cardLists: [],
      orderDatalist: null
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:finance:funds:flow'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.getList(1);
    },
    //選擇類型
    isSpreadChange: function isSpreadChange(index) {
      this.tableFrom.isSpread = index;
      console.log(this.tableFrom.isSpread);
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.getList(1);
    },
    exports: function exports() {
      var _this = this;
      this.exportLoading = true;
      (0, _finance.fundsExcelApi)(this.tableFrom).then(function (res) {
        var tempPage = window.open('', '_blank');
        tempPage.location = res.fileName;
      }).finally(function () {
        _this.exportLoading = false;
      });
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this2 = this;
      (0, _finance.capitalFlowExportApi)(this.tableFrom).then(function (res) {
        var h = _this2.$createElement;
        _this2.$msgbox({
          title: _this2.$t('finance.journalAccount.capitalFlow.index.5u0yqynbsmc0'),
          message: h('p', null, [h('span', null, _this2.$t('finance.journalAccount.capitalFlow.index.5u0yqynbso40')), h('span', {
            style: 'color: teal'
          }, _this2.$t('finance.journalAccount.capitalFlow.index.5u0yqynbspo0')), h('span', null, '"查看~ ')]),
          confirmButtonText: _this2.$t('finance.journalAccount.capitalFlow.index.5u0yqynbsr00')
        }).then(function (action) {});
      }).catch(function (res) {
        _this2.$message.error(res.message);
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList();
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _finance.capitalFlowLstApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.$message.error(res.message);
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    }
  }
};