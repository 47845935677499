"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _devise = require("@/api/devise");
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'index',
  data: function data() {
    return {
      MaDefaultID: '',
      //小程序首页ID
      grid: {
        xl: 16,
        lg: 20,
        md: 20,
        sm: 24,
        xs: 24
      },
      grid2: {
        xl: 12,
        lg: 12,
        md: 20,
        sm: 24,
        xs: 24
      },
      listActive: 0,
      tabList: [this.$t('pagediy.devise.index.5trmcttk7kk0'), this.$t('pagediy.devise.index.5trmcttk7o00'), this.$t('pagediy.devise.index.5trmcttk7kk1')],
      tableForm: {
        page: 1,
        limit: 10,
        name: ''
      },
      name: '',
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      frontDomain: '',
      perViewDia: {
        // 列表二维码预览
        visible: false,
        perViewUrl: ''
      },
      Qrcode: '',
      //小程序二维码
      // 用一个字符串来保存当前双击的是哪一个单元格
      currentCell: null
    };
  },
  mounted: function mounted() {
    //判断移动端配置地址中是否包含https://，http://
    var url = ['https://', 'http://'];
    var frontDomain = localStorage.getItem('frontDomain');
    var contains = url.some(function (item) {
      return frontDomain.includes(item);
    });
    this.frontDomain = contains ? frontDomain : "https://".concat(frontDomain);
    if ((0, _permission.checkPermi)(['platform:pagediy:list'])) this.getList();
    // this.getWechatQrcode();
    this.getQRcode();
    console.log(this.$t('pagediy.devise.index.5trmcttk7kk0'), 11111112222);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 给单元格绑定横向和竖向的index，这样就能确定是哪一个单元格
    tableCellClassName: function tableCellClassName(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 获得当前双击的单元格的横竖index，然后拼接成一个唯一字符串用于判断，并赋给currentCell
    // 拼接后类似这样："1,0","1,1",
    handleName: function handleName(row, column) {
      var _this = this;
      this.currentCell = row.index + ',' + column.index;
      // 这里必须要setTimeout，因为在点击的时候，input才刚被v-if显示出来，不然拿不到dom
      setTimeout(function () {
        // 双击后自动获得焦点
        _this.$refs[row.index + ',' + column.index].focus();
      });
    },
    // 当input失去焦点的时候，隐藏input
    hideInput: function hideInput(item) {
      var _this2 = this;
      if (!item.name) {
        return this.$message.warning(this.$t('pagediy.devise.index.5trmcttk7rc0'));
      } else {
        this.currentCell = null;
      }
      (0, _devise.pagediyUpdatenameApi)({
        id: item.id,
        name: item.name
      }).then(function (res) {
        _this2.$message.success(_this2.$t('pagediy.devise.index.5trmcttk7vc0'));
      });
    },
    //微信二维码
    getQRcode: function getQRcode() {
      document.getElementById('diyQrcode').innerHTML = '';
      new _qrcodejs.default('diyQrcode', {
        width: 120,
        height: 120,
        text: this.frontDomain
      });
    },
    //小程序二维码
    getWechatQrcode: function getWechatQrcode() {
      var _this3 = this;
      (0, _devise.wechatQrcodeApi)({
        scene: 'id=0',
        path: 'pages/index/index',
        env_version: 'trial'
      }).then(function (res) {
        _this3.Qrcode = res.code;
      });
    },
    //点击左侧菜单
    ProductNavTab: function ProductNavTab(index) {
      this.listActive = index;
    },
    //预览
    previewProtol: function previewProtol(id) {
      if (!id) return;
      this.perViewDia.perViewUrl = this.frontDomain + '?id=' + id;
      this.perViewDia.visible = true;
    },
    // 使用模板
    setHomepage: function setHomepage(id) {
      var _this4 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk7zo0')).then(function () {
        (0, _devise.pagediySetdefaultApi)(id).then(function (res) {
          _this4.$message.success(_this4.$t('pagediy.devise.index.5trmcttk85k0'));
          _this4.getList();
        });
      });
    },
    // 小程序使用模板
    setHomepageX: function setHomepageX(id) {
      var _this5 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk7zo0')).then(function () {
        (0, _devise.pagediySetdefaultApiX)(id).then(function (res) {
          _this5.$message.success(_this5.$t('pagediy.devise.index.5trmcttk85k0'));
          _this5.getList();
        });
      });
    },
    // DIY 编辑首页
    handlerDiyHomePage: function handlerDiyHomePage() {
      var _this6 = this;
      (0, _devise.pagediyGetSetHome)().then(function (homePageId) {
        _this6.handlerEditDiyPage(homePageId);
      });
    },
    // DIY 小程序编辑首页
    handlerDiyHomePageX: function handlerDiyHomePageX() {
      var _this7 = this;
      (0, _devise.pagediyGetSetHomeX)().then(function (homePageId) {
        _this7.handlerEditDiyPage(homePageId);
      });
    },
    // 添加
    handlerEditDiyPage: function handlerEditDiyPage(id) {
      var _this$$router$resolve = this.$router.resolve({
          path: "/page/design/creatDevise/".concat(id)
        }),
        href = _this$$router$resolve.href;
      window.open(href);
    },
    getList: function getList(num) {
      var _this8 = this;
      this.listLoading = true;
      this.tableForm.page = num ? num : this.tableForm.page;
      this.tableForm.name = encodeURIComponent(this.name);
      (0, _devise.pagediyListApi)(this.tableForm).then(function (res) {
        _this8.listLoading = false;
        _this8.tableData.data = res.list;
        _this8.tableData.total = res.total;
      }).then(function (res) {
        _this8.listLoading = false;
      });
      var obj = {
        limit: 999,
        name: encodeURIComponent(this.name)
      };
      (0, _devise.pagediyListApi)(obj).then(function (res) {
        _this8.MaDefaultID = res.list.filter(function (item) {
          return item.isMaDefault == 1;
        })[0].id;
        console.log(_this8.MaDefaultID);
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this9 = this;
      this.$modalSure(this.$t('pagediy.devise.index.5trmcttk88k0')).then(function () {
        (0, _devise.pagediyDeleteApi)({
          id: id
        }).then(function (res) {
          _this9.$message.success(_this9.$t('pagediy.devise.index.5trmcttk8cw0'));
          if (_this9.tableData.data.length === 1 && _this9.tableForm.page > 1) _this9.tableForm.page = _this9.tableForm.page - 1;
          _this9.getList();
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableForm.limit = val;
      this.getList();
    },
    pageChange: function pageChange(val) {
      this.tableForm.page = val;
      this.getList();
    }
  }
};