"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'summaryCapitalFlow',
  data: function data() {
    return {
      timeVal: [],
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        dateLimit: ''
      },
      dialogVisible: false,
      accountDetails: {},
      fromList: this.$constants.fromList
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:finance:summary:financial:statements'])) this.getList(1);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.getList(1);
    },
    handleClick: function handleClick() {
      this.tableFrom.dateLimit = '';
      this.timeVal = [];
      this.getList(1);
    },
    onDetails: function onDetails(date) {
      this.dialogVisible = true;
      this.accountDetails = date;
    },
    seachList: function seachList() {
      this.handleClose();
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _finance.statementsApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure().then(function () {
        storeApi.brandDeleteApi(id).then(function (res) {
          _this2.$message.success(_this2.$t('finance.journalAccount.summaryCapitalFlow.index.5u0yshirm2o0'));
          _this2.$store.commit('merchant/SET_MerchantClassify', []);
          _this2.getList(1);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this3 = this;
      activityApi.activitySwitchApi(row.id).then(function (res) {
        _this3.$message.success(_this3.$t('finance.journalAccount.summaryCapitalFlow.index.5u0yshirm400'));
        _this3.getList();
      });
    }
  }
};