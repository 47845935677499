"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var storeApi = _interopRequireWildcard(require("@/api/product.js"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "edit"
  props: {
    prent: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object
    },
    biztype: {
      type: Object,
      required: true
    },
    allTreeList: {
      type: Array
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      constants: this.$constants,
      editPram: {
        icon: null,
        name: null,
        pid: null,
        sort: 0,
        // status: true,
        type: this.biztype.value,
        url: null,
        id: 0
      },
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      },
      parentOptions: []
    };
  },
  mounted: function mounted() {
    this.initEditData();
  },
  methods: {
    handleChange: function handleChange() {
      this.prent.level = this.$refs['cascader'].getCheckedNodes()[0].level;
    },
    // 点击图标
    addIcon: function addIcon() {
      var _this = this;
      _this.$modalIcon(function (icon) {
        _this.editPram.icon = icon;
      });
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      var _this = this;
      var attr = [];
      this.$modalUpload(function (img) {
        if (tit === '1' && !num) {
          _this.editPram.icon = img[0].sattDir;
        }
        if (tit === '2' && !num) {
          img.map(function (item) {
            attr.push(item.attachment_src);
            _this.formValidate.slider_image.push(item);
          });
        }
      }, tit, 'store');
    },
    close: function close() {
      this.$emit('hideEditDialog');
    },
    initEditData: function initEditData() {
      this.parentOptions = _toConsumableArray(this.allTreeList);
      this.addTreeListLabelForCasCard(this.parentOptions, 'child');
      var _this$editData = this.editData,
        icon = _this$editData.icon,
        name = _this$editData.name,
        pid = _this$editData.pid,
        sort = _this$editData.sort,
        type = _this$editData.type,
        id = _this$editData.id,
        url = _this$editData.url,
        level = _this$editData.level;
      if (this.isCreate === 1) {
        this.editPram.icon = icon;
        this.editPram.name = name;
        this.editPram.pid = pid;
        this.editPram.sort = sort;
        this.editPram.type = type;
        this.editPram.url = url;
        this.editPram.id = id;
        this.editPram.level = level;
      } else {
        this.editPram.pid = this.prent.id;
        this.editPram.type = this.biztype.value;
        this.editPram.level = parseInt(this.prent.level) + 1;
      }
    },
    addTreeListLabelForCasCard: function addTreeListLabelForCasCard(arr, child) {
      var _this2 = this;
      arr.forEach(function (item) {
        _this2.treeListCheckLevelLT3ForDisabled(item.children);
      });
    },
    treeListCheckLevelLT3ForDisabled: function treeListCheckLevelLT3ForDisabled(children) {
      var _this3 = this;
      if (!children) return;
      children.forEach(function (j) {
        if (j.level >= 3) {
          j.disabled = true;
        } else _this3.treeListCheckLevelLT3ForDisabled(j.children);
      });
    },
    handlerSubmit: function handlerSubmit(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (!valid) return;
        _this4.handlerSaveOrUpdate(_this4.isCreate === 0);
      });
    },
    handlerSaveOrUpdate: function handlerSaveOrUpdate(isSave) {
      var _this5 = this;
      if (isSave) {
        // this.editPram.pid = this.prent.id
        this.loadingBtn = true;
        if (this.biztype.value !== 2) {
          if (this.editPram.pid === 0) this.editPram.level = 1;
          if (!this.editPram.level) this.editPram.level = parseInt(this.prent.level) + 1;
          storeApi.productCategoryAddApi(this.editPram).then(function (data) {
            _this5.$emit('hideEditDialog');
            _this5.$message.success(_this5.$t('product.category.editDialogConfig.createSuccTips'));
            _this5.$store.commit('product/SET_AdminProductClassify', []);
            _this5.loadingBtn = false;
          }).catch(function () {
            _this5.loadingBtn = false;
          });
        } else {
          articleApi.articleCategoryAddApi(this.editPram).then(function (data) {
            _this5.$emit('hideEditDialog');
            _this5.$message.success(_this5.$t('product.category.editDialogConfig.createSuccTips'));
            localStorage.clear('articleClass');
            _this5.loadingBtn = false;
          }).catch(function () {
            _this5.loadingBtn = false;
          });
        }
      } else {
        this.loadingBtn = true;
        if (this.biztype.value !== 2) {
          if (this.editPram.pid === this.editData.id) return this.$message.warning(this.$t('product.category.editDialogConfig.createWarningTips'));
          storeApi.productCategoryUpdateApi(this.editPram).then(function (data) {
            _this5.$emit('hideEditDialog');
            _this5.$message.success(_this5.$t('product.category.editDialogConfig.createSuccTips'));
            _this5.$store.commit('product/SET_AdminProductClassify', []);
            _this5.loadingBtn = false;
          }).catch(function () {
            _this5.loadingBtn = false;
          });
        } else {
          this.editPram.pid = Array.isArray(this.editPram.pid) ? this.editPram.pid[0] : this.editPram.pid;
          articleApi.articleCategoryUpdateApi(this.editPram).then(function (data) {
            _this5.$emit('hideEditDialog');
            _this5.$message.success(_this5.$t('product.category.editDialogConfig.createSuccTips'));
            localStorage.clear('articleClass');
            _this5.loadingBtn = false;
          }).catch(function () {
            _this5.loadingBtn = false;
          });
        }
      }
    }
  }
};