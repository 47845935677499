"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'wechat_live',
  cname: '小程序直播',
  configName: 'c_wechat_live',
  type: 1,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'liveBroadcast',
  // 外面匹配名称
  icon: 't-icon-zujian-xiaochengxuzhibo',
  props: {
    index: {
      type: null,
      default: -1
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), (0, _vuex.mapGetters)(['mediaDomain'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: this.configObj.mbConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + '10px' + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //内容圆角
    contentStyle: function contentStyle() {
      return {
        'border-radius': this.configObj.contentStyle.val ? this.configObj.contentStyle.val + 'px' : '0'
      };
    },
    //更多颜色
    moreColor: function moreColor() {
      return {
        color: this.configObj.moreColor.color[0].item
      };
    },
    //标题颜色
    titleColor: function titleColor() {
      return {
        color: this.configObj.titleColor.color[0].item
      };
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'liveBroadcast',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '小程序直播'
        },
        logoConfig: {
          isShow: 1,
          tabTitle: '图标设置',
          title: '上传图标',
          tips: '建议：124px*32px',
          url: localStorage.getItem('mediaDomain') + '/crmebimage/presets/zhibo.png'
        },
        titleConfig: {
          tabTitle: '标题设置',
          title: '标题内容',
          val: '精彩抢先看',
          place: '请输入标题',
          isShow: 1,
          max: 10
        },
        linkConfig: {
          title: '更多链接',
          val: '/pages/activity/liveBroadcast/index',
          place: '请选择链接',
          isShow: 1,
          max: 100
        },
        //数量
        // numConfig: {
        //   tabTitle: '显示内容',
        //   title: '展示数量',
        //   val: 3,
        //   isShow: 1,
        // },
        titleColor: {
          title: '标题颜色',
          color: [{
            item: '#999999'
          }],
          default: [{
            item: '#999999'
          }]
        },
        moreColor: {
          title: '更多按钮颜色',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        // 背景颜色
        bgColor: {
          tabTitle: '颜色设置',
          title: '背景颜色',
          color: [{
            item: 'rgba(255, 255, 255, 0)'
          }, {
            item: 'rgba(255, 255, 255, 0)'
          }],
          default: [{
            item: 'rgba(255, 255, 255, 0)'
          }, {
            item: 'rgba(255, 255, 255, 0)'
          }]
        },
        tabConfig: {
          title: '展示样式',
          tabTitle: '布局设置',
          name: 'listStyle',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '单行',
            icon: 'icon-yangshiyi'
          }, {
            val: '多行',
            icon: 'icon-yangshisan'
          }, {
            val: '双排',
            icon: 'icon-yangshier'
          }]
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        },
        contentStyle: {
          title: '图片圆角',
          name: 'contentStyle',
          val: 10,
          min: 0,
          max: 30
        },
        // 上间距
        upConfig: {
          tabTitle: '边距设置',
          title: '上边距',
          val: 10,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          title: '下边距',
          val: 10,
          min: 0
        },
        contentConfig: {
          title: '内容间距',
          val: 10,
          min: 0,
          max: 30
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 25
        },
        mbConfig: {
          title: '页面间距',
          val: 10,
          min: 0
        }
      },
      live: [{
        title: '直播中',
        name: 'playBg',
        type: 2,
        color: '',
        icon: 'iconzhibozhong',
        goods: []
      }, {
        title: '回放',
        name: 'endBg',
        type: 0,
        color: '',
        icon: 'iconyijieshu',
        goods: [{
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }]
      }, {
        title: '预告',
        name: 'notBg',
        type: 1,
        color: '',
        icon: 'iconweikaishi',
        goods: [{
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }]
      }, {
        title: '直播中',
        name: 'playBg',
        type: 2,
        color: '',
        icon: 'iconzhibozhong',
        goods: [{
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }, {
          img: 'http://admin.jiefanghui.net/uploads/attach/2020/05/20200515/4f17d0727e277eb86ecc6296e96c2c09.png',
          price: '199'
        }]
      }],
      configObj: null,
      pageData: {},
      listStyle: 0,
      src: '',
      contentConfig: 0 //内容间距
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.contentConfig = this.configObj.contentConfig.val ? this.configObj.contentConfig.val + 'px' : '0';
        this.listStyle = data.tabConfig.tabVal;
        this.src = this.configObj.logoConfig.url ? this.configObj.logoConfig.url : localStorage.getItem('mediaDomain') + '/crmebimage/presets/zhibo.png';
      }
    }
  }
};