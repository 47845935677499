"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.atmosphereDelete = atmosphereDelete;
exports.atmosphereStatusApi = atmosphereStatusApi;
exports.atuosphereList = atuosphereList;
exports.atuosphereUpdateApi = atuosphereUpdateApi;
exports.birthdayPresentConfigApi = birthdayPresentConfigApi;
exports.birthdayPresentEditApi = birthdayPresentEditApi;
exports.canSendList = canSendList;
exports.canSendListApi = canSendListApi;
exports.couponDeleteApi = couponDeleteApi;
exports.couponInfoApi = couponInfoApi;
exports.couponSendApi = couponSendApi;
exports.couponStatusApi = couponStatusApi;
exports.couponUserListApi = couponUserListApi;
exports.createAtuosphere = createAtuosphere;
exports.groupActivityAddApi = groupActivityAddApi;
exports.groupActivityDetailApi = groupActivityDetailApi;
exports.groupActivityListApi = groupActivityListApi;
exports.groupActivityPage = groupActivityPage;
exports.groupAtivityDelApi = groupAtivityDelApi;
exports.groupAtivitySwitchApi = groupAtivitySwitchApi;
exports.groupAtivityUpdateApi = groupAtivityUpdateApi;
exports.groupIntervalListApi = groupIntervalListApi;
exports.groupIntervalSwitcheApi = groupIntervalSwitcheApi;
exports.groupProAuditApi = groupProAuditApi;
exports.groupProDelApi = groupProDelApi;
exports.groupProListApi = groupProListApi;
exports.integralListApi = integralListApi;
exports.liveGoodsDelApi = liveGoodsDelApi;
exports.liveGoodsInfoApi = liveGoodsInfoApi;
exports.liveGoodsListApi = liveGoodsListApi;
exports.liveGoodsReviewApi = liveGoodsReviewApi;
exports.liveGoodsSortApi = liveGoodsSortApi;
exports.liveRoomDeleteApi = liveRoomDeleteApi;
exports.liveRoomGoodsListApi = liveRoomGoodsListApi;
exports.liveRoomGoodsortApi = liveRoomGoodsortApi;
exports.liveRoomIsFeedsPublicApi = liveRoomIsFeedsPublicApi;
exports.liveRoomListApi = liveRoomListApi;
exports.liveRoomReviewApi = liveRoomReviewApi;
exports.liveRoomShowApi = liveRoomShowApi;
exports.liveRoomUpdatecommentApi = liveRoomUpdatecommentApi;
exports.liveRoomUpdatefeedpublicApi = liveRoomUpdatefeedpublicApi;
exports.liveRoomUpdatereplayApi = liveRoomUpdatereplayApi;
exports.newPeoplePresentConfigApi = newPeoplePresentConfigApi;
exports.newPeoplePresentEditApi = newPeoplePresentEditApi;
exports.platformCouponAddApi = platformCouponAddApi;
exports.platformCouponEditApi = platformCouponEditApi;
exports.platformCouponListApi = platformCouponListApi;
exports.seckillActivityAddApi = seckillActivityAddApi;
exports.seckillActivityDetailApi = seckillActivityDetailApi;
exports.seckillActivityListApi = seckillActivityListApi;
exports.seckillAtivityDelApi = seckillAtivityDelApi;
exports.seckillAtivitySwitchApi = seckillAtivitySwitchApi;
exports.seckillAtivityUpdateApi = seckillAtivityUpdateApi;
exports.seckillIntervalAddApi = seckillIntervalAddApi;
exports.seckillIntervalDeleteApi = seckillIntervalDeleteApi;
exports.seckillIntervalListApi = seckillIntervalListApi;
exports.seckillIntervalSwitcheApi = seckillIntervalSwitcheApi;
exports.seckillIntervalUpdateApi = seckillIntervalUpdateApi;
exports.seckillProAdd = seckillProAdd;
exports.seckillProAuditApi = seckillProAuditApi;
exports.seckillProDelApi = seckillProDelApi;
exports.seckillProDownApi = seckillProDownApi;
exports.seckillProListApi = seckillProListApi;
exports.seckillProSetPriceApi = seckillProSetPriceApi;
exports.selectProductList = selectProductList;
exports.signAddConfigApi = signAddConfigApi;
exports.signBaseConfigApi = signBaseConfigApi;
exports.signeDelConfigApi = signeDelConfigApi;
exports.signeEditConfigApi = signeEditConfigApi;
exports.signeGetConfigApi = signeGetConfigApi;
exports.signeRecordListApi = signeRecordListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 优惠券 添加
 * @param pram
 */
function platformCouponAddApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/add',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券 列表
 * @param pram
 */
function platformCouponListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/list',
    method: 'get',
    params: params
  });
}

/**
 * 用户管理 发送优惠券
 * @param pram
 */
function couponSendApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/batch/send',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券 可发送优惠券列表
 * @param pram
 */
function canSendListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/can/send/list',
    method: 'get',
    params: params
  });
}
function canSendList(params) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/list',
    method: 'get',
    params: params
  });
}

/**
 * 优惠券 详情
 * @param pram
 */
function couponInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/coupon/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 优惠券 修改状态
 * @param pram
 */
function couponStatusApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/coupon/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 优惠券 删除
 * @param pram
 */
function couponDeleteApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/delete',
    method: 'post',
    data: data
  });
}

/**
 * 优惠券 优惠券记录列表
 * @param pram
 */
function couponUserListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/user/list',
    method: 'get',
    params: params
  });
}

/**
 * 优惠券 编辑
 * @param pram
 */
function platformCouponEditApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/coupon/update',
    method: 'post',
    data: data
  });
}

/**
 * 新人礼 获取
 * @param pram
 */
function newPeoplePresentConfigApi() {
  return (0, _request.default)({
    url: '/admin/platform/marketing/activity/new/people/present/config',
    method: 'get'
  });
}

/**
 * 生日礼 获取
 * @param pram
 */
function birthdayPresentConfigApi() {
  return (0, _request.default)({
    url: '/admin/platform/marketing/activity/birthday/present/config',
    method: 'get'
  });
}

/**
 * 新人礼 编辑
 * @param pram
 */
function newPeoplePresentEditApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/marketing/activity/new/people/present/edit',
    method: 'post',
    data: data
  });
}

/**
 * 生日礼 编辑
 * @param pram
 */
function birthdayPresentEditApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/marketing/activity/birthday/present/edit',
    method: 'post',
    data: data
  });
}

/**
 * 积分日志 列表
 * @param pram
 */
function integralListApi(params, data) {
  return (0, _request.default)({
    url: '/admin/platform/integral/list',
    method: 'get',
    params: params,
    data: data
  });
}

/**
 * 签到 基础签到配置
 */
function signBaseConfigApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/sign/edit/base/config",
    method: 'post',
    data: data
  });
}

/**
 * 签到 新增连续签到配置
 */
function signAddConfigApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/sign/add/config",
    method: 'post',
    data: data
  });
}

/**
 * 签到 编辑连续签到配置
 */
function signeEditConfigApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/sign/edit/award/config",
    method: 'post',
    data: data
  });
}

/**
 * 签到 删除连续签到配置
 */
function signeDelConfigApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/sign/delete/config/".concat(id),
    method: 'post'
  });
}

/**
 * 签到 获取签到配置
 */
function signeGetConfigApi() {
  return (0, _request.default)({
    url: "/admin/platform/sign/get/config",
    method: 'get'
  });
}

/**
 * 签到 用户签到记录分页列表
 */
function signeRecordListApi() {
  return (0, _request.default)({
    url: "/admin/platform/sign/user/record/list",
    method: 'get'
  });
}

/**
 * 拼团活动分页列表
 */
function groupActivityPage(params) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/page",
    method: 'get',
    params: params
  });
}

/**
 * 拼团 拼团时段列表
 */
function groupIntervalListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/group/time/interval/list",
    method: 'get',
    params: params
  });
}

/**
 * 拼团 拼团时段开关
 */
function groupIntervalSwitcheApi(id) {
  return (0, _request.default)({
    url: "admin/platform/group/time/interval/switch/".concat(id),
    method: 'POST'
  });
}

/**
 * 拼团 新增拼团活动
 */
function groupActivityAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/add",
    method: 'POST',
    data: data
  });
}

/**
 * 拼团 拼团活动详情
 */
function groupActivityDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 拼团 拼团活动分页列表
 */
function groupActivityListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/page",
    method: 'get',
    params: params
  });
}

/**
 * 拼团 删除拼团活动
 */
function groupAtivityDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/delete/".concat(id),
    method: 'POST'
  });
}

/**
 * 拼团 编辑拼团活动
 */
function groupAtivityUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/update",
    method: 'POST',
    data: data
  });
}

/**
 * 拼团 拼团活动开关
 */
function groupAtivitySwitchApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/group/activity/switch/".concat(id),
    method: 'POST'
  });
}

/**
 * 拼团 拼团商品列表
 */
function groupProListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/group/product/page",
    method: 'get',
    params: params
  });
}

/**
 * 团购 团购商品删除
 */
function groupProDelApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/group/product/delete",
    method: 'POST',
    data: data
  });
}

/**
 * 拼团 拼团商品审核
 */
function groupProAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/group/product/audit",
    method: 'post',
    data: data
  });
}

/**
 * 秒杀 秒杀时段列表
 */
function seckillIntervalListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/time/interval/list",
    method: 'get',
    params: params
  });
}

/**
 * 秒杀 新增秒杀时段
 */
function seckillIntervalAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/time/interval/add",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 编辑秒杀时段
 */
function seckillIntervalUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/time/interval/update",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 删除秒杀时段
 */
function seckillIntervalDeleteApi(id) {
  return (0, _request.default)({
    url: "admin/platform/seckill/time/interval/delete/".concat(id),
    method: 'POST'
  });
}

/**
 * 秒杀 秒杀时段开关
 */
function seckillIntervalSwitcheApi(id) {
  return (0, _request.default)({
    url: "admin/platform/seckill/time/interval/switch/".concat(id),
    method: 'POST'
  });
}

/**
 * 秒杀 新增秒杀活动
 */
function seckillActivityAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/activity/add",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 秒杀活动详情
 */
function seckillActivityDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/activity/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 秒杀 秒杀活动分页列表
 */
function seckillActivityListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/activity/page",
    method: 'get',
    params: params
  });
}

/**
 * 秒杀 删除秒杀活动
 */
function seckillAtivityDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/activity/delete/".concat(id),
    method: 'POST'
  });
}

/**
 * 秒杀 编辑秒杀活动
 */
function seckillAtivityUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/activity/update",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 秒杀活动开关
 */
function seckillAtivitySwitchApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/activity/switch/".concat(id),
    method: 'POST'
  });
}

/**
 * 秒杀 秒杀商品列表
 */
function seckillProListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/product/page",
    method: 'get',
    params: params
  });
}

/**
 * 秒杀 秒杀商品删除
 */
function seckillProDelApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/product/delete",
    method: 'POST',
    data: data
  });
}

/**
 * 秒杀 秒杀商品设置活动价
 */
function seckillProSetPriceApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/product/set/price",
    method: 'post',
    data: data
  });
}

/**
 * 秒杀 秒杀商品下架
 */
function seckillProDownApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/product/down",
    method: 'post',
    data: data
  });
}

/**
 * 秒杀 秒杀商品审核
 */
function seckillProAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/product/audit",
    method: 'post',
    data: data
  });
}

/**
 * @description 氛围图 -- 选择商品列表 氛围图、活动边框公用同一接口，type传值判断
 */
function selectProductList(data) {
  return _request.default.get("marketing/spu/lst", data);
}

/**
 * @description 氛围图 -- 创建氛围图
 */
function createAtuosphere(data) {
  return _request.default.post("admin/platform/activitystyle/save", data);
}

/**
 * @description 氛围图 -- 编辑氛围图
 */
function atuosphereUpdateApi(data) {
  return _request.default.post("admin/platform/activitystyle/update", data);
}

/**
 * @description 活动样式
 */
function atuosphereList(params) {
  return (0, _request.default)({
    url: "/admin/platform/activitystyle/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 活动样式
 */
function atmosphereStatusApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/activitystyle/status",
    method: 'post',
    data: data
  });
}

/**
 * @description 活动样式
 */
function atmosphereDelete(params) {
  return (0, _request.default)({
    url: "/admin/platform/activitystyle/delete",
    method: 'get',
    params: params
  });
}

/**
 * @description 秒杀商品添加
 */
function seckillProAdd(data) {
  return (0, _request.default)({
    url: "/admin/platform/seckill/product/add",
    method: 'POST',
    data: data
  });
}

/**
 * @description 直播-商品-删除
 */
function liveGoodsDelApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/goods/delete/".concat(id),
    method: 'get'
  });
}

/**
 * @description 直播-商品-详情
 */
function liveGoodsInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/goods/info/".concat(id),
    method: 'get'
  });
}

/**
 * @description 直播-商品-分页列表
 */
function liveGoodsListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/goods/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 直播-商品-平台审核
 */
function liveGoodsReviewApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/goods/review",
    method: 'POST',
    data: data
  });
}

/**
 * @description 直播-商品-修改排序
 */
function liveGoodsSortApi(id, sort) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/goods/sort/".concat(id, "/").concat(sort),
    method: 'GET'
  });
}

/**
 * @description 直播-直播间 列表
 */
function liveRoomListApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/list",
    method: 'post',
    data: {
      pageParamRequest: {
        limit: data.limit,
        page: data.page
      },
      searchRequest: {
        reviewStatus: data.reviewStatus,
        liveStatus: data.liveStatus,
        keywords: data.keywords,
        storeShow: data.storeShow,
        merType: data.merType,
        star: data.star
      }
    }
  });
}

/**
 * 直播 直播间 删除直播室
 */
function liveRoomDeleteApi(roomId) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/delete/".concat(roomId),
    method: 'GET'
  });
}

/**
 * 直播 直播间 商品排序
 */
function liveRoomGoodsortApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/goodsort",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 关闭评论
 */
function liveRoomUpdatecommentApi(id, banComment) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/updatecomment/".concat(id, "/").concat(banComment),
    method: 'get'
  });
}

/**
 * 直播 直播间 客服管理
 */
function liveRoomUpdatefeedpublicApi(id, closeKf) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/updatefeedpublic/".concat(id, "/").concat(closeKf),
    method: 'get'
  });
}

/**
 * 直播 直播间 官方收录管理
 */
function liveRoomIsFeedsPublicApi(id, isFeedsPublic) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/updatefeedpublic/".concat(id, "/").concat(isFeedsPublic),
    method: 'get'
  });
}

/**
 * 直播 直播间 开启回放
 */
function liveRoomUpdatereplayApi(id, closeReplay) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/updatereplay/".concat(id, "/").concat(closeReplay),
    method: 'get'
  });
}

/**
 * 直播 直播间 直播间商品列表
 */
function liveRoomGoodsListApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/goodslist/".concat(id),
    method: 'get'
  });
}

/**
 * 直播 直播间 直播室审核
 */
function liveRoomReviewApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/review",
    method: 'post',
    data: data
  });
}

/**
 * 直播 直播间 显示在商城
 */
function liveRoomShowApi(id, show) {
  return (0, _request.default)({
    url: "/admin/platform/mp/live/room/showstore/".concat(id, "/").concat(show),
    method: 'get'
  });
}