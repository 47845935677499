"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.brandCatAuditResultApi = brandCatAuditResultApi;
exports.brandListApi = brandListApi;
exports.catAuditApi = catAuditApi;
exports.catListApi = catListApi;
exports.draftInfoApi = draftInfoApi;
exports.draftListApi = draftListApi;
exports.draftProductReviewApi = draftProductReviewApi;
exports.draftUpdateApi = draftUpdateApi;
exports.registerCheck = registerCheck;
exports.shopImgUploadApi = shopImgUploadApi;
exports.submitBrandAuditApi = submitBrandAuditApi;
exports.videoAddApi = videoAddApi;
exports.videoChanelRegisterApply = videoChanelRegisterApply;
exports.videoDelApi = videoDelApi;
exports.videoDownApi = videoDownApi;
exports.videoProductListApi = videoProductListApi;
exports.videoUpApi = videoUpApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 微信自定义交易组件 检查接入状态
 */
function registerCheck() {
  return (0, _request.default)({
    url: '/admin/platform/pay/component/register/register/check',
    method: 'get'
  });
}

/**
 * 视频号 草稿列表
 */
function videoChanelRegisterApply(params) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/register/apply",
    method: 'get',
    params: params
  });
}

/**
 * 视频号 草稿列表
 */
function draftListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/draftproduct/draft/list",
    method: 'get',
    params: params
  });
}

/**
 * 视频号 商品列表
 */
function videoProductListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/product/list",
    method: 'get',
    params: params
  });
}

/**
 * 视频号 类目
 */
function catListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/cat/get/list",
    method: 'get',
    params: params
  });
}

/**
 * 视频号 类目资质提交
 */
function catAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/shop/category/audit",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 草稿商品
 */
function videoAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/draftproduct/add",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 上架
 */
function videoUpApi(proId) {
  return (0, _request.default)({
    url: "/admin/pay/component/product/listing/".concat(proId),
    method: 'post'
  });
}

/**
 * 视频号 下架
 */
function videoDownApi(proId) {
  return (0, _request.default)({
    url: "/admin/pay/component/product/delisting/".concat(proId),
    method: 'post'
  });
}

/**
 * 视频号 删除
 */
function videoDelApi(proId) {
  return (0, _request.default)({
    url: "/admin/pay/component/product/delete/".concat(proId),
    method: 'post'
  });
}

/**
 * 视频号 草稿商品详情
 */
function draftInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/draftproduct/draft/get/".concat(id),
    method: 'get'
  });
}

/**
 * 视频号 草稿商品编辑
 */
function draftUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/draftproduct/update",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 草稿商品平台审核
 */
function draftProductReviewApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/draftproduct/review",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 草稿商品图片上传至微信
 */
function shopImgUploadApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/shop/img/upload",
    method: 'post',
    data: data
  });
}

/**
 * 视频号 品牌列表
 * @param data 行业参数
 * @returns {*} 查询结果
 */
function brandListApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/shop/brand/list",
    method: 'get',
    data: data
  });
}

/**
 * 视频号 品牌和类目结果查询
 * @param data 行业和类目参数
 * @returns {*} 查询结果
 */
function brandCatAuditResultApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/shop/audit/result",
    method: 'post',
    data: data
  });
}

/**
 * 提交品牌审核
 * @param data 品牌参数
 * @returns {*} 提交结果
 */
function submitBrandAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/pay/component/shop/brand/audit",
    method: 'post',
    data: data
  });
}