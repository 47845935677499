"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_comb',
  cname: '组合组件',
  icon: 't-icon-zujian-zuhezujian',
  configName: 'c_home_comb',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'homeComb',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //标签文字颜色
    textColor: function textColor() {
      return this.configObj.fontColor.color[0].item;
    },
    //选中颜色
    checkColor: function checkColor() {
      return {
        background: this.configObj.checkColor.color[0].item
      };
    },
    //指示器样式
    dotStyle: function dotStyle() {
      return [{
        padding: '0 22px'
      }, {
        'justify-content': this.configObj.txtStyle.tabVal === 1 ? 'center' : this.configObj.txtStyle.tabVal === 2 ? 'flex-end' : 'flex-start'
      }];
    },
    //搜索框样式
    contentStyle: function contentStyle() {
      return [{
        'border-radius': this.configObj.contentStyle.val ? this.configObj.contentStyle.val + 'px' : '0'
      }, {
        background: this.configObj.borderColor.color[0].item
      }, {
        color: this.configObj.textColor.color[0].item
      }, {
        'text-align': this.configObj.textPosition.list[this.configObj.textPosition.tabVal].style
      }];
    },
    //轮播图圆角
    //内容圆角
    contentStyleBanner: function contentStyleBanner() {
      return [{
        'border-radius': this.configObj.contentStyleBanner.val ? this.configObj.contentStyleBanner.val + 'px' : '0'
      }];
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'homeComb',
        timestamp: this.num,
        setUp: {
          cname: '组合组件'
        },
        tabConfig: {
          title: '选择组件',
          tabTitle: '设置内容',
          tabVal: 0,
          type: 0,
          isShow: 1,
          list: [{
            val: '搜索框',
            icon: 'icon-zuhe-sousuokuang',
            count: 1
          }, {
            val: '选项卡',
            icon: 'icon-zuhe-fenlei',
            count: 2
          }, {
            val: '轮播图',
            icon: 'icon-zuhe-lunbotu',
            count: 3
          }]
        },
        hotWords: {
          title: '搜索热词',
          tabTitle: '搜索热词',
          tips: '最多可设置20个热词，鼠标拖拽左侧圆点可调整热词顺序',
          list: [{
            val: '兰蔻小黑瓶'
          }]
        },
        placeWords: {
          title: '提示文字',
          tabTitle: '提示文字设置',
          value: '搜索商品名称',
          isShow: 1
        },
        titleConfig: {
          title: '切换时间',
          val: 3,
          place: '设置搜索热词显示时间',
          max: 100,
          unit: '秒',
          isShow: 1
        },
        searConfig: {
          title: '选择模板',
          tabTitle: '布局设置',
          tabVal: 1,
          isShow: 1,
          list: [{
            val: '通栏',
            icon: 'icon-tonglan',
            count: 1
          }, {
            val: 'logo',
            icon: 'icon-logo',
            count: 2
          }]
        },
        logoConfig: {
          isShow: 1,
          tabTitle: '设置logo',
          title: '上传图标',
          tips: '建议上传大小：宽138px，高48px',
          header: '设置logo',
          url: localStorage.getItem('mediaDomain') + '/crmebimage/presets/shoplogo.png'
        },
        textPosition: {
          title: '文本位置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '居左',
            style: 'left',
            icon: 'icon-juzuo'
          }, {
            val: '居中',
            style: 'center',
            icon: 'icon-juzhong'
          }, {
            val: '居右',
            style: 'right',
            icon: 'icon-juyou'
          }]
        },
        // 框体颜色
        borderColor: {
          title: '框体颜色',
          color: [{
            item: '#fff'
          }],
          default: [{
            item: '#fff'
          }]
        },
        textColor: {
          tabTitle: '样式设置',
          title: '文字颜色',
          default: [{
            item: '#303133'
          }],
          color: [{
            item: '#303133'
          }]
        },
        //分类设置
        listConfig: {
          title: '鼠标拖拽左侧圆点可调整选项卡顺序',
          tabTitle: '选项卡设置',
          max: 10,
          list: [{
            title: '精选',
            val: '',
            type: 0,
            url: ''
          }, {
            title: '靓丽美妆',
            val: '',
            type: 0,
            url: ''
          }]
        },
        fontColor: {
          tabTitle: '样式设置',
          title: '文字颜色',
          default: [{
            item: '#303133'
          }],
          color: [{
            item: '#303133'
          }]
        },
        checkColor: {
          title: '选中颜色',
          name: 'checkColor',
          color: [{
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }]
        },
        contentStyle: {
          title: '内容圆角',
          name: 'contentStyle',
          val: 7,
          min: 0,
          max: 30
        },
        contentStyleBanner: {
          title: '内容圆角',
          name: 'contentStyleBanner',
          val: 7,
          min: 0,
          max: 30
        },
        // mbConfig: {
        //   title: '顶部间距',
        //   val: 10,
        //   min: 0,
        // },
        // 轮播图 图片列表
        swiperConfig: {
          tabTitle: '内容设置',
          tips: '最多可添加10张图片，建议宽度750*332px；鼠标拖拽左侧圆点可调整图片顺序',
          title: '最多可添加10张图片，建议宽度750*332px；鼠标拖拽左侧圆点可调整图片顺序',
          maxList: 10,
          isSmall: true,
          list: [{
            img: '',
            info: [{
              title: '标题',
              value: '',
              tips: '选填，不超过10个字',
              max: 10
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100
            }]
          }]
        },
        // 指示器颜色
        docColor: {
          title: '指示器颜色',
          name: 'docColor',
          color: [{
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }]
        },
        // 左右间距
        // lrConfig: {
        //   title: '左右边距',
        //   val: 10,
        //   min: 0,
        // },
        // 轮播图点样式
        docConfig: {
          cname: 'swiper',
          title: '指示器样式',
          tabTitle: '样式设置',
          isShow: 1,
          tabVal: 0,
          list: [{
            val: '圆形',
            icon: 'icon-yuandian'
          }, {
            val: '直线',
            icon: 'icon-xiantiao'
          }, {
            val: '无指示器',
            icon: 'icon-buxianshi'
          }]
        },
        txtStyle: {
          title: '指示器位置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '居左',
            icon: 'icon-juzuo'
          }, {
            val: '居中',
            icon: 'icon-juzhong'
          }, {
            val: '居右',
            icon: 'icon-juyou'
          }]
        }
      },
      // tabVal: '',
      pageData: {},
      logoUrl: '',
      hotWord: '',
      imgSrc: '',
      style: 0,
      list: [],
      curIndex: 0,
      bgUrl: '',
      docType: 0,
      configObj: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    getConfig: function getConfig(data) {},
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.list = data.listConfig.list;
        this.logoUrl = data.logoConfig.url ? data.logoConfig.url : localStorage.getItem('mediaDomain') + '/crmebimage/presets/shoplogo.png';
        this.docType = data.docConfig.tabVal;
        this.hotWord = data.hotWords.list.length > 0 ? data.hotWords.list[0].val : data.placeWords.val;
        this.imgSrc = data.swiperConfig.list.length ? data.swiperConfig.list[0].img : '';
        //this.style = data.tabConfig.tabVal;
      }
    }
  }
};