"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoChannel = require("@/api/videoChannel");
var _permission = require("@/utils/permission");
var _info = _interopRequireDefault(require("../info"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'videoList',
  data: function data() {
    return {
      isAtud: false,
      productId: 0,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        proId: '',
        search: ''
      },
      search: ''
    };
  },
  components: {
    infoFrom: _info.default
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:pay:component:product:draft:list'])) this.getList();
    if (!JSON.parse(sessionStorage.getItem('videoCategory'))) this.getCatList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 类目；
    getCatList: function getCatList() {
      var _this = this;
      (0, _videoChannel.catListApi)().then(function (res) {
        _this.options = res;
        sessionStorage.setItem('videoCategory', JSON.stringify(res));
      });
    },
    subSuccess: function subSuccess() {
      this.getList('');
    },
    // 审核
    handleAudit: function handleAudit(id, c) {
      this.productId = id;
      this.$refs.infoFrom.dialogVisible = true;
      this.isAtud = c;
      this.$refs.infoFrom.getInfo(id);
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.search = encodeURIComponent(this.search);
      (0, _videoChannel.draftListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};