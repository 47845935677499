"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.couponCodeExport = couponCodeExport;
exports.deleteCouponCodeByCCids = deleteCouponCodeByCCids;
exports.download = download;
exports.insertCouponCode = insertCouponCode;
exports.selectCouponCodeByCcid = selectCouponCodeByCcid;
exports.selectCouponCodeList = selectCouponCodeList;
exports.updateCouponCode = updateCouponCode;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//券碼列表
function selectCouponCodeList(data) {
  return (0, _request.default)({
    url: 'admin/platform/couponCode/list',
    method: 'GET',
    params: data
  });
}

//券碼詳情
function selectCouponCodeByCcid(data) {
  return (0, _request.default)({
    url: 'admin/platform/couponCode/detail',
    method: 'POST',
    data: data
  });
}

//券碼導出
function couponCodeExport(data) {
  return (0, _request.default)({
    url: 'admin/platform/couponCode/export',
    method: 'POST',
    data: data
  });
}

//券碼添加
function insertCouponCode(data) {
  return (0, _request.default)({
    url: 'admin/platform/couponCode/insert',
    method: 'POST',
    data: data
  });
}

//券碼編輯
function updateCouponCode(data) {
  return (0, _request.default)({
    url: 'admin/platform/couponCode/update',
    method: 'POST',
    data: data
  });
}

//券碼刪除
function deleteCouponCodeByCCids(data) {
  return (0, _request.default)({
    url: 'admin/platform/couponCode/delete',
    method: 'POST',
    data: data
  });
}

// 通用下载方法
function download(fileName) {
  window.location.href = process.env.VUE_APP_BASE_API + "/common/download?fileName=" + encodeURI(fileName) + "&delete=" + true;
}