"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'search_box',
  cname: '搜索框',
  icon: 't-icon-zujian-sousuokuang',
  configName: 'c_search_box',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'headerSerch',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: 0 + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: '12px' + ' ' + '12px' + ' ' + '12px'
      }];
    },
    //内容圆角
    contentStyle: function contentStyle() {
      return [{
        'border-radius': this.configObj.contentStyle.val ? this.configObj.contentStyle.val + 'px' : '0'
      }, {
        background: this.configObj.borderColor.color[0].item
      }, {
        color: this.configObj.textColor.color[0].item
      }, {
        'text-align': this.configObj.textPosition.list[this.configObj.textPosition.tabVal].style
      }];
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'headerSerch',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '搜索框'
        },
        textPosition: {
          title: '文本位置',
          tabTitle: '文本位置',
          name: 'textPosition',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '居左',
            icon: 'icon-juzuo',
            style: 'left'
          }, {
            val: '居中',
            icon: 'icon-juzhong',
            style: 'center'
          }]
        },
        bgStyle: {
          title: '背景圆角',
          tabTitle: '圆角设置',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        },
        contentStyle: {
          title: '内容圆角',
          name: 'contentStyle',
          val: 15,
          min: 0,
          max: 30
        },
        // 背景颜色
        bgColor: {
          title: '背景颜色',
          tabTitle: '颜色设置',
          color: [{
            item: '#E93323'
          }, {
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }, {
            item: '#E93323'
          }]
        },
        // 框体颜色
        borderColor: {
          title: '框体颜色',
          color: [{
            item: '#fff'
          }],
          default: [{
            item: '#fff'
          }]
        },
        textColor: {
          title: '输入文字颜色',
          default: [{
            item: '#303133'
          }],
          color: [{
            item: '#303133'
          }]
        },
        hotWords: {
          title: '搜索热词',
          tabTitle: '搜索热词',
          tips: '最多可设置20个热词，鼠标拖拽左侧圆点可调整热词顺序',
          list: [{
            val: '兰蔻小黑瓶'
          }]
        },
        placeWords: {
          title: '提示文字',
          tabTitle: '提示文字设置',
          val: '搜索商品名称',
          isShow: 1
        },
        // prConfig: {
        //   title: '背景边距',
        //   val: 15,
        //   min: 0,
        // },
        titleConfig: {
          title: '切换时间',
          val: 3,
          place: '设置搜索热词显示时间',
          max: 100,
          unit: '秒',
          isShow: 1
        },
        // 左右间距
        lrConfig: {
          tabTitle: '边距设置',
          title: '左右边距',
          val: 12,
          min: 0,
          max: 20
        },
        searConfig: {
          title: '选择模板',
          tabTitle: '布局设置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '通栏',
            icon: 'icon-tonglan',
            count: 1
          }, {
            val: 'logo',
            icon: 'icon-logo',
            count: 2
          }]
        },
        logoConfig: {
          isShow: 0,
          title: '设置logo',
          tips: '建议上传大小：宽138px，高48px',
          header: '设置logo',
          url: ''
        }
      },
      // tabVal: '',
      bgColor: [],
      pageData: {},
      logoUrl: '',
      hotWord: '',
      slider: 0,
      prConfig: 0,
      configObj: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.bgColor = data.bgColor.color;
        this.logoUrl = data.logoConfig.url;
        this.prConfig = data.lrConfig.val;
        this.hotWord = data.hotWords.list.length > 0 ? data.hotWords.list[0].val : data.placeWords.val;
      }
    }
  }
};