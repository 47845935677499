"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("../mobileConfigRight/index.js"));
var _index2 = _interopRequireDefault(require("../rightBtn/index.vue"));
var _vuex = require("vuex");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_news_roll',
  componentsName: 'home_news_roll',
  cname: '新闻播报',
  props: {
    activeIndex: {
      type: null
    },
    num: {
      type: null
    },
    index: {
      type: null
    }
  },
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  data: function data() {
    return {
      hotIndex: 1,
      configObj: {},
      // 配置对象
      rCom: [{
        components: _index.default.c_checked_tab,
        configNme: 'setUp'
      }] // 当前页面组件
    };
  },
  watch: {
    num: function num(nVal) {
      var value = JSON.parse(JSON.stringify(this.$store.state.mobildConfig.defaultArray[nVal]));
      this.configObj = value;
    },
    configObj: {
      handler: function handler(nVal, oVal) {
        this.$store.commit('mobildConfig/UPDATEARR', {
          num: this.num,
          val: nVal
        });
      },
      deep: true
    },
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        var arr = [this.rCom[0]];
        if (nVal == 0) {
          var tempArr = [{
            components: _index.default.c_title,
            configNme: 'logoConfig'
          }, {
            components: _index.default.c_upload_img,
            configNme: 'logoConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'listConfig'
          }, {
            components: _index.default.c_product,
            configNme: 'listConfig'
          }];
          this.rCom = arr.concat(tempArr);
        } else {
          var _tempArr = [{
            components: _index.default.c_title,
            configNme: 'directionConfig'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'directionConfig'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'textPosition'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'textColor'
          }, {
            components: _index.default.c_title,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_title,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'downConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'lrConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'mbConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'bgStyle'
          }];
          this.rCom = arr.concat(_tempArr);
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var value = JSON.parse(JSON.stringify(_this.$store.state.mobildConfig.defaultArray[_this.num]));
      _this.configObj = value;
    });
  },
  methods: {
    // 获取组件参数
    getConfig: function getConfig(data) {}
  }
};