"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'nav_bar',
  configName: 'c_nav_bar',
  cname: '商品分类',
  icon: 't-icon-zujian-shangpinfenlei',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'tabNav',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //外部盒子
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: 0 + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }
      // { padding: this.configObj.upConfig.val + 'px' + ' ' + '10px' + ' ' + this.configObj.downConfig.val + 'px' },
      ];
    },
    //标签文字颜色
    fontColor: function fontColor() {
      return this.configObj.fontColor.color[0].item;
    },
    //选中颜色
    checkColor: function checkColor() {
      return {
        background: this.configObj.checkColor.color[0].item
      };
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'tabNav',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '商品分类'
        },
        listConfig: {
          title: '鼠标拖拽左侧圆点可调整选项卡顺序',
          tabTitle: '选项卡设置',
          max: 10,
          list: [{
            title: '精选',
            val: '',
            type: 0
          }, {
            title: '靓丽美妆',
            val: '',
            type: 0
          }]
        },
        status: {
          title: '开关',
          default: {
            status: false
          }
        },
        fontColor: {
          tabTitle: '标签设置',
          title: '文字颜色',
          name: 'fontColor',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        checkColor: {
          title: '选中颜色',
          name: 'checkColor',
          color: [{
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }]
        },
        // 背景颜色
        bgColor: {
          title: '背景颜色',
          tabTitle: '颜色设置',
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        // // 上间距
        // upConfig: {
        //   tabTitle: '边距设置',
        //   title: '上边距',
        //   val: 0,
        //   min: 0,
        //   max: 100,
        // },
        // // 下间距
        // downConfig: {
        //   title: '下边距',
        //   val: 0,
        //   min: 0,
        //   max: 100,
        // },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 0,
          min: 0,
          max: 30
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        }
      },
      curIndex: 0,
      pageData: {},
      configObj: null,
      list: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.list = this.configObj.listConfig.list;
      }
    }
  }
};