"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var merchant = _interopRequireWildcard(require("@/api/merchant"));
var _vuex = require("vuex");
var _keyword = _interopRequireDefault(require("../../../components/base/keyword"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'creatClassify',
  components: {
    Keyword: _keyword.default
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['merchantClassify', 'merchantType'])),
  props: {
    merId: {
      type: Number,
      default: 0
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    // dialogVisible: {
    //   type: Boolean,
    //   default: false,
    // },
    indexKey: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    var _this2 = this;
    var validatePhone = function validatePhone(rule, value, callback) {
      if (!value) {
        return callback(new Error(_this2.$t('merchant.list.creatMerchant.5thh1u6ri5g0')));
      } else {
        callback();
      }
    };
    var validateVal = function validateVal(rule, value, callback) {
      if (_this2.labelarr.length === 0) {
        callback(new Error(_this2.$t('merchant.list.creatMerchant.5thh1u6ri6w0')));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      loading: false,
      loadingFrom: false,
      rules: {
        name: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5thh1u6ret40'),
          trigger: 'blur'
        }],
        licenseNo: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5th7d23rf1c0'),
          trigger: 'blur'
        }],
        taxNo: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5thhd23rf1c0'),
          trigger: 'blur'
        }],
        categoryId: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5thh1u6rf4w0'),
          trigger: 'change'
        }],
        typeId: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5thh1u6rf9k0'),
          trigger: 'change'
        }],
        realName: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5thh1u6rf1c0'),
          trigger: 'blur'
        }],
        labelarr: [{
          required: true,
          validator: validateVal,
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          validator: validatePhone,
          trigger: 'blur'
        }],
        handlingFee: [{
          required: true,
          message: this.$t('merchant.list.creatMerchant.5thh1u6ri8o0'),
          trigger: 'blur'
        }]
        // sliderImages: [
        //   {
        //     required: true,
        //     message: this.$t('merchant.list.creatMerchant.5thh1u6ria00'),
        //     type: 'array',
        //     trigger: 'change',
        //   },
        // ],
      },
      dataForm: {
        categoryId: null,
        handlingFee: 0,
        isRecommend: false,
        isSelf: false,
        isSwitch: false,
        keywords: '',
        name: '',
        phone: '',
        licenseNo: '',
        taxNo: '',
        productSwitch: false,
        qualificationPicture: '',
        realName: '',
        remark: '',
        sort: 0,
        typeId: null,
        sliderImages: [],
        id: 0
      },
      isCn: true,
      labelarr: []
    };
  },
  watch: {
    merId: {
      handler: function handler(val) {
        if (val > 0) this.onInfo();
      },
      deep: true
    },
    'dataForm.name': function dataFormName(val) {
      var pattern = new RegExp("[\u4E00-\u9FA5]+");
      var pattern2 = new RegExp('[A-Za-z]+');
      if (pattern.test(val)) {
        this.isCn = true;
      } else if (pattern2.test(val)) {
        this.isCn = false;
      }
    }
  },
  mounted: function mounted() {
    if (!this.merchantClassify.length) this.$store.dispatch('merchant/getMerchantClassify');
    if (!this.merchantType.length) this.$store.dispatch('merchant/getMerchantType');
    if (this.merId > 0) this.onInfo();
  },
  methods: {
    getLabelarr: function getLabelarr(attr) {
      Array.isArray(attr) ? this.labelarr = attr : this.labelarr.push(attr);
    },
    onChange: function onChange(id) {
      this.dataForm.handlingFee = this.merchantClassify.find(function (item) {
        return item.id === id;
      }).handlingFee;
    },
    // 詳情
    onInfo: function onInfo() {
      var _this3 = this;
      if (!this.merchantClassify.length) this.$store.dispatch('merchant/getMerchantClassify');
      if (!this.merchantType.length) this.$store.dispatch('merchant/getMerchantType');
      this.loadingFrom = true;
      merchant.merchantDetailApi(this.merId).then(function (res) {
        _this3.$set(res, 'sliderImages', res.qualificationPicture ? JSON.parse(res.qualificationPicture) : []);
        _this3.dataForm = res;
        _this3.labelarr = res.keywords.split(',') || [];
        _this3.loadingFrom = false;
      });
    },
    // 點擊商品圖
    modalPicTap: function modalPicTap(tit) {
      var _this = this;
      this.$modalUpload(function (img) {
        if (img.length > 10) return this.$message.warning(this.$t('merchant.list.creatMerchant.5thh1u6rib80'));
        if (img.length + _this.dataForm.sliderImages.length > 10) return this.$message.warning(this.$t('merchant.list.creatMerchant.5thh1u6rib80'));
        img.map(function (item) {
          _this.dataForm.sliderImages.push(item.sattDir);
        });
      }, tit, 'store');
    },
    handleClose: function handleClose() {
      var _this4 = this;
      this.$nextTick(function () {
        _this4.$refs['dataForm'].resetFields();
      });
    },
    handleRemove: function handleRemove(i) {
      this.dataForm.sliderImages.splice(i, 1);
    },
    onClose: function onClose() {
      this.$refs['dataForm'].resetFields();
      this.loading = false;
      this.$emit('getList');
    },
    onsubmit: function onsubmit(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          if (_this5.dataForm.addressDetail) _this5.$delete(_this5.dataForm, 'addressDetail');
          _this5.dataForm.qualificationPicture = JSON.stringify(_this5.dataForm.sliderImages);
          _this5.dataForm.keywords = _this5.labelarr.join(',');
          _this5.dataForm.id === 0 ? merchant.merchantAddApi(_this5.dataForm).then(function (res) {
            _this5.$modalSure("\u65B0\u589E\u6210\u529F\uFF0C\u5546\u6237\u521D\u59CB\u5BC6\u78BC\u662F".concat(res, ",\u95DC\u9589\u5F8C\u5BC6\u78BC\u7121\u6CD5\u518D\u6B21\u67E5\u770B\u8ACB\u59A5\u5584\u4FDD\u7BA1"), false).then(function () {
              _this5.onClose();
            });
          }).catch(function () {
            _this5.loading = false;
          }) : merchant.merchantUpdateApi(_this5.dataForm).then(function (res) {
            _this5.$message.success(_this5.$t('merchant.list.creatMerchant.5thh1u6rick0'));
            _this5.onClose();
          }).catch(function () {
            _this5.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 移動
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = 'move';
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.dataForm.sliderImages);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.dataForm.sliderImages = newItems;
    }
  }
};