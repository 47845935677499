"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchantName = _interopRequireDefault(require("@/components/merchantName"));
var _product = require("@/api/product");
var _index = require("@/utils/index");
var _user = require("@/api/user");
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'StoreComment',
  filters: {
    formatDate: function formatDate(time) {
      if (time !== 0) {
        var date = new Date(time * 1000);
        return (0, _index.formatDates)(date, 'yyyy-MM-dd hh:mm');
      }
    }
  },
  components: {
    merchantName: _merchantName.default
  },
  data: function data() {
    return {
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      fromList: this.$constants.fromList,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        isReply: '',
        dateLimit: '',
        merId: '',
        nickname: '',
        productSearch: '',
        isDel: false
      },
      timeVal: [],
      loading: false,
      uids: [],
      options: [],
      timer: ''
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.getList();
    }
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:product:reply:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    remoteMethod: function remoteMethod(query) {
      var _this = this;
      if (query !== '') {
        this.loading = true;
        setTimeout(function () {
          _this.loading = false;
          (0, _user.userListApi)({
            keywords: query,
            page: 1,
            limit: 10
          }).then(function (res) {
            _this.options = res.list;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    getMerId: function getMerId(id) {
      this.tableFrom.merId = id;
      this.seachList();
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this2 = this;
      this.$modalSure().then(function () {
        (0, _product.replyDeleteApi)(id).then(function () {
          _this2.$message.success(_this2.$t('product.comment.index.5thee721g5k0'));
          _this2.tableData.data.splice(idx, 1);
        });
      });
    },
    // 列表
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      this.tableFrom.uid = this.uids.join(',');
      (0, _product.replyListApi)(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};