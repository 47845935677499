"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var schedule = _interopRequireWildcard(require("@/api/schedule.js"));
var _creatJob = _interopRequireDefault(require("./creatJob"));
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
var _utils = require("@/utils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'list',
  components: {
    creatJob: _creatJob.default
  },
  data: function data() {
    return {
      tableData: [],
      listLoading: false,
      dialogVisible: false,
      status: 0,
      jobId: 0,
      editData: {
        jobId: 0,
        beanName: '',
        cronExpression: '',
        methodName: '',
        remark: '',
        param: ''
      }
    };
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['platform:schedule:job:list'])) this.getjobList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //权限控制
    // 获取定时任务列表
    getjobList: function getjobList() {
      var _this = this;
      this.listLoading = true;
      schedule.jobList().then(function (data) {
        _this.listLoading = false;
        _this.tableData = data;
      });
    },
    submit: (0, _validate.Debounce)(function (data) {}),
    //  关闭模态框
    handleClose: function handleClose(done) {
      this.formConf.fields = [];
      this.dialogVisible = false;
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      if (row.status == 1) {
        schedule.scheduleJobSuspend(row.jobId).then(function () {
          _this2.$message.success(_this2.$t('maintain.schedule.list.index.5u0zc1j5g7s0'));
          _this2.getjobList();
        }).catch(function () {
          row.status = !row.status;
        });
      } else {
        schedule.scheduleJobStart(row.jobId).then(function () {
          _this2.$message.success(_this2.$t('maintain.schedule.list.index.5u0zc1j5g7s0'));
          _this2.getjobList();
        }).catch(function () {
          row.status = !row.status;
        });
      }
    },
    // 添加定时任务
    handleAddJob: function handleAddJob() {
      this.editData = {};
      this.dialogVisible = true;
    },
    // 修改定时任务
    onEdit: function onEdit(row) {
      this.editData = row;
      this.dialogVisible = true;
    },
    onTrig: function onTrig(row) {
      var _this3 = this;
      schedule.scheduleJobTrig(row.jobId).then(function (res) {
        _this3.$message.success(_this3.$t('maintain.schedule.list.index.5u0zc1j5ga80'));
        _this3.getjobList();
      });
    },
    closeModel: function closeModel() {
      this.dialogVisible = false;
    },
    // 删除定时任务
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure().then(function () {
        schedule.scheduleJobDelete(id).then(function (res) {
          _this4.$message.success(_this4.$t('maintain.schedule.list.index.5u0zc1j5gdc0'));
          _this4.getjobList();
        });
      });
    }
  }
};