"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _creatLevel = _interopRequireDefault(require("./creatLevel"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'Grade',
  filters: {
    typeFilter: function typeFilter(status) {
      var statusMap = {
        wechat: this.$t('user.level.list.5tqrjeq5d0o0'),
        routine: this.$t('user.level.list.5tqrjeq5d2g0'),
        h5: 'H5用户'
      };
      return statusMap[status];
    }
  },
  components: {
    creatLevel: _creatLevel.default
  },
  data: function data() {
    return {
      listLoading: true,
      userInfo: {},
      tableData: {
        data: [],
        total: 0
      },
      levelNumData: []
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:system:user:level:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.getList();
    },
    add: function add() {
      this.$refs.grades.dialogVisible = true;
      this.userInfo = {};
    },
    //编辑
    handleEdit: function handleEdit(row) {
      var _this = this;
      this.tableData.data.map(function (item) {
        _this.levelNumData.push(item.grade);
      });
      this.userInfo = JSON.parse(JSON.stringify(row));
      this.$refs.grades.dialogVisible = true;
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      this.levelNumData = [];
      (0, _user.levelListApi)().then(function (res) {
        _this2.tableData.data = res;
        _this2.listLoading = false;
      }).catch(function () {
        _this2.listLoading = false;
      });
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure(this.$t('user.level.list.5tqrjeq5d480')).then(function () {
        (0, _user.levelDeleteApi)(id).then(function () {
          _this3.$message.success(_this3.$t('user.level.list.5tqrjeq5d640'));
          _this3.tableData.data.splice(idx, 1);
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this4 = this;
      if (row.isShow == false) {
        row.isShow = !row.isShow;
        (0, _user.levelUseApi)({
          id: row.id,
          isShow: row.isShow
        }).then(function () {
          _this4.$message.success(_this4.$t('user.level.list.5tqrjeq5d7c0'));
          _this4.getList();
        }).catch(function () {
          row.isShow = !row.isShow;
        });
      } else {
        this.$modalSure(this.$t('user.level.list.5tqrjeq5d900')).then(function () {
          row.isShow = !row.isShow;
          (0, _user.levelUseApi)({
            id: row.id,
            isShow: row.isShow
          }).then(function () {
            _this4.$message.success(_this4.$t('user.level.list.5tqrjeq5d7c0'));
            _this4.getList();
          }).catch(function () {
            row.isShow = !row.isShow;
          });
        });
      }
    }
  }
};