"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      languageText: {
        zh: '简体中文',
        mo: '繁體中文'
      }
    };
  },
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('settings/setLanguage', lang);
      this.$message({
        message: '切換成功',
        type: 'success'
      });
    }
  }
};