"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var product = _interopRequireWildcard(require("@/api/product"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      keyNum: 0,
      id: 0
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:product:guarantee:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      product.guaranteeListApi().then(function (res) {
        _this2.tableData.data = res;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
        _this2.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      var _this = this;
      this.id = editDate ? editDate.id : 0;
      this.$modalParserFrom(isCreate === 0 ? this.$t('product.guarantee.editDialogConfig.title') : this.$t('product.guarantee.editDialogConfig.title2'), '保障服务', isCreate, isCreate === 0 ? {} : Object.assign({}, editDate), function (formValue) {
        _this.submit(formValue);
      }, this.keyNum += 4);
    },
    submit: function submit(formValue) {
      var _this3 = this;
      var data = {
        id: this.id,
        name: formValue.name,
        content: formValue.content,
        icon: formValue.icon,
        sort: formValue.sort
      };
      !this.id ? product.guaranteeAddApi(data).then(function (res) {
        _this3.$message.success(_this3.$t('operateBtn.operateTips'));
        _this3.$msgbox.close();
        _this3.getList();
      }).catch(function () {
        _this3.loading = false;
      }) : product.guaranteeUpdateApi(data).then(function (res) {
        _this3.$message.success(_this3.$t('operateBtn.operateTips'));
        _this3.$msgbox.close();
        _this3.getList();
      }).catch(function () {
        _this3.loading = false;
      });
    },
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this4 = this;
      this.$modalSure(this.$t('product.guarantee.delTips')).then(function () {
        product.guaranteeDeleteApi(rowData.id).then(function (data) {
          _this4.$message.success(_this4.$t('operateBtn.delSucess'));
          _this4.getList();
        });
      });
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this5 = this;
      product.guaranteeShowApi(row.id).then(function () {
        _this5.$message.success(_this5.$t('operateBtn.operateTips'));
        _this5.getList();
      });
    }
  }
};