"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _community = require("@/api/community");
var _index = require("@/utils/index");
var _user = require("@/api/user");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'StoreComment',
  filters: {
    formatDate: function formatDate(time) {
      if (time !== 0) {
        var date = new Date(time * 1000);
        return (0, _index.formatDates)(date, 'yyyy-MM-dd hh:mm');
      }
    }
  },
  data: function data() {
    return {
      loadingBtn: false,
      dialogVisible: false,
      statusList: [{
        label: '待审核',
        value: 0
      }, {
        label: '已通过',
        value: 1
      }, {
        label: '已拒绝',
        value: 2
      }],
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      fromList: this.$constants.fromList,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        page: 1,
        limit: 20,
        auditStatus: '',
        dateLimit: '',
        keywords: '',
        userName: '',
        title: ''
      },
      keywords: '',
      title: '',
      timeVal: [],
      loading: false,
      uids: [],
      options: [],
      timer: '',
      commentsInfo: {},
      //审核
      auditStatusFrom: {
        auditStatus: 1,
        id: 0,
        refusalReason: ''
      },
      id: 0,
      loadingBtnSuccess: false //审核成功通过
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.getList();
    }
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:community:reply:page:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    onClose: function onClose() {
      this.dialogVisible = false;
    },
    //审核拒绝
    cancelForm: function cancelForm() {
      var _this = this;
      this.$modalPrompt('textarea', '拒绝原因').then(function (V) {
        _this.auditStatusFrom.refusalReason = V;
        _this.onAuditSubmit();
      });
    },
    // 审核点击
    onAuditStatus: function onAuditStatus(type) {
      var _this2 = this;
      this.auditStatusFrom.auditStatus = type;
      if (type === 1) {
        this.$modalSure('审核通过该内容吗？').then(function () {
          _this2.onAuditSubmit();
        });
      } else {
        this.cancelForm();
      }
    },
    //审核提交
    onAuditSubmit: function onAuditSubmit() {
      var _this3 = this;
      this.auditStatusFrom.id = this.id;
      if (this.auditStatusFrom.auditStatus === 1) {
        this.loadingBtnSuccess = true;
      } else {
        this.loadingBtn = true;
      }
      (0, _community.communityReplyAuditApi)(this.auditStatusFrom).then(function (res) {
        _this3.$message.success('操作成功');
        _this3.dialogVisible = false;
        if (_this3.auditStatusFrom.auditStatus === 1) {
          _this3.loadingBtnSuccess = false;
        } else {
          _this3.loadingBtn = false;
        }
        _this3.getList();
      }).catch(function (res) {
        if (_this3.auditStatusFrom.auditStatus === 1) {
          _this3.loadingBtnSuccess = false;
        } else {
          _this3.loadingBtn = false;
        }
      });
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this4 = this;
      this.$modalSure('删除该评论吗？').then(function () {
        (0, _community.communityReplyDelApi)(id).then(function () {
          _this4.$message.success('删除成功');
          if (_this4.tableData.data.length === 1 && _this4.tableFrom.page > 1) _this4.tableFrom.page = _this4.tableFrom.page - 1;
          _this4.getList();
        });
      });
    },
    // 列表
    getList: function getList() {
      var _this5 = this;
      this.listLoading = true;
      this.tableFrom.title = encodeURIComponent(this.title);
      this.tableFrom.keywords = encodeURIComponent(this.keywords);
      (0, _community.communityReplyListApi)(this.tableFrom).then(function (res) {
        _this5.tableData.data = res.list;
        _this5.tableData.total = res.total;
        _this5.listLoading = false;
      }).catch(function () {
        _this5.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 审核
    onAudit: function onAudit(row) {
      this.commentsInfo = row;
      this.id = row.id;
      this.dialogVisible = true;
    }
  }
};