"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _categoryList = _interopRequireDefault(require("@/views/videoChannel/weChatcategoryAndBrand/categoryList"));
var _brandList = _interopRequireDefault(require("@/views/videoChannel/weChatcategoryAndBrand/brandList"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'weChatcategoryAndBrand',
  components: {
    BrandList: _brandList.default,
    CategoryList: _categoryList.default
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};