"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemConfig = require("@/api/systemConfig");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      active: 0,
      tabList: [{
        tit: this.$t('pagediy.theme.index.5troegx0hgo0'),
        class: 'bg1'
      }, {
        tit: this.$t('pagediy.theme.index.5troegx0iaw0'),
        class: 'bg2'
      }, {
        tit: this.$t('pagediy.theme.index.5troegx0ids0'),
        class: 'bg3'
      }, {
        tit: this.$t('pagediy.theme.index.5troegx0ifo0'),
        class: 'bg4'
      }, {
        tit: this.$t('pagediy.theme.index.5troegx0ihs0'),
        class: 'bg5'
      }, {
        tit: this.$t('pagediy.theme.index.5troegx0ijk0'),
        class: 'bg6'
      }],
      themeData: [],
      imgList: [require('@/assets/theme/theme1.png')],
      theme1: [require('@/assets/theme/theme1.png')],
      theme2: [require('@/assets/theme/theme2.png')],
      theme3: [require('@/assets/theme/theme3.png')],
      theme4: [require('@/assets/theme/theme4.png')],
      theme5: [require('@/assets/theme/theme5.png')]
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:system:config:getuniq'])) this.getSet();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    selected: function selected(index) {
      this.active = index;
      if (index == 0) {
        this.$set(this, 'imgList', this.theme1);
      } else if (index == 1) {
        this.$set(this, 'imgList', this.theme2);
      } else if (index == 2) {
        this.$set(this, 'imgList', this.theme3);
      } else if (index == 3) {
        this.$set(this, 'imgList', this.theme4);
      } else if (index == 4) {
        this.$set(this, 'imgList', this.theme5);
      }
    },
    getSet: function getSet() {
      var _this = this;
      (0, _systemConfig.configGetUniq)({
        key: 'change_color_config'
      }).then(function (res) {
        _this.active = res - 1;
        if (res == 1) {
          _this.$set(_this, 'imgList', _this.theme1);
        } else if (res == 2) {
          _this.$set(_this, 'imgList', _this.theme2);
        } else if (res == 3) {
          _this.$set(_this, 'imgList', _this.theme3);
        } else if (res == 4) {
          _this.$set(_this, 'imgList', _this.theme4);
        } else if (res == 5) {
          _this.$set(_this, 'imgList', _this.theme5);
        }
      });
    },
    saveTheme: (0, _validate.Debounce)(function () {
      var _this2 = this;
      var data = {
        key: 'change_color_config',
        value: this.active + 1
      };
      (0, _systemConfig.configSaveUniq)(data).then(function (res) {
        _this2.$message.success(_this2.$t('pagediy.theme.index.5troegx0ilc0'));
      });
    })
  }
};