"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_news_roll',
  cname: '新闻播报',
  configName: 'c_news_roll',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'news',
  // 外面匹配名称
  icon: 't-icon-zujian-xinwenbobao',
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //外部盒子
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: this.configObj.mbConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + 0 + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //文字位置
    textPosition: function textPosition() {
      return {
        'text-align': this.configObj.textPosition.list[this.configObj.textPosition.tabVal].style
      };
    },
    //文字颜色
    textColor: function textColor() {
      return {
        color: this.configObj.textColor.color[0].item
      };
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'news',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '新闻播报'
        },
        directionConfig: {
          title: '滚动方向',
          tabTitle: '文字设置',
          name: 'directionConfig',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '上下',
            icon: 'icon-shangxia',
            style: 'left'
          }, {
            val: '左右',
            icon: 'icon-zuoyou',
            style: 'center'
          }]
        },
        textPosition: {
          title: '文本位置',
          name: 'textPosition',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '居左',
            icon: 'icon-juzuo',
            style: 'left'
          }, {
            val: '居中',
            icon: 'icon-juzhong',
            style: 'center'
          }, {
            val: '居右',
            icon: 'icon-juyou',
            style: 'right'
          }]
        },
        textColor: {
          title: '文字颜色',
          color: [{
            item: '#333'
          }],
          default: [{
            item: '#333'
          }]
        },
        // 背景颜色
        bgColor: {
          title: '背景颜色',
          tabTitle: '颜色设置',
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        listConfig: {
          title: '最多可添加10个版块；鼠标拖拽左侧圆点可调整版块顺序',
          tabTitle: '公告设置',
          max: 10,
          list: [{
            status: true,
            chiild: [{
              title: '标题',
              val: '标题',
              max: 30,
              pla: '选填，不超过30个字',
              empty: true
            }, {
              title: '链接',
              val: '链接',
              max: 200,
              pla: '请输入连接',
              status: true
            }]
          }]
        },
        // 上间距
        upConfig: {
          tabTitle: '边距设置',
          title: '上边距',
          val: 0,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          title: '下边距',
          val: 0,
          min: 0,
          max: 100
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 30
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          tabTitle: '边距设置',
          val: 10,
          min: 0
        },
        logoConfig: {
          tabTitle: '图标设置',
          header: '上传图标',
          title: '上传图标',
          tips: '支持上传1张图片，建议宽度130*36px',
          isShow: 1,
          url: require('@/assets/imgs/new_header.png')
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        }
      },
      pageData: {},
      list: [],
      imgUrl: '',
      configObj: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.list = data.listConfig.list;
        this.imgUrl = data.logoConfig.url;
      }
    }
  }
};