"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddArticle = AddArticle;
exports.DelArticle = DelArticle;
exports.InfoArticle = InfoArticle;
exports.ListArticle = ListArticle;
exports.UpdateArticle = UpdateArticle;
exports.articleCategoryAddApi = articleCategoryAddApi;
exports.articleCategoryDelApi = articleCategoryDelApi;
exports.articleCategoryListApi = articleCategoryListApi;
exports.articleCategorySwitchApi = articleCategorySwitchApi;
exports.articleCategoryUpdateApi = articleCategoryUpdateApi;
exports.articleSwitchApi = articleSwitchApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 删除文章
 * @param id
 * @constructor
 */
function DelArticle(id) {
  return (0, _request.default)({
    url: "/admin/platform/article/delete/".concat(id),
    method: 'POST'
  });
}

/**
 * 文章详情
 * @param id
 * @constructor
 */
function InfoArticle(id) {
  return (0, _request.default)({
    url: "/admin/platform/article/info/".concat(id),
    method: 'get'
  });
}

/**
 * 文章列表
 * @param pram
 * @constructor
 */
function ListArticle(pram) {
  var data = {
    author: pram.author,
    cid: pram.cid,
    page: pram.page,
    limit: pram.limit,
    title: pram.title
  };
  return (0, _request.default)({
    url: '/admin/platform/article/list',
    method: 'GET',
    params: data
  });
}

/**
 * 新增文章
 * @param pram
 * @constructor
 */
function AddArticle(pram) {
  var data = {
    author: pram.author,
    cid: pram.cid,
    content: pram.content,
    cover: pram.cover,
    isBanner: pram.isBanner,
    isHot: pram.isHot,
    sort: pram.sort,
    synopsis: pram.synopsis,
    title: pram.title,
    url: pram.url
  };
  return (0, _request.default)({
    url: '/admin/platform/article/save',
    method: 'post',
    data: data
  });
}

/**
 * 更新文章
 * @param pram
 * @constructor
 */
function UpdateArticle(pram) {
  var data = {
    author: pram.author,
    cid: pram.cid,
    content: pram.content,
    cover: pram.cover,
    isBanner: pram.isBanner,
    isHot: pram.isHot,
    sort: pram.sort,
    synopsis: pram.synopsis,
    title: pram.title,
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/article/update',
    method: 'post',
    data: data
  });
}

/**
 * 新增文章分类
 * @param data
 * @constructor
 */
function articleCategoryAddApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/article/category/add',
    method: 'POST',
    data: data
  });
}

/**
 * 删除文章分类
 * @param data
 * @constructor
 */
function articleCategoryDelApi(row) {
  return (0, _request.default)({
    url: "admin/platform/article/category/delete/".concat(row.id),
    method: 'POST'
  });
}

/**
 * 文章分类分页列表
 * @constructor
 */
function articleCategoryListApi() {
  return (0, _request.default)({
    url: "admin/platform/article/category/list",
    method: 'GET'
  });
}

/**
 * 修改文章分类
 * @param data
 * @constructor
 */
function articleCategoryUpdateApi(data) {
  return (0, _request.default)({
    url: "admin/platform/article/category/update",
    method: 'POST',
    data: data
  });
}

/**
 * 文章开关
 * @param data
 */
function articleSwitchApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/article/switch/".concat(id),
    method: 'POST'
  });
}

/**
 * 文章分类开关
 * @param data
 */
function articleCategorySwitchApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/article/category/switch/".concat(id),
    method: 'POST'
  });
}