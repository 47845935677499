"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
require("core-js/modules/es6.array.copy-within");
require("core-js/modules/es6.array.fill");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.array.from");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.array.iterator");
require("core-js/modules/es6.array.of");
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.array.species");
require("core-js/modules/es6.date.to-primitive");
require("core-js/modules/es6.function.has-instance");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.map");
require("core-js/modules/es6.math.acosh");
require("core-js/modules/es6.math.asinh");
require("core-js/modules/es6.math.atanh");
require("core-js/modules/es6.math.cbrt");
require("core-js/modules/es6.math.clz32");
require("core-js/modules/es6.math.cosh");
require("core-js/modules/es6.math.expm1");
require("core-js/modules/es6.math.fround");
require("core-js/modules/es6.math.hypot");
require("core-js/modules/es6.math.imul");
require("core-js/modules/es6.math.log1p");
require("core-js/modules/es6.math.log10");
require("core-js/modules/es6.math.log2");
require("core-js/modules/es6.math.sign");
require("core-js/modules/es6.math.sinh");
require("core-js/modules/es6.math.tanh");
require("core-js/modules/es6.math.trunc");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.epsilon");
require("core-js/modules/es6.number.is-finite");
require("core-js/modules/es6.number.is-integer");
require("core-js/modules/es6.number.is-nan");
require("core-js/modules/es6.number.is-safe-integer");
require("core-js/modules/es6.number.max-safe-integer");
require("core-js/modules/es6.number.min-safe-integer");
require("core-js/modules/es6.number.parse-float");
require("core-js/modules/es6.number.parse-int");
require("core-js/modules/es6.object.assign");
require("core-js/modules/es7.object.define-getter");
require("core-js/modules/es7.object.define-setter");
require("core-js/modules/es7.object.entries");
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.object.get-own-property-descriptor");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.get-own-property-names");
require("core-js/modules/es6.object.get-prototype-of");
require("core-js/modules/es7.object.lookup-getter");
require("core-js/modules/es7.object.lookup-setter");
require("core-js/modules/es6.object.prevent-extensions");
require("core-js/modules/es6.object.is");
require("core-js/modules/es6.object.is-frozen");
require("core-js/modules/es6.object.is-sealed");
require("core-js/modules/es6.object.is-extensible");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.object.seal");
require("core-js/modules/es6.object.set-prototype-of");
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.promise");
require("core-js/modules/es7.promise.finally");
require("core-js/modules/es6.reflect.apply");
require("core-js/modules/es6.reflect.construct");
require("core-js/modules/es6.reflect.define-property");
require("core-js/modules/es6.reflect.delete-property");
require("core-js/modules/es6.reflect.get");
require("core-js/modules/es6.reflect.get-own-property-descriptor");
require("core-js/modules/es6.reflect.get-prototype-of");
require("core-js/modules/es6.reflect.has");
require("core-js/modules/es6.reflect.is-extensible");
require("core-js/modules/es6.reflect.own-keys");
require("core-js/modules/es6.reflect.prevent-extensions");
require("core-js/modules/es6.reflect.set");
require("core-js/modules/es6.reflect.set-prototype-of");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.flags");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.set");
require("core-js/modules/es6.symbol");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.string.anchor");
require("core-js/modules/es6.string.big");
require("core-js/modules/es6.string.blink");
require("core-js/modules/es6.string.bold");
require("core-js/modules/es6.string.code-point-at");
require("core-js/modules/es6.string.ends-with");
require("core-js/modules/es6.string.fixed");
require("core-js/modules/es6.string.fontcolor");
require("core-js/modules/es6.string.fontsize");
require("core-js/modules/es6.string.from-code-point");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.string.italics");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.string.link");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es7.string.pad-end");
require("core-js/modules/es6.string.raw");
require("core-js/modules/es6.string.repeat");
require("core-js/modules/es6.string.small");
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/es6.string.strike");
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.string.sup");
require("core-js/modules/es6.typed.array-buffer");
require("core-js/modules/es6.typed.int8-array");
require("core-js/modules/es6.typed.uint8-array");
require("core-js/modules/es6.typed.uint8-clamped-array");
require("core-js/modules/es6.typed.int16-array");
require("core-js/modules/es6.typed.uint16-array");
require("core-js/modules/es6.typed.int32-array");
require("core-js/modules/es6.typed.uint32-array");
require("core-js/modules/es6.typed.float32-array");
require("core-js/modules/es6.typed.float64-array");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.weak-set");
require("core-js/modules/web.timers");
require("core-js/modules/web.immediate");
require("core-js/modules/web.dom.iterable");
require("regenerator-runtime/runtime");
var _vue = _interopRequireDefault(require("vue"));
var _elementUi = _interopRequireDefault(require("element-ui"));
require("normalize.css/normalize.css");
require("./styles/element-variables.scss");
require("@/styles/index.scss");
require("@/assets/iconfont/iconfont-weapp-icon.css");
require("@/assets/iconfont/iconfont.css");
require("swiper/dist/css/swiper.css");
require("vue-ydui/dist/ydui.base.css");
var _vueLazyload = _interopRequireDefault(require("vue-lazyload"));
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _debounce = _interopRequireDefault(require("./libs/debounce.js"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
var _index2 = _interopRequireDefault(require("./components/base/index"));
var _uploadFrom = _interopRequireDefault(require("./components/uploadFrom"));
var _goodListFrom = _interopRequireDefault(require("./components/goodList/goodListFrom"));
var _couponFrom = _interopRequireDefault(require("./components/couponList/couponFrom"));
var _articleFrom = _interopRequireDefault(require("./components/articleList/articleFrom"));
var _loadScript = require("@/components/FormGenerator/utils/loadScript");
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _utils = require("@/utils");
var _plugins = _interopRequireDefault(require("./plugins"));
var _directive = _interopRequireDefault(require("./directive"));
var _index3 = _interopRequireDefault(require("./libs/index.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } // a modern alternative to CSS resets
// global css
// import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/parsing";
// 懒加载
//防抖自定义指令
_vue.default.config.devtools = true;

// internationalization
// 公共组件

// icon
// permission control
// error integralLog
// global filters

//directive

// 全局函数

_vue.default.use(_vueLazyload.default, {
  preLoad: 1.3,
  error: require('./assets/imgs/no.png'),
  loading: require('./assets/imgs/moren.jpg'),
  attempt: 1,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
});
_vue.default.use(_uploadFrom.default);
_vue.default.use(_goodListFrom.default);
_vue.default.use(_articleFrom.default);
_vue.default.use(_couponFrom.default);
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.use(_plugins.default);
_vue.default.use(_directive.default);
_vue.default.use(_index3.default);
_vue.default.use(_index2.default);
var cookieName = 'VCONSOLE';
var query = (0, _utils.parseQuery)();
var urlSpread = query['spread'];
var vconsole = query[cookieName.toLowerCase()];
var md5jiefanghui = 'b14d1e9baeced9bb7525ab19ee35f2d2'; //jiefanghui MD5 加密开启vconsole模式
var md5Unjiefanghui = '3dca2162c4e101b7656793a1af20295c'; //UN_CREMB MD5 加密关闭vconsole模式

if (vconsole !== undefined) {
  if (vconsole === md5Unjiefanghui && _jsCookie.default.has(cookieName)) _jsCookie.default.remove(cookieName);
} else vconsole = _jsCookie.default.get(cookieName);
if (vconsole !== undefined && vconsole === md5jiefanghui) {
  _jsCookie.default.set(cookieName, md5jiefanghui, 3600);
  var _module = function module() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('vconsole'));
    });
  };
  _module().then(function (Module) {
    new Module.default();
  });
}
// 自定义实现String 类型的replaceAll方法
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, 'gm'), s2);
};
// Vue.prototype.$modalCoupon = modalCoupon
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }
_vue.default.use(_elementUi.default, {
  size: 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  },
  zIndex: 3000
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
var $previewApp = document.getElementById('previewApp');
var childAttrs = {
  file: '',
  dialog: ' width="600px" class="dialog-width" v-if="visible" :visible.sync="visible" :modal-append-to-body="false" '
};
window.addEventListener('message', init, false);
function buildLinks(links) {
  var strs = '';
  links.forEach(function (url) {
    strs += "<link href=\"".concat(url, "\" rel=\"stylesheet\">");
  });
  return strs;
}
function init(event) {
  if (event.data.type === 'refreshFrame') {
    var code = event.data.data;
    var attrs = childAttrs[code.generateConf.type];
    var links = '';
    if (Array.isArray(code.links) && code.links.length > 0) {
      links = buildLinks(code.links);
    }
    $previewApp.innerHTML = "".concat(links, "<style>").concat(code.css, "</style><div id=\"app\"></div>");
    if (Array.isArray(code.scripts) && code.scripts.length > 0) {
      (0, _loadScript.loadScriptQueue)(code.scripts, function () {
        newVue(attrs, code.js, code.html);
      });
    } else {
      newVue(attrs, code.js, code.html);
    }
  }
}
function newVue(attrs, main, html) {
  // eslint-disable-next-line no-eval
  main = eval("(".concat(main, ")"));
  main.template = "<div>".concat(html, "</div>");
  new _vue.default({
    components: {
      child: main
    },
    data: function data() {
      return {
        visible: true
      };
    },
    template: "\n      <div>\n      <child ".concat(attrs, "/>\n      </div>")
  }).$mount('#app');
}
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, 'gm'), s2);
};

/**
 * 防抖 防止重复点击
 * 传参：v-debounceClick="() =>{handleFun(arg)}"
 * 不传参:v-debounceClick="handleFun"
 * delayTime:延迟的时间,只执行最后一次
 */
_vue.default.directive('debounceClick', {
  bind: function bind(el, binding, vnode, oldvnode) {},
  inserted: function inserted(el, binding) {
    var delayTime = el.getAttribute('delay-time') || 500;
    el.onclick = (0, _debounce.default)(function () {
      binding.value();
    }, delayTime);
  }
});
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});