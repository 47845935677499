"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxApi = require("@/api/wxApi");
var _auth = require("@/utils/auth");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'WechatKeyword',
  data: function data() {
    return {
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        keywords: '',
        type: ''
      },
      listLoading: false
    };
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['platform:wechat:public:keywords:reply:list'])) this.getList(1);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.getList(1);
    },
    onchangeIsShow: function onchangeIsShow(row) {
      var _this = this;
      (0, _wxApi.wechatReplyStatusApi)(row.id).then(function () {
        _this.$message.success('修改成功');
        _this.getList(1);
      }).catch(function () {
        row.status = !row.status;
      });
    },
    // 列表
    getList: function getList(num) {
      var _this2 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.listLoading = true;
      (0, _wxApi.wechatReplyListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure().then(function () {
        (0, _wxApi.wechatReplyDeleteApi)(id).then(function () {
          _this3.$message.success('删除成功');
          _this3.getList(1);
        });
      });
    }
  }
};