"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _combineEdit = _interopRequireDefault(require("./combineEdit"));
var systemGroupDataApi = _interopRequireWildcard(require("@/api/systemGroupData.js"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "combineDataList"
  components: {
    edit: _combineEdit.default
  },
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        gid: null,
        keywords: null,
        status: null,
        // 1=开启 2=关闭
        page: 1,
        pageSize: this.$constants.page.limit[0]
      },
      editDataConfig: {
        visible: false,
        isCreate: 0,
        // 0=create 1=edit
        editData: {}
      },
      formConf: {
        fields: []
      },
      dataList: {
        list: [],
        total: 0
      },
      formMark: 0
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:system:config:info'])) this.handlerGetFormConfig();
    this.listPram.gid = this.formData.id;
    if ((0, _permission.checkPermi)(['platform:system:group:data:list'])) this.handlerGetListData(this.listPram);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handlerSearch: function handlerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this = this;
      // 获取列表数据
      systemGroupDataApi.groupDataList(pram).then(function (data) {
        var _selfList = [];
        data.list.forEach(function (_lItem) {
          _lItem.value = JSON.parse(_lItem.value);
          var _fields = _lItem.value.fields;
          var _rowData = {};
          _fields.map(function (item) {
            _rowData[item.name] = item.value;
          });
          _rowData.id = _lItem.id;
          _rowData.sort = _lItem.sort;
          _rowData.status = _lItem.status;
          _selfList.push(_rowData);
        });
        _this.dataList.list = _selfList;
        _this.dataList.total = data.total;
      });
    },
    handlerGetFormConfig: function handlerGetFormConfig() {
      var _this2 = this;
      // 获取表单配置后生成table列
      var _pram = {
        id: this.formData.formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this2.formMark = parseInt(data.id);
        _this2.formConf = JSON.parse(data.content);
      });
    },
    handlerOpenEditData: function handlerOpenEditData(rowData, isCreate) {
      this.editDataConfig.editData = rowData;
      this.editDataConfig.isCreate = isCreate;
      this.editDataConfig.visible = true;
    },
    handlerHideDia: function handlerHideDia() {
      this.handlerGetListData(this.listPram);
      this.editDataConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this3 = this;
      this.$confirm('确实删除当前数据', '提示').then(function () {
        systemGroupDataApi.groupDataDelete(rowData).then(function (data) {
          _this3.$message.success('删除数据成功');
          _this3.handlerHideDia();
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};