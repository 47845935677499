"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_menu_list',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    },
    index: {
      type: null
    },
    isRub: {
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable: _vuedraggable.default,
    linkaddress: _linkaddress.default
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      gridBtn: {
        xl: 4,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 8
      },
      gridPic: {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 12,
        xs: 12
      },
      activeIndex: 0,
      indexLast: 0,
      lastObj: {},
      linkList: []
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      console.log(_this2.configObj);
      _this2.defaults = _this2.configObj;
      _this2.configData = _this2.configObj[_this2.configNme];
    });
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    }
  },
  methods: {
    //状态切换
    onchangeIsShow: function onchangeIsShow(e) {
      this.configData.list[this.activeIndex].status = e;
    },
    linkUrl: function linkUrl(e) {
      this.configData.list[this.activeIndex].info[this.indexLast].value = e;
      if (this.defaults.name == 'pictureCube') {
        this.defaults.picStyle.picList[this.defaults.picStyle.tabVal].link = e;
      }
    },
    getLink: function getLink(index, key, item) {
      this.indexLast = item.length - 1;
      if (key != item.length - 1) {
        return;
      }
      this.activeIndex = index;
      this.$refs.linkaddres.dialogVisible = true;
    },
    addBox: function addBox() {
      if (this.configData.list.length == 0) {
        this.lastObj.img = '';
        this.lastObj.info[0].value = '';
        this.lastObj.info[1].value = '';
        this.configData.list.push(this.lastObj);
      } else {
        var obj = JSON.parse(JSON.stringify(this.configData.list[this.configData.list.length - 1]));
        obj.img = '';
        obj.info[0].value = '';
        obj.info[1].value = '';
        this.configData.list.push(obj);
      }
    },
    // 点击图文封面
    modalPicTap: function modalPicTap(item, index) {
      var _this = this;
      _this.$modalUpload(function (img) {
        item.img = img[0].sattDir;
        if (_this.isRub) _this.getPic(img[0].sattDir);
      });
    },
    // 获取图片信息
    getPic: function getPic(pc) {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.configData.list[_this3.activeIndex].img = pc;
        var data = _this3.defaults.menuConfig;
        if (data && data.isCube) {
          _this3.defaults.picStyle.picList.splice(_this3.defaults.picStyle.tabVal, 1, {
            image: pc,
            link: data.list[0].info[0].value
          });
        }
      });
    },
    onBlur: function onBlur() {
      var data = this.defaults.menuConfig;
      this.defaults.picStyle.picList[this.defaults.picStyle.tabVal].link = data.list[0].info[0].value;
    },
    // 删除
    bindDelete: function bindDelete(item, index) {
      if (this.configData.list.length == 1) {
        this.lastObj = this.configData.list[0];
      }
      this.configData.list.splice(index, 1);
    }
  }
};