"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("../index.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CouponFrom',
  components: {
    articleList: _index.default
  },
  data: function data() {
    return {
      visible: false,
      callback: function callback() {},
      handle: '',
      keyNum: 0,
      couponId: [],
      userIds: ''
    };
  },
  watch: {
    // show() {
    //   this.visible = this.show
    // }
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getArticle: function getArticle(couponObj) {
      this.callback(couponObj);
      this.visible = false;
    }
  }
};