var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "divBox" }, [
    _c(
      "div",
      { staticClass: "container_box" },
      [
        _c("el-card", { staticClass: "box-card mb20" }, [
          _c("div", { staticClass: "acea-row row-center row-middle" }, [
            _c(
              "div",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    return _vm.back()
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-arrow-left" }),
                _vm._v(" "),
                _c("span", { staticClass: "pl10" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "marketing.group.groupActivity.creatGroup.5u119u9mhn00"
                      )
                    )
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "f-s-18 f-w-500 ml32 add_title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.pageType
                      ? _vm.$t(
                          "marketing.group.groupActivity.creatGroup.5u119u9mia80"
                        )
                      : _vm.title + "拼团活动"
                  ) +
                  "\n        "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "box-card box-body" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                !_vm.pageType
                  ? _c("el-tab-pane", {
                      attrs: {
                        label: _vm.$t(
                          "marketing.group.groupActivity.creatGroup.5u119u9micw0"
                        ),
                        name: "first",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "120px",
                  size: "small",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  },
                  [
                    _vm.activeName == "first" && !_vm.pageType
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u119u9mieg0"
                                ),
                                prop: "name",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  placeholder: _vm.$t(
                                    "marketing.group.groupActivity.creatGroup.5u119u9mifw0"
                                  ),
                                },
                                model: {
                                  value: _vm.ruleForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "name", $$v)
                                  },
                                  expression: "ruleForm.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u119u9mihs0"
                                ),
                                prop: "timeVal",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "value-format": "yyyy-MM-dd",
                                  align: "right",
                                  "unlink-panels": "",
                                  format: "yyyy-MM-dd",
                                  size: "small",
                                  type: "daterange",
                                  placement: "bottom-end",
                                  placeholder: _vm.$t(
                                    "marketing.group.groupActivity.creatGroup.5u119u9mijc0"
                                  ),
                                  "picker-options": _vm.pickerOptions,
                                },
                                on: { change: _vm.onchangeTime },
                                model: {
                                  value: _vm.ruleForm.timeVal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "timeVal", $$v)
                                  },
                                  expression: "ruleForm.timeVal",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.group.groupActivity.creatGroup.5u119u9mimk0"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u119u93b80"
                                ),
                                prop: "successQuota",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "controls-position": "right",
                                  min: 0,
                                  type: "number",
                                  placeholder: _vm.$t(
                                    "marketing.group.groupActivity.creatGroup.5u119u9mirk0"
                                  ),
                                  max: 99999,
                                },
                                model: {
                                  value: _vm.ruleForm.successQuota,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "successQuota",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.successQuota",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5u119u93bn0"
                                ),
                                prop: "ageing",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "controls-position": "right",
                                  min: 0,
                                  placeholder: _vm.$t(
                                    "marketing.group.groupActivity.creatGroup.5u119u9mit80"
                                  ),
                                  max: 99999,
                                  type: "number",
                                },
                                model: {
                                  value: _vm.ruleForm.ageing,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "ageing",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.ageing",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.groupActivity.creatGroup.5u119u9miv40"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5ub19u93bn0"
                                ),
                                prop: "joinQuota",
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "controls-position": "right",
                                  min: 0,
                                  max: 99999,
                                },
                                model: {
                                  value: _vm.ruleForm.joinQuota,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "joinQuota", $$v)
                                  },
                                  expression: "ruleForm.joinQuota",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.group.groupActivity.creatGroup.5u119u9miww0"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5ub19ub3bn0"
                                ),
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "controls-position": "right",
                                  min: 0,
                                  max: 99999,
                                },
                                model: {
                                  value: _vm.ruleForm.allQuota,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "allQuota", $$v)
                                  },
                                  expression: "ruleForm.allQuota",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.groupActivity.creatGroup.5u119u9miyg0"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5ub19u93bb0"
                                ),
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "controls-position": "right",
                                  min: 0,
                                  max: 99999,
                                },
                                model: {
                                  value: _vm.ruleForm.oneQuota,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "oneQuota", $$v)
                                  },
                                  expression: "ruleForm.oneQuota",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.groupActivity.creatGroup.5u119u9mj000"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5ub19u9320"
                                ),
                              },
                            },
                            [
                              _c("el-input-number", {
                                staticClass: "selWidth",
                                attrs: {
                                  disabled: _vm.pageType,
                                  "controls-position": "right",
                                  min: 0,
                                  max: 99999,
                                },
                                model: {
                                  value: _vm.ruleForm.virtualQuota,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "virtualQuota", $$v)
                                  },
                                  expression: "ruleForm.virtualQuota",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.groupActivity.creatGroup.5u119u9mj1s0"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.5ub19u93b2"
                                ),
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "selWidth",
                                  attrs: {
                                    disabled: _vm.pageType,
                                    placeholder: _vm.$t(
                                      "marketing.group.groupActivity.creatGroup.5u119u9mj4c0"
                                    ),
                                    multiple: "",
                                  },
                                  model: {
                                    value: _vm.ruleForm.proCategorylist,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "proCategorylist",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.proCategorylist",
                                  },
                                },
                                _vm._l(
                                  _vm.adminProductClassify,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id + "onl",
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                        disabled: !item.isShow,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.group.groupActivity.creatGroup.5u119u9mj5s0"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "marketing.group.groupActivity.creatGroup.52b19u93b2"
                                ),
                                prop: "merStars",
                              },
                            },
                            [
                              _c("el-rate", {
                                staticStyle: { "margin-top": "8px" },
                                attrs: { disabled: _vm.pageType },
                                model: {
                                  value: _vm.ruleForm.merStars,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "merStars", $$v)
                                  },
                                  expression: "ruleForm.merStars",
                                },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "desc mt10" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.group.groupActivity.creatGroup.5u119u9mj7w0"
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-card",
          { staticClass: "fixed-card box-card", attrs: { "dis-hover": "" } },
          [
            _c(
              "div",
              { staticClass: "acea-row row-center-wrapper" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.checkPermi([
                          "platform:group:activity:add",
                          "platform:group:activity:update",
                        ]),
                        expression:
                          "checkPermi(['platform:group:activity:add', 'platform:group:activity:update'])",
                      },
                      {
                        name: "debounceClick",
                        rawName: "v-debounceClick",
                        value: function () {
                          _vm.submitForm("ruleForm")
                        },
                        expression:
                          "\n            () => {\n              submitForm('ruleForm');\n            }\n          ",
                      },
                    ],
                    attrs: { type: "primary", size: "small" },
                  },
                  [_vm._v("保存\n        ")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }