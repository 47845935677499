var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.permList, function (item, index) {
          return _c("el-col", { key: index, staticClass: "ivu-mb" }, [
            _c(
              "div",
              [
                _c(
                  "el-card",
                  {
                    attrs: {
                      bordered: false,
                      "dis-hover": "",
                      padding: 12,
                      "body-style": { padding: "18px" },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "nav_item",
                        on: {
                          click: function ($event) {
                            return _vm.navigatorTo(item.url)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "pic_badge" }, [
                          _c("span", {
                            staticClass: "iconfont",
                            class: item.icon,
                            style: { color: item.bgColor },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-14" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "route." + _vm.convertToCamelCase(item.url)
                                )
                              )
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }