"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("../mobileConfigRight/index.js"));
var _index2 = _interopRequireDefault(require("../rightBtn/index.vue"));
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'c_home_article',
  componentsName: 'home_article',
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  props: {
    activeIndex: {
      type: null
    },
    num: {
      type: null
    },
    index: {
      type: null
    }
  },
  data: function data() {
    return {
      configObj: {},
      rCom: [{
        components: _index.default.c_checked_tab,
        configNme: 'setUp'
      }],
      categoryId: ''
    };
  },
  watch: {
    num: function num(nVal) {
      var value = JSON.parse(JSON.stringify(this.$store.state.mobildConfig.defaultArray[nVal]));
      this.configObj = value;
    },
    configObj: {
      handler: function handler(nVal, oVal) {
        this.$store.commit('mobildConfig/UPDATEARR', {
          num: this.num,
          val: nVal
        });
      },
      deep: true
    },
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        var arr = [this.rCom[0]];
        if (nVal == 0) {
          var tempArr = [{
            components: _index.default.c_title,
            configNme: 'layoutConfig'
          }, {
            components: _index.default.c_txt_tab,
            configNme: 'layoutConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'selectConfig'
          }, {
            components: _index.default.c_select,
            configNme: 'selectConfig'
          }, {
            components: _index.default.c_input_number,
            configNme: 'numConfig'
          }];
          this.rCom = arr.concat(tempArr);
        } else {
          var _tempArr = [{
            components: _index.default.c_title,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'titleColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'timeColor'
          }, {
            components: _index.default.c_title,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'downConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'contentConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'lrConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'mbConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'contentStyle'
          }];
          this.rCom = arr.concat(_tempArr);
        }
        //文章分类
        if (localStorage.getItem('articleClass')) {
          this.configObj.selectConfig.list = JSON.parse(localStorage.getItem('articleClass'));
        } else {
          if ((0, _permission.checkPermi)(['platform:article:category:list'])) this.handlerGetTreeList();
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        var value = JSON.parse(JSON.stringify(_this.$store.state.mobildConfig.defaultArray[_this.num]));
        _this.configObj = value;
      }
    });
  },
  created: function created() {},
  methods: {
    checkPermi: _permission.checkPermi,
    //文章分类
    handlerGetTreeList: function handlerGetTreeList() {
      var _this2 = this;
      articleApi.articleCategoryListApi().then(function (data) {
        var list = data.filter(function (item) {
          return item.status;
        });
        localStorage.setItem('articleClass', JSON.stringify(list));
        _this2.configObj.selectConfig.list = list;
      });
    },
    getConfig: function getConfig(data) {
      if (data.name === 'select') this.categoryId = data.values;
      if (data.name && (data.name === 'select' || data.name === 'number')) this.getArticleList();
    },
    //文章列表
    getArticleList: function getArticleList() {
      var _this3 = this;
      articleApi.ListArticle({
        author: null,
        cid: this.categoryId,
        page: 1,
        title: '',
        limit: this.configObj.numConfig.val
      }).then(function (data) {
        _this3.configObj.selectConfig.articleList = data.list;
      });
    }
  }
};