"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authInformation = require("@/api/authInformation");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'i-copyright',
  data: function data() {
    return {
      links: [
        // {
        //   title: '官网',
        //   key: '1',
        //   href: 'https://www.jiefanghui.com',
        //   blankTarget: true,
        // },
        // {
        //   title: '社区',
        //   key: '2',
        //   href: 'https://q.jiefanghui.net/?categoryId=122&sequence=0',
        //   blankTarget: true,
        // },
        // {
        //   title: '文档',
        //   key: '3',
        //   href: 'https://help.jiefanghui.net/jiefanghui_java/1748037',
        //   blankTarget: true,
        // },
      ],
      copyright: 'Copyright © 2023 西安众邦网络科技有限公司',
      copyrightNew: ''
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:copyright:get:info'])) this.getVersion();
  },
  methods: {
    getVersion: function getVersion() {
      var _this = this;
      (0, _authInformation.copyrightInfoApi)().then(function (res) {
        var data = res || {};
        _this.copyrightNew = data.companyName ? data.companyName : _this.copyright;
      });
    }
  }
};