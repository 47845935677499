var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.onClose,
        title:
          _vm.type == 1
            ? _vm.$t("marketing.seckill.seckillActivity.activity.5trgezt6xbo0")
            : _vm.$t("marketing.seckill.seckillActivity.activity.5trgezt6y7s0"),
        visible: _vm.showStatus,
        width: "470px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showStatus = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { size: "small", model: _vm.form, "label-width": "100px" },
        },
        [
          _vm.type == 1
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t(
                        "marketing.seckill.seckillActivity.activity.5trgezt6ya00"
                      ),
                      required: "",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "marketing.seckill.seckillActivity.activity.5trgezt6ybo0"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "marketing.seckill.seckillActivity.activity.5trgezt6yds0"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.type == 0
                  ? _c(
                      "el-form-item",
                      [
                        _c("el-input-number", {
                          staticClass: "input_width",
                          attrs: {
                            type: "number",
                            precision: 2,
                            min: 0,
                            max: 99999,
                            controls: false,
                          },
                          model: {
                            value: _vm.form.price,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "price", $$v)
                            },
                            expression: "form.price",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.type == 1
                  ? _c(
                      "el-form-item",
                      [
                        _c("el-input-number", {
                          staticClass: "input_width",
                          attrs: {
                            type: "number",
                            step: 1,
                            "step-strictly": "",
                            min: 0,
                            max: 100,
                            controls: false,
                          },
                          model: {
                            value: _vm.form.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "discount", $$v)
                            },
                            expression: "form.discount",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.type == 2
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t(
                        "marketing.seckill.seckillActivity.activity.5trgezt6yfo0"
                      ),
                      required: "",
                    },
                  },
                  [
                    _c("el-input-number", {
                      staticClass: "input_width",
                      attrs: {
                        step: 1,
                        "step-strictly": "",
                        type: "number",
                        min: 1,
                        max: 99999,
                      },
                      model: {
                        value: _vm.form.activity_stock,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "activity_stock", $$v)
                        },
                        expression: "form.activity_stock",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "acea-row row-right" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.onClose } },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "marketing.seckill.seckillActivity.activity.5trgezt6yhc0"
                  )
                ) +
                  "\n      " +
                  _vm._s(
                    _vm.$t(
                      "marketing.seckill.seckillActivity.activity.5trgezt6yj40"
                    )
                  )
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.confirmSet },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "marketing.seckill.seckillActivity.activity.5trgezt6ykw0"
                  )
                ) +
                  "\n      " +
                  _vm._s(
                    _vm.$t(
                      "marketing.seckill.seckillActivity.activity.5trgezt6ymo0"
                    )
                  )
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }