"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'audit',
  data: function data() {
    return {
      dialogVisible: false,
      direction: 'rtl',
      isDisabled: true,
      rules: {
        auditStatus: [{
          required: true,
          message: this.$t('merchant.application.audit.5to3biw8f8s0'),
          trigger: 'change'
        }],
        denialReason: [{
          required: true,
          message: this.$t('merchant.application.audit.5to3biw8fb40'),
          trigger: 'blur'
        }]
      },
      ruleForm: {
        denialReason: '',
        auditStatus: 2,
        id: ''
      },
      loadingBtn: false
    };
  },
  props: {
    merData: {
      type: Object,
      default: function _default() {
        return null;
      }
    },
    isSHOW: {
      type: String,
      default: function _default() {
        return '';
      }
    }
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['merchantClassify', 'merchantType'])),
  watch: {
    merData: {
      handler: function handler(val) {
        if (val.qualificationPicture) val.qualificationPictureData = JSON.parse(val.qualificationPicture);
        this.dataForm = _objectSpread({}, val);
      },
      deep: true
    }
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
      this.ruleForm = {
        denialReason: '',
        auditStatus: 2
      };
    },
    //审核拒绝
    cancelForm: function cancelForm() {
      var _this = this;
      this.$modalPrompt('textarea', this.$t('merchant.application.audit.5to3biw8fd40')).then(function (V) {
        _this.ruleForm.denialReason = V;
        _this.submit();
      });
    },
    // 审核提交
    onSubmit: function onSubmit(type) {
      var _this2 = this;
      this.ruleForm.auditStatus = type;
      if (type === 2) {
        this.$modalSure(this.$t('merchant.application.audit.5to3biw8few0')).then(function () {
          _this2.submit();
        });
      } else {
        this.cancelForm();
      }
    },
    submit: function submit() {
      var _this3 = this;
      this.loadingBtn = true;
      this.ruleForm.id = this.dataForm.id;
      (0, _merchant.merApplyAuditApi)(this.ruleForm).then(function (res) {
        _this3.loadingBtn = false;
        _this3.$modalSure("\u5BE9\u6838\u6210\u529F\uFF0C\u5BC6\u78BC\u662F".concat(res, ",\u95DC\u9589\u5F8C\u5BC6\u78BC\u7121\u6CD5\u518D\u6B21\u67E5\u770B\u8ACB\u59A5\u5584\u4FDD\u7BA1"), false).then(function () {
          _this3.dialogVisible = false;
          _this3.$emit('subSuccess');
        });
        // this.$message.success(this.$t('merchant.application.audit.5to3biw8fgc0'));
      }).catch(function (res) {
        _this3.loadingBtn = false;
      });
    }
  }
};