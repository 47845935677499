"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _OrderDetail = _interopRequireDefault(require("@/components/OrderDetail"));
var _product = require("@/api/product");
var _permission = require("@/utils/permission");
var _merchantName = _interopRequireDefault(require("@/components/merchantName"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'orderRefund',
  components: {
    detailsFrom: _OrderDetail.default,
    merchantName: _merchantName.default
  },
  data: function data() {
    return {
      RefuseVisible: false,
      RefuseData: {},
      orderNo: '',
      refundVisible: false,
      refundData: {},
      dialogVisibleJI: false,
      tableDataLog: {
        data: [],
        total: 0
      },
      tableFromLog: {
        page: 1,
        limit: 10,
        orderNo: 0
      },
      LogLoading: false,
      isCreate: 1,
      editData: null,
      dialogVisible: false,
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true,
      tableFrom: {
        refundStatus: '9',
        dateLimit: '',
        orderNo: '',
        refundOrderNo: '',
        page: 1,
        limit: 20,
        merId: 0
      },
      orderChartType: {},
      timeVal: [],
      fromList: this.$constants.fromList,
      selectionList: [],
      ids: '',
      orderids: '',
      cardLists: [],
      proType: 0,
      active: false,
      card_select_show: false,
      checkAll: false,
      checkedCities: [this.$t('order.refund.5tqsgqlcxh40'), this.$t('order.refund.5tqsgqlcxjg0'), this.$t('order.refund.5tqsgqlcxp40'), this.$t('order.refund.5tqsgqlcxm00'), this.$t('order.refund.5tqsgqlcxq80'), this.$t('order.refund.5tqsgqlcxsk0'), this.$t('order.refund.5tqsgqlcxu00')],
      columnData: [this.$t('order.refund.5tqsgqlcxh40'), this.$t('order.refund.5tqsgqlcxjg0'), this.$t('order.refund.5tqsgqlcxp40'), this.$t('order.refund.5tqsgqlcxm00'), this.$t('order.refund.5tqsgqlcxq80'), this.$t('order.refund.5tqsgqlcxsk0'), this.$t('order.refund.5tqsgqlcxu00')],
      isIndeterminate: true
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:refund:order:page:list'])) this.getList();
    if ((0, _permission.checkPermi)(['platform:refund:order:status:num'])) this.getOrderStatusNum();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getMerId: function getMerId(id) {
      this.tableFrom.merId = id;
      this.seachList();
    },
    resetFormRefundhandler: function resetFormRefundhandler() {
      this.refundVisible = false;
    },
    resetFormRefusehand: function resetFormRefusehand() {
      this.RefuseVisible = false;
    },
    resetForm: function resetForm(formValue) {
      this.dialogVisible = false;
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
    },
    // 详情
    onOrderDetails: function onOrderDetails(row) {
      this.orderNo = row.orderNo;
      this.$refs.orderDetail.getDetail(row.orderNo);
      this.$refs.orderDetail.getRefundOrderDetail(row.refundOrderNo);
      this.$refs.orderDetail.getOrderInvoiceList(row.orderNo);
      this.$refs.orderDetail.dialogVisible = true;
    },
    getDetail: function getDetail(id) {
      var _this = this;
      this.loading = true;
      (0, _order.orderDetailApi)(id).then(function (res) {
        _this.orderDatalist = res;
        _this.loading = false;
      }).catch(function () {
        _this.orderDatalist = null;
        _this.loading = false;
      });
    },
    // 订单记录
    onOrderLog: function onOrderLog(id) {
      var _this2 = this;
      this.dialogVisibleJI = true;
      this.LogLoading = true;
      this.tableFromLog.orderNo = id;
      (0, _order.orderLogApi)(this.tableFromLog).then(function (res) {
        _this2.tableDataLog.data = res.list;
        _this2.tableDataLog.total = res.total;
        _this2.LogLoading = false;
      }).catch(function () {
        _this2.LogLoading = false;
      });
    },
    pageChangeLog: function pageChangeLog(page) {
      this.tableFromLog.page = page;
    },
    handleSizeChangeLog: function handleSizeChangeLog(val) {
      this.tableFromLog.limit = val;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 备注
    onOrderMark: function onOrderMark(row) {
      var _this3 = this;
      this.$modalPrompt('textarea', this.$t('order.refund.5tqsgqlcy2s0'), row.platformRemark).then(function (V) {
        (0, _order.refundMarkApi)({
          remark: V,
          refundOrderNo: row.refundOrderNo
        }).then(function () {
          _this3.$message.success(_this3.$t('order.refund.5tqsgqlcy400'));
          _this3.getList();
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectionList = val;
      var data = [];
      this.selectionList.map(function (item) {
        data.push(item.orderNo);
      });
      this.ids = data.join(',');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList();
      this.getOrderStatusNum();
      // this.getOrderListData();
    },
    // 列表
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _order.refundListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list || [];
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
        _this4.checkedCities = _this4.$cache.local.has('order_stroge') ? _this4.$cache.local.getJSON('order_stroge') : _this4.checkedCities;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    // 获取各状态数量
    getOrderStatusNum: function getOrderStatusNum() {
      var _this5 = this;
      (0, _order.refundStatusNumApi)({
        dateLimit: this.tableFrom.dateLimit
      }).then(function (res) {
        _this5.orderChartType = res;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    exports: function exports() {
      var data = {
        dateLimit: this.tableFrom.dateLimit,
        orderNo: this.tableFrom.orderNo,
        refundStatus: this.tableFrom.status,
        type: this.tableFrom.type
      };
      (0, _product.orderExcelApi)(data).then(function (res) {
        window.open(res.fileName);
      });
    },
    renderHeader: function renderHeader(h) {
      var _this6 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, [this.$t('order.refund.5tqsgqlcxx40')]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this6.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$set(this, 'card_select_show', false);
      this.$modal.loading(this.$t('order.refund.5tqsgqlcy540'));
      this.$cache.local.setJSON('order_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    }
  }
};