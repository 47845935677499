"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoChannel = require("@/api/videoChannel");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'videoList',
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        proId: '',
        search: ''
      }
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:pay:component:product:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 上架
    upChange: function upChange(id) {
      var _this = this;
      (0, _videoChannel.videoUpApi)(id).then(function () {
        _this.$message.success('上架成功');
        _this.getList();
      });
    },
    // 下架
    downChange: function downChange(id) {
      var _this2 = this;
      (0, _videoChannel.videoDownApi)(id).then(function () {
        _this2.$message.success('下架成功');
        _this2.getList();
      });
    },
    // 订单删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure('删除吗？此操作不可逆，请谨慎操作！').then(function () {
        (0, _videoChannel.videoDelApi)(id).then(function () {
          _this3.$message.success('删除成功');
          _this3.tableData.data.splice(idx, 1);
        });
      });
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _videoChannel.videoProductListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};