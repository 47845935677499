"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  bind: function bind(el) {
    var dragDom = el.querySelector('.el-dialog');
    var lineEl = document.createElement('div');
    lineEl.style = 'width: 5px; background: inherit; height: 80%; position: absolute; right: 0; top: 0; bottom: 0; margin: auto; z-index: 1; cursor: w-resize;';
    lineEl.addEventListener('mousedown', function (e) {
      // 鼠标按下，计算当前元素距离可视区的距离
      var disX = e.clientX - el.offsetLeft;
      // 当前宽度
      var curWidth = dragDom.offsetWidth;
      document.onmousemove = function (e) {
        e.preventDefault(); // 移动时禁用默认事件
        // 通过事件委托，计算移动的距离
        var l = e.clientX - disX;
        dragDom.style.width = "".concat(curWidth + l, "px");
      };
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }, false);
    dragDom.appendChild(lineEl);
  }
};