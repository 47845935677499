"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import parser from '@/components/FormGenerator/components/parser/ZBParser'
// 权限判断函数
var _default = exports.default = {
  // components: { parser },
  data: function data() {
    return {
      formConf: {
        fields: []
      },
      loading: false,
      keyNum: 0,
      editData: {},
      formName: this.$t('商户结算设置')
    };
  },
  created: function created() {
    //this.keyNum += 1
    this.getConfigInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handlerSubmit: (0, _validate.Debounce)(function (formValue) {
      var _this = this;
      if ((0, _permission.checkPermi)(['platform:finance:merchant:closing:config:edit'])) {
        (0, _finance.closingEditApi)(formValue).then(function (res) {
          _this.$message.success(_this.$t('finance.setting.index.5u0ybfq5yjc0'));
          _this.getConfigInfo();
        }).catch(function () {
          _this.loading = false;
        });
      } else {
        this.$message.warning(this.$t('finance.setting.index.5u0ybfq5ys40'));
      }
    }),
    // 获取转账配置信息
    getConfigInfo: function getConfigInfo() {
      var _this2 = this;
      this.keyNum += 1;
      this.loading = true;
      (0, _finance.closingConfigApi)().then(function (res) {
        for (var key in res) {
          res[key] = parseFloat(res[key]);
        }
        _this2.editData = res;
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    }
  }
};