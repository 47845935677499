"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoChannel = require("@/api/videoChannel");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 开通视频号步骤页面
// 权限判断函数
var _default = exports.default = {
  name: 'index',
  data: function data() {
    return {
      // 申请接入
      toRegister: {
        errcode: 0,
        // 1040002 已经接入
        errmsg: ''
      },
      // 接入状态检查
      registerCheckData: {
        errcode: 0,
        errmsg: null,
        data: {
          status: 2,
          reject_reason: '',
          access_info: {
            spu_audit_success: 0,
            // 上传商品并审核成功，0:未成功，1:成功
            spu_audit_finished: 0,
            // 商品接口调试完成，0:未完成，1:已完成
            ec_order_success: 0,
            // 发起一笔订单并支付成功，0:未成功，1:成功
            ec_order_finished: 0,
            // 订单接口调试完成，0:未完成，1:已完成
            send_delivery_finished: 0,
            // 物流接口调试完成，0:未完成，1:已完成
            send_delivery_success: 0,
            // 物流接口调用成功，0:未成功，1:成功
            ec_after_sale_finished: 0,
            // 售后接口调试完成，0:未完成，1:已完成
            ec_after_sale_success: 0,
            // 售后接口调用成功，0:未成功，1:成功
            test_api_finished: 0,
            // 测试完成，0:未完成，1:已完成
            deploy_wxa_finished: 0,
            // 发版完成，0:未完成，1:已完成
            open_product_task_finished: 0 // 完成自定义组件全部任务 0:未完成 1:已完成
          },
          scene_group_list: [{
            group_id: 1,
            // 场景枚举，1:视频号、公众号场景
            reason: '',
            // 审核理由
            name: '',
            // 场景名称
            status: 0,
            // 审核状态，0:审核中，1:审核完成，2:审核失败，3未审核
            scene_group_ext_list: [
            // 场景相关审核结果
            {
              ext_id: 1,
              // 审核事项id，1:客服售后，2:电商平台
              status: 1 // 场景相关审核结果，0:审核中，1:审核成功，2:审核失败，3未审核
            }]
          }]
        }
      }
    };
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['platform:pay:component:shop:register:scene', 'platform:pay:component:shop:register'])) this.registerApply();
    if ((0, _permission.checkPermi)(['platform:pay:component:shop:register:check'])) this.handleRegisterCheck();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 申请接入 如果多次接入及时关闭再次开启 这个状态检查有可能直接 开通
    registerApply: function registerApply() {
      var _this = this;
      (0, _videoChannel.videoChanelRegisterApply)().then(function (res) {
        _this.toRegister = res;
      });
    },
    // 接入状态检查
    handleRegisterCheck: function handleRegisterCheck() {
      var _this2 = this;
      (0, _videoChannel.registerCheck)().then(function (res) {
        _this2.registerCheckData = res;
      }).finally(function () {
        _this2.$message.success('检查接入状态已更新');
      });
    }
  }
};