var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "el-tabs",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:group:product:list"],
                      expression: "['platform:group:product:list']",
                    },
                  ],
                  on: {
                    "tab-click": function ($event) {
                      return _vm.getList(1)
                    },
                  },
                  model: {
                    value: _vm.tableFrom.auditStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.tableFrom, "auditStatus", $$v)
                    },
                    expression: "tableFrom.auditStatus",
                  },
                },
                _vm._l(_vm.headeNum, function (item, index) {
                  return _c("el-tab-pane", {
                    key: index,
                    attrs: { label: item.name, name: item.type },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:group:product:list"],
                      expression: "['platform:group:product:list']",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "small",
                        "label-width": "90px",
                        inline: true,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.list.index.5u1190xhaa40"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "marketing.group.list.index.5u1190xhcyc0"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.proName,
                                        callback: function ($$v) {
                                          _vm.proName = $$v
                                        },
                                        expression: "proName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:group:product:list",
                                            ],
                                            expression:
                                              "['platform:group:product:list']",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getList(1)
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.list.index.5u1190xhd940"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "selWidth",
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "marketing.group.list.index.5u1190xhdd40"
                                        ),
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.activityName,
                                        callback: function ($$v) {
                                          _vm.activityName = $$v
                                        },
                                        expression: "activityName",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        directives: [
                                          {
                                            name: "hasPermi",
                                            rawName: "v-hasPermi",
                                            value: [
                                              "platform:group:product:list",
                                            ],
                                            expression:
                                              "['platform:group:product:list']",
                                          },
                                        ],
                                        attrs: {
                                          slot: "append",
                                          icon: "el-icon-search",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getList(1)
                                          },
                                        },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.list.index.5u1190xhdgo0"
                                    ),
                                  },
                                },
                                [
                                  _c("el-rate", {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: ["platform:group:product:list"],
                                        expression:
                                          "['platform:group:product:list']",
                                      },
                                    ],
                                    staticStyle: { "margin-top": "8px" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getList(1)
                                      },
                                    },
                                    model: {
                                      value: _vm.tableFrom.merStars,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.tableFrom, "merStars", $$v)
                                      },
                                      expression: "tableFrom.merStars",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.list.index.5u1190xhdio0"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: [
                                            "platform:group:product:list",
                                          ],
                                          expression:
                                            "['platform:group:product:list']",
                                        },
                                      ],
                                      staticClass: "selWidth",
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "marketing.group.list.index.5u1190xhdoo0"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getList(1)
                                        },
                                      },
                                      model: {
                                        value: _vm.tableFrom.proStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "proStatus",
                                            $$v
                                          )
                                        },
                                        expression: "tableFrom.proStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.list.index.5u1190xhdrc0"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.list.index.5u1190xhdts0"
                                          ),
                                          value: 0,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "inline",
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.list.index.5u1190xhdxc0"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: [
                                            "platform:group:product:list",
                                          ],
                                          expression:
                                            "['platform:group:product:list']",
                                        },
                                      ],
                                      staticClass: "selWidth",
                                      attrs: {
                                        clearable: "",
                                        placeholder: _vm.$t(
                                          "marketing.group.list.index.5u1190xhdoo0"
                                        ),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.getList(1)
                                        },
                                      },
                                      model: {
                                        value: _vm.tableFrom.activityStatus,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tableFrom,
                                            "activityStatus",
                                            $$v
                                          )
                                        },
                                        expression: "tableFrom.activityStatus",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.list.index.5u1190xhe000"
                                          ),
                                          value: 1,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-option", {
                                        attrs: {
                                          label: _vm.$t(
                                            "marketing.group.list.index.5u1190xhe340"
                                          ),
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:group:product:list"],
                                      expression:
                                        "['platform:group:product:list']",
                                    },
                                  ],
                                  attrs: {
                                    label: _vm.$t(
                                      "marketing.group.list.index.5u1190xhe6o0"
                                    ),
                                  },
                                },
                                [
                                  _c("merchant-name", {
                                    attrs: { merIdChecked: _vm.merIds },
                                    on: { getMerId: _vm.getMerId },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:group:product:list"],
                                      expression:
                                        "['platform:group:product:list']",
                                    },
                                  ],
                                  attrs: { type: "primary", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getList(1)
                                    },
                                  },
                                },
                                [_vm._v("查询\n                ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reset("tableFrom")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "marketing.group.list.index.5u1190xhe9c0"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["platform:group:product:delete"],
                                      expression:
                                        "['platform:group:product:delete']",
                                    },
                                  ],
                                  attrs: {
                                    size: "small",
                                    disabled: !_vm.multipleSelection.length,
                                  },
                                  on: { click: _vm.batchDel },
                                },
                                [_vm._v("批量删除\n                ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "row-key": "id",
                "header-cell-style": { fontWeight: "bold" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  "reserve-selection": true,
                  width: "45",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "45" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhebw0"),
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "demo-image__preview" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "36px", height: "36px" },
                              attrs: {
                                src: scope.row.image,
                                "preview-src-list": [scope.row.image],
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhef00"),
                  prop: "name",
                  "min-width": "180",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "categoryName",
                  label: _vm.$t("marketing.group.list.index.5u1190xhen80"),
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "merName",
                  "show-overflow-tooltip": true,
                  label: _vm.$t("marketing.group.list.index.5u1190xheqc0"),
                  "min-width": "130",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: _vm.$t("marketing.group.list.index.5u1190xhesw0"),
                  "min-width": "150",
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: _vm.$t("marketing.group.list.index.5u1190xhevc0"),
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "groupPrice",
                  label: _vm.$t("marketing.group.list.index.5u1190xhezw0"),
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhf3c0"),
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-rate", {
                          staticStyle: { "margin-top": "8px" },
                          attrs: { disabled: "" },
                          model: {
                            value: scope.row.merStarLevel,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "merStarLevel", $$v)
                            },
                            expression: "scope.row.merStarLevel",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: _vm.$t("marketing.group.list.index.5u1190xhf6o0"),
                  "min-width": "60",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhf900"),
                  fixed: "right",
                  "min-width": "70",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isShow
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhdrc0"
                                  )
                                )
                              ),
                            ])
                          : _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhdts0"
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhfaw0"),
                  fixed: "right",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.activityStatus === 0
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhffk0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus === 1
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhe000"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.activityStatus === 2
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhe340"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhfjc0"),
                  fixed: "right",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditStatus === 1
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhflc0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus === 2
                          ? _c("el-tag", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhfn40"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.auditStatus === 3
                          ? _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.group.list.index.5u1190xhfss0"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _vm.tableFrom.auditStatus === "3"
                ? _c("el-table-column", {
                    attrs: {
                      prop: "reason",
                      label: _vm.$t("marketing.group.list.index.5u1190xhfug0"),
                      fixed: "right",
                      "min-width": "120",
                      "show-overflow-tooltip": true,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.group.list.index.5u1190xhfww0"),
                  "min-width": "150",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.tableFrom.auditStatus === "1" &&
                        _vm.checkPermi(["platform:group:time:interval:delete"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row, 1)
                                  },
                                },
                              },
                              [_vm._v("审核\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        Number(_vm.tableFrom.auditStatus) > 1 &&
                        _vm.checkPermi(["platform:group:product:list"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row, 2)
                                  },
                                },
                              },
                              [_vm._v("详情\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.tableFrom.auditStatus === "2" &&
                        _vm.checkPermi(["platform:group:product:list"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(scope.row, 3)
                                  },
                                },
                              },
                              [_vm._v("编辑\n            ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["platform:group:product:delete"])
                          ? _c(
                              "el-button",
                              {
                                staticClass: "mr10",
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除\n            ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            "custom-class": "demo-drawer",
            direction: "rtl",
            visible: _vm.dialogVisible,
            size: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.close("ruleForm")
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isShow === 1
                    ? _vm.$t("marketing.group.list.index.5u1190xhfzk0")
                    : _vm.isShow === 3
                    ? _vm.$t("marketing.group.list.index.5u1190xhg100")
                    : _vm.$t("marketing.group.list.index.5u1190xhg380")
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "demo-drawer__content",
            },
            [
              _c("div", { staticClass: "description mt10" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("marketing.group.list.index.5u1190xhg5c0"))
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row" }, [
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.list.index.5u1190xhd940")
                        )
                      ),
                    ]),
                    _vm._v(
                      _vm._s(_vm.groupInfo.activityName) + "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.list.index.5u1190xhdxc0")
                        )
                      ),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm._f("activityStatusFilter")(
                          _vm.groupInfo.activityStatus
                        )
                      ) + "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.list.index.5u1190xhg740")
                        )
                      ),
                    ]),
                    _vm._v(
                      _vm._s(
                        _vm.groupInfo.auditStatus == 1
                          ? _vm.$t("marketing.group.list.index.5u1190xhflc0")
                          : _vm.groupInfo.auditStatus == 2
                          ? _vm.$t("marketing.group.list.index.5u1190xhg8s0")
                          : _vm.$t("marketing.group.list.index.5u1190xhfss0")
                      ) + "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.list.index.5u1190xhgag0")
                        )
                      ),
                    ]),
                    _vm._v(
                      _vm._s(_vm.groupInfo.categoryName) + "\n            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("marketing.group.list.index.5u1190xhgc00"))
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row" }, [
                  _c("div", { staticClass: "description-term sp100" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.list.index.5u1190xhe6o0")
                        )
                      ),
                    ]),
                    _vm._v(_vm._s(_vm.groupInfo.merName) + "\n            "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "description-term sp100 acea-row" },
                    [
                      _c("label", { staticClass: "name" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("marketing.group.list.index.5u1190xhdgo0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-rate", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.groupInfo.merStarLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.groupInfo, "merStarLevel", $$v)
                          },
                          expression: "groupInfo.merStarLevel",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("marketing.group.list.index.5u1190xhgg00"))
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "acea-row mb20" }, [
                  _c("div", { staticClass: "description-term mb20" }, [
                    _c("label", { staticClass: "name" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.group.list.index.5u1190xhaa40")
                        )
                      ),
                    ]),
                    _vm._v(_vm._s(_vm.groupInfo.name) + "\n            "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.listLoading,
                              expression: "listLoading",
                            },
                          ],
                          ref: "tableList",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "row-key": "id",
                            data: _vm.groupInfo.attrValue,
                            size: "mini",
                            border: "",
                            "default-expand-all": "",
                            "tree-props": { children: "children" },
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              "min-width": "140",
                              label: _vm.$t(
                                "marketing.group.list.index.5u1190xhgg00"
                              ),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", { staticClass: "acea-row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "demo-image__preview mr10",
                                        },
                                        [
                                          _c("el-image", {
                                            staticStyle: {
                                              width: "36px",
                                              height: "36px",
                                            },
                                            attrs: {
                                              src: scope.row.image,
                                              "preview-src-list": [
                                                scope.row.image,
                                              ],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "row_title line2" },
                                        [_vm._v(_vm._s(scope.row.sku))]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "otPrice",
                              label: _vm.$t(
                                "marketing.group.list.index.5u1190xhgj00"
                              ),
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quotaShow",
                              label: _vm.$t(
                                "marketing.group.list.index.5u1190xhgkg0"
                              ),
                              width: "80",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "quota",
                              label: _vm.$t(
                                "marketing.group.list.index.5u1190xhgpw0"
                              ),
                              width: "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "price",
                              label: _vm.$t(
                                "marketing.group.list.index.5u1190xhgsw0"
                              ),
                              "min-width": "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input-number", {
                                      staticClass: "input_width",
                                      attrs: {
                                        disabled: _vm.isShow < 3,
                                        type: "number",
                                        precision: 2,
                                        min: 0,
                                        max: 99999,
                                        controls: false,
                                      },
                                      model: {
                                        value: scope.row.price,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "price", $$v)
                                        },
                                        expression: "scope.row.price",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "demo-drawer__footer" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShow === 1,
                    expression: "isShow === 1",
                  },
                ],
                staticClass: "from-foot-btn drawer_fix",
              },
              [
                _c(
                  "div",
                  { staticClass: "acea-row justify-content" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["platform:group:product:audit"],
                            expression: "['platform:group:product:audit']",
                          },
                          {
                            name: "debounceClick",
                            rawName: "v-debounceClick",
                            value: function () {
                              _vm.onSubmit("fail")
                            },
                            expression:
                              "\n                () => {\n                  onSubmit('fail');\n                }\n              ",
                          },
                        ],
                        staticStyle: { "margin-left": "0" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.loadingBtn
                              ? "提交中 ..."
                              : _vm.$t(
                                  "marketing.group.list.index.5u1190xhgv80"
                                )
                          ) + "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["platform:group:product:audit"],
                            expression: "['platform:group:product:audit']",
                          },
                          {
                            name: "debounceClick",
                            rawName: "v-debounceClick",
                            value: function () {
                              _vm.onSubmit("success")
                            },
                            expression:
                              "\n                () => {\n                  onSubmit('success');\n                }\n              ",
                          },
                        ],
                        attrs: { type: "primary" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.loadingBtn
                              ? "提交中 ..."
                              : _vm.$t(
                                  "marketing.group.list.index.5u1190xhg8s0"
                                )
                          ) + "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }