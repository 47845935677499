"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditStatusFilter = auditStatusFilter;
exports.broadcastStatusFilter = broadcastStatusFilter;
exports.editStatusFilter = editStatusFilter;
exports.liveReviewStatusFilter = liveReviewStatusFilter;
exports.platformStatusFilter = platformStatusFilter;
exports.priceTypeFilter = priceTypeFilter;
exports.productQualificationTypeFilter = productQualificationTypeFilter;
exports.roomReviewStatusFilter = roomReviewStatusFilter;
exports.roomShowFilter = roomShowFilter;
exports.videoStatusFilter = videoStatusFilter;
/**
 * 審核狀態
 */
function auditStatusFilter(status) {
  var statusMap = {
    0: '無需審核',
    1: '待審核',
    2: '審核成功',
    3: '審核拒絕'
  };
  return statusMap[status];
}

/**
 * @description 視頻號草稿商品微信審核狀態
 */
function editStatusFilter(status) {
  var statusMap = {
    '-1': '違規/風控系統下',
    '-2': '平台下架',
    '-3': '商家下架',
    1: '未審核',
    2: '審核中',
    3: '審核失敗',
    4: '審核成功'
  };
  return statusMap[status];
}

/**
 * @description 視頻號草稿商品平台審核狀態
 */
function platformStatusFilter(status) {
  var statusMap = {
    1: '未審核',
    2: '審核中',
    3: '審核失敗',
    4: '審核成功'
  };
  return statusMap[status];
}

/**
 * @description 視頻號正式商品狀態
 */
function videoStatusFilter(status) {
  var statusMap = {
    0: '初始值',
    5: '上架',
    11: '自主下架',
    13: '違規下架/風控系統下架'
  };
  return statusMap[status];
}

/**
 * @description 視頻號 微信商品類型資質類型
 */
function productQualificationTypeFilter(status) {
  var statusMap = {
    0: '不需要',
    1: '必填',
    2: '選填',
    null: '無要求'
  };
  return statusMap[status];
}

/**
 * @description 直播商品審核狀態
 */
function liveReviewStatusFilter(status) {
  var statusMap = {
    0: '商户創建/撤回',
    1: '平台待審核/商户重新提交審核',
    2: '平台審核通過/微信審核中',
    3: '平台審核失敗',
    4: '微信審核成功',
    5: '微信審核失敗'
  };
  return statusMap[status];
}

/**
 * @description 直播狀態
 */
function broadcastStatusFilter(status) {
  var statusMap = {
    101: '直播中',
    102: '未開始',
    103: '已結束',
    104: '禁播',
    105: '暫停',
    106: '異常',
    107: '已過期'
  };
  return statusMap[status];
}

/**
 * @description 直播間審核狀態
 */
function roomReviewStatusFilter(status) {
  var statusMap = {
    0: '平台待審核',
    1: '平台審核失敗',
    2: '微信審核失敗',
    3: '微信審核成功'
  };
  return statusMap[status];
}

/**
 * @description 直播間開啓關閉狀態
 */
function roomShowFilter(status) {
  var statusMap = {
    1: '開啓',
    0: '關閉'
  };
  return statusMap[status];
}

/**
 * @description 直播商品價格類型
 */
function priceTypeFilter(status) {
  var statusMap = {
    1: '一口價',
    2: '價格區間',
    3: '折扣價'
  };
  return statusMap[status];
}