"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
var _vuex = require("vuex");
var _audit = _interopRequireDefault(require("./audit"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'MerchantApplication',
  components: {
    auditFrom: _audit.default
  },
  data: function data() {
    return {
      props: {
        emitPath: false
      },
      fromList: this.$constants.fromList,
      statusList: this.$constants.statusList,
      //筛选状态列表
      isChecked: false,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        dateLimit: '',
        auditStatus: '',
        keywords: '',
        categoryId: '',
        typeId: ''
      },
      mer_id: this.$route.query.id ? this.$route.query.id : '',
      autoUpdate: true,
      timeVal: [],
      merData: {},
      isSHOW: ''
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['merchantClassify', 'merchantType'])),
  watch: {
    mer_id: function mer_id(newName, oldName) {
      if ((0, _permission.checkPermi)(['platform:merchant:apply:page:list'])) this.getList('');
    }
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:merchant:apply:page:list'])) this.getList('');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    subSuccess: function subSuccess() {
      this.getList('');
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.tableFrom.page = 1;
      this.getList('');
    },
    statusChange: function statusChange(tab) {
      this.tableFrom.auditStatus = tab;
      this.tableFrom.page = 1;
      this.getList('');
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = this.timeVal ? this.timeVal.join(',') : '';
      this.tableFrom.page = 1;
      this.getList('');
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      if (!this.merchantClassify.length) this.$store.dispatch('merchant/getMerchantClassify');
      if (!this.merchantType.length) this.$store.dispatch('merchant/getMerchantType');
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _merchant.merApplyListApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    // 审核
    onchangeIsShow: function onchangeIsShow(row, type) {
      this.merData = row;
      this.isSHOW = type;
      this.$refs.auditFroms.dialogVisible = true;
    },
    // 添加
    // 备注
    onEdit: function onEdit(row) {
      var _this2 = this;
      this.$modalPrompt('textarea', this.$t('merchant.application.index.5to32iuhk3c0'), row.remark).then(function (V) {
        (0, _merchant.merApplyRemarkApi)({
          id: row.id,
          remark: V
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: _this2.$t('merchant.application.index.5to32iuhk5c0')
          });
          _this2.getList('');
        });
      });
    },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this3 = this;
      this.$modalSure().then(function () {
        intentionDelte(id).then(function (_ref) {
          var message = _ref.message;
          _this3.$message.success(message);
          _this3.getList(1);
        });
      });
    }
  }
};