"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
var _merchantName = _interopRequireDefault(require("@/components/merchantName"));
var _auth = require("@/utils/auth");
var _systemSetting = require("@/api/systemSetting");
var _permission = require("@/utils/permission");
var _order = require("@/api/order");
var _systemadmin = require("@/api/systemadmin");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'transferAccount',
  data: function data() {
    return {
      exportLoading: false,
      myHeaders: {
        'X-Token': (0, _auth.getToken)()
      },
      isShow: 0,
      loadingBtn: false,
      rules: {
        auditStatus: [{
          required: true,
          message: this.$t('closing.merchantClosing.index.5u0yepezi780'),
          trigger: 'change'
        }],
        refusalReason: [{
          required: true,
          message: this.$t('closing.merchantClosing.index.5u0yepezi900'),
          trigger: 'blur'
        }],
        closingProof: []
      },
      tableData: {
        data: [],
        total: 0
      },
      arrivalStatusList: [{
        label: this.$t('closing.merchantClosing.index.5u0yepezid40'),
        value: 1
      }, {
        label: this.$t('closing.merchantClosing.index.5u0yepezies0'),
        value: 0
      }],
      closingTypeList: [{
        label: this.$t('closing.merchantClosing.index.5u0yepezih40'),
        value: 'bank'
      }, {
        label: this.$t('closing.merchantClosing.index.5u0yepezij00'),
        value: 'wechat'
      }, {
        label: this.$t('closing.merchantClosing.index.5u0yepezikw0'),
        value: 'alipay'
      }],
      listLoading: true,
      tableFrom: {
        dateLimit: '',
        page: 1,
        limit: 20,
        closingNo: '',
        auditStatus: '',
        accountStatus: '',
        merId: ''
      },
      timeVal: [],
      fromList: this.$constants.fromList,
      loading: false,
      dialogVisible: false,
      pictureVisible: false,
      closingData: {},
      baseInfoform: {
        amount: 0,
        mark: '',
        transferType: ''
      },
      merchantList: [],
      search: {
        limit: 10,
        page: 1,
        keywords: ''
      },
      ruleForm: {
        refusalReason: '',
        auditStatus: 1,
        id: '',
        closingProof: []
      },
      localImg: '',
      closingNos: [],
      closingNo: ''
    };
  },
  components: {
    merchantName: _merchantName.default
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:finance:merchant:closing:page:list'])) this.getList(1);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getMerId: function getMerId(id) {
      this.tableFrom.merId = id;
      this.getList(1);
    },
    onRemark: function onRemark(row) {
      var _this = this;
      this.$modalPrompt('textarea', this.$t('closing.merchantClosing.index.5u0yepezgbw0'), row.platformMark).then(function (V) {
        (0, _finance.merClosingRemarkApi)({
          closingNo: row.closingNo,
          remark: V
        }).then(function (res) {
          _this.$message({
            type: 'success',
            message: _this.$t('closing.merchantClosing.index.5u0yepezimg0')
          });
          _this.getList('');
        });
      });
    },
    batchClosing: function batchClosing() {
      var _this2 = this;
      this.$modal.confirm(this.$t('closing.merchantClosing.index.5unuglu0f100')).then(function () {
        _this2.loadingBtn = true;
        return (0, _finance.transferProofApi)({
          closingNos: _this2.closingNos,
          closingProof: ''
        });
      }).then(function () {
        _this2.$message.success(_this2.$t('closing.merchantClosing.index.5u0yepeziq80'));
        _this2.getList(1);
        _this2.close('ruleForm');
        _this2.loadingBtn = false;
      }).catch(function (err) {});
    },
    // 设置选中的方法
    handleSelectionChange: function handleSelectionChange(val) {
      this.closingNos = val.map(function (item) {
        return item.closingNo;
      });
    },
    exports: function exports() {
      var _this3 = this;
      this.exportLoading = true;
      (0, _finance.merchantClosingExcelApi)(this.tableFrom).then(function (res) {
        var tempPage = window.open('', '_blank');
        tempPage.location = res.fileName;
      }).finally(function () {
        _this3.exportLoading = false;
      });
    },
    onSubmit: function onSubmit(formName) {
      var _this4 = this;
      if (this.isShow === 2) {
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            _this4.loadingBtn = true;
            var data = {
              closingNo: _this4.closingNo,
              refusalReason: _this4.ruleForm.refusalReason,
              auditStatus: _this4.ruleForm.auditStatus
            };
            (0, _finance.closingAuditApi)(data).then(function (res) {
              _this4.$message.success(_this4.$t('closing.merchantClosing.index.5u0yepeziq80'));
              _this4.dialogVisible = false;
              _this4.getList(1);
              _this4.close('ruleForm');
              _this4.loadingBtn = false;
            }).catch(function (res) {
              _this4.loadingBtn = false;
            });
          } else {
            return false;
          }
        });
      } else {
        this.loadingBtn = true;
        var data = {
          closingNos: this.closingNos,
          closingProof: JSON.stringify(this.ruleForm.closingProof)
        };
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            (0, _finance.transferProofApi)(data).then(function (res) {
              _this4.$message.success(_this4.$t('closing.merchantClosing.index.5u0yepeziq80'));
              _this4.dialogVisible = false;
              _this4.getList(1);
              _this4.close('ruleForm');
              _this4.loadingBtn = false;
            }).catch(function (res) {
              _this4.loadingBtn = false;
            });
          }
        });
      }
    },
    handleUploadForm: function handleUploadForm(param) {
      var _this5 = this;
      var formData = new FormData();
      var data = {
        model: 'finance',
        pid: 0
      };
      formData.append('multipart', param.file);
      var loading = this.$loading({
        lock: true,
        text: this.$t('closing.merchantClosing.index.5u0yepezisw0'),
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _systemSetting.fileImageApi)(formData, data).then(function (res) {
        loading.close();
        _this5.$message.success(_this5.$t('closing.merchantClosing.index.5u0yepeziv80'));
        _this5.ruleForm.closingProof.push(res.url);
      }).catch(function (res) {
        loading.close();
      });
    },
    handleRemove: function handleRemove(i) {
      this.ruleForm.closingProof.splice(i, 1);
    },
    close: function close(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
      this.dialogVisible = false;
      this.ruleForm.closingProof = [];
    },
    // 商户结算记录详情
    closingDetail: function closingDetail(id, num) {
      var _this6 = this;
      this.closingNo = id;
      this.closingNos = [id];
      this.isShow = num;
      this.dialogVisible = true;
      this.loading = true;
      (0, _finance.merClosingDetailApi)(id).then(function (res) {
        _this6.closingData = res;
        _this6.loading = false;
      }).catch(function (res) {
        _this6.loading = false;
      });
    },
    // 查看图片
    getPicture: function getPicture(url) {
      this.pictureVisible = true;
      this.pictureUrl = url;
    },
    // 选择时间
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.getList(1);
    },
    // 导出
    exportRecord: function exportRecord() {
      var _this7 = this;
      transferRecordsExportApi(this.tableFrom).then(function (res) {
        var h = _this7.$createElement;
        _this7.$msgbox({
          title: _this7.$t('closing.merchantClosing.index.5u0yepeziww0'),
          message: h('p', null, [h('span', null, _this7.$t('closing.merchantClosing.index.5u0yepeziyw0')), h('span', {
            style: 'color: teal'
          }, _this7.$t('closing.merchantClosing.index.5u0yepezj0k0')), h('span', null, '"查看~ ')]),
          confirmButtonText: _this7.$t('closing.merchantClosing.index.5u0yepezj2k0')
        }).then(function (action) {});
      }).catch(function (res) {
        _this7.$message.error(res.message);
      });
    },
    // 导出列表
    getExportFileList: function getExportFileList() {
      this.$refs.exportList.exportFileList();
    },
    // 列表
    getList: function getList(num) {
      var _this8 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _finance.merchantClosingListApi)(this.tableFrom).then(function (res) {
        _this8.tableData.data = res.list;
        _this8.tableData.total = res.total;
        _this8.listLoading = false;
      }).catch(function (res) {
        _this8.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList('');
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList('');
    },
    handleClose: function handleClose() {
      this.dialogLogistics = false;
    }
  },
  computed: {
    batchClosingDisabled: function batchClosingDisabled() {
      return !(this.closingNos.length > 0 && +this.tableFrom.auditStatus === 1 && this.tableFrom.accountStatus === 0);
    }
  }
};