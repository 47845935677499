"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_menu',
  cname: '导航组',
  icon: 't-icon-zujian-daohangzu',
  configName: 'c_home_menu',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'menus',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: this.configObj.mbConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + 0 + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //行数
    gridColumns: function gridColumns() {
      if (this.configObj.number.tabVal == 0) {
        return [{
          'grid-row-gap': this.configObj.contentConfig.val + 'px'
        }, {
          'grid-template-columns': 'repeat(3, 1fr)'
        }];
      } else if (this.configObj.number.tabVal == 1) {
        return [{
          'grid-row-gap': this.configObj.contentConfig.val + 'px'
        }, {
          'grid-template-columns': 'repeat(4, 1fr)'
        }];
      } else {
        return [{
          'grid-row-gap': this.configObj.contentConfig.val + 'px'
        }, {
          'grid-template-columns': 'repeat(5, 1fr)'
        }];
      }
    },
    titleColor: function titleColor() {
      return {
        color: this.configObj.titleColor.color[0].item
      };
    },
    contentStyle: function contentStyle() {
      return {
        'border-radius': this.configObj.contentStyle.val ? this.configObj.contentStyle.val + 'px' : '0'
      };
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'menus',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '导航组'
        },
        tabConfig: {
          title: '展示样式',
          tabTitle: '展示设置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '单行展示',
            icon: 'icon-yangshiyi'
          }, {
            val: '多行展示',
            icon: 'icon-yangshier'
          }]
        },
        rowsNum: {
          title: '显示行数',
          name: 'rowsNum',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '2行',
            icon: 'icon-daohang-2hang'
          }, {
            val: '3行',
            icon: 'icon-daohang-3hang'
          }, {
            val: '4行',
            icon: 'icon-daohang-4hang'
          }]
        },
        number: {
          title: '显示个数',
          name: 'number',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '3个',
            icon: 'icon-daohang-3ge'
          }, {
            val: '4个',
            icon: 'icon-daohang-4ge'
          }, {
            val: '5个',
            icon: 'icon-daohang-5ge'
          }]
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        },
        contentStyle: {
          title: '内容圆角',
          name: 'contentStyle',
          val: 30,
          min: 0,
          max: 30
        },
        menuConfig: {
          tabTitle: '内容设置',
          title: '最多可添加1张图片，建议宽度90 * 90px',
          maxList: 100,
          list: [{
            img: '',
            info: [{
              title: '标题',
              value: '今日推荐',
              tips: '选填，不超过4个字',
              max: 4,
              status: true
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100,
              status: true
            }],
            status: true
          }, {
            img: '',
            info: [{
              title: '标题',
              value: '热门榜单',
              tips: '选填，不超过4个字',
              max: 4,
              status: true
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100,
              status: true
            }],
            status: true
          }, {
            img: '',
            info: [{
              title: '标题',
              value: '首发新品',
              tips: '选填，不超过4个字',
              max: 4,
              status: true
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100,
              status: true
            }],
            status: true
          }, {
            img: '',
            info: [{
              title: '标题',
              value: '促销单品',
              tips: '选填，不超过4个字',
              max: 4,
              status: true
            }, {
              title: '链接',
              value: '',
              tips: '请输入链接',
              max: 100,
              status: true
            }],
            status: true
          }]
        },
        bgColor: {
          tabTitle: '颜色设置',
          title: '背景颜色',
          name: 'bgColor',
          color: [{
            item: '#fff'
          }, {
            item: '#fff'
          }],
          default: [{
            item: '#fff'
          }, {
            item: '#fff'
          }]
        },
        titleColor: {
          title: '文字颜色',
          name: 'titleColor',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        contentConfig: {
          title: '内容间距',
          val: 10,
          min: 0,
          max: 30
        },
        // 上间距
        upConfig: {
          tabTitle: '边距设置',
          title: '上边距',
          val: 10,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          title: '下边距',
          val: 10,
          min: 0
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 25
        },
        // 顶部间距
        mbConfig: {
          title: '顶部间距',
          val: 10,
          min: 0
        }
      },
      configObj: null,
      vuexMenu: '',
      isOne: 0,
      number: 0,
      rowsNum: 0,
      pageData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    // 对象转数组
    objToArr: function objToArr(data) {
      var obj = Object.keys(data);
      var m = obj.map(function (key) {
        return data[key];
      });
      return m;
    },
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.isOne = data.tabConfig.tabVal;
        var rowsNum = data.rowsNum.tabVal;
        var number = data.number.tabVal;
        var list = this.objToArr(data.menuConfig.list);
        this.number = number;
        this.rowsNum = rowsNum;
        var vuexMenu = [];
        if (rowsNum === 0) {
          if (number === 0) {
            vuexMenu = list.splice(0, 6);
          } else if (number === 1) {
            vuexMenu = list.splice(0, 8);
          } else {
            vuexMenu = list.splice(0, 10);
          }
        } else if (rowsNum === 1) {
          if (number === 0) {
            vuexMenu = list.splice(0, 9);
          } else if (number === 1) {
            vuexMenu = list.splice(0, 12);
          } else {
            vuexMenu = list.splice(0, 15);
          }
        } else {
          if (number === 0) {
            vuexMenu = list.splice(0, 12);
          } else if (number === 1) {
            vuexMenu = list.splice(0, 16);
          } else {
            vuexMenu = list.splice(0, 20);
          }
        }
        this.vuexMenu = vuexMenu;
      }
    }
  }
};