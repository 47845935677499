"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SaveDataApi = SaveDataApi;
exports.designListApi = designListApi;
exports.getBottomNavigationApi = getBottomNavigationApi;
exports.getDataApi = getDataApi;
exports.goodDesignList = goodDesignList;
exports.groupDataList = groupDataList;
exports.groupDelete = groupDelete;
exports.groupEdit = groupEdit;
exports.groupInfo = groupInfo;
exports.groupList = groupList;
exports.groupSave = groupSave;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function groupDelete(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/delete',
    method: 'GET',
    params: data
  });
}
function groupInfo(pram) {
  var data = {
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/info',
    method: 'GET',
    params: data
  });
}
function groupList(pram) {
  var data = {
    keywords: pram.keywords,
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/list',
    method: 'GET',
    params: data
  });
}
function groupDataList(pram) {
  var data = {
    gid: pram.gid,
    keywords: pram.keywords,
    page: pram.page,
    limit: pram.limit
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/data/list',
    method: 'GET',
    params: data
  });
}
function groupSave(pram) {
  var data = {
    formId: pram.formId,
    info: pram.info,
    name: pram.name
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/save',
    method: 'POST',
    params: data
  });
}
function groupEdit(pram) {
  var data = {
    formId: pram.formId,
    info: pram.info,
    name: pram.name,
    id: pram.id
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/update',
    method: 'POST',
    params: data
  });
}

/**
 * @description 页面设计 获取数据
 */
function designListApi() {
  return _request.default.get("/admin/platform/page/layout/index");
}

/**
 * @description 页面设计商品Tab 获取数据
 */
function goodDesignList(pram) {
  var data = {
    gid: pram.gid
  };
  return (0, _request.default)({
    url: '/admin/platform/system/group/data/list',
    method: 'GET',
    params: data
  });
}

/**
 * @description 页面设计 保存
 */
function SaveDataApi(data, url) {
  return (0, _request.default)({
    url: url,
    method: 'POST',
    data: data
  });
}

/**
 * @description 获取配置
 */
function getDataApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/page/layout/category/config',
    method: 'GET',
    data: data
  });
}

/**
 * @description 页面底部导航
 */
function getBottomNavigationApi() {
  return (0, _request.default)({
    url: '/admin/platform/page/layout/bottom/navigation/get',
    method: 'GET'
  });
}