"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var systemSettingApi = _interopRequireWildcard(require("@/api/systemSetting.js"));
var systemConfigApi = _interopRequireWildcard(require("@/api/systemConfig.js"));
var _index = _interopRequireDefault(require("@/views/application/wxAccount/wxTemplate/index"));
var _utils = require("@/components/FormGenerator/utils");
var _permission = require("@/utils/permission");
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "index",
  components: {
    Template: _index.default,
    parser: _Parser.default
  },
  data: function data() {
    return {
      loading: false,
      formConf: {
        content: {
          fields: []
        },
        id: null,
        render: false,
        isEdit: false
      },
      formConfChild: {
        content: {
          fields: []
        },
        id: null,
        render: false,
        isEdit: false
      },
      activeNamel1: null,
      activeNamel2: '',
      //针对文件特殊处理
      treeList: [],
      editDataChild: {},
      isCreate: 0,
      currentEditId: null,
      currentEditData: null,
      currentSelectedUploadFlag: null
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:category:list'])) this.handlerGetTreeList();
    if ((0, _permission.checkPermi)(['platform:system:config:getuniq'])) this.getCurrentUploadSelectedFlag();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleTabClick: function handleTabClick(tab) {
      this.formConfChild.render = false;
      if (tab.$children.length && tab.$children[0].panes) {
        this.activeNamel2 = tab.$children[0].panes[0].name;
      } else {
        this.getFromId();
      }
      if (this.activeNamel2) this.handlerGetLevel2FormConfig(this.activeNamel2);
    },
    getFromId: function getFromId() {
      var _this = this;
      this.activeNamel2 = this.treeList.find(function (item) {
        return item.name == _this.activeNamel1;
      }).extra;
    },
    handlerGetLevel1FormConfig: function handlerGetLevel1FormConfig(id) {
      var _this2 = this;
      var formPram = {
        id: id
      };
      this.currentEditId = id;
      this.formConf.content = {
        fields: []
      };
      this.formConf.render = false;
      this.loading = true;
      systemFormConfigApi.getFormConfigInfo(formPram).then(function (data) {
        var id = data.id,
          name = data.name,
          info = data.info,
          content = data.content;
        _this2.formConf.content = JSON.parse(content);
        _this2.formConf.id = id;
        _this2.handlerGetSettingInfo(id, 1);
        _this2.loading = false;
      }).catch(function () {
        _this2.loading = false;
      });
    },
    handleItemTabClick: function handleItemTabClick(tab, event) {
      //这里对tabs=tab.name和radio=id做了兼容
      var _id = tab.name ? tab.name : tab;
      if (!_id) return this.$message.error(this.$t('systemSetting.setting.index.5u0yxanzlgo0'));
      this.handlerGetLevel2FormConfig(_id);
    },
    handlerGetLevel2FormConfig: function handlerGetLevel2FormConfig(id) {
      var _this3 = this;
      var formPram = {
        name: id
      };
      this.currentEditId = id;
      this.formConfChild.content = {
        fields: []
      };
      this.formConfChild.render = false;
      this.loading = true;
      systemFormConfigApi.formTempNameInfoApi(formPram).then(function (data) {
        var id = data.id,
          name = data.name,
          info = data.info,
          content = data.content;
        _this3.formConfChild.content = JSON.parse(content);
        _this3.formConfChild.id = id;
        _this3.handlerGetSettingInfo(id, 2);
        _this3.loading = false;
      }).catch(function () {
        _this3.loading = false;
      });
    },
    handlerGetSettingInfo: function handlerGetSettingInfo(id, level) {
      var _this4 = this;
      this.formConf.id = id;
      systemConfigApi.configInfo({
        id: id
      }).then(function (data) {
        _this4.currentEditData = data;
        _this4.formConfChild.isEdit = _this4.currentEditData !== null;
        _this4.formConfChild.render = true;
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (formValue) {
      if ((0, _permission.checkPermi)(['platform:system:config:save:form'])) {
        this.handlerSave(formValue);
      } else {
        this.$message.warning(this.$t('systemSetting.setting.index.5u0yxanzm4o0'));
      }
    }),
    handlerSave: function handlerSave(formValue) {
      var _this5 = this;
      var _pram = this.buildFormPram(formValue);
      //大写转小写
      _pram.fields.map(function (item) {
        if (item.title === 'image_ext_str' || item.title === 'file_ext_str') item.value = item.value.toLowerCase();
        if (item.title === 'site_url') _this5.$store.commit('settings/SET_FrontDomain', item.value);
      });
      var _formId = 0;
      _pram.id = this.formConf.id;
      systemConfigApi.configSaveForm(_pram).then(function (data) {
        _this5.$message.success(_this5.$t('systemSetting.setting.index.5u0yxanzm780'));
      });
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this6 = this;
      var _pram = {
        type: this.$constants.categoryType[5].value,
        status: 1
      };
      this.loading = true;
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this6.treeList = _this6.handleAddArrt(data);
        if (_this6.treeList.length > 0) _this6.activeNamel1 = _this6.treeList[0].id.toString();
        if (_this6.treeList.length > 0 && _this6.treeList[0].child) {
          _this6.activeNamel2 = _this6.treeList[0].child[0].extra;
        } else {
          _this6.getFromId();
        }
        _this6.handlerGetLevel2FormConfig(_this6.activeNamel2);
        _this6.loading = false;
      }).catch(function () {
        _this6.loading = false;
      });
    },
    handleAddArrt: function handleAddArrt(treeData) {
      // let _result = this.addTreeListLabel(treeData)
      var _result = selfUtil.addTreeListLabel(treeData);
      return _result;
    },
    buildFormPram: function buildFormPram(formValue) {
      var _pram = {
        fields: [],
        id: this.currentEditId,
        sort: 0,
        // 参数暂时无用
        status: true // 参数暂时无用
      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.fields = _fields;
      return _pram;
    },
    getCurrentUploadSelectedFlag: function getCurrentUploadSelectedFlag() {
      var _this7 = this;
      systemConfigApi.configGetUniq({
        key: 'uploadType'
      }).then(function (data) {
        _this7.currentSelectedUploadFlag = parseInt(data);
      });
    }
  }
};