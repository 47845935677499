"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_tab',
  cname: '选项卡',
  configName: 'c_home_tab',
  icon: 't-icon-zujian-xuanxiangka',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'homeTab',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: this.configObj.topConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + 0 + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //页面间距
    mbConfig: function mbConfig() {
      return {
        marginTop: this.configObj.mbConfig.val ? this.configObj.mbConfig.val + 'px' : 0
      };
    },
    //选项卡背景颜色
    tabBgColor: function tabBgColor() {
      return {
        background: "linear-gradient(".concat(this.configObj.tabBgColor.color[0].item, ", ").concat(this.configObj.tabBgColor.color[1].item, ")")
      };
    },
    //商品标题颜色
    titleColor: function titleColor() {
      return {
        color: this.configObj.titleColor.color[0].item
      };
    },
    //标签文字颜色
    fontColor: function fontColor() {
      return this.configObj.fontColor.color[0].item;
    },
    //选中颜色
    checkColor: function checkColor() {
      return {
        background: this.configObj.checkColor.color[0].item
      };
    },
    //价格颜色
    priceColor: function priceColor() {
      return {
        color: this.configObj.priceColor.color[0].item
      };
    },
    //已售数量
    soldColor: function soldColor() {
      return {
        color: this.configObj.soldColor.color[0].item
      };
    },
    //标签
    labelColor: function labelColor() {
      return [{
        backgroundColor: this.configObj.labelColor.color[0].item
      }, {
        color: this.configObj.labelFontColor.color[0].item
      }];
    },
    //内容圆角
    contentStyle: function contentStyle() {
      return {
        'border-radius': this.configObj.contentStyle.val ? this.configObj.contentStyle.val + 'px' : '0'
      };
    },
    //商品名称
    titleShow: function titleShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(1) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    //店铺类型
    merTypeShow: function merTypeShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(0) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    //价格
    priceShow: function priceShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(2) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    //销量
    soldShow: function soldShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(3) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }),
  watch: {
    // pageData: {
    //   handler(nVal, oVal) {
    //     this.setConfig(nVal);
    //   },
    //   deep: true,
    // },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      curIndex: 0,
      //分类选中
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'homeTab',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '选项卡'
        },
        tabItemConfig: {
          tabTitle: '选项卡设置',
          title: '选项卡设置',
          name: 'tabItemConfig',
          tabVal: 0,
          list: [{
            val: '选项卡',
            activeList: null,
            activeTabIndex: 0,
            brandGoodsList: [],
            selectConfigList: [],
            merList: []
          }]
        },
        itemStyle: {
          tabTitle: '展现形式',
          title: '展现样式',
          name: 'itemSstyle',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '单列',
            icon: 'icon-shangpin-danlie'
          }, {
            val: '两列',
            icon: 'icon-shangpin-lianglie'
          }, {
            val: '横向滚动',
            icon: 'icon-shangpin-sanlie'
          }, {
            val: '大图',
            icon: 'icon-shangpin-datu'
          }]
        },
        tabConfig: {
          tabTitle: '商品设置',
          title: '商品列表',
          tabVal: 0,
          isShow: 1,
          list: [{
            name: '指定商品'
          }, {
            name: '指定品牌'
          }, {
            name: '指定分类'
          }, {
            name: '指定商户'
          }]
        },
        //显示内容
        typeConfig: {
          title: '展示信息',
          tabTitle: '显示内容',
          name: 'rowsNum',
          activeValue: [0, 1, 2, 3],
          list: [{
            val: '店铺类型'
          }, {
            val: '商品名称'
          }, {
            val: '商品价格'
          }, {
            val: '已售数量'
          }]
        },
        selectConfig: {
          title: '商品分类',
          activeValue: [],
          list: [],
          isMultiple: true,
          goodsList: [],
          isShow: 0
        },
        //分类
        activeValueMer: {
          title: '商户名称',
          activeValue: [],
          list: [],
          isMultiple: true,
          goodsList: [],
          isShow: 0
        },
        //商户
        activeValueBrand: {
          title: '商品品牌',
          activeValue: [],
          list: [],
          isMultiple: true,
          goodsList: [],
          isShow: 0
        },
        //品牌
        goodsSort: {
          title: '商品排序',
          name: 'goodsSort',
          tabVal: 0,
          isShow: 0,
          list: [{
            val: '综合',
            icon: 'icon-zonghe'
          }, {
            val: '销量',
            icon: 'icon-xiaoliang'
          }, {
            val: '价格',
            icon: 'icon-jiage'
          }]
        },
        numConfig: {
          val: 6,
          isShow: 1,
          max: 50
        },
        //背景设置
        bgColor: {
          tabTitle: '背景设置',
          title: '背景颜色',
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        tabBgColor: {
          title: '背景颜色',
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        //标签设置
        fontColor: {
          tabTitle: '标签设置',
          title: '文字颜色',
          name: 'fontColor',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        checkColor: {
          title: '选中颜色',
          name: 'checkColor',
          color: [{
            item: '#e93323'
          }],
          default: [{
            item: '#e93323'
          }]
        },
        priceColor: {
          title: '价格颜色',
          name: 'priceColor',
          default: [{
            item: '#e93323'
          }],
          color: [{
            item: '#e93323'
          }]
        },
        soldColor: {
          title: '已售数量颜色',
          name: 'soldColor',
          color: [{
            item: '#666'
          }],
          default: [{
            item: '#666'
          }]
        },
        titleColor: {
          title: '商品标题颜色',
          name: 'titleColor',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        labelColor: {
          tabTitle: '显示内容',
          title: '标签背景颜色',
          name: 'labelColor',
          color: [{
            item: '#e93323'
          }],
          default: [{
            item: '#e93323'
          }]
        },
        labelFontColor: {
          title: '标签文字颜色',
          name: 'labelFontColor',
          color: [{
            item: '#fff'
          }],
          default: [{
            item: '#fff'
          }]
        },
        // 上间距
        upConfig: {
          title: '上边距',
          tabTitle: '边距设置',
          val: 10,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          tabTitle: '边距设置',
          title: '下边距',
          val: 10,
          min: 0,
          max: 100
        },
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 15
        },
        contentConfig: {
          title: '商品间距',
          val: 10,
          min: 0,
          max: 20
        },
        mbConfig: {
          title: '顶部间距',
          val: 0,
          min: 0
        },
        topConfig: {
          title: '内容间距',
          val: 10,
          min: 0,
          max: 30
        },
        //商品设置
        productList: {},
        goodsList: {
          max: 20,
          list: []
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 7,
          min: 0,
          max: 30
        },
        contentStyle: {
          title: '内容圆角',
          name: 'contentStyle',
          val: 7,
          min: 0,
          max: 30
        }
      },
      navlist: [],
      goodslist: [],
      //商品列表
      pageData: {},
      itemStyle: 0,
      bgStyle: 0,
      configObj: null,
      contentConfig: '' //内容间距
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      this.configObj = data;
      this.navlist = data.tabItemConfig.list; // 分类
      this.curIndex = data.tabItemConfig.tabVal; //分类选中的值
      if (data.tabItemConfig.list.length) {
        this.itemStyle = data.tabItemConfig.list[this.curIndex].activeList ? data.tabItemConfig.list[this.curIndex].activeList.styleType : 0;
        switch (data.tabConfig.tabVal) {
          case 0:
            this.goodslist = data.tabItemConfig.list[this.curIndex].activeList ? data.tabItemConfig.list[this.curIndex].activeList.goods : []; //指定商品列表
            break;
          case 1:
            this.goodslist = data.tabItemConfig.list[this.curIndex].activeList ? data.tabItemConfig.list[this.curIndex].brandGoodsList : []; //品牌商品列表展示
            break;
          case 2:
            this.goodslist = data.tabItemConfig.list[this.curIndex].activeList ? data.tabItemConfig.list[this.curIndex].selectConfigList : []; //分类商品列表展示
            break;
          case 3:
            this.goodslist = data.tabItemConfig.list[this.curIndex].activeList ? data.tabItemConfig.list[this.curIndex].merList : []; //商户商品列表展示
            break;
        }
        this.contentConfig = data.contentConfig.val || 0;
      }
    },
    // 列表
    arrayChunk: function arrayChunk(arr) {
      this.leftList = [];
      this.rightList = [];
      for (var i = 0; i < arr.length; i++) {
        if (i % 2 == 0) {
          this.leftList.push(arr[i]);
        } else {
          this.rightList.push(arr[i]);
        }
      }
    }
  }
};