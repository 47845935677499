"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var merchant = _interopRequireWildcard(require("@/api/merchant"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'c_merchantName',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    },
    number: {
      type: null
    }
  },
  data: function data() {
    return {
      merProps: {
        value: 'id',
        label: 'name',
        children: 'merchantList',
        expandTrigger: 'hover',
        emitPath: false,
        multiple: true
      },
      merchantList: [],
      newMerchantList: [],
      defaults: {},
      configData: {},
      timeStamp: '',
      merIds: [],
      oldList: [],
      list: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    if ((0, _permission.checkPermi)(['platform:merchant:page:list'])) this.getMerList();
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
      _this.merIds = _this.configData.activeValue;
      _this.oldList = _this.merIds;
    });
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    },
    number: function number(nVal) {
      this.timeStamp = nVal;
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 列表
    getMerList: function getMerList() {
      var _this2 = this;
      merchant.merCategoryListApi().then(function (res) {
        _this2.merchantList = res;
        res.forEach(function (item) {
          item.merchantList.forEach(function (item1) {
            _this2.newMerchantList.push(item1);
          });
        });
        _this2.list = _this2.getNewList();
      });
    },
    onChangeMerId: function onChangeMerId(e) {
      var arr = this.findDifferentElements(e, this.oldList);
      console.log(this.oldList, arr);
      if (this.oldList.length > e.length) {
        this.oldList = this.mergeArray(this.oldList, arr);
      } else {
        this.oldList = [].concat(_toConsumableArray(this.oldList), _toConsumableArray(arr));
      }
      this.list = this.getNewList();
      this.configData.activeValue = this.oldList;
      this.$emit('getConfig', {
        name: 'merchantName',
        values: this.oldList
      });
    },
    getNewList: function getNewList() {
      var _this3 = this;
      var arr = [];
      this.oldList.forEach(function (item) {
        _this3.newMerchantList.forEach(function (item1) {
          if (item == item1.id) {
            arr.push(item1);
          }
        });
      });
      return arr;
    },
    findDifferentElements: function findDifferentElements(arr1, arr2) {
      var arrayDiff = function arrayDiff(a, b) {
        return a.concat(b).filter(function (item, index, arr) {
          return arr.indexOf(item) === arr.lastIndexOf(item);
        });
      };
      return arrayDiff(arr1, arr2);
    },
    deleteItem: function deleteItem(id) {
      var _this4 = this;
      this.oldList = this.oldList.filter(function (item) {
        return item !== id;
      });
      this.list = this.getNewList();
      console.log(this.oldList);
      this.$nextTick(function () {
        _this4.merIds = _this4.oldList;
        _this4.configData.activeValue = _this4.oldList;
        _this4.$emit('getConfig', {
          name: 'merchantName',
          values: _this4.oldList
        });
      });
    },
    mergeArray: function mergeArray(arr1, arr2) {
      arr2.forEach(function (item) {
        arr1 = arr1.filter(function (item1) {
          return item1 !== item;
        });
      });
      return arr1;
    }
  }
};