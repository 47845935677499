"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _linkaddress = _interopRequireDefault(require("@/components/linkaddress"));
var _vuex = require("vuex");
var _devise = require("@/api/devise");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_classify',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    },
    index: {
      type: null
    }
  },
  components: {
    linkaddress: _linkaddress.default,
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      itemObj: {},
      activeIndex: 0,
      tableForm: {
        page: 1,
        limit: 1000,
        name: ''
      },
      microList: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify'])),
  mounted: function mounted() {
    var _this = this;
    this.getList();
    this.$nextTick(function () {
      if (!localStorage.getItem('adminProductClassify')) _this.$store.dispatch('product/getAdminProductClassify');
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
    });
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    }
  },
  methods: {
    //微页面地址
    getList: function getList() {
      var _this2 = this;
      (0, _devise.pagediyListApi)(this.tableForm).then(function (res) {
        _this2.microList = res.list;
      });
    },
    linkUrl: function linkUrl(e) {
      this.configData.list[this.activeIndex].url = "/pages/small_page/index?id=".concat(e);
    },
    getLink: function getLink(index) {
      this.activeIndex = index;
      this.$refs.linkaddres.dialogVisible = true;
    },
    addHotTxt: function addHotTxt() {
      var obj = {
        title: '精选',
        val: '',
        type: 0,
        url: ''
      };
      this.configData.list.push(obj);
    },
    // 删除数组
    bindDelete: function bindDelete(index) {
      if (this.configData.list.length == 1) {
        var itemObj = this.configData.list[0];
        this.itemObj = itemObj;
        var storage = window.localStorage;
        storage.setItem('itemObj', JSON.stringify(itemObj));
      }
      this.configData.list.splice(index, 1);
      this.configData.tabCur = 0;
      this.$emit('getConfig', {
        name: 'delete',
        values: this.configData.list
      });
    },
    sliderChange: function sliderChange(index) {
      this.configData.tabCur = index;
      this.$emit('getConfig', {
        name: 'product',
        indexs: index
      });
    },
    activeBtn: function activeBtn(index) {
      // this.configData.tabCur = index;
      // this.$emit('getConfig', { name: 'product', indexs: index });
    }
  }
};