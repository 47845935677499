"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'banner',
  // 组件名称
  cname: '轮播图',
  // 标题名称
  icon: 't-icon-zujian-lunbotu',
  defaultName: 'swiperBg',
  // 外面匹配名称
  configName: 'c_banner',
  // 右侧配置名称
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: this.configObj.mbConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + 0 + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //内容圆角
    contentStyle: function contentStyle() {
      return [{
        'border-radius': this.configObj.contentStyle.val ? this.configObj.contentStyle.val + 'px' : '0'
      }];
    },
    //指示器样式
    dotStyle: function dotStyle() {
      return [{
        'padding-left': this.configObj.lrConfig.val ? this.configObj.lrConfig.val + 10 + 'px' : '10' + 'px'
      }, {
        'padding-right': this.configObj.lrConfig.val ? this.configObj.lrConfig.val + 10 + 'px' : '10' + 'px'
      }, {
        'justify-content': this.configObj.txtStyle.tabVal === 1 ? 'center' : this.configObj.txtStyle.tabVal === 2 ? 'flex-end' : 'flex-start'
      }];
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'swiperBg',
        isShow: true,
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '轮播图'
        },
        // 图片列表
        swiperConfig: {
          tabTitle: '版块设置',
          tips: '建议：图片尺寸750*332px；鼠标拖拽版块可调整图片顺序',
          title: '建议：图片尺寸750*332px；鼠标拖拽版块可调整图片顺序',
          maxList: 1000,
          list: [{
            img: '',
            info: [{
              title: '标题',
              value: '今日推荐',
              tips: '选填，不超过8个字',
              max: 8
            }, {
              title: '链接',
              value: '',
              tips: '请选择链接',
              max: 100
            }]
          }]
        },
        // 上间距
        upConfig: {
          title: '上边距',
          val: 10,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          tabTitle: '边距设置',
          title: '下边距',
          val: 10,
          min: 0
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 40
        },
        // 页面间距
        mbConfig: {
          title: '页面间距',
          val: 0,
          min: 0
        },
        // 背景颜色
        bgColor: {
          tabTitle: '颜色设置',
          title: '背景颜色',
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        // 指示器颜色
        docColor: {
          title: '指示器颜色',
          name: 'docColor',
          color: [{
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }]
        },
        // 轮播图点样式
        docConfig: {
          cname: 'swiper',
          title: '指示器样式',
          tabTitle: '指示器设置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '圆形',
            icon: 'icon-yuandian'
          }, {
            val: '直线',
            icon: 'icon-xiantiao'
          }, {
            val: '无指示器',
            icon: 'icon-buxianshi'
          }]
        },
        contentStyle: {
          title: '内容圆角',
          name: 'contentStyle',
          val: 7,
          min: 0,
          max: 30
        },
        txtStyle: {
          title: '指示器位置',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '居左',
            icon: 'icon-juzuo'
          }, {
            val: '居中',
            icon: 'icon-juzhong'
          }, {
            val: '居右',
            icon: 'icon-juyou'
          }]
        }
      },
      configObj: null,
      pageData: {},
      imgSrc: '',
      docType: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    onChange: function onChange() {},
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.imgSrc = data.swiperConfig.list.length ? data.swiperConfig.list[0].img : '';
        this.docType = data.docConfig.tabVal;
      }
    }
  }
};