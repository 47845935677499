var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "language-box" },
    [
      _c(
        "el-dropdown",
        {
          staticClass: "international",
          attrs: { trigger: "click" },
          on: { command: _vm.handleSetLanguage },
        },
        [
          _c("div", [
            _vm._v(
              "\n      " + _vm._s(_vm.languageText[_vm.language]) + "\n      "
            ),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
          ]),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                { attrs: { disabled: _vm.language === "zh", command: "zh" } },
                [_vm._v("\n        中文\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                { attrs: { disabled: _vm.language === "mo", command: "mo" } },
                [_vm._v("\n        繁體中文\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }