"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var productRouter = {
  path: '/product',
  component: _layout.default,
  redirect: '/product/index',
  name: 'Product',
  meta: {
    title: '商品',
    icon: 'clipboard'
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/product/index'));
      });
    },
    name: 'ProductIndex',
    meta: {
      title: '商品列表',
      icon: ''
    }
  }, {
    path: 'category',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/product/category/index'));
      });
    },
    name: 'ProductCategory',
    meta: {
      title: '商品分类',
      icon: ''
    }
  }, {
    path: 'comment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/product/comment/index'));
      });
    },
    name: 'ProductComment',
    meta: {
      title: '商品评论',
      icon: ''
    }
  }, {
    path: 'brand',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/product/brand/index'));
      });
    },
    name: 'ProductBrand',
    meta: {
      title: '品牌管理',
      icon: ''
    }
  }, {
    path: 'guarantee',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/product/guarantee/index'));
      });
    },
    name: 'ProductGuarantee',
    meta: {
      title: '保障服务',
      icon: ''
    }
  }]
};
var _default = exports.default = productRouter;