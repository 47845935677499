"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var categoryApi = _interopRequireWildcard(require("@/api/categoryApi.js"));
var _configCategotyEdit = _interopRequireDefault(require("@/views/maintain/devconfig/configCategotyEdit.vue"));
var selfUtil = _interopRequireWildcard(require("@/utils/ZBKJIutil.js"));
var _configList = _interopRequireDefault(require("./configList"));
var _formConfig = _interopRequireDefault(require("@/views/maintain/formConfig"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "configCategroy"
  components: {
    edit: _configCategotyEdit.default,
    configList: _configList.default,
    formConfigList: _formConfig.default
  },
  props: {},
  data: function data() {
    return {
      constants: this.$constants,
      searchPram: {
        status: null,
        type: null
      },
      editDialogConfig: {
        visible: false,
        isCreate: 0,
        // 0=创建，1=编辑
        prent: {},
        // 父级对象
        data: {}
      },
      treeList: [],
      listPram: {
        pid: 0,
        type: this.$constants.categoryType[5].value,
        status: null,
        name: null,
        page: this.$constants.page.page,
        limit: this.$constants.page.limit[1]
      },
      configFormSelectedDialog: {
        visible: false,
        currentData: {}
      }
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:category:list:tree'])) this.handlerGetTreeList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handlerOpenFormConfig: function handlerOpenFormConfig(rowData) {
      this.configFormSelectedDialog.currentData = rowData;
      this.configFormSelectedDialog.visible = true;
    },
    handlerOpenAdd: function handlerOpenAdd(rowData) {
      this.editDialogConfig.isCreate = 0;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.data = {};
      this.editDialogConfig.biztype = this.biztype;
      this.editDialogConfig.visible = true;
    },
    handleEditMenu: function handleEditMenu(rowData) {
      this.editDialogConfig.isCreate = 1;
      this.editDialogConfig.data = rowData;
      this.editDialogConfig.prent = rowData;
      this.editDialogConfig.visible = true;
    },
    handleDelMenu: function handleDelMenu(rowData) {
      var _this = this;
      this.$confirm(this.$t('maintain.devconfig.configCategroy.5u0zeu9n44w0')).then(function () {
        categoryApi.deleteCategroy(rowData.id).then(function (data) {
          _this.handlerGetTreeList();
          _this.$message.success(_this.$t('maintain.devconfig.configCategroy.5u0zeu9n47c0'));
        });
      });
    },
    hideEditDialog: function hideEditDialog() {
      var _this2 = this;
      setTimeout(function () {
        _this2.editDialogConfig.prent = {};
        _this2.editDialogConfig.type = 0;
        _this2.editDialogConfig.visible = false;
        _this2.handlerGetTreeList();
      }, 200);
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this3 = this;
      // status: this.selectModel?1:-1
      var _pram = {
        type: this.constants.categoryType[5].value,
        status: -1
      };
      categoryApi.treeCategroy(_pram).then(function (data) {
        _this3.treeList = _this3.handleAddArrt(data);
      });
    },
    handleAddArrt: function handleAddArrt(treeData) {
      // let _result = this.addTreeListLabel(treeData)
      var _result = selfUtil.addTreeListLabel(treeData);
      return _result;
    },
    handlerSelectedRowData: function handlerSelectedRowData(rowData) {
      this.configFormSelectedDialog.currentData.extra = rowData.name;
    },
    handlerAddFormExtra: function handlerAddFormExtra() {
      var _this4 = this;
      categoryApi.updateCategroy(this.configFormSelectedDialog.currentData).then(function (data) {
        _this4.$message.success(_this4.$t('maintain.devconfig.configCategroy.5u0zeu9n4980'));
        setTimeout(function () {
          _this4.configFormSelectedDialog.visible = false;
          _this4.handlerGetTreeList();
        }, 800);
      });
    }
  }
};