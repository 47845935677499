"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemConfig = require("@/api/systemConfig.js");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'integralconfig',
  data: function data() {
    return {
      isShow: false,
      isCreate: 0,
      editData: {},
      formName: '积分配置',
      loading: false
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:integral:get:config'])) this.getFormInfo();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handlerSubmit: (0, _validate.Debounce)(function (data) {
      var _this = this;
      (0, _systemConfig.integralSetConfigApi)(data).then(function (res) {
        _this.getFormInfo();
        _this.$message.success('操作成功');
      });
    }),
    // 获取表单详情
    getFormInfo: function getFormInfo() {
      var _this2 = this;
      this.loading = true;
      (0, _systemConfig.integralGetConfigApi)().then(function (res) {
        _this2.isShow = false;
        _this2.editData = {
          freezeIntegralDay: res.freezeIntegralDay,
          integralDeductionMoney: res.integralDeductionMoney,
          integralDeductionRatio: res.integralDeductionRatio,
          integralDeductionStartMoney: res.integralDeductionStartMoney,
          integralDeductionSwitch: res.integralDeductionSwitch,
          orderGiveIntegral: res.orderGiveIntegral
        };
        _this2.isCreate = 1;
        setTimeout(function () {
          // 让表单重复渲染待编辑数据
          _this2.isShow = true;
        }, 20);
        _this2.loading = false;
      });
    }
  }
};