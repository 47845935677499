"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.categoryApi = categoryApi;
exports.getWeChatKeywordsByTidApi = getWeChatKeywordsByTidApi;
exports.getWechatConfig = getWechatConfig;
exports.keywordsInfoApi = keywordsInfoApi;
exports.menuCreate = menuCreate;
exports.menuDelete = menuDelete;
exports.myTempInfoApi = myTempInfoApi;
exports.myTempListApi = myTempListApi;
exports.myTempSaveApi = myTempSaveApi;
exports.myTempStatusApi = myTempStatusApi;
exports.myTempTypeApi = myTempTypeApi;
exports.myTempUpdateApi = myTempUpdateApi;
exports.publicTempInfoApi = publicTempInfoApi;
exports.publicTempListApi = publicTempListApi;
exports.routineAsyncApi = routineAsyncApi;
exports.tempAsyncApi = tempAsyncApi;
exports.unbindApi = unbindApi;
exports.wechatAsyncApi = wechatAsyncApi;
exports.wechatAuth = wechatAuth;
exports.wechatCodeDownload = wechatCodeDownload;
exports.wechatMenuAddApi = wechatMenuAddApi;
exports.wechatMenuApi = wechatMenuApi;
exports.wechatReplyDeleteApi = wechatReplyDeleteApi;
exports.wechatReplyInfoApi = wechatReplyInfoApi;
exports.wechatReplyListApi = wechatReplyListApi;
exports.wechatReplySaveApi = wechatReplySaveApi;
exports.wechatReplyStatusApi = wechatReplyStatusApi;
exports.wechatReplyUpdateApi = wechatReplyUpdateApi;
exports.wechatTemplateDeleteApi = wechatTemplateDeleteApi;
exports.wechatTemplateInfoApi = wechatTemplateInfoApi;
exports.wechatTemplateListApi = wechatTemplateListApi;
exports.wechatTemplateSaveApi = wechatTemplateSaveApi;
exports.wechatTemplateStatusApi = wechatTemplateStatusApi;
exports.wechatTemplateUpdateApi = wechatTemplateUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// TODO 微信沟通难度大暂放 呵呵

function menuCreate(data) {
  return (0, _request.default)({
    url: '/admin/platform/wechat/menu/public/create',
    method: 'post',
    params: data
  });
}
function menuDelete(data) {
  return (0, _request.default)({
    url: '/admin/platform/wechat/menu/public/delete',
    method: 'post',
    params: data
  });
}

/**
 * 微信模板消息 列表
 * @param pram
 */
function wechatTemplateListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/template/list",
    method: 'get',
    params: params
  });
}

/**
 * 微信模板消息 新增
 * @param pram
 */
function wechatTemplateSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/wechat/template/save",
    method: 'post',
    data: data
  });
}

/**
 * 微信模板消息 编辑
 * @param pram
 */
function wechatTemplateUpdateApi(id, data) {
  return (0, _request.default)({
    url: "/admin/wechat/template/update/".concat(id),
    method: 'post',
    data: data
  });
}

/**
 * 微信模板消息 详情
 * @param pram
 */
function wechatTemplateInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/wechat/template/info/".concat(id),
    method: 'get'
  });
}

/**
 * 微信模板消息 修改状态
 * @param pram
 */
function wechatTemplateStatusApi(id, params) {
  return (0, _request.default)({
    url: "/admin/wechat/template/update/status/".concat(id),
    method: 'post',
    params: params
  });
}

/**
 * 微信模板消息 删除
 * @param pram
 */
function wechatTemplateDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/wechat/template/delete/".concat(id),
    method: 'get'
  });
}

/**
 * 关键字回复 列表
 * @param pram
 */
function wechatReplyListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/list",
    method: 'get',
    params: params
  });
}

/**
 * 关键字回复 新增
 * @param pram
 */
function wechatReplySaveApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/save",
    method: 'post',
    data: data
  });
}

/**
 * 关键字回复 修改状态
 * @param pram
 */
function wechatReplyStatusApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/status/".concat(id),
    method: 'post'
  });
}
/**
 * 关键字回复 编辑
 * @param pram
 */
function wechatReplyUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/update",
    method: 'post',
    data: data
  });
}

/**
 * 关键字回复 详情
 * @param pram
 */
function wechatReplyInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/info/".concat(id),
    method: 'get'
  });
}

/**
 * 关键字回复 删除
 * @param pram
 */
function wechatReplyDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/delete/".concat(id),
    method: 'get'
  });
}

/**
 * 根据关键字查询微信关键字回复
 * @param pram
 */
function keywordsInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/keywords/reply/info/keywords",
    method: 'get',
    params: params
  });
}

/**
 * 微信菜单 获取数据
 * @param pram
 */
function wechatMenuApi() {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/customize/menu",
    method: 'get'
  });
}

/**
 * 微信菜单 新增
 * @param pram
 */
function wechatMenuAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/public/customize/menu/create",
    method: 'post',
    data: data
  });
}

/**
 * 小程序 公共模板列表
 */
function publicTempListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/public/temp/list",
    method: 'get',
    params: params
  });
}

/**
 * 小程序 模版所属类目
 */
function categoryApi() {
  return (0, _request.default)({
    url: "/admin/wechat/program/category",
    method: 'get'
  });
}

/**
 * 小程序 通过微信模板tid获取关键字列表
 */
function getWeChatKeywordsByTidApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/getWeChatKeywordsByTid",
    method: 'get',
    params: params
  });
}

/**
 * 小程序 模板详情，主要是获取左侧标题
 */
function publicTempInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/public/temp/info",
    method: 'get',
    params: params
  });
}

/**
 * 小程序 我的模板列表
 */
function myTempListApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/list",
    method: 'get',
    params: params
  });
}

/**
 * 小程序 我的模板详情
 */
function myTempInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/info",
    method: 'get',
    params: params
  });
}

/**
 * 小程序 模板新增
 */
function myTempSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/save",
    method: 'post',
    data: data
  });
}

/**
 * 小程序 模板修改
 */
function myTempUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 小程序 我的模板修改状态
 */
function myTempStatusApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/update/status",
    method: 'get',
    params: params
  });
}

/**
 * 小程序 我的模板修改应用场景
 */
function myTempTypeApi(params) {
  return (0, _request.default)({
    url: "/admin/wechat/program/my/temp/update/type",
    method: 'get',
    params: params
  });
}

/**
 * 获取微信sdk配置
 * @returns {*}
 */
function getWechatConfig() {
  return (0, _request.default)({
    url: "/admin/platform/wechat/get/public/js/config",
    method: 'get',
    params: {
      url: encodeURIComponent(location.href.split('#')[0])
    } // for Test
  });
}

/**
 * 微信授权登录
 * @returns {*}
 */
function wechatAuth(code) {
  return (0, _request.default)({
    url: "/admin/authorize/login",
    method: 'get',
    params: {
      code: code
    }
  });
}

/**
 * 与微信解绑账号
 */
function unbindApi() {
  return (0, _request.default)({
    url: "/admin/unbind",
    method: 'get'
  });
}

/**
 * 一键同步我的模板到小程序
 */
function tempAsyncApi() {
  return (0, _request.default)({
    url: "/admin/platform/wechat/program/my/temp/async",
    method: 'get'
  });
}

/**
 * 公众号模板消息同步
 */
function wechatAsyncApi() {
  return (0, _request.default)({
    url: "/admin/platform/wechat/template/whcbqhn/sync",
    method: 'post'
  });
}

/**
 * 小程序模板消息同步
 */
function routineAsyncApi() {
  return (0, _request.default)({
    url: "/admin/platform/wechat/template/routine/sync",
    method: 'post'
  });
}

/**
 * 小程序源码下载
 */
function wechatCodeDownload() {
  return (0, _request.default)({
    url: "/admin/platform/wechat/code/download",
    method: 'get'
  });
}