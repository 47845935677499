"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
var _wechat = require("@/libs/wechat");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var service = _axios.default.create({
  baseURL: _settingMer.default.apiBaseURL,
  timeout: 60000 // 過期時間
});

// request interceptor
service.interceptors.request.use(function (config) {
  // 發送請求之前做的
  var token = !_store.default.getters.token ? sessionStorage.getItem('token') : _store.default.getters.token;
  if (token) {
    config.headers['Authori-zation'] = token;
  }
  if (/get/i.test(config.method)) {
    config.params = config.params || {};
    config.params.temp = Date.parse(new Date()) / 1000;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code === 401) {
    // to re-login
    _elementUi.Message.error('登錄已過期，請重新登錄。');
    // if (window.location.pathname !== '/login') location.href = '/login';
  } else if (res.code === 403) {
    _elementUi.Message.error('沒有權限訪問。');
  }
  if (res.code !== 200 && res.code !== 401) {
    if ((0, _wechat.isPhone)()) {
      //移動端
      return Promise.reject(res || 'Error');
    }
    (0, _elementUi.Message)({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject();
  } else {
    return res.data;
  }
}, function (error) {
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;