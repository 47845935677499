var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "promoterForm",
              staticClass: "demo-promoterForm",
              attrs: {
                model: _vm.promoterForm,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreSwitch" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vmhc0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vnac0"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.promoterForm.retailStoreSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.promoterForm, "retailStoreSwitch", $$v)
                        },
                        expression: "promoterForm.retailStoreSwitch",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vndk0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vnf80")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreLine" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vngw0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vnj00"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      placeholder: _vm.$t(
                        "distribution.config.index.5trlly5vnj00"
                      ),
                      min: -1,
                      step: 1,
                    },
                    nativeOn: {
                      keydown: function ($event) {
                        return _vm.channelInputLimit($event)
                      },
                    },
                    model: {
                      value: _vm.promoterForm.retailStoreLine,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.promoterForm,
                          "retailStoreLine",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "promoterForm.retailStoreLine",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreBindingType" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vnkg0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vnm80"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.promoterForm.retailStoreBindingType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.promoterForm,
                            "retailStoreBindingType",
                            $$v
                          )
                        },
                        expression: "promoterForm.retailStoreBindingType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vnnw0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vnq00")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreBrokerageFirstRatio" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vns80")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vnu00"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      "step-strictly": "",
                      min: 0,
                      max: 100,
                      placeholder: _vm.$t(
                        "distribution.config.index.5trlly5vnu00"
                      ),
                    },
                    model: {
                      value: _vm.promoterForm.retailStoreBrokerageFirstRatio,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.promoterForm,
                          "retailStoreBrokerageFirstRatio",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "promoterForm.retailStoreBrokerageFirstRatio",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("%")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreBrokerageSecondRatio" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vnvs0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vnxs0"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      "step-strictly": "",
                      min: 0,
                      max: 100,
                      placeholder: _vm.$t(
                        "distribution.config.index.5trlly5vnxs0"
                      ),
                    },
                    model: {
                      value: _vm.promoterForm.retailStoreBrokerageSecondRatio,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.promoterForm,
                          "retailStoreBrokerageSecondRatio",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "promoterForm.retailStoreBrokerageSecondRatio",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("%")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreExtractMinPrice" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vnz80")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vo0s0"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      min: 0,
                      step: 1,
                      placeholder: _vm.$t(
                        "distribution.config.index.5trlly5vo0s0"
                      ),
                    },
                    model: {
                      value: _vm.promoterForm.retailStoreExtractMinPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.promoterForm,
                          "retailStoreExtractMinPrice",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "promoterForm.retailStoreExtractMinPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreExtractBank" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vo2s0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5vo780"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("keyword", {
                    staticStyle: { width: "600px" },
                    attrs: { labelarr: _vm.labelarr, type: _vm.keywordType },
                    on: { getLabelarr: _vm.getLabelarr },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "retailStoreBrokerageFreezingTime" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("distribution.config.index.5trlly5vo8s0")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.$t(
                              "distribution.config.index.5trlly5voa80"
                            ),
                            placement: "top-start",
                          },
                        },
                        [_c("i", { staticClass: "el-icon-warning-outline" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input-number", {
                    staticClass: "selWidth",
                    attrs: {
                      min: 0,
                      placeholder: _vm.$t(
                        "distribution.config.index.5trlly5voa80"
                      ),
                    },
                    model: {
                      value: _vm.promoterForm.retailStoreBrokerageFreezingTime,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.promoterForm,
                          "retailStoreBrokerageFreezingTime",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "promoterForm.retailStoreBrokerageFreezingTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "hasPermi",
                          rawName: "v-hasPermi",
                          value: ["platform:retail:store:config:save"],
                          expression: "['platform:retail:store:config:save']",
                        },
                      ],
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("promoterForm")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("distribution.config.index.5trlly5vod00"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }