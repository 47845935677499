"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _index = _interopRequireDefault(require("../mobileConfigRight/index.js"));
var _vuex = require("vuex");
var _index2 = _interopRequireDefault(require("../rightBtn/index.vue"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_home_tab',
  componentsName: 'home_tab',
  cname: '选项卡',
  props: {
    activeIndex: {
      type: null
    },
    num: {
      type: null
    },
    index: {
      type: null
    }
  },
  components: _objectSpread(_objectSpread({}, _index.default), {}, {
    rightBtn: _index2.default
  }),
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify', 'productBrand'])), {}, {
    //商品排序
    goodsSort: function goodsSort() {
      return this.configObj.goodsSort.tabVal;
    },
    //选择方式,0,指定商品,1指定品牌，2指定分类，3指定商户
    tabConfig: function tabConfig() {
      return this.configObj.tabConfig.tabVal;
    }
  }),
  data: function data() {
    return {
      configObj: {},
      rCom: [{
        components: _index.default.c_checked_tab,
        configNme: 'setUp'
      }],
      automatic: [{
        components: _index.default.c_title,
        configNme: 'tabItemConfig'
      }, {
        components: _index.default.c_tab_input,
        configNme: 'tabItemConfig'
      }, {
        components: _index.default.c_tab_radio,
        configNme: 'tabConfig'
      }, {
        components: _index.default.c_goods,
        configNme: 'goodsList'
      }, {
        components: _index.default.c_select,
        configNme: 'activeValueBrand'
      }, {
        components: _index.default.c_cascader,
        configNme: 'selectConfig'
      }, {
        components: _index.default.c_merchantName,
        configNme: 'activeValueMer'
      }, {
        components: _index.default.c_input_number,
        configNme: 'numConfig'
      }, {
        components: _index.default.c_txt_tab,
        configNme: 'goodsSort'
      }, {
        components: _index.default.c_title,
        configNme: 'itemStyle'
      }, {
        components: _index.default.c_txt_tab,
        configNme: 'itemStyle'
      }, {
        components: _index.default.c_title,
        configNme: 'typeConfig'
      }, {
        components: _index.default.c_checkbox_group,
        configNme: 'typeConfig'
      }],
      setUp: 0,
      type: 0,
      lockStatus: false,
      goodsList: [] //指定商品列表
    };
  },
  watch: {
    num: function num(nVal) {
      var value = JSON.parse(JSON.stringify(this.$store.state.mobildConfig.defaultArray[nVal]));
      this.configObj = value;
    },
    configObj: {
      handler: function handler(nVal, oVal) {
        this.$store.commit('mobildConfig/UPDATEARR', {
          num: this.num,
          val: nVal
        });
      },
      deep: true
    },
    'configObj.setUp.tabVal': {
      handler: function handler(nVal, oVal) {
        this.setUp = nVal;
        var arr = [this.rCom[0]];
        if (nVal == 0) {
          this.rCom = arr.concat(this.automatic);
        } else {
          var tempArr = [{
            components: _index.default.c_title,
            configNme: 'fontColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'tabBgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'fontColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'checkColor'
          }, {
            components: _index.default.c_title,
            configNme: 'labelColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'bgColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'labelColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'labelFontColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'titleColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'priceColor'
          }, {
            components: _index.default.c_bg_color,
            configNme: 'soldColor'
          }, {
            components: _index.default.c_title,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'upConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'downConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'topConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'lrConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'contentConfig'
          }, {
            components: _index.default.c_slider,
            configNme: 'mbConfig'
          }, {
            components: _index.default.c_title,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'bgStyle'
          }, {
            components: _index.default.c_slider,
            configNme: 'contentStyle'
          }];
          this.rCom = arr.concat(tempArr);
        }
      },
      deep: true
    },
    //选择方式选择，0指定商品，1指定品牌，2指定分类，3指定商户
    'configObj.tabConfig.tabVal': {
      handler: function handler(nVal, oVal) {
        this.type = nVal;
        switch (this.type) {
          case 0:
            this.$set(this.configObj.selectConfig, 'list', this.adminProductClassify);
            this.$set(this.configObj.numConfig, 'isShow', 0); //是否显示inputnumber框
            this.$set(this.configObj.goodsList, 'isShow', 1); //选择商品
            this.$set(this.configObj.activeValueBrand, 'isShow', 0); //选择品牌
            this.$set(this.configObj.selectConfig, 'isShow', 0); //选择分类
            this.$set(this.configObj.activeValueMer, 'isShow', 0); //选择商户
            this.$set(this.configObj.goodsSort, 'isShow', 0); //商品排序
            break;
          case 1:
            this.$set(this.configObj.activeValueBrand, 'list', this.productBrand);
            this.$set(this.configObj.numConfig, 'isShow', 1);
            this.$set(this.configObj.goodsList, 'isShow', 0); //选择商品
            this.$set(this.configObj.activeValueBrand, 'isShow', 1); //选择品牌
            this.$set(this.configObj.selectConfig, 'isShow', 0); //选择分类
            this.$set(this.configObj.activeValueMer, 'isShow', 0); //选择商户
            this.$set(this.configObj.goodsSort, 'isShow', 1); //商品排序
            break;
          case 2:
            this.$set(this.configObj.selectConfig, 'list', this.adminProductClassify);
            this.$set(this.configObj.numConfig, 'isShow', 1);
            this.$set(this.configObj.goodsList, 'isShow', 0); //选择商品
            this.$set(this.configObj.activeValueBrand, 'isShow', 0); //选择品牌
            this.$set(this.configObj.selectConfig, 'isShow', 1); //选择分类
            this.$set(this.configObj.activeValueMer, 'isShow', 0); //选择商户
            this.$set(this.configObj.goodsSort, 'isShow', 1); //商品排序
            break;
          case 3:
            this.$set(this.configObj.numConfig, 'isShow', 1);
            this.$set(this.configObj.goodsList, 'isShow', 0); //选择商品
            this.$set(this.configObj.activeValueBrand, 'isShow', 0); //选择品牌
            this.$set(this.configObj.selectConfig, 'isShow', 0); //选择分类
            this.$set(this.configObj.activeValueMer, 'isShow', 1); //选择商户
            this.$set(this.configObj.goodsSort, 'isShow', 1); //商品排序
            break;
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      var value = JSON.parse(JSON.stringify(_this.$store.state.mobildConfig.defaultArray[_this.num]));
      _this.configObj = value;
      if (!localStorage.getItem('adminProductClassify')) _this.$store.dispatch('product/getAdminProductClassify');
      if (!localStorage.getItem('productBrand')) _this.$store.dispatch('product/getMerProductBrand');
      _this.$nextTick(function () {
        _this.$set(_this.configObj.selectConfig, 'list', _this.adminProductClassify);
      });
    });
  },
  methods: {
    getConfig: function getConfig(data) {
      //选择tab获取商品列表.0指定商品
      if (data.name && data.name === 'goods') {
        this.configObj.goodsList.list = data.values;
        this.setProConfig();
      }
      //删除事件
      if (data.name === 'del_tab') {
        this.configObj.tabItemConfig.tabVal = 0;
      }
      //商品列表选择方式
      if (data.name && data.name === 'tab_radio') {
        this.configObj.tabConfig.tabVal = data.values;
        this.setProConfig();
      }
      if (data.name && data.name === 'radio') this.setProConfig();
      //选择tab获取商品列表,1指定品牌，2指定分类，3指定商户
      if (this.configObj.tabConfig.tabVal > 0) this.getProList(data);
      //选择选项卡tab切换
      this.changeTab(data);
    },
    //选择选项卡tab切换
    changeTab: function changeTab(data) {
      if (data.name && data.name === 'tab_input') {
        this.configObj.tabItemConfig.tabVal = data.values; //选项卡选中的值
        this.onTabConfig(data);
        this.onGoodsSort(data);
        this.setProConfig();
      }
    },
    //选项卡选择后指定选择商品列表
    onTabConfig: function onTabConfig(data) {
      this.configObj.tabConfig.tabVal = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.activeProTabIndex : 0;
      switch (this.configObj.tabConfig.tabVal) {
        case 0:
          this.configObj.goodsList.list = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.goods : [];
          break;
        case 1:
          //商品品牌选中的值
          this.configObj.activeValueBrand.activeValue = this.configObj.tabItemConfig.list[data.values].activeList && this.configObj.tabItemConfig.list[data.values].activeList.activeValue.length ? this.configObj.tabItemConfig.list[data.values].activeList.activeValue.split(',').map(Number) : [];
          break;
        case 2:
          //商品分类选中的值
          this.configObj.selectConfig.activeValue = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.activeValue.split(',').map(Number) : [];
          break;
        case 3:
          //商户选中的值
          this.configObj.activeValueMer.activeValue = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.activeValue.split(',') : [];
          break;
      }
    },
    //选项卡选择后排序/数量/样式/展示内容
    onGoodsSort: function onGoodsSort(data) {
      this.configObj.goodsSort.tabVal = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.goodsSort : 0;
      this.configObj.numConfig.val = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.num : 6;
      this.configObj.itemStyle.tabVal = this.configObj.tabItemConfig.list[data.values].activeList ? this.configObj.tabItemConfig.list[data.values].activeList.styleType : 0;
    },
    //选择tab获取商品列表
    getProList: function getProList(data) {
      var _this2 = this;
      if (data.name && (data.name === 'merchantName' || data.name === 'select' || data.name === 'cascader' || data.name === 'radio' || data.name === 'number')) {
        var activeValue = '';
        var priceOrder = '';
        var salesOrder = '';
        switch (this.configObj.tabConfig.tabVal) {
          case 0:
            break;
          case 1:
            activeValue = this.configObj.activeValueBrand.activeValue.join(',');
            break;
          case 2:
            activeValue = this.configObj.selectConfig.activeValue.join(',');
            break;
          case 3:
            activeValue = this.configObj.activeValueMer.activeValue.join(',');
            break;
        }
        if (this.goodsSort === 0) {
          priceOrder = '';
          salesOrder = '';
        } else if (this.goodsSort === 1) {
          priceOrder = '';
          salesOrder = 'desc';
        } else {
          priceOrder = 'desc';
          salesOrder = '';
        }
        (0, _product.productActivityListApi)({
          categoryId: this.configObj.tabConfig.tabVal === 2 ? activeValue : null,
          page: 1,
          limit: this.configObj.numConfig.val,
          type: '1',
          brandId: this.configObj.tabConfig.tabVal === 1 ? activeValue : null,
          merIds: this.configObj.tabConfig.tabVal === 3 ? activeValue : null,
          priceOrder: priceOrder,
          salesOrder: salesOrder
        }).then(function (res) {
          //选择tab获取商品列表,1指定品牌，2指定分类，3指定商户
          switch (_this2.configObj.tabConfig.tabVal) {
            case 1:
              _this2.configObj.activeValueBrand.goodsList = res.list;
              break;
            case 2:
              _this2.configObj.selectConfig.goodsList = res.list;
              break;
            case 3:
              _this2.configObj.activeValueMer.goodsList = res.list;
              break;
          }
          _this2.setProConfig();
        });
      }
    },
    //设置数据对象
    setProConfig: function setProConfig() {
      var activeValue = '';
      switch (this.configObj.tabConfig.tabVal) {
        case 0:
          break;
        case 1:
          activeValue = this.configObj.activeValueBrand.activeValue.length ? this.configObj.activeValueBrand.activeValue.join(',') : [];
          break;
        case 2:
          activeValue = this.configObj.selectConfig.activeValue.join(',');
          break;
        case 3:
          activeValue = this.configObj.activeValueMer.activeValue.join(',');
          break;
      }
      this.configObj.tabItemConfig.list[this.configObj.tabItemConfig.tabVal].activeList = {
        goods: this.configObj.goodsList.list || [],
        //商品列表，只有指定商品使用
        activeProTabIndex: this.configObj.tabConfig.tabVal,
        //商品列表类型，商品、品牌、分类、商户
        activeValue: activeValue,
        //品牌、分类、商户选中的值（分别）
        styleType: this.configObj.itemStyle.tabVal,
        //样式选项
        num: this.configObj.numConfig.val,
        //商品数量
        goodsSort: this.configObj.goodsSort.tabVal //商品排序
      };
      this.configObj.tabItemConfig.list[this.configObj.tabItemConfig.tabVal].activeTabIndex = this.configObj.tabItemConfig.tabVal;
      this.configObj.tabItemConfig.list[this.configObj.tabItemConfig.tabVal].brandGoodsList = this.configObj.activeValueBrand.goodsList;
      this.configObj.tabItemConfig.list[this.configObj.tabItemConfig.tabVal].selectConfigList = this.configObj.selectConfig.goodsList;
      this.configObj.tabItemConfig.list[this.configObj.tabItemConfig.tabVal].merList = this.configObj.activeValueMer.goodsList;
    }
  }
};