"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
var _yearOptions = _interopRequireDefault(require("@/libs/yearOptions"));
var _xlsx = _interopRequireDefault(require("xlsx"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 權限判斷函數
var _default = exports.default = {
  name: 'statement',
  components: {},
  data: function data() {
    return {
      orderSourceList: [{
        value: '',
        text: this.$t('order.index.5tqsfbq9hgs0')
      }, {
        value: '1',
        text: this.$t('order.index.5tqsfbq9xxx0')
      }, {
        value: '2',
        text: this.$t('order.index.5tqsfbq9aqw0')
      }],
      orderSource: '',
      activeNames: [],
      timeVal: [],
      activeName: 'day',
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      tableFrom: {
        page: 1,
        limit: 20,
        dateLimit: ''
      },
      dialogVisible: false,
      accountDetails: {},
      pickerOptions: this.$timeOptions,
      pickerOptionsYear: _yearOptions.default
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:finance:daily:statement:page:list', 'platform:finance:month:statement:page:list'])) this.getList(1);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    // 具體日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.getList(1);
    },
    handleClick: function handleClick() {
      this.tableFrom.dateLimit = '';
      this.timeVal = [];
      this.getList(1);
    },
    onDetails: function onDetails(date) {
      this.dialogVisible = true;
      this.accountDetails = date;
    },
    seachList: function seachList() {
      this.handleClose();
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      console.log(this.orderSource);
      this.tableFrom.orderSource = this.orderSource;
      this.activeName === 'day' ? (0, _finance.dayStatementApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      }) : (0, _finance.monthStatementApi)(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function () {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    exports: function exports() {
      var _this2 = this;
      var obj = {};
      obj.orderSource = this.tableFrom.orderSource;
      obj.dateLimit = this.tableFrom.dateLimit;
      obj.limit = 9999999;
      console.log(obj);
      if (this.activeName == 'day') {
        (0, _finance.dayStatementApi)(obj).then(function (res) {
          console.log(res, 'm');
          _this2.exportDataToExcel(res.list, '日賬單.xlsx');
        }).catch(function () {});
      } else {
        (0, _finance.monthStatementApi)(obj).then(function (res) {
          console.log(res, 'm');
          _this2.exportDataToExcel(res.list, '月賬單.xlsx');
        }).catch(function () {});
      }
    },
    exportDataToExcel: function exportDataToExcel(data, filename) {
      // 1. 获取表头
      var headerRow = Array.from(this.$refs.table.$el.querySelectorAll('.el-table__header-wrapper th'));
      var labelValues = headerRow.map(function (header) {
        return header.textContent.trim(); // 使用textContent获取表头文本
      });
      // 去除数组里面是空的
      var newList = labelValues.filter(function (item) {
        return item !== "";
      });
      // 2. 获取数据
      var dataArray = data.map(function (obj, index) {
        var arr = [obj.id, obj.orderSource == 1 ? '小程序' : 'H5', obj.dataDate, obj.currentDayBalance, obj.merchantTransferAmount, obj.handlingFee, obj.orderReceivable, obj.payNum, obj.orderRefundable, obj.refundNum, obj.platCouponPrice];
        return arr;
      });
      dataArray.unshift(newList);
      // 3. 导出Excel
      var worksheet = _xlsx.default.utils.aoa_to_sheet(dataArray);
      var workbook = _xlsx.default.utils.book_new();
      _xlsx.default.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      _xlsx.default.writeFile(workbook, filename);
    }
  }
};