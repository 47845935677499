"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elementUi = _interopRequireDefault(require("element-ui"));
require("@/styles/element-variables.scss");
var _index = _interopRequireDefault(require("./index.vue"));
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _lang = _interopRequireDefault(require("@/lang"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
var uploadFrom = {};
uploadFrom.install = function (Vue, options) {
  var ToastConstructor = Vue.extend(_index.default);
  // 生成一个该子类的实例
  var instance = new ToastConstructor();
  instance.$mount(document.createElement('div'));
  document.body.appendChild(instance.$el);
  Vue.prototype.$modalUpload = function (callback, isMore, modelName, boolean) {
    instance.visible = true;
    instance.callback = callback;
    instance.isMore = isMore; //是否是多选
    instance.modelName = modelName; //上传文件包名字
    instance.booleanVal = boolean;
  };
};
var _default = exports.default = uploadFrom;