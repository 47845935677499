"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.captchaApi = captchaApi;
exports.configApi = configApi;
exports.exportTempApi = exportTempApi;
exports.expressAllApi = expressAllApi;
exports.isLoginApi = isLoginApi;
exports.logoutApi = logoutApi;
exports.payCodeApi = payCodeApi;
exports.phoneValidatorApi = phoneValidatorApi;
exports.registerApi = registerApi;
exports.serviceOpenApi = serviceOpenApi;
exports.smsInfoApi = smsInfoApi;
exports.smsLstApi = smsLstApi;
exports.smsNumberApi = smsNumberApi;
exports.smsPriceApi = smsPriceApi;
exports.smsSaveApi = smsSaveApi;
exports.smsSignApi = smsSignApi;
exports.smsTempLstApi = smsTempLstApi;
exports.tempCreateApi = tempCreateApi;
exports.updateHoneApi = updateHoneApi;
exports.updatePasswordApi = updatePasswordApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * @description 短信发送记录 -- 列表
 */
function smsLstApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/user/record',
    method: 'get',
    params: params
  });
}
/**
 * @description 短信账户 -- 登录
 */
function configApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/login',
    method: 'post',
    data: data
  });
}
/**
 * @description 短信账户 -- 获取验证码
 */
function captchaApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/one/pass/sendUserCode",
    method: 'post',
    data: data
  });
}
/**
 * @description 短信账户 -- 注册
 */
function registerApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/register',
    method: 'post',
    data: data
  });
}
/**
 * @description 短信账户 -- 是否登录
 */
function isLoginApi() {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/isLogin',
    method: 'get'
  });
}
/**
 * @description 短信账户 -- 退出登录
 */
function logoutApi() {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/logout',
    method: 'post'
  });
}
/**
 * @description 短信账户 -- 剩余条数
 */
function smsNumberApi() {
  return (0, _request.default)({
    url: '/admin/sms/logout',
    method: 'get'
  });
}
/**
 * @description 短信模板 -- 列表
 */
function smsTempLstApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/sms/temps',
    method: 'get',
    params: params
  });
}
/**
 * @description 短信购买 -- 支付套餐
 */
function smsPriceApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/meal/list',
    method: 'get',
    params: params
  });
}
/**
 * @description 短信购买 -- 支付码
 */
function payCodeApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/meal/code',
    method: 'post',
    data: data
  });
}
/**
 * @description 短信模板 -- 添加表单
 */
function tempCreateApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/sms/temp/apply',
    method: 'post',
    data: data
  });
}
/**
 * @description 短信 -- 用户信息
 */
function smsInfoApi() {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/info',
    method: 'get'
  });
}

/**
 * @description 短信 -- 短信提醒开关保存
 */
function smsSaveApi(params) {
  return (0, _request.default)({
    url: '/admin/sms/config/save',
    method: 'post',
    params: params
  });
}

/**
 * @description 短信 -- 修改密码
 */
function updatePasswordApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/update/password',
    method: 'post',
    data: data
  });
}

/**
 * @description 短信 -- 修改手机号
 */
function updateHoneApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/update/phone',
    method: 'post',
    data: data
  });
}

/**
 * @description 一号通 -- 服务开通
 */
function serviceOpenApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/service/open',
    method: 'post',
    data: data
  });
}

/**
 * @description 一号通 -- 电子面单模板
 */
function exportTempApi(params) {
  return (0, _request.default)({
    url: '/admin/express/template',
    method: 'get',
    params: params
  });
}

/**
 * @description 全部物流公司
 */
function expressAllApi(params) {
  return (0, _request.default)({
    url: 'admin/express/all',
    method: 'get',
    params: params
  });
}

/**
 * @description 修改签名
 */
function smsSignApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/one/pass/sms/modify/sign',
    method: 'post',
    data: data
  });
}

/**
 * @description 修改手机号验证账号密码
 */
function phoneValidatorApi(data) {
  return (0, _request.default)({
    url: 'admin/platform/one/pass/update/phone/validator',
    method: 'post',
    data: data
  });
}