"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachmentMoveApi = attachmentMoveApi;
exports.fileDeleteApi = fileDeleteApi;
exports.fileFileApi = fileFileApi;
exports.fileImageApi = fileImageApi;
exports.fileListApi = fileListApi;
exports.wechatUploadApi = wechatUploadApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 文件上传
 * @param data
 */
function fileFileApi(data, params) {
  return (0, _request.default)({
    url: '/admin/platform/upload/file',
    method: 'POST',
    params: params,
    data: data
  });
}

/**
 * 图片上传
 * @param data
 */
function fileImageApi(data, params) {
  return (0, _request.default)({
    url: '/admin/platform/upload/image',
    method: 'POST',
    params: params,
    data: data
  });
}

/**
 * 图片列表
 * @param data
 */
function fileListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/attachment/list',
    method: 'get',
    params: params
  });
}

/**
 * 图片列表 删除图片
 * @param data
 */
function fileDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/attachment/delete",
    method: 'post',
    data: {
      ids: id
    }
  });
}

/**
 * 图片列表 移動分類
 * @param data
 */
function attachmentMoveApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/attachment/move",
    method: 'post',
    data: data
  });
}

/**
 * 微信开放平台上传素材
 * @param data
 */
function wechatUploadApi(data, params) {
  return (0, _request.default)({
    url: "/admin/platform/wechat/open/media/upload",
    method: 'post',
    data: data,
    params: params
  });
}