"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wxApi = require("@/api/wxApi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {};
  },
  methods: {
    downCode: function downCode() {
      (0, _wxApi.wechatCodeDownload)().then(function (res) {
        window.open(res);
      });
    }
  }
};