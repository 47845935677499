"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var components = require.context('./', false, /\.vue$/);
var _default = exports.default = function _default(Vue) {
  components.keys().map(function (item) {
    Vue.component(components(item).default.name, components(item).default);
  });
};