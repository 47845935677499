"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_slider',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  data: function data() {
    return {
      defaults: {},
      sliderWidth: 0,
      configData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
    });
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    }
  },
  methods: {
    sliderChange: function sliderChange(e) {}
  }
};