"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_footer',
  cname: '底部导航',
  configName: 'c_home_footer',
  icon: 't-icon-zujian-dibucaidan',
  type: 0,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'footer',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), {}, {
    //外部盒子
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        background: "linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      }, {
        margin: '0' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }];
    },
    //标签文字颜色
    fontColor: function fontColor() {
      return this.configObj.fontColor.color[0].item;
    },
    //选中颜色
    checkColor: function checkColor() {
      return {
        background: this.configObj.checkColor.color[0].item
      };
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'footer',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '底部导航'
        },
        // 背景颜色
        bgColor: {
          title: '背景颜色',
          tabTitle: '颜色设置',
          color: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }],
          default: [{
            item: '#FFFFFF'
          }, {
            item: '#FFFFFF'
          }]
        },
        fontColor: {
          tabTitle: '标签设置',
          title: '文字颜色',
          name: 'fontColor',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        checkColor: {
          title: '选中颜色',
          name: 'checkColor',
          color: [{
            item: '#E93323'
          }],
          default: [{
            item: '#E93323'
          }]
        },
        lrConfig: {
          title: '左右边距',
          tabTitle: '边距设置',
          val: 0,
          min: 0
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 0,
          min: 0,
          max: 30
        },
        menuList: {
          tabTitle: '内容设置',
          tabVal: 0,
          list: [{
            checked: require('@/assets/imgs/foot-002.png'),
            unchecked: require('@/assets/imgs/foot-001.png'),
            name: '首页',
            link: '/pages/index/index'
          }, {
            checked: require('@/assets/imgs/foot-004.png'),
            unchecked: require('@/assets/imgs/foot-003.png'),
            name: '分类',
            link: '/pages/goods_cate/index'
          }, {
            checked: require('@/assets/imgs/foot-006.png'),
            unchecked: require('@/assets/imgs/foot-005.png'),
            name: '逛逛',
            link: '/pages/discover_index/index'
          }, {
            checked: require('@/assets/imgs/foot-008.png'),
            unchecked: require('@/assets/imgs/foot-007.png'),
            name: '购物车',
            link: '/pages/order_addcart/order_addcart'
          }, {
            checked: require('@/assets/imgs/foot-0010.png'),
            unchecked: require('@/assets/imgs/foot-009.png'),
            name: '我的',
            link: '/pages/user/index'
          }]
        }
      },
      pageData: {},
      curIndex: 0,
      configObj: null,
      menuList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
      _this.setConfig(_this.pageData);
    });
  },
  methods: {
    // 页面底部点击
    showFoot: function showFoot() {
      // this.activeIndex = -101;
      // let obj = {};
      // for (var i in mConfig) {
      //   if (i == 'pageFoot') {
      //     obj = mConfig[i];
      //     obj.configName = mConfig[i].name;
      //     obj.cname = '底部菜单';
      //   }
      // }
      // let abc = obj;
      // this.rConfig = [];
      // this.rConfig[0] = JSON.parse(JSON.stringify(obj));
    },
    setConfig: function setConfig(data) {
      if (!data) return;
      if (data) {
        this.configObj = data;
        this.menuList = data.menuList.list;
      }
    }
  }
};