"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_input_number',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  data: function data() {
    return {
      defaults: {},
      sliderWidth: 0,
      configData: {}
    };
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
        //从数组中选择相应的值去赋值，productList为提交数据的集合，nVal.productList[nVal.tabItemConfig.tabVal]为选择这个tab时的对象
        // if (nVal.name === 'homeTab') this.configData.tabVal = nVal.productList[nVal.tabItemConfig.tabVal].goodsSort;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    bindChange: function bindChange() {
      this.$emit('getConfig', {
        name: 'number',
        numVal: this.configData.val
      });
      // console.log(this.configData)
    }
  }
};