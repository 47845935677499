var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox relative" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              ref: "headerBox",
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:coupon:page:list"],
                      expression: "['platform:coupon:page:list']",
                    },
                  ],
                  staticClass: "container",
                },
                [
                  _c(
                    "el-form",
                    { attrs: { size: "small", inline: "" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "marketing.platformCoupon.couponList.index.5tqtlfwsd3o0"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: {
                                placeholder: _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwse7s0"
                                ),
                                clearable: "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "status", $$v)
                                },
                                expression: "tableFrom.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwseeo0"
                                  ),
                                  value: 1,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsejc0"
                                  ),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "marketing.platformCoupon.couponList.index.5tqtlfwsenc0"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwserk0"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.receiveType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "receiveType", $$v)
                                },
                                expression: "tableFrom.receiveType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsevs0"
                                  ),
                                  value: 1,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsezs0"
                                  ),
                                  value: 3,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "marketing.platformCoupon.couponList.index.5tqtlfwsf3w0"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item selWidth mr20",
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwsf800"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getList(1)
                                },
                              },
                              model: {
                                value: _vm.tableFrom.category,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tableFrom, "category", $$v)
                                },
                                expression: "tableFrom.category",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsfc40"
                                  ),
                                  value: 2,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsfg40"
                                  ),
                                  value: 3,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsfk80"
                                  ),
                                  value: 4,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsfok0"
                                  ),
                                  value: 5,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsfsc0"
                                  ),
                                  value: 6,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              "marketing.platformCoupon.couponList.index.5tqtlfwsfw40"
                            ),
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "350px" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwsg0k0"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name =
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                },
                                expression: "name",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getList(1)
                                  },
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["platform:coupon:add"],
                      expression: "['platform:coupon:add']",
                    },
                  ],
                  attrs: {
                    to: { path: "/marketing/platformCoupon/creatCoupon" },
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "mr10",
                      attrs: { size: "small", type: "primary" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwsg4g0"
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", "min-width": "50" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  "show-overflow-tooltip": true,
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsg8k0"
                  ),
                  "min-width": "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "channel",
                  label: "领取渠道",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.cChannelList[scope.row.channel - 1]
                                ? _vm.cChannelList[scope.row.channel - 1].name
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "category",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsgcg0"
                  ),
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("couponCategory")(scope.row.category))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "money",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsghc0"
                  ),
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "minPrice",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsgkw0"
                  ),
                  "min-width": "90",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveType",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwshy40"
                  ),
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("receiveType")(scope.row.receiveType))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "receiveStartTime",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsi5s0"
                  ),
                  "min-width": "150",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.receiveEndTime
                          ? _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.receiveStartTime) +
                                  " -"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.receiveEndTime) +
                                  "\n            "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsia00"
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "issuedNum",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsidc0"
                  ),
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.usedNum) +
                              "/" +
                              _vm._s(scope.row.issuedNum)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isLimited",
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsih00"
                  ),
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              !scope.row.isLimited
                                ? _vm.$t(
                                    "marketing.platformCoupon.couponList.index.5tqtlfwsil40"
                                  )
                                : scope.row.total
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsipc0"
                  ),
                  fixed: "right",
                  "min-width": "90",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:coupon:switch"])
                          ? _c("el-switch", {
                              attrs: {
                                "active-text": _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwseeo0"
                                ),
                                "inactive-text": _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwsejc0"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onchangeIsShow(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.status,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "status", $$v)
                                },
                                expression: "scope.row.status",
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.status
                                      ? _vm.$t(
                                          "marketing.platformCoupon.couponList.index.5tqtlfwseeo0"
                                        )
                                      : _vm.$t(
                                          "marketing.platformCoupon.couponList.index.5tqtlfwsejc0"
                                        )
                                  ) +
                                  "\n            "
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.creatCoupon.5tqu9q5j91k1"
                  ),
                  "min-width": "70",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.currGenerate))]),
                        _vm._v(" "),
                        _c("span", [_vm._v("/")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(scope.row.maxGenerate))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.platformCoupon.couponList.index.5tqtlfwsixs0"
                  ),
                  "min-width": "160",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:coupon:detail"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.look(scope.row.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.platformCoupon.couponList.index.5tqtlfwsj280"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                        _vm._v(" "),
                        _c(
                          "el-dropdown",
                          { attrs: { trigger: "click" } },
                          [
                            _c("span", { staticClass: "el-dropdown-link" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.platformCoupon.couponList.index.5tqtlfwsj5s0"
                                    )
                                  )
                              ),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-down el-icon--right",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:coupon:detail",
                                          "platform:coupon:update",
                                        ],
                                        expression:
                                          "['platform:coupon:detail', 'platform:coupon:update']",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/marketing/platformCoupon/creatCoupon/" +
                                              scope.row.id,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "marketing.platformCoupon.couponList.index.5tqtlfwsja40"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "platform:coupon:detail",
                                          "platform:coupon:add",
                                        ],
                                        expression:
                                          "['platform:coupon:detail', 'platform:coupon:add']",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            path:
                                              "/marketing/platformCoupon/creatCoupon/" +
                                              scope.row.id +
                                              "/" +
                                              1,
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "marketing.platformCoupon.couponList.index.5tqtlfwsjec0"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: ["platform:coupon:delete"],
                                        expression:
                                          "['platform:coupon:delete']",
                                      },
                                    ],
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleDelete(scope.row.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "marketing.platformCoupon.couponList.index.5tqtlfwsjis0"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                scope.row.receiveType == 5
                                  ? _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.couponCodeExport(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "marketing.platformCoupon.couponList.index.5tqtlfwsjqd0"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t(
              "marketing.platformCoupon.couponList.index.5tqtlfwsjns0"
            ),
            size: "800px",
            visible: _vm.showInfo,
            direction: "rtl",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInfo = $event
            },
          },
        },
        [
          _vm.showTab
            ? _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: {
                      label: _vm.$t(
                        "marketing.platformCoupon.couponList.index.5tqtlfwsjro0"
                      ),
                      name: "one",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: _vm.tabPaneName, name: "two" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeNames == "one",
                  expression: "activeNames == 'one'",
                },
              ],
              staticClass: "px20",
            },
            [
              _c(
                "div",
                { staticClass: "section" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "marketing.platformCoupon.couponList.index.5tqtlfwsjuw0"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v("优惠券名称：" + _vm._s(_vm.cell.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "领取渠道：" +
                        _vm._s(
                          _vm.cChannelList[_vm.cell.channel - 1]
                            ? _vm.cChannelList[_vm.cell.channel - 1].name
                            : ""
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "使用范围：" +
                        _vm._s(_vm._f("couponCategory")(_vm.cell.category))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v("优惠券面值：" + _vm._s(_vm.cell.money)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v("使用门槛：" + _vm._s(_vm.cell.minPrice)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "领取方式：" +
                        _vm._s(_vm._f("receiveType")(_vm.cell.receiveType))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "\n            领取时间：" +
                        _vm._s(
                          _vm.cell.isTimeReceive
                            ? _vm.cell.receiveStartTime +
                                " - " +
                                _vm.cell.receiveEndTime
                            : _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwsia00"
                              )
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "\n            使用有效期：" +
                        _vm._s(
                          _vm.cell.isFixedTime
                            ? _vm.cell.useStartTime +
                                "-" +
                                _vm.cell.useEndTime +
                                _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwsjzg0"
                                )
                            : _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwsk3s0"
                              ) +
                                _vm.cell.day +
                                _vm.$t(
                                  "marketing.platformCoupon.couponList.index.5tqtlfwsk9g0"
                                )
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "\n            发布数量：" +
                        _vm._s(
                          !_vm.cell.isLimited
                            ? _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwsil40"
                              )
                            : _vm.cell.total
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "重复领取：" +
                        _vm._s(
                          _vm.cell.isRepeated ? "可重复领取" : "不可重复领取"
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v(
                      "\n            是否开启：" +
                        _vm._s(
                          _vm.cell.status
                            ? _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwseeo0"
                              )
                            : _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwsejc0"
                              )
                        ) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title mt20" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "marketing.platformCoupon.couponList.index.5tqtlfwskcw0"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v("已发放数量：" + _vm._s(_vm.cell.issuedNum)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cell" }, [
                    _vm._v("已使用数量：" + _vm._s(_vm.cell.usedNum)),
                  ]),
                  _vm._v(" "),
                  _vm.cell.category == 4 && _vm.cell.linkedDataStr
                    ? [
                        _c("div", { staticClass: "title mt20" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwskgc0"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell" }, [
                          _vm._v("适用品类：" + _vm._s(_vm.cell.linkedDataStr)),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cell.category == 5 && _vm.cell.linkedDataStr
                    ? [
                        _c("div", { staticClass: "title mt20" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "marketing.platformCoupon.couponList.index.5tqtlfwskjo0"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cell" }, [
                          _vm._v("适用品牌：" + _vm._s(_vm.cell.linkedDataStr)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _vm.cell.category == 2 && _vm.activeNames == "two"
            ? _c(
                "div",
                { staticClass: "px20" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "tableList",
                      staticClass: "mt20",
                      attrs: { data: _vm.cell.productList, size: "mini" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "ID", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwskpg0"
                          ),
                          "min-width": "80",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "demo-image__preview" },
                                    [
                                      _c("el-image", {
                                        attrs: {
                                          src: scope.row.image,
                                          "preview-src-list": [scope.row.image],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1825039654
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "name",
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwskss0"
                          ),
                          "min-width": "150",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "price",
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwskw80"
                          ),
                          "min-width": "90",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "stock",
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwskz40"
                          ),
                          "min-width": "70",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.cell.category == 6 && _vm.activeNames == "two"
            ? _c(
                "div",
                { staticClass: "px20" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "tableList",
                      staticClass: "mt20",
                      attrs: { data: _vm.cell.merchantList, size: "mini" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "categoryId",
                          label: "ID",
                          "min-width": "55",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwsl440"
                          ),
                          "min-width": "80",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "demo-image__preview" },
                                    [
                                      _c("el-image", {
                                        attrs: {
                                          src: scope.row.avatar,
                                          "preview-src-list": [
                                            scope.row.avatar,
                                          ],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4159822182
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          "show-overflow-tooltip": true,
                          prop: "name",
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwsl800"
                          ),
                          "min-width": "150",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "marketing.platformCoupon.couponList.index.5tqtlfwslc80"
                          ),
                          "min-width": "80",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("selfTypeFilter")(
                                          scope.row.isSelf
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          611084893
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(
              "marketing.platformCoupon.couponList.index.5tqtlfwsjis0"
            ),
            visible: _vm.delShow,
            width: "430px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.delShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.loseEfficacyStatus,
                    callback: function ($$v) {
                      _vm.loseEfficacyStatus = $$v
                    },
                    expression: "loseEfficacyStatus",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "marketing.platformCoupon.couponList.index.5tqtlfwslfs0"
                      )
                    )
                  ),
                ]
              ),
              _c("br"),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  staticClass: "mt20",
                  attrs: { label: 1 },
                  model: {
                    value: _vm.loseEfficacyStatus,
                    callback: function ($$v) {
                      _vm.loseEfficacyStatus = $$v
                    },
                    expression: "loseEfficacyStatus",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "marketing.platformCoupon.couponList.index.5tqtlfwsllg0"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "acea-row row-right mt20" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.delShow = false
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "marketing.platformCoupon.couponList.index.5tqtlfwslow0"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDelete()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "marketing.platformCoupon.couponList.index.5tqtlfwsjis0"
                      )
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }