"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var community = _interopRequireWildcard(require("@/api/community"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      form: {},
      rules: {},
      tableFrom: {
        page: 1,
        limit: 20,
        name: '',
        isHot: ''
      },
      name: '',
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      keyNum: 0,
      id: 0
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:community:topic:page:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    onchangeIsShow: function onchangeIsShow(row) {
      var _this2 = this;
      community.communityTopicRecommendApi(row.id).then(function (res) {
        _this2.$message.success('操作成功');
        _this2.getList();
      });
    },
    // 列表
    getList: function getList(num) {
      var _this3 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.name = encodeURIComponent(this.name);
      this.listLoading = true;
      community.communityTopicListApi(this.tableFrom).then(function (res) {
        _this3.tableData.data = res.list;
        _this3.tableData.total = res.total;
        _this3.listLoading = false;
      }).catch(function (res) {
        _this3.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    },
    handlerOpenEdit: function handlerOpenEdit(isCreate, editDate) {
      var _this = this;
      this.id = editDate ? editDate.id : 0;
      this.$modalParserFrom(isCreate === 0 ? '新建话题' : '编辑话题', 'communityTopics', isCreate, isCreate === 0 ? {
        id: 0,
        name: '',
        handlingFee: ''
      } : Object.assign({}, editDate), function (formValue) {
        _this.submit(formValue);
        _this.resetForm(formValue);
      }, this.keyNum = Math.random());
    },
    submit: function submit(formValue) {
      var _this4 = this;
      var data = {
        id: this.id,
        name: formValue.name
      };
      !this.id ? community.communityTopicAddApi(data).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.$msgbox.close();
        _this4.getList();
      }).catch(function () {
        _this4.loading = false;
      }) : community.communityTopicUpdateApi(data).then(function (res) {
        _this4.$message.success('操作成功');
        _this4.$msgbox.close();
        _this4.getList();
      }).catch(function () {
        _this4.loading = false;
      });
    },
    handlerOpenDel: function handlerOpenDel(rowData) {
      var _this5 = this;
      this.$modalSure('删除当前话题吗？删除话题之后，与此相关的文章将取消关联话题！').then(function () {
        community.communityTopicDelApi(rowData.id).then(function (data) {
          _this5.$message.success('删除话题成功');
          if (_this5.tableData.data.length === 1 && _this5.tableFrom.page > 1) _this5.tableFrom.page = _this5.tableFrom.page - 1;
          _this5.getList();
        });
      });
    }
  }
};