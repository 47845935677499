"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _settings = _interopRequireDefault(require("@/settings"));
var _cache = _interopRequireDefault(require("@/plugins/cache"));
var _index = require("@/lang/index");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var showSettings = _settings.default.showSettings,
  tagsView = _settings.default.tagsView,
  fixedHeader = _settings.default.fixedHeader,
  sidebarLogo = _settings.default.sidebarLogo,
  topNav = _settings.default.topNav,
  sideTheme = _settings.default.sideTheme,
  navIcon = _settings.default.navIcon;
var storageSetting = _cache.default.local.has('layout-setting') ? _cache.default.local.getJSON('layout-setting') : '';
var state = {
  language: (0, _index.getLanguage)(),
  theme: storageSetting.theme || '#409EFF',
  sideTheme: storageSetting.sideTheme || sideTheme,
  topNav: storageSetting.topNav === undefined ? topNav : storageSetting.topNav,
  showSettings: showSettings,
  tagsView: storageSetting.tagsView === undefined ? tagsView : storageSetting.tagsView,
  fixedHeader: storageSetting.fixedHeader === undefined ? fixedHeader : storageSetting.fixedHeader,
  sidebarLogo: storageSetting.sidebarLogo === undefined ? sidebarLogo : storageSetting.sidebarLogo,
  navIcon: storageSetting.navIcon === undefined ? navIcon : storageSetting.navIcon,
  frontDomain: localStorage.getItem('frontDomain') || '',
  //移动端域名
  mediaDomain: localStorage.getItem('mediaDomain') || '' //图片域名
};
var mutations = {
  SET_LANGUAGE: function SET_LANGUAGE(state, language) {
    state.language = language;
    _jsCookie.default.set('language', language);
  },
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
  SET_FrontDomain: function SET_FrontDomain(state, frontDomain) {
    state.frontDomain = frontDomain;
    localStorage.setItem('frontDomain', frontDomain);
    if (!frontDomain) localStorage.removeItem('frontDomain');
  },
  SET_mediaDomain: function SET_mediaDomain(state, mediaDomain) {
    state.mediaDomain = mediaDomain;
    if (!mediaDomain) localStorage.removeItem('mediaDomain');
  }
};
var actions = {
  setLanguage: function setLanguage(_ref2, language) {
    var commit = _ref2.commit;
    commit('SET_LANGUAGE', language);
  },
  changeSetting: function changeSetting(_ref3, data) {
    var commit = _ref3.commit;
    commit('CHANGE_SETTING', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};