"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ScrollPane = _interopRequireDefault(require("./ScrollPane"));
var _path = _interopRequireDefault(require("path"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    ScrollPane: _ScrollPane.default
  },
  data: function data() {
    return {
      fullWidth: document.body.clientWidth,
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      isPhone: this.$wechat.isPhone()
    };
  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    routes: function routes() {
      return this.$store.state.permission.routes;
    },
    theme: function theme() {
      return this.$store.state.settings.theme;
    },
    topNav: {
      get: function get() {
        return this.$store.state.settings.topNav;
      }
    }
  },
  watch: {
    $route: function $route() {
      this.addTags();
      if (this.topNav) this.setSidebarRouters(); //左侧二级菜单同步
      if (!this.isPhone) this.moveToCurrentTag();
    },
    visible: function visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    }
  },
  mounted: function mounted() {
    window.addEventListener('resize', this.handleResize);
    this.initTags();
    this.addTags();
    if (this.topNav) this.setSidebarRouters();
  },
  methods: {
    handleResize: function handleResize(event) {
      this.fullWidth = document.body.clientWidth;
    },
    isActive: function isActive(route) {
      return route.path === this.$route.path;
    },
    isAffix: function isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    filterAffixTags: function filterAffixTags(routes) {
      var _this = this;
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var tags = [];
      routes.forEach(function (route) {
        if (route.meta && route.meta.affix) {
          var tagPath = _path.default.resolve(basePath, route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: _objectSpread({}, route.meta)
          });
        }
        if (route.children) {
          var tempTags = _this.filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [].concat(_toConsumableArray(tags), _toConsumableArray(tempTags));
          }
        }
      });
      return tags;
    },
    initTags: function initTags() {
      var affixTags = this.affixTags = this.filterAffixTags(this.routes);
      var _iterator = _createForOfIteratorHelper(affixTags),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var tag = _step.value;
          // Must have tag name
          if (tag.name) {
            this.$store.dispatch('tagsView/addVisitedView', tag);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    /**
     * 左侧二级菜单同步
     */
    setSidebarRouters: function setSidebarRouters() {
      var _this2 = this;
      var key = this.$route.path.split('/')[1];
      //通过截取当前路由的第一级目录跟顶部一级菜单选中项的val值做匹配
      key = '/' + key;
      this.routes.map(function (item) {
        if (key == item.url && item.child.length > 0) {
          //如果匹配，就给侧边导航赋值为选中项的子级数组
          _this2.$store.commit('permission/SET_SIDEBAR_ROUTERS', item.child);
        } else if (key == item.url && !item.child.length) {
          //如果遍历以后val值等于item的url，但是有没有子级，就把它子级赋值给侧边导航的数组，这里针对dashboard控制台
          _this2.$store.commit('permission/SET_SIDEBAR_ROUTERS', [item]);
        }
      });
    },
    addTags: function addTags() {
      var name = this.$route.name;
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route);
      }
      return false;
    },
    moveToCurrentTag: function moveToCurrentTag() {
      var _this3 = this;
      var tags = this.$refs.tag;
      this.$nextTick(function () {
        var _iterator2 = _createForOfIteratorHelper(tags),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var tag = _step2.value;
            if (tag.to.path === _this3.$route.path) {
              _this3.$refs.scrollPane.moveToTarget(tag);
              // when query is different then update
              if (tag.to.fullPath !== _this3.$route.fullPath) {
                _this3.$store.dispatch('tagsView/updateVisitedView', _this3.$route);
              }
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      });
    },
    refreshSelectedTag: function refreshSelectedTag(view) {
      var _this4 = this;
      this.$store.dispatch('tagsView/delCachedView', view).then(function () {
        var fullPath = view.fullPath;
        _this4.$nextTick(function () {
          _this4.$router.replace({
            path: '/redirect' + fullPath
          });
        });
      });
    },
    closeSelectedTag: function closeSelectedTag(view) {
      var _this5 = this;
      this.$store.dispatch('tagsView/delView', view).then(function (_ref) {
        var visitedViews = _ref.visitedViews;
        if (_this5.isActive(view)) {
          _this5.toLastView(visitedViews, view);
        }
      });
    },
    closeOthersTags: function closeOthersTags() {
      var _this6 = this;
      this.$router.push(this.selectedTag);
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(function () {
        _this6.moveToCurrentTag();
      });
    },
    closeAllTags: function closeAllTags(view) {
      var _this7 = this;
      this.$store.dispatch('tagsView/delAllViews').then(function (_ref2) {
        var visitedViews = _ref2.visitedViews;
        if (_this7.affixTags.some(function (tag) {
          return tag.path === view.path;
        })) {
          return;
        }
        _this7.toLastView(visitedViews, view);
      });
    },
    toLastView: function toLastView(visitedViews, view) {
      var latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({
            path: '/redirect' + view.fullPath
          });
        } else {
          this.$router.push('/dashboard');
        }
      }
    },
    openMenu: function openMenu(tag, e) {
      var menuMinWidth = 105;
      var offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      var offsetWidth = this.$el.offsetWidth; // container width
      var maxLeft = offsetWidth - menuMinWidth; // left boundary
      var left = e.clientX - offsetLeft + 15; // 15: margin right
      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      this.top = e.clientY;
      this.visible = true;
      this.selectedTag = tag;
    },
    closeMenu: function closeMenu() {
      this.visible = false;
    }
  }
};