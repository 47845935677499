var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "hasPermi",
                  rawName: "v-hasPermi",
                  value: [
                    "platform:sign:get:config",
                    "platform:sign:edit:base:config",
                  ],
                  expression:
                    "['platform:sign:get:config', 'platform:sign:edit:base:config']",
                },
              ],
              ref: "signForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.signForm,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("marketing.sign.config.index.5trgn0bjpqo0"),
                    prop: "integral",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "acea-row" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.signForm.isIntegral,
                            callback: function ($$v) {
                              _vm.$set(_vm.signForm, "isIntegral", $$v)
                            },
                            expression: "signForm.isIntegral",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("marketing.sign.config.index.5trgn0bjqgg0")
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input-number", {
                        staticClass: "ml40",
                        attrs: {
                          min: 0,
                          max: 9999,
                          step: 1,
                          label: _vm.$t(
                            "marketing.sign.config.index.5trgn0bjqjw0"
                          ),
                        },
                        model: {
                          value: _vm.signForm.integral,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.signForm,
                              "integral",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "signForm.integral",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { prop: "experience" } }, [
                _c(
                  "div",
                  { staticClass: "acea-row" },
                  [
                    _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.signForm.isExperience,
                          callback: function ($$v) {
                            _vm.$set(_vm.signForm, "isExperience", $$v)
                          },
                          expression: "signForm.isExperience",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("marketing.sign.config.index.5trgn0bjqlo0")
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-input-number", {
                      staticClass: "ml40",
                      attrs: {
                        min: 0,
                        max: 9999,
                        step: 1,
                        label: _vm.$t(
                          "marketing.sign.config.index.5trgn0bjqns0"
                        ),
                      },
                      model: {
                        value: _vm.signForm.experience,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.signForm,
                            "experience",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "signForm.experience",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("marketing.sign.config.index.5trgn0bjqrk0"),
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "600px" },
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: _vm.$t(
                        "marketing.sign.config.index.5trgn0bjqt40"
                      ),
                    },
                    model: {
                      value: _vm.signForm.signRuleDescription,
                      callback: function ($$v) {
                        _vm.$set(_vm.signForm, "signRuleDescription", $$v)
                      },
                      expression: "signForm.signRuleDescription",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.signForm.signRuleDescription =
                            _vm.defaultDescription
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("marketing.sign.config.index.5trgn0bjquo0")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "title",
                  attrs: {
                    label: _vm.$t("marketing.sign.config.index.5trgn0bjqwo0"),
                  },
                },
                [
                  _c("el-alert", {
                    attrs: {
                      closable: false,
                      title: _vm.$t("marketing.sign.config.index.5trgn0bjqyo0"),
                      type: "warning",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _vm.checkPermi(["platform:sign:edit:base:config"])
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("signForm")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("marketing.sign.config.index.5trgn0bjr0g0")
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.checkPermi([
            "platform:sign:get:config",
            "platform:sign:add:config",
          ])
            ? _c(
                "el-button",
                {
                  staticClass: "mt20 mb15",
                  attrs: { type: "primary" },
                  on: { click: _vm.addSign },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("marketing.sign.config.index.5trgn0bjr200"))
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            { attrs: { data: _vm.signConfigList, border: "" } },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.sign.config.index.5trgn0bjr3k0"),
                  "min-width": "90",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.show,
                              expression: "scope.row.show",
                            },
                          ],
                          attrs: {
                            placeholder: _vm.$t(
                              "marketing.sign.config.index.5trgn0bjqt40"
                            ),
                          },
                          model: {
                            value: scope.row.day,
                            callback: function ($$v) {
                              _vm.$set(
                                scope.row,
                                "day",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "scope.row.day",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !scope.row.show,
                                expression: "!scope.row.show",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(scope.row.day))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.sign.config.index.5trgn0bjr6s0"),
                  "min-width": "800",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "acea-row row-center-wrapper" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { disabled: !scope.row.show },
                                model: {
                                  value: scope.row.isIntegral,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "isIntegral", $$v)
                                  },
                                  expression: "scope.row.isIntegral",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.sign.config.index.5trgn0bjqgg0"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              staticClass: "mr20",
                              attrs: {
                                disabled: !scope.row.show,
                                min: 0,
                                max: 9999,
                                step: 1,
                                label: _vm.$t(
                                  "marketing.sign.config.index.5trgn0bjqjw0"
                                ),
                              },
                              model: {
                                value: scope.row.integral,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "integral",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.integral",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "el-checkbox",
                              {
                                staticClass: "ml40 mr20",
                                attrs: { disabled: !scope.row.show },
                                model: {
                                  value: scope.row.isExperience,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "isExperience", $$v)
                                  },
                                  expression: "scope.row.isExperience",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.sign.config.index.5trgn0bjqlo0"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("el-input-number", {
                              attrs: {
                                disabled: !scope.row.show,
                                min: 0,
                                max: 9999,
                                step: 1,
                                label: _vm.$t(
                                  "marketing.sign.config.index.5trgn0bjqns0"
                                ),
                              },
                              model: {
                                value: scope.row.experience,
                                callback: function ($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "experience",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.experience",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("marketing.sign.config.index.5trgn0bjr880"),
                  "min-width": "180",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermi(["platform:sign:edit:award:config"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editSign(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.sign.config.index.5trgn0bjr9o0"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi([
                          "platform:sign:add:config",
                          "platform:sign:edit:award:config",
                        ])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveSign(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.sign.config.index.5trgn0bjrbk0"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.checkPermi(["platform:sign:delete:config"])
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delSign(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "marketing.sign.config.index.5trgn0bjrdo0"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }