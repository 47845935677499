"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; } /** When your routing table is too long, you can split it into small modules **/
var marketingRouter = {
  path: '/marketing',
  component: _layout.default,
  redirect: '/PlatformCoupon/list',
  name: 'Marketing',
  meta: {
    title: '营销',
    icon: 'clipboard'
  },
  children: [{
    path: 'sign',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/sign'));
      });
    },
    name: 'Sign',
    meta: {
      title: '签到',
      icon: ''
    },
    children: [{
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/sign/config/index'));
        });
      },
      name: 'signConfig',
      hidden: true,
      meta: {
        title: '签到配置',
        icon: ''
      }
    }, {
      path: 'record',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/sign/record/index'));
        });
      },
      name: 'signRecord',
      hidden: true,
      meta: {
        title: '签到记录',
        icon: ''
      }
    }]
  }, {
    path: 'integral',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/integral/index'));
      });
    },
    name: 'Integral',
    meta: {
      title: '积分',
      icon: ''
    },
    children: [{
      path: 'integralconfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/config/index'));
        });
      },
      name: 'integralConfig',
      meta: {
        title: '积分配置',
        icon: ''
      }
    }, {
      path: 'integrallog',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/integral/integralLog/index'));
        });
      },
      name: 'integralLog',
      meta: {
        title: '积分日志',
        icon: ''
      }
    }]
  }, {
    path: 'seckill',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/seckill/index'));
      });
    },
    name: 'Seckill',
    meta: {
      title: '秒杀',
      icon: ''
    },
    children: [{
      path: 'config',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillConfig/index'));
        });
      },
      name: 'SeckillConfig',
      meta: {
        title: '秒杀配置',
        icon: ''
      }
    }, {
      path: 'list/:timeId?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillList/index'));
        });
      },
      name: 'SeckillList',
      meta: {
        title: '秒杀商品',
        icon: ''
      }
    }, {
      path: 'seckillActivity',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillActivity/index'));
        });
      },
      name: 'SeckillActivity',
      meta: {
        title: '秒杀活动',
        icon: ''
      }
    }, {
      path: 'creatSeckill/:id?/:type?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/seckill/seckillActivity/creatSeckill'));
        });
      },
      name: 'CreatSeckill',
      meta: {
        title: '添加秒杀商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/seckill/seckillActivity"
      }
    }]
  }, {
    path: 'group',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/group/index'));
      });
    },
    name: 'Group',
    meta: {
      title: '拼团',
      icon: ''
    },
    children: [{
      path: 'productList/:timeId?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/group/product-list/index'));
        });
      },
      name: 'GroupList',
      meta: {
        title: '拼团商品',
        icon: ''
      }
    }, {
      path: 'groupActivity',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/group/groupActivity/index'));
        });
      },
      name: 'GroupActivity',
      meta: {
        title: '拼团活动',
        icon: ''
      }
    }, {
      path: 'creatGroup/:id?/:type?',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/group/groupActivity/creatGroup'));
        });
      },
      name: 'creatGroup',
      meta: {
        title: '添加拼团商品',
        icon: '',
        noCache: true,
        activeMenu: "/marketing/group/seckillActivity"
      }
    }]
  }, {
    path: 'atmosphere',
    name: 'atmosphere',
    meta: {
      title: '活动氛围',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/atmosphere/index'));
      });
    },
    children: [{
      path: 'list',
      name: "atmosphereList",
      meta: {
        title: '氛围列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/atmosphere/atmosphereList/list'));
        });
      }
    }, {
      path: 'add/:id?',
      name: "addAtmosphere",
      meta: {
        title: '添加活动氛围',
        noCache: true,
        activeMenu: "/marketing/atmosphere/list"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/atmosphere/atmosphereList/addAtmosphere'));
        });
      }
    }]
  }, {
    path: 'border',
    name: 'border',
    meta: {
      title: '活动边框',
      icon: ''
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/border/index'));
      });
    },
    children: [{
      path: 'list',
      name: "borderList",
      meta: {
        title: '活动边框列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/atmosphere/atmosphereList/list'));
        });
      }
    }, {
      path: 'add/:id?',
      name: "addBorder",
      meta: {
        title: '添加活动边框',
        noCache: true,
        activeMenu: "/marketing/border/list"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/atmosphere/atmosphereList/addAtmosphere'));
        });
      }
    }]
  }, {
    path: 'broadcast',
    name: 'Broadcast',
    meta: {
      title: '直播',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/broadcast/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'BroadcastList',
      meta: {
        title: '直播间管理',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/broadcast/list/index'));
        });
      }
    }, {
      path: 'product',
      name: 'BroadcastProduct',
      meta: {
        title: '直播商品列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/broadcast/product/index'));
        });
      }
    }]
  }, {
    path: 'PlatformCoupon',
    name: 'PlatformCoupon',
    meta: {
      title: '平台优惠券',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/marketing/platformCoupon/index'));
      });
    },
    children: [{
      path: 'list',
      name: 'PlatformCouponlist',
      meta: {
        title: '优惠劵列表',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/platformCoupon/couponList/index'));
        });
      }
    }, {
      path: 'creatCoupon/:id?/:copy?',
      name: 'CreatCoupon',
      meta: {
        title: '添加优惠劵',
        noCache: true,
        activeMenu: "/marketing/PlatformCoupon/list"
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/platformCoupon/couponList/creatCoupon'));
        });
      }
    }, {
      path: 'couponRecord',
      name: 'couponRecord',
      meta: {
        title: '领取记录',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/platformCoupon/couponList/record'));
        });
      }
    }, {
      path: 'newGift',
      name: 'newGift',
      meta: {
        title: '用户有礼',
        noCache: true
      },
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/marketing/platformCoupon/couponList/newGift'));
        });
      }
    }]
  }, {
    path: 'videoChannel',
    name: 'VideoChannel',
    meta: {
      title: '视频号',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/videoChannel'));
      });
    },
    children: [{
      path: 'list',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/videoChannel/videoList/index'));
        });
      },
      name: 'VideoChannelList',
      meta: {
        title: '商品列表',
        icon: '',
        noCache: true
      }
    }, {
      path: 'draftList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/videoChannel/draftList/index'));
        });
      },
      name: 'draftList',
      meta: {
        title: '草稿列表',
        icon: '',
        noCache: true
      }
    }, {
      path: 'apply',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/videoChannel/apply/index'));
        });
      },
      name: 'videoApply',
      meta: {
        title: '申请接入',
        icon: '',
        noCache: true
      }
    }, {
      path: 'weChatcategory',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/videoChannel/weChatcategoryAndBrand/index'));
        });
      },
      name: 'videoWeChatcategory',
      meta: {
        title: '微信商品类目',
        icon: '',
        noCache: true
      }
    }]
  }, {
    path: 'community',
    name: 'Community',
    meta: {
      title: '种草社区',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/community'));
      });
    },
    children: [{
      path: 'classification',
      name: 'communityClassification',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/community/classification/index'));
        });
      },
      meta: {
        title: '社区分类',
        icon: 'clipboard'
      }
    }, {
      path: 'topics',
      name: 'communityTopics',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/community/topics/index'));
        });
      },
      meta: {
        title: '社区话题',
        icon: 'clipboard'
      }
    }, {
      path: 'content',
      name: 'communityContent',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/community/content/index'));
        });
      },
      meta: {
        title: '社区内容',
        icon: 'clipboard'
      }
    }, {
      path: 'comments',
      name: 'communityComments',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/community/comments/index'));
        });
      },
      meta: {
        title: '社区评论',
        icon: 'clipboard'
      }
    }, {
      path: 'config',
      name: 'communityConfig',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/community/config/index'));
        });
      },
      meta: {
        title: '社区配置',
        icon: 'clipboard'
      }
    }]
  }, {
    path: 'content',
    name: 'Content',
    meta: {
      title: '公告内容',
      noCache: true
    },
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/content'));
      });
    },
    children: [{
      path: 'articleManager',
      name: 'articleManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/content/article/list'));
        });
      },
      meta: {
        title: '文章管理',
        icon: 'clipboard'
      }
    }, {
      path: 'articleCreat/:id?',
      name: 'articleCreat',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/content/article/edit'));
        });
      },
      meta: {
        title: '添加文章',
        noCache: true,
        activeMenu: "/content/articleManager"
      }
    }, {
      path: 'classifManager',
      name: 'classifManager',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require('@/views/content/articleclass/list'));
        });
      },
      meta: {
        title: '文章分类',
        icon: 'clipboard'
      }
    }]
  }]
};
var _default = exports.default = marketingRouter;