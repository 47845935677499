var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "pram",
          attrs: { model: _vm.pram, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1d3s0"
                ),
                prop: "roleName",
                rules: [
                  {
                    required: true,
                    message: _vm.$t(
                      "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1edc0"
                    ),
                    trigger: ["blur", "change"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t(
                    "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1evc0"
                  ),
                },
                model: {
                  value: _vm.pram.roleName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.pram,
                      "roleName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "pram.roleName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1f1c0"
                ),
              },
            },
            [
              _c("el-switch", {
                attrs: { "active-value": true, "inactive-value": false },
                model: {
                  value: _vm.pram.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.pram, "status", $$v)
                  },
                  expression: "pram.status",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1f5w0"
                ),
              },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeExpand($event, "menu")
                    },
                  },
                  model: {
                    value: _vm.menuExpand,
                    callback: function ($$v) {
                      _vm.menuExpand = $$v
                    },
                    expression: "menuExpand",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1fa40"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeConnect($event, "menu")
                    },
                  },
                  model: {
                    value: _vm.menuCheckStrictly,
                    callback: function ($$v) {
                      _vm.menuCheckStrictly = $$v
                    },
                    expression: "menuCheckStrictly",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1ffs0"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-tree", {
                ref: "menu",
                staticClass: "tree-border",
                attrs: {
                  data: _vm.menuOptions,
                  "show-checkbox": "",
                  "node-key": "id",
                  "check-strictly": !_vm.menuCheckStrictly,
                  "empty-text": _vm.$t(
                    "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1fk00"
                  ),
                  props: _vm.defaultProps,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: [
                        "platform:admin:role:update",
                        "platform:admin:role:save",
                      ],
                      expression:
                        "['platform:admin:role:update', 'platform:admin:role:save']",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSubmit("pram")
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.isCreate === 0
                        ? _vm.$t(
                            "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1fo40"
                          )
                        : _vm.$t(
                            "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1frw0"
                          )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "systemSetting.administratorAuthority.identityManager.edit.5u0z1qb1fvo0"
                    )
                  )
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }