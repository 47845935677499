"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.menuMerAdd = menuMerAdd;
exports.menuMerDelete = menuMerDelete;
exports.menuMerInfo = menuMerInfo;
exports.menuMerListApi = menuMerListApi;
exports.menuMerUpdate = menuMerUpdate;
exports.menuMerUpdateShowStatus = menuMerUpdateShowStatus;
exports.merApplyAuditApi = merApplyAuditApi;
exports.merApplyListApi = merApplyListApi;
exports.merApplyRemarkApi = merApplyRemarkApi;
exports.merCategoryListApi = merCategoryListApi;
exports.merHeaderNumApi = merHeaderNumApi;
exports.merRsetPasswordApi = merRsetPasswordApi;
exports.merchantAddApi = merchantAddApi;
exports.merchantCategoryAddApi = merchantCategoryAddApi;
exports.merchantCategoryAllListApi = merchantCategoryAllListApi;
exports.merchantCategoryDeleteApi = merchantCategoryDeleteApi;
exports.merchantCategoryListApi = merchantCategoryListApi;
exports.merchantCategoryUpdateApi = merchantCategoryUpdateApi;
exports.merchantCloseApi = merchantCloseApi;
exports.merchantCopyNumApi = merchantCopyNumApi;
exports.merchantDetailApi = merchantDetailApi;
exports.merchantListApi = merchantListApi;
exports.merchantOpenApi = merchantOpenApi;
exports.merchantSwitchApi = merchantSwitchApi;
exports.merchantTypeAddApi = merchantTypeAddApi;
exports.merchantTypeAllListApi = merchantTypeAllListApi;
exports.merchantTypeDeleteApi = merchantTypeDeleteApi;
exports.merchantTypeListApi = merchantTypeListApi;
exports.merchantTypeUpdateApi = merchantTypeUpdateApi;
exports.merchantUpdateApi = merchantUpdateApi;
exports.merchantupdatePhoneApi = merchantupdatePhoneApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 商户分类 列表
 */
function merchantCategoryListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/category/list',
    method: 'get',
    params: params
  });
}

/**
 * 商户分类 全部列表
 */
function merchantCategoryAllListApi() {
  return (0, _request.default)({
    url: '/admin/platform/merchant/category/all/list',
    method: 'get'
  });
}

/**
 * 商户分类 添加
 */
function merchantCategoryAddApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/category/add',
    method: 'post',
    data: data
  });
}

/**
 * 商户分类 编辑
 */
function merchantCategoryUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/category/update',
    method: 'post',
    data: data
  });
}

/**
 * 商户分类 删除
 */
function merchantCategoryDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/category/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 店铺类型 列表
 */
function merchantTypeListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/type/list',
    method: 'get',
    params: params
  });
}

/**
 * 店铺类型 全部列表
 */
function merchantTypeAllListApi() {
  return (0, _request.default)({
    url: '/admin/platform/merchant/type/all/list',
    method: 'get'
  });
}

/**
 * 店铺类型 添加
 */
function merchantTypeAddApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/type/add',
    method: 'post',
    data: data
  });
}

/**
 * 店铺类型 编辑
 */
function merchantTypeUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/type/update',
    method: 'post',
    data: data
  });
}

/**
 * 店铺类型 删除
 */
function merchantTypeDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/type/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 列表
 */
function merchantListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/list',
    method: 'get',
    params: params
  });
}

/**
 * 商户 添加
 */
function merchantAddApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/add',
    method: 'post',
    data: data
  });
}

/**
 * 商户 编辑
 */
function merchantUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/merchant/update',
    method: 'post',
    data: data
  });
}

/**
 * 商户 关闭
 */
function merchantCloseApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/close/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 详情
 */
function merchantDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/detail/".concat(id),
    method: 'get'
  });
}

/**
 * 商户 开启
 */
function merchantOpenApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/open/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 推荐开关
 */
function merchantSwitchApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/recommend/switch/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 修改复制商品数量
 */
function merchantCopyNumApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/update/copy/product/num",
    method: 'post',
    data: data
  });
}

/**
 * 商户 修改商户手机号
 */
function merchantupdatePhoneApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/update/phone",
    method: 'post',
    data: data
  });
}

/**
 * 商户 权限规则菜单列表
 * @param pram
 */
function menuMerListApi(params) {
  var data = {
    menuType: params.menuType,
    //菜单类型:M-目录，C-菜单，A-按钮
    name: params.name //菜单名称
  };
  return (0, _request.default)({
    url: "/admin/platform/merchant/menu/list",
    method: 'get',
    params: data
  });
}

/**
 * 商户 权限规则新增菜单
 * @param data
 */
function menuMerAdd(data) {
  var systemMenuRequest = data;
  return (0, _request.default)({
    url: "/admin/platform/merchant/menu/add",
    method: 'post',
    data: systemMenuRequest
  });
}

/**
 * 商户 权限规则删除菜单
 * @param data
 */
function menuMerDelete(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/menu/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 商户 权限规则菜单详情
 * @param data
 */
function menuMerInfo(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/menu/info/".concat(id),
    method: 'get'
  });
}

/**
 * 商户 权限规则菜单修改
 * @param data
 */
function menuMerUpdate(data) {
  var systemMenuRequest = data;
  return (0, _request.default)({
    url: "/admin/platform/merchant/menu/update",
    method: 'post',
    data: systemMenuRequest
  });
}

/**
 * 权限规则修改菜单显示状态
 * @param data
 */
function menuMerUpdateShowStatus(params) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/menu/update/show/".concat(params.id),
    method: 'post'
  });
}

/**
 * 商户入驻分页列表
 * @param data
 */
function merApplyListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/apply/list",
    method: 'get',
    params: params
  });
}

/**
 * 商户入驻审核
 * @param data
 */
function merApplyAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/apply/audit",
    method: 'post',
    data: data
  });
}

/**
 * 商户入驻备注
 * @param data
 */
function merApplyRemarkApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/apply/remark",
    method: 'post',
    data: data
  });
}

/**
 * 商户分页列表表头数量
 * @param data
 */
function merHeaderNumApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/list/header/num",
    method: 'get',
    params: params
  });
}

/**
 * 重置商户密码
 * @param data
 */
function merRsetPasswordApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/merchant/reset/password/".concat(id),
    method: 'post'
  });
}

/**
 * 可用分类商户列表
 * @param data
 */
function merCategoryListApi() {
  return (0, _request.default)({
    url: "/admin/platform/merchant/use/category/list",
    method: 'get'
  });
}