"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _vuex = require("vuex");
var _permission = require("@/utils/permission");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'creatgroup',
  data: function data() {
    return {
      activeName: 'first',
      ruleForm: {
        joinQuota: 0,
        // 购买次数限制，0不限制
        successQuota: null,
        // 拼团人数
        ageing: null,
        // 拼团时效（小时）
        virtualQuota: null,
        // 虚拟成团
        allQuota: 0,
        endDate: '',
        merStars: 1,
        name: '',
        oneQuota: 0,
        proCategory: '',
        startDate: '',
        timeIntervals: '',
        id: '',
        timeVal: [],
        proCategorylist: []
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mifw0'),
          trigger: 'blur'
        }],
        successQuota: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjbc0'),
          trigger: 'blur'
        }],
        ageing: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjcs0'),
          trigger: 'blur'
        }],
        share: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjeg0'),
          trigger: 'change'
        }],
        timeVal: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjfo0')
        }],
        discount: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjgw0')
        }],
        merStars: [{
          required: true,
          message: this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjjw0'),
          trigger: 'change'
        }]
      },
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      multipleSelection: [],
      activityType: null,
      listLoading: false,
      isIndeterminate: true,
      tempRoute: {},
      isShowCheck: false,
      keyNum: 0,
      loading: false
    };
  },
  created: function created() {
    this.tempRoute = Object.assign({}, this.$route);
  },
  mounted: function mounted() {
    if (!this.adminProductClassify.length) this.$store.dispatch('product/getAdminProductClassify');
    if (this.pageType) this.activeName = 'second';
    this.setTagsViewTitle();
    if (this.$route.params.id && !this.pageType) {
      this.getInfo();
    }
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify'])), {}, {
    //是否是编辑
    isEdit: function isEdit() {
      return this.$route.params.id ? true : false;
    },
    title: function title() {
      return this.$route.params.id ? this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjm00') : this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjn40');
    },
    //页面是添加商品还是编辑页面，add添加商品，edit编辑
    pageType: function pageType() {
      return this.$route.params.type === 'add' ? true : false;
    }
  }),
  methods: {
    checkPermi: _permission.checkPermi,
    setTagsViewTitle: function setTagsViewTitle() {
      if (this.$route.params.id) {
        var title = this.$route.params.id ? this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjog0') : this.$t('marketing.group.groupActivity.creatGroup.5u119u9mjpk0');
        var route = Object.assign({}, this.tempRoute, {
          title: "".concat(title, "-").concat(this.$route.params.id)
        });
        this.$store.dispatch('tagsView/updateVisitedView', route);
      }
    },
    //详情
    getInfo: function getInfo() {
      var _this = this;
      this.loading = true;
      console.log(1111111);
      (0, _marketing.groupActivityDetailApi)(this.$route.params.id).then(function (res) {
        var info = res;
        _this.ruleForm = _objectSpread(_objectSpread({}, info), {}, {
          timeVal: [info.startDate, info.endDate],
          proCategorylist: info.proCategory !== '0' ? info.proCategory.split(',').map(function (item) {
            return item * 1;
          }) : []
        });
        _this.loading = false;
        //this.isIndeterminate = !this.isIndeterminate;
      }).catch(function (res) {
        _this.loading = false;
      });
    },
    back: function back() {
      this.$router.push({
        path: "/marketing/group/groupActivity"
      });
    },
    //设置活动价
    setPrice: function setPrice(type) {
      this.keyNum = Math.random();
      this.$refs.activityModal.type = type;
      this.$refs.activityModal.showStatus = true;
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.ruleForm.timeVal = e;
      this.ruleForm.startDate = e ? e[0] : '';
      this.ruleForm.endDate = e ? e[1] : '';
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.ruleForm.proCategory = _this2.ruleForm.proCategorylist.length ? _this2.ruleForm.proCategorylist.toString() : '0';
          _this2.$route.params.id ? (0, _marketing.groupAtivityUpdateApi)(_this2.ruleForm).then(function (res) {
            _this2.$message.success(_this2.$t('marketing.group.groupActivity.creatGroup.5u119u9mjr80'));
            _this2.$router.push({
              path: "/marketing/group/groupActivity"
            });
          }).catch(function (res) {}) : (0, _marketing.groupActivityAddApi)(_this2.ruleForm).then(function (res) {
            _this2.$message.success(_this2.$t('marketing.group.groupActivity.creatGroup.5u119u9mjt40'));
            _this2.$router.push({
              path: "/marketing/group/groupActivity"
            });
          }).catch(function (res) {});
        } else {
          return false;
        }
      });
    }
  }
};