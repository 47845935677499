"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _baseInfo = _interopRequireDefault(require("./components/baseInfo"));
var _gridMenu = _interopRequireDefault(require("./components/gridMenu"));
var _userOverview = _interopRequireDefault(require("./components/userOverview"));
var _permission = require("@/utils/permission");
var _systemConfig = require("@/api/systemConfig");
var _cache = _interopRequireDefault(require("@/plugins/cache"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
//import { authCertQuery } from '@/api/authInformation';
var _default = exports.default = {
  name: 'Dashboard',
  components: {
    baseInfo: _baseInfo.default,
    gridMenu: _gridMenu.default,
    userOverview: _userOverview.default
  },
  data: function data() {
    return {
      authStatus: null,
      authHost: '',
      authQueryStatus: false,
      notInformation: null
    };
  },
  mounted: function mounted() {
    if (window.location.host.indexOf('localhost') == -1) {
      this.authStatus = _cache.default.local.has('auth-information') ? _cache.default.local.getJSON('auth-information') : null;
      this.notInformation = _cache.default.local.has('not-information') ? _cache.default.local.getJSON('not-information') : null;
      if (!this.authStatus) {
        this.getUniq();
      }
    }
  },
  methods: {
    checkPermi: _permission.checkPermi,
    authInformationQuery: function authInformationQuery() {
      var _this = this;
      authCertQuery({
        host: this.authHost
      }).then(function (res) {
        var oneDay = 24 * 3600 * 1000;
        if (res.data.status == -1 || res.data.status == 2) {
          _cache.default.local.setItem({
            name: 'not-information',
            value: true,
            expires: oneDay
          });
          if (!notInformation) {
            _this.$modal.confirm(res.data.msg).then(function () {
              _this.$router.push({
                path: '/operation/maintain/authjiefanghui'
              });
            }).catch(function () {});
          }
        } else if (res.data.status == 1) {
          _this.$modal.msgSuccess(res.data.msg);
          _cache.default.local.setItem({
            name: 'auth-information',
            value: true,
            expires: oneDay * 7
          });
        } else if (res.data.status == 3 || res.data.status == 0) {
          _this.$modal.msg(res.data.msg);
        }
      });
    },
    getUniq: function getUniq() {
      var _this2 = this;
      (0, _systemConfig.configGetUniq)({
        key: 'authHost'
      }).then(function (res) {
        _this2.authHost = res;
        if (res !== '') {
          //this.authInformationQuery();
        } else {
          _this2.$modal.confirm('您尚未提交授权申请').then(function () {
            _this2.$router.push({
              path: '/operation/maintain/authjiefanghui'
            });
          });
        }
      });
    }
  }
};