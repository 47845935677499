var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", [
        _c("div", { staticClass: "group", style: _vm.boxStyle }, [
          _c("div", { staticClass: "bg_box", style: _vm.boxBgStyle }),
          _vm._v(" "),
          _c("div", { staticClass: "title acea-row row-between-wrapper" }, [
            _c("div", { staticClass: "acea-row row-middle" }, [
              _c(
                "div",
                { staticClass: "pictrue skeleton-rect" },
                [
                  _c("el-image", {
                    staticClass: "image",
                    attrs: { src: _vm.src },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "lines" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "point skeleton-rect", style: _vm.titleColor },
                [_vm._v(_vm._s(_vm.configObj.titleConfig.val))]
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listStyle === 0,
                  expression: "listStyle === 0",
                },
              ],
              staticClass: "conter",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "white-space": "nowrap",
                    "vertical-align": "middle",
                  },
                },
                _vm._l(_vm.spikeList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "itemCon",
                      style: _vm.contentConfig,
                    },
                    [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "pictrue skeleton-rect" }, [
                          _c(
                            "div",
                            {
                              staticClass: "empty-box",
                              style: _vm.contentStyle,
                            },
                            [_c("span", { staticClass: "iconfont icon-tu" })]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nameShow,
                                expression: "nameShow",
                              },
                            ],
                            staticClass: "name line1 skeleton-rect",
                            style: _vm.nameColor,
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.priceShow,
                                expression: "priceShow",
                              },
                            ],
                            staticClass: "x_money line1 skeleton-rect",
                            style: _vm.priceColor,
                          },
                          [
                            _vm._v("\n              ¥"),
                            _c("span", { staticClass: "num" }, [
                              _vm._v(_vm._s(item.price)),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listStyle === 1,
                  expression: "listStyle === 1",
                },
              ],
              staticClass: "conter_y",
              style: _vm.gridGap,
            },
            _vm._l(_vm.spikeList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "item", style: _vm.contentConfig },
                [
                  _c("div", { staticClass: "pictrue" }, [
                    _c(
                      "div",
                      { staticClass: "empty-box", style: _vm.contentStyle },
                      [_c("span", { staticClass: "iconfont icon-tu" })]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-info text-add" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.nameShow,
                            expression: "nameShow",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "title line2", style: _vm.nameColor },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.priceShow,
                            expression: "priceShow",
                          },
                        ],
                        staticClass: "price",
                        style: _vm.priceColor,
                      },
                      [
                        _c("div", [
                          _c("span", [_vm._v("￥")]),
                          _vm._v(
                            "\n              " +
                              _vm._s(item.price) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "more acea-row row-center-wrapper skeleton-rect" },
      [_vm._v("GO"), _c("span", { staticClass: "iconfont icon-xuanze" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }