"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _product = require("@/api/product");
var _permission = require("@/utils/permission");
var _clipboard = _interopRequireDefault(require("clipboard"));
var _merchantName = _interopRequireDefault(require("@/components/merchantName"));
var _info = _interopRequireDefault(require("./info"));
var _previewBox = _interopRequireDefault(require("./previewBox"));
var _vuex = require("vuex");
var _settingMer = _interopRequireDefault(require("@/utils/settingMer"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
// 复制
var headerName = [{
  name: '出售中商品',
  type: 1
}, {
  name: '仓库中商品',
  type: 2
}, {
  name: '待审核商品',
  type: 6
}, {
  name: '审核未通过商品',
  type: 7
}];
var _default = exports.default = {
  name: 'ProductList',
  components: {
    infoFrom: _info.default,
    merchantName: _merchantName.default,
    previewBox: _previewBox.default
  },
  data: function data() {
    return {
      componentKey: 0,
      isAtud: false,
      isShow: false,
      productId: 0,
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'childList',
        expandTrigger: 'hover',
        checkStrictly: false,
        emitPath: false,
        multiple: false
      },
      props: {
        children: 'child',
        label: 'name',
        value: 'id',
        emitPath: false
      },
      isShowSeach: true,
      categoryIdData: [],
      headeNum: [],
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      isSpreadList: [{
        label: '',
        name: '全部'
      }, {
        label: 0,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg4')
      },
      // {label: 1, name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg5')},
      {
        label: 3,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg6')
      }],
      subTypeList: [{
        label: 1,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg4')
      }, {
        label: 2,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg5')
      }, {
        label: 3,
        name: this.$t('finance.journalAccount.capitalFlow.index.5u3djec2cyg6')
      }],
      tableFrom: {
        page: 1,
        limit: 20,
        categoryId: null,
        subType: '',
        keywords: '',
        type: '1',
        isSelf: null,
        merId: null
      },
      keywords: '',
      categoryList: [],
      merCateList: [],
      objectUrl: process.env.VUE_APP_BASE_API,
      dialogVisible: false,
      card_select_show: false,
      checkAll: false,
      checkedCities: this.$t('product.list.checkedCities'),
      columnData: this.$t('product.list.columnData'),
      isIndeterminate: true,
      merchantList: [],
      search: {
        limit: 10,
        page: 1,
        keywords: ''
      },
      loading: false,
      totalPage: 0,
      total: 0,
      keyNum: 0,
      multipleSelection: [],
      OffId: [],
      previewVisible: false,
      frontDomainUrl: '' // iframe地址
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:product:tabs:headers'])) this.goodHeade();
    if (!localStorage.getItem('adminProductClassify')) this.$store.dispatch('product/getAdminProductClassify');
    if ((0, _permission.checkPermi)(['platform:product:page:list'])) this.getList();
    this.checkedCities = this.$cache.local.has('goods_stroge') ? this.$cache.local.getJSON('goods_stroge') : this.checkedCities;
    this.$nextTick(function () {
      var _this2 = this;
      var clipboard = new _clipboard.default('.copy-data');
      clipboard.on('success', function () {
        _this2.$message.success(_this2.$t('pagediy.devise.creatDevise.5trmh81e91c0'));
      });
    });
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['adminProductClassify', 'frontDomain'])), {}, {
    heightBoxs: function heightBoxs() {
      var _this3 = this;
      this.$nextTick(function () {
        // 页面渲染完成后的回调
        return Number(_this3.$refs.headerBox.offsetHeight) - Number(document.documentElement.clientHeight);
      });
    }
  }),
  methods: {
    checkPermi: _permission.checkPermi,
    ishttp: function ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
    },
    // 预览
    handlePreview: function handlePreview(id) {
      this.previewVisible = true;
      if (this.ishttp(this.frontDomain)) {
        this.frontDomainUrl = "".concat(this.frontDomain, "/pages/goods/goods_details/index?id=").concat(id);
      } else {
        this.frontDomainUrl = "https://".concat(this.frontDomain, "/pages/goods/goods_details/index?id=").concat(id);
      }
    },
    // 复制链接
    copy: function copy(id) {
      var clipboard = new Clipboard(id);
      console.log(clipboard);
    },
    getMerId: function getMerId(id) {
      this.tableFrom.merId = id;
      this.subSuccess();
    },
    // 批量下架
    batchOff: function batchOff() {
      if (this.multipleSelection.length === 0) return this.$message.warning(this.$t('product.list.batchOffTips'));
      this.handleOff(this.OffId);
    },
    // 下架
    handleOff: function handleOff(id) {
      var _this4 = this;
      this.$modalSure(this.$t('product.list.handleOffTips')).then(function () {
        (0, _product.offShellApi)({
          ids: id.toString()
        }).then(function (res) {
          _this4.$message({
            type: 'success',
            message: _this4.$t('operateBtn.operateTips')
          });
          _this4.subSuccess();
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      var data = [];
      this.multipleSelection.map(function (item) {
        data.push(item.id);
      });
      this.OffId = data;
    },
    handleEdit: function handleEdit(row) {
      var _this = this;
      this.$modalParserFrom('编辑商品', '平台商品编辑', 1, {
        ficti: row.ficti || '0',
        id: row.id,
        rank: row.sort
      }, function (formValue) {
        _this.submit(formValue, row.id);
      }, this.keyNum += 5);
    },
    submit: function submit(formValue, id) {
      var _this5 = this;
      var data = {
        id: id,
        ficti: formValue.ficti,
        sort: formValue.rank
      };
      (0, _product.updateProductApi)(data).then(function (res) {
        _this5.$message.success(_this5.$t('operateBtn.operateTips'));
        _this5.$msgbox.close();
        _this5.getList(1);
      }).catch(function () {
        _this5.loading = false;
      });
    },
    subSuccess: function subSuccess() {
      this.getList('');
      this.goodHeade();
    },
    handleAudit: function handleAudit(id, c) {
      this.productId = id;
      this.$refs.infoFrom.dialogVisible = true;
      this.isShow = true;
      this.isAtud = c;
      this.componentKey += 1;
      this.$refs.infoFrom.getInfo(id);
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 导出
    exports: function exports() {
      (0, _product.productExcelApi)({
        cateId: this.tableFrom.cateId,
        keywords: this.tableFrom.keywords,
        type: this.tableFrom.type
      }).then(function (res) {
        window.location.href = res.fileName;
      });
    },
    // 获取商品表单头数量
    goodHeade: function goodHeade() {
      var _this6 = this;
      (0, _product.productHeadersApi)().then(function (res) {
        res.map(function (item, index) {
          if (item.type === headerName[index].type) item.name = headerName[index].name;
        });
        _this6.headeNum = res;
      }).catch(function (res) {
        _this6.$message.error(res.message);
      });
    },
    //選擇類型
    isSpreadChange: function isSpreadChange(index) {
      this.tableFrom.subType = index;
      console.log(this.tableFrom.subType);
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this7 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.keywords = encodeURIComponent(this.keywords);
      this.listLoading = true;
      (0, _product.productLstApi)(this.tableFrom).then(function (res) {
        _this7.tableData.data = res.list;
        _this7.tableData.total = res.total;
        _this7.listLoading = false;
      }).catch(function (res) {
        _this7.listLoading = false;
        _this7.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, type) {
      var _this8 = this;
      this.$modalSure(this.$t('operateBtn.delTips')).then(function () {
        var deleteFlag = type == 5 ? 'delete' : 'recycle';
        (0, _product.productDeleteApi)(id, deleteFlag).then(function () {
          _this8.$message.success(_this8.$t('operateBtn.delSucess'));
          _this8.getList();
          _this8.goodHeade();
        });
      });
    },
    renderHeader: function renderHeader(h) {
      var _this9 = this;
      return h("p", [h("span", {
        "style": "padding-right:5px;"
      }, ["\u64CD\u4F5C"]), h("i", {
        "class": "el-icon-setting",
        "on": {
          "click": function click() {
            return _this9.handleAddItem();
          }
        }
      })]);
    },
    handleAddItem: function handleAddItem() {
      if (this.card_select_show) {
        this.$set(this, 'card_select_show', false);
      } else if (!this.card_select_show) {
        this.$set(this, 'card_select_show', true);
      }
    },
    handleCloseMod: function handleCloseMod(item) {
      this.dialogVisible = item;
      this.goodHeade();
      this.getList();
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedCities = val ? this.columnData : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.columnData.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnData.length;
    },
    checkSave: function checkSave() {
      this.$set(this, 'card_select_show', false);
      this.$modal.loading(this.$t('product.list.checkSave'));
      this.$cache.local.setJSON('goods_stroge', this.checkedCities);
      setTimeout(this.$modal.closeLoading(), 1000);
    }
  }
};