"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalFlowExportApi = capitalFlowExportApi;
exports.capitalFlowLstApi = capitalFlowLstApi;
exports.closingAuditApi = closingAuditApi;
exports.closingConfigApi = closingConfigApi;
exports.closingEditApi = closingEditApi;
exports.dayStatementApi = dayStatementApi;
exports.fundsExcelApi = fundsExcelApi;
exports.fundsExcelDaily = fundsExcelDaily;
exports.fundsExcelMonth = fundsExcelMonth;
exports.merClosingDetailApi = merClosingDetailApi;
exports.merClosingRemarkApi = merClosingRemarkApi;
exports.merchantClosingExcelApi = merchantClosingExcelApi;
exports.merchantClosingListApi = merchantClosingListApi;
exports.monthStatementApi = monthStatementApi;
exports.rechargeLstApi = rechargeLstApi;
exports.statementsApi = statementsApi;
exports.transferProofApi = transferProofApi;
exports.userClosingAuditApi = userClosingAuditApi;
exports.userClosingListApi = userClosingListApi;
exports.userClosingProofApi = userClosingProofApi;
exports.userClosingRemarkApi = userClosingRemarkApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 商户结算分页列表
 * @param params
 */
function merchantClosingListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/finance/merchant/closing/list',
    method: 'get',
    params: params
  });
}

/**
 * 商户结算备注
 * @param params
 */
function merClosingRemarkApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/finance/merchant/closing/remark',
    method: 'post',
    data: data
  });
}

/**
 * 商户结算记录详情
 * @param params
 */
function merClosingDetailApi(closingNo) {
  return (0, _request.default)({
    url: "admin/platform/finance/merchant/closing/detail/".concat(closingNo),
    method: 'get'
  });
}

/**
 * 导出商户结算Excel
 * @param params 对象
 */
function merchantClosingExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/export/merchant/closing/excel",
    method: 'get',
    params: params
  });
}

/**
 * 导出平台流水Excel
 * @param params 对象
 */
function fundsExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/export/funds/excel",
    method: 'get',
    params: params
  });
}
/**
 * 导出日賬單Excel
 * @param params 对象
 */
function fundsExcelDaily(params) {
  return (0, _request.default)({
    url: "/admin/platform/finance/daily/statement/export",
    method: 'get',
    params: params
  });
}
/**
 * 导出月賬單Excel
 * @param params 对象
 */
function fundsExcelMonth(params) {
  return (0, _request.default)({
    url: "/admin/platform/finance/month/statement/export",
    method: 'get',
    params: params
  });
}

/**
 * @description 商户结算到账凭证
 */
function transferProofApi(data) {
  return (0, _request.default)({
    url: "admin/platform/finance/merchant/closing/proof",
    method: 'POST',
    data: data
  });
}

/**
 * @description商户结算申请审核
 */
function closingAuditApi(data) {
  return (0, _request.default)({
    url: "admin/platform/finance/merchant/closing/audit",
    method: 'POST',
    data: data
  });
}

/**
 * @获取商户结算设置
 */
function closingConfigApi(data) {
  return (0, _request.default)({
    url: "admin/platform/finance/merchant/closing/config",
    method: 'get'
  });
}

/**
 * @编辑商户结算设置
 */
function closingEditApi(data) {
  return (0, _request.default)({
    url: "admin/platform/finance/merchant/closing/config/edit",
    method: 'post',
    data: data
  });
}

/**
 * 用户结算备注
 * @param params
 */
function userClosingRemarkApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/finance/user/closing/remark',
    method: 'post',
    data: data
  });
}

/**
 * 用户结算申请审核
 * @param params
 */
function userClosingAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/finance/user/closing/audit",
    method: 'POST',
    data: data
  });
}

/**
 * 用户结算分页列表
 * @param params
 */
function userClosingListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/finance/user/closing/list",
    method: 'get',
    params: params
  });
}

/**
 * 用户结算到账凭证
 * @param params
 */
function userClosingProofApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/finance/user/closing/proof",
    method: 'POST',
    data: data
  });
}

/**
 * @description 账单管理 日帐单管理分页列表
 */
function dayStatementApi(params) {
  return (0, _request.default)({
    url: "admin/platform/finance/daily/statement/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 账单管理 月帐单管理分页列表
 */
function monthStatementApi(params) {
  return (0, _request.default)({
    url: "admin/platform/finance/month/statement/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 资金流水 -- 列表
 */
function capitalFlowLstApi(params) {
  return (0, _request.default)({
    url: "admin/platform/finance/funds/flow",
    method: 'get',
    params: params
  });
}

/**
 * @description 资金流水 -- 导出
 */
function capitalFlowExportApi(data) {
  return _request.default.get("financial_record/export", data);
}

/**
 * @description 充值订单分页列表
 */
function rechargeLstApi(params) {
  return (0, _request.default)({
    url: "admin/platform/recharge/order/list",
    method: 'get',
    params: params
  });
}

/**
 * @description 财务流水汇总
 */
function statementsApi(params) {
  return (0, _request.default)({
    url: "admin/platform/finance/summary/financial/statements",
    method: 'get',
    params: params
  });
}