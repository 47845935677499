"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_goods',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  components: {
    draggable: _vuedraggable.default
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
      },
      immediate: true,
      deep: true
    }
  },
  data: function data() {
    return {
      goodsList: [],
      defaults: this.configObj
    };
  },
  created: function created() {
    this.defaults = this.configObj;
  },
  methods: {
    //选择商品
    addGoods: function addGoods() {
      var _this = this;
      this.$modalGoodList(function (row) {
        _this.listLoading = false;
        _this.getAttrValue(row);
      }, 'many', _this.defaults.goodsList.list);
    },
    // 选中商品
    getAttrValue: function getAttrValue(row) {
      var _this = this;
      _this.defaults.goodsList.list = row;
      _this.$emit('getConfig', {
        name: 'goods',
        values: row
      });
    },
    bindDelete: function bindDelete(index) {
      this.defaults.goodsList.list.splice(index, 1);
    }
  }
};