"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_select',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    },
    number: {
      type: null
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      timeStamp: '',
      multiple: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
    });
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      deep: true
    },
    number: function number(nVal) {
      this.timeStamp = nVal;
    }
  },
  methods: {
    sliderChange: function sliderChange(e) {
      var storage = window.localStorage;
      this.configData.activeValue = e ? e : storage.getItem(this.timeStamp);
      this.$emit('getConfig', {
        name: 'select',
        values: e
      });
    }
  }
};