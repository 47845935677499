"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  inheritAttrs: false,
  props: ['originResource'],
  data: function data() {
    return {
      resources: null
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onOpen: function onOpen() {
      this.resources = this.originResource.length ? JSON.parse(JSON.stringify(this.originResource)) : [''];
    },
    onClose: function onClose() {},
    close: function close() {
      this.$emit('update:visible', false);
    },
    handelConfirm: function handelConfirm() {
      var results = this.resources.filter(function (item) {
        return !!item;
      }) || [];
      this.$emit('save', results);
      this.close();
      if (results.length) {
        this.resources = results;
      }
    },
    deleteOne: function deleteOne(index) {
      this.resources.splice(index, 1);
    },
    addOne: function addOne(url) {
      if (this.resources.indexOf(url) > -1) {
        this.$message('资源已存在');
      } else {
        this.resources.push(url);
      }
    }
  }
};