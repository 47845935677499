"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'home_discover',
  cname: '种草社区',
  configName: 'c_home_discover',
  icon: 't-icon-zujian-zhongcaoshequ',
  type: 1,
  // 0 基础组件 1 营销组件 2工具组件
  defaultName: 'homeDiscover',
  // 外面匹配名称
  props: {
    index: {
      type: null
    },
    num: {
      type: null
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, (0, _vuex.mapState)('mobildConfig', ['defaultArray'])), (0, _vuex.mapGetters)(['mediaDomain'])), {}, {
    //最外层盒子的样式
    boxStyle: function boxStyle() {
      return [{
        'border-radius': this.configObj.bgStyle.val ? this.configObj.bgStyle.val + 'px' : '0'
      }, {
        margin: this.configObj.mbConfig.val + 'px' + ' ' + this.configObj.lrConfig.val + 'px' + ' ' + 0
      }, {
        padding: this.configObj.upConfig.val + 'px' + ' ' + '10px' + ' ' + this.configObj.downConfig.val + 'px'
      }];
    },
    //图片背景
    boxBgStyle: function boxBgStyle() {
      return {
        backgroundImage: "url('https://api.admin.merchant.jiefanghui.xbdzz.cn/crmebimage/public/activitystyle/2023/05/30/5fb66919d78c4c2892fee5900915fba5bepulyen1m.png'),linear-gradient(".concat(this.configObj.bgColor.color[0].item, ", ").concat(this.configObj.bgColor.color[1].item, ")")
      };
    },
    //内容边距
    contentConfig: function contentConfig() {
      if (this.listStyle === 0) {
        return [{
          'margin-right': this.configObj.contentConfig.val ? this.configObj.contentConfig.val + 'px' : '0'
        }, {
          'border-radius': this.configObj.contentStyle.val + 'px'
        }];
      } else {
        return [{
          'grid-gap': this.configObj.contentConfig.val ? this.configObj.contentConfig.val + 'px' : '0'
        }, {
          'border-radius': this.configObj.contentStyle.val + 'px'
        }];
      }
    },
    //作者昵称
    nameColor: function nameColor() {
      return {
        color: this.configObj.nameColor.color[0].item
      };
    },
    //标题颜色
    titleColor: function titleColor() {
      return {
        color: this.configObj.titleColor.color[0].item
      };
    },
    //文章标题颜色
    laberColor: function laberColor() {
      return {
        color: this.configObj.laberColor.color[0].item
      };
    },
    //更多颜色
    moreColor: function moreColor() {
      return {
        color: this.configObj.moreColor.color[0].item
      };
    },
    //展示数量
    numConfig: function numConfig() {
      return this.configObj.numConfig.val;
    },
    //图片样式
    contentStyle: function contentStyle() {
      return {
        'border-radius': this.configObj.contentStyle.val + 'px'
      };
    },
    //标题
    titleShow: function titleShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(0) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    //头像
    avatarShow: function avatarShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(1) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    //昵称
    nicknameShow: function nicknameShow() {
      if (this.configObj.typeConfig.activeValue.indexOf(2) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }),
  watch: {
    pageData: {
      handler: function handler(nVal, oVal) {
        this.setConfig(nVal);
      },
      deep: true
    },
    num: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[nVal];
        this.setConfig(data);
      },
      deep: true
    },
    defaultArray: {
      handler: function handler(nVal, oVal) {
        var data = this.$store.state.mobildConfig.defaultArray[this.num];
        this.setConfig(data);
      },
      deep: true
    }
  },
  data: function data() {
    return {
      // 默认初始化数据禁止修改
      defaultConfig: {
        name: 'homeDiscover',
        timestamp: this.num,
        setUp: {
          tabVal: 0,
          cname: '种草社区'
        },
        productList: {
          title: '推荐组',
          list: []
        },
        logoConfig: {
          isShow: 1,
          tabTitle: '图标设置',
          title: '上传图标',
          tips: '建议：124px*32px',
          url: localStorage.getItem('mediaDomain') + '/crmebimage/presets/guangguang.png'
        },
        titleConfig: {
          tabTitle: '标题设置',
          title: '标题内容',
          val: '生活好物分享',
          place: '请输入标题',
          isShow: 1,
          max: 15
        },
        linkConfig: {
          title: '更多链接',
          val: '/pages/discover_index/index',
          place: '请选择链接',
          isShow: 1,
          max: 100
        },
        //数量
        numConfig: {
          tabTitle: '内容数量',
          title: '展示数量',
          val: 3,
          isShow: 1,
          max: 8
        },
        //显示内容
        typeConfig: {
          tabTitle: '显示内容',
          title: '展示内容',
          name: 'typeConfig',
          activeValue: [0, 1, 2],
          list: [{
            val: '文章标题'
          }, {
            val: '作者头像'
          }, {
            val: '作者昵称'
          }]
        },
        tabConfig: {
          tabTitle: '布局设置',
          title: '展示样式',
          tabVal: 0,
          isShow: 1,
          list: [{
            val: '单行模式',
            icon: 'icon-yangshiyi'
          }, {
            val: '双排模式',
            icon: 'icon-yangshisan'
          }]
        },
        bgColor: {
          tabTitle: '颜色设置',
          title: '背景颜色',
          color: [{
            item: '#D2B0FF'
          }, {
            item: '#FFFFFF'
          }],
          default: [{
            item: '#D2B0FF'
          }, {
            item: '#FFFFFF'
          }]
        },
        laberColor: {
          name: 'laberColor',
          title: '文章标题颜色',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        nameColor: {
          name: 'nameColor',
          title: '作者昵称',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        titleColor: {
          title: '标题颜色',
          color: [{
            item: '#999999'
          }],
          default: [{
            item: '#999999'
          }]
        },
        moreColor: {
          title: '更多按钮颜色',
          color: [{
            item: '#282828'
          }],
          default: [{
            item: '#282828'
          }]
        },
        bgStyle: {
          tabTitle: '圆角设置',
          title: '背景圆角',
          name: 'bgStyle',
          val: 7,
          min: 0,
          max: 30
        },
        contentStyle: {
          title: '图片圆角',
          name: 'bgStyle',
          val: 7,
          min: 0,
          max: 30
        },
        // 上间距
        upConfig: {
          tabTitle: '边距设置',
          title: '上边距',
          val: 10,
          min: 0,
          max: 100
        },
        // 下间距
        downConfig: {
          title: '下边距',
          val: 10,
          min: 0
        },
        // 左右间距
        lrConfig: {
          title: '左右边距',
          val: 12,
          min: 0,
          max: 25
        },
        mbConfig: {
          title: '顶部间距',
          val: 10,
          min: 0
        },
        contentConfig: {
          title: '内容间距',
          val: 10,
          min: 0,
          max: 30
        }
      },
      navlist: [],
      list: [{
        image: '',
        store_name: '西安首家线下体验店',
        nickname: '国宝小熊猫',
        avatar: '',
        iconfont: 'icon-shoucang',
        likes: '1.5w'
      }, {
        image: '',
        store_name: '西安首家线下体验店',
        nickname: '国宝小熊猫',
        avatar: '',
        iconfont: 'icon-shoucang1',
        likes: '215'
      }, {
        image: '',
        store_name: '西安首家线下体验店',
        nickname: '国宝小熊猫',
        avatar: '',
        iconfont: 'icon-shoucang1',
        likes: '1.5w'
      }
      // {
      //   image: '',
      //   store_name: '西安首家线下体验店',
      //   nickname: '国宝小熊猫',
      //   avatar: '',
      //   iconfont: 'icon-shoucang',
      //   likes: '215',
      // },
      // {
      //   image: '',
      //   store_name: '西安首家线下体验店',
      //   nickname: '国宝小熊猫',
      //   avatar: '',
      //   iconfont: 'icon-shoucang',
      //   likes: '1.5w',
      // },
      // {
      //   image: '',
      //   store_name: '西安首家线下体验店',
      //   nickname: '国宝小熊猫',
      //   avatar: '',
      //   iconfont: 'icon-shoucang1',
      //   likes: '215',
      // },
      // {
      //   image: '',
      //   store_name: '西安首家线下体验店',
      //   nickname: '国宝小熊猫',
      //   avatar: '',
      //   iconfont: 'icon-shoucang1',
      //   likes: '1.5w',
      // },
      // {
      //   image: '',
      //   store_name: '西安首家线下体验店',
      //   nickname: '国宝小熊猫',
      //   avatar: '',
      //   iconfont: 'icon-shoucang',
      //   likes: '215',
      // },
      ],
      discoverList: [],
      pageData: {},
      listStyle: 0,
      configObj: null,
      src: '' //标题图片
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.num) {
        _this.pageData = _this.$store.state.mobildConfig.defaultArray[_this.num];
        _this.setConfig(_this.pageData);
      }
    });
  },
  methods: {
    setConfig: function setConfig(data) {
      if (!data) return;
      //this.discoverList = this.list;
      if (data) {
        this.configObj = data;
        this.navlist = data.tabConfig.list;
        this.listStyle = data.tabConfig.tabVal;
        this.src = this.configObj.logoConfig.url ? this.configObj.logoConfig.url : localStorage.getItem('mediaDomain') + '/crmebimage/presets/guangguang.png';
        //this.discoverList = this.list.splice(0, this.configObj.numConfig.val)
      }
    }
  }
};