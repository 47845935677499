"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_pictrue',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      isUpdate: false,
      // 重新渲染
      currentIndex: 0,
      arrayObj: {
        image: '',
        link: ''
      }
    };
  },
  computed: {
    style: function style() {
      return this.configObj.tabConfig.tabVal;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      if (_this.configObj.hasOwnProperty('timestamp')) {
        _this.isUpdate = true;
      } else {
        _this.isUpdate = false;
      }
      _this.$set(_this, 'configData', _this.configObj[_this.configNme]);
    });
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.$set(this, 'configData', nVal[this.configNme]);
        this.isUpdate = true;
        this.$set(this, 'isUpdate', true);
      },
      deep: true
    },
    'configObj.tabConfig.tabVal': {
      handler: function handler(nVal, oVal) {
        this.count = this.defaults.tabConfig.list[nVal].count;
        this.picArrayConcat(this.count);
        this.configData.picList.splice(nVal + 1);
        this.currentIndex = 0;
        var list = this.defaults.menuConfig.list[0];
        if (this.configData.picList[0]) {
          list.img = this.configData.picList[0].image;
          list.info[0].value = this.configData.picList[0].link;
        }
      },
      deep: true
    }
  },
  methods: {
    currentTab: function currentTab(e, data) {
      this.currentIndex = e;
      this.configData.tabVal = e;
      if (this.defaults.menuConfig.isCube) {
        var list = this.defaults.menuConfig.list[0];
        if (data.picList[e] && data.picList[e].image) {
          list.img = data.picList[e].image;
          list.info[0].value = data.picList[e].link;
        } else {
          list.img = '';
          list.info[0].value = '';
        }
      }
    },
    picArrayConcat: function picArrayConcat(count) {
      for (var i = this.configData.picList.length; i < count; i++) {
        this.configData.picList.push(this.arrayObj);
      }
    }
  }
};