"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/components/Tinymce/index"));
var _auth = require("@/utils/auth");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_page_ueditor',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  components: {
    Tinymce: _index.default
  },
  data: function data() {
    // const url = SettingMer.https + '/upload/image/0/file?ueditor=1&token=' + getToken()
    return {
      myConfig: {
        autoHeightEnabled: false,
        // 编辑器不自动被内容撑高
        initialFrameHeight: 350,
        // 初始容器高度
        initialFrameWidth: '100%',
        // 初始容器宽度
        UEDITOR_HOME_URL: '/UEditor/',
        imageFieldName: 'file',
        imageUrlPrefix: '',
        imageActionName: 'upfile',
        imageMaxSize: 2048000,
        imageAllowFiles: ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
      },
      description: '',
      defaults: {},
      configData: {}
    };
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  }
};