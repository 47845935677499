"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDateRange = addDateRange;
exports.formatRoutes = formatRoutes;
exports.handleTree = handleTree;
exports.mergeRecursive = mergeRecursive;
exports.parseTime = parseTime;
exports.praseStrEmpty = praseStrEmpty;
exports.resetForm = resetForm;
exports.sprintf = sprintf;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/**
 * 通用js方法封装处理
 */

// 日期格式化
function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

// 表单重置
function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
}

// 添加日期范围
function addDateRange(params, dateRange, propName) {
  var search = params;
  search.params = _typeof(search.params) === 'object' && search.params !== null && !Array.isArray(search.params) ? search.params : {};
  dateRange = Array.isArray(dateRange) ? dateRange : [];
  if (typeof propName === 'undefined') {
    search.params['beginTime'] = dateRange[0];
    search.params['endTime'] = dateRange[1];
  } else {
    search.params['begin' + propName] = dateRange[0];
    search.params['end' + propName] = dateRange[1];
  }
  return search;
}

// 字符串格式化(%s )
function sprintf(str) {
  var args = arguments,
    flag = true,
    i = 1;
  str = str.replace(/%s/g, function () {
    var arg = args[i++];
    if (typeof arg === 'undefined') {
      flag = false;
      return '';
    }
    return arg;
  });
  return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
function praseStrEmpty(str) {
  if (!str || str == 'undefined' || str == 'null') {
    return '';
  }
  return str;
}

// 数据合并
function mergeRecursive(source, target) {
  for (var p in target) {
    try {
      if (target[p].constructor == Object) {
        source[p] = mergeRecursive(source[p], target[p]);
      } else {
        source[p] = target[p];
      }
    } catch (e) {
      source[p] = target[p];
    }
  }
  return source;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
function handleTree(data, id, parentId, children) {
  var config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  };
  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];
  var _iterator = _createForOfIteratorHelper(data),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var d = _step.value;
      var _parentId = d[config.parentId];
      if (childrenListMap[_parentId] == null) {
        childrenListMap[_parentId] = [];
      }
      nodeIds[d.id] = d;
      childrenListMap[_parentId].push(d);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var _iterator2 = _createForOfIteratorHelper(data),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _d = _step2.value;
      var _parentId2 = _d[config.parentId];
      if (nodeIds[_parentId2] == null) {
        tree.push(_d);
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  for (var _i = 0, _tree = tree; _i < _tree.length; _i++) {
    var t = _tree[_i];
    adaptToChildrenList(t);
  }
  function adaptToChildrenList(o) {
    if (childrenListMap[o.id] !== null) {
      o[config.childrenList] = childrenListMap[o.id];
    }
    if (o[config.childrenList]) {
      var _iterator3 = _createForOfIteratorHelper(o[config.childrenList]),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var c = _step3.value;
          adaptToChildrenList(c);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    }
  }
  return tree;
}

/**
 * 路由数据遍历
 *
 */
function formatRoutes(routerArr) {
  var arr = [],
    obj = {};
  routerArr.forEach(function (tmp) {
    obj = {
      id: tmp.id,
      pid: tmp.pid,
      name: tmp.name,
      url: tmp.component,
      path: '/' + tmp.pid + '/',
      perms: tmp.perms,
      child: tmp.childList.length ? tmp.childList.map(function (item) {
        return {
          id: item.id,
          pid: item.pid,
          name: item.name,
          url: item.component,
          path: '/' + tmp.pid + '/' + item.pid + '/',
          perms: item.perms,
          extra: item.icon,
          child: item.childList.length ? item.childList.map(function (item1) {
            return {
              id: item1.id,
              pid: item1.pid,
              name: item1.name,
              url: item1.component,
              path: '/' + tmp.pid + '/' + item.pid + '/' + item1.pid + '/',
              perms: item1.perms,
              extra: item1.icon,
              child: item1.childList.length ? item1.childList.map(function (item2) {
                return {
                  id: item2.id,
                  pid: item2.pid,
                  name: item2.name,
                  url: item2.component,
                  path: '/' + tmp.pid + '/' + item.pid + '/' + item1.pid + '/' + item2.pid + '/',
                  perms: item2.perms,
                  extra: item2.icon
                };
              }) : []
            };
          }) : []
        };
      }) : [],
      extra: tmp.icon
    };
    arr.push(obj);
  });
  return arr;
}