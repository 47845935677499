"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_hot_word',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  components: {
    draggable: _vuedraggable.default
  },
  data: function data() {
    return {
      hotWordList: [],
      hotIndex: 1,
      defaults: {},
      configData: {}
    };
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        // this.hotWordList = nVal.hotList
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    addHotTxt: function addHotTxt() {
      // let obj = {}
      // if(this.configData.list.length){
      //     obj = JSON.parse(JSON.stringify(this.configData.list[this.configData.list.length - 1]))
      // }else {
      //     obj = {
      //         val: ''
      //     }
      // }
      var obj = {
        val: ''
      };
      this.configData.list.push(obj);
      // this.$emit('input', this.hotWordList);
    },
    // 删除数组
    bindDelete: function bindDelete(index) {
      this.configData.list.splice(index, 1);
    }
  }
};