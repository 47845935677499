"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _systemFormConfig = require("@/api/systemFormConfig");
var _wxApi = require("@/api/wxApi");
var _validate = require("@/utils/validate");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  data: function data() {
    return {
      modalTitle: '',
      notificationModal: false,
      headerList: [{
        label: this.$t('systemSetting.notification.index.5u0ywczfwew0'),
        value: '1'
      }
      // { label: '通知平台', value: '2' },
      ],
      id: 0,
      levelLists: [],
      currentTab: '1',
      loading: false,
      formData: {},
      industry: null,
      loadingList: false,
      centerDialogVisible: false,
      infoList: [],
      infoList1: [{
        label: this.$t('systemSetting.notification.index.5u0ywczfwio0'),
        value: 'sms'
      }],
      form: {
        content: '',
        name: '',
        id: '',
        status: null,
        tempId: '',
        tempKey: '',
        title: ''
      },
      detailType: '',
      infoTab: ''
    };
  },
  created: function created() {
    if ((0, _permission.checkPermi)(['platform:system:notification:list'])) this.getNotificationList(Number(this.currentTab));
  },
  methods: {
    checkPermi: _permission.checkPermi,
    changeTab: function changeTab(data) {
      this.getNotificationList(data.name);
    },
    //获取消息列表
    getNotificationList: function getNotificationList(id) {
      var _this = this;
      this.loadingList = true;
      (0, _systemFormConfig.notificationListApi)({
        sendType: id
      }).then(function (res) {
        _this.loadingList = false;
        _this.levelLists = res;
      }).catch(function (res) {
        _this.loadingList = false;
      });
    },
    //公众号消息开关
    changeWechat: function changeWechat(row) {
      var _this2 = this;
      (0, _systemFormConfig.notificationWechat)(row.id).then(function (res) {
        _this2.$modal.msgSuccess(_this2.$t('systemSetting.notification.index.5u0ywczfwls0'));
      });
    },
    //小程序消息开关
    changeRoutine: function changeRoutine(row) {
      var _this3 = this;
      (0, _systemFormConfig.notificationRoutine)(row.id).then(function (res) {
        _this3.$modal.msgSuccess(_this3.$t('systemSetting.notification.index.5u0ywczfwls0'));
      });
    },
    //短信消息开关
    changeSms: function changeSms(row) {
      var _this4 = this;
      (0, _systemFormConfig.notificationSms)(row.id).then(function (res) {
        _this4.$modal.msgSuccess(_this4.$t('systemSetting.notification.index.5u0ywczfwls0'));
      });
    },
    //详情tab切换
    changeInfo: function changeInfo(data) {
      this.getNotificationDetail(data);
    },
    //详情数据
    getNotificationDetail: function getNotificationDetail(param) {
      var _this5 = this;
      this.loading = true;
      var data = {
        id: this.id,
        type: param.name
      };
      this.$set(this, 'detailType', data.type);
      (0, _systemFormConfig.notificationDetail)(data).then(function (res) {
        _this5.form = res;
        _this5.$set(_this5.form, 'status', res.status.toString());
        _this5.loading = false;
      }).catch(function (res) {
        _this5.loading = false;
      });
    },
    // 设置
    setting: function setting(row) {
      this.infoList = [];
      this.id = row.id;
      this.centerDialogVisible = true;
      if (row.isWechat !== 0) {
        this.infoList.push({
          label: this.$t('systemSetting.notification.index.5u0ywczfwqg0'),
          value: 'wechat'
        });
      }
      if (row.isRoutine !== 0) {
        this.infoList.push({
          label: this.$t('systemSetting.notification.index.5u0ywczfwts0'),
          value: 'routine'
        });
      }
      if (row.isSms !== 0) {
        this.infoList.push({
          label: this.$t('systemSetting.notification.index.5u0ywczfwio0'),
          value: 'sms'
        });
      }
      this.infoTab = this.infoList[0].value;
      this.getNotificationDetail({
        name: this.infoTab
      });
    },
    //修改通知
    submit: (0, _validate.Debounce)(function () {
      var _this6 = this;
      var data = {
        id: this.id,
        status: Number(this.form.status),
        tempId: this.form.tempId,
        type: this.detailType
      };
      (0, _systemFormConfig.notificationUpdate)(data).then(function (res) {
        _this6.$modal.msgSuccess(_this6.$t('systemSetting.notification.index.5u0ywczfwls0'));
        _this6.centerDialogVisible = false;
        _this6.getNotificationList();
      });
    }),
    syncWechat: function syncWechat() {
      var _this7 = this;
      (0, _wxApi.wechatAsyncApi)().then(function (res) {
        _this7.$message.success(_this7.$t('systemSetting.notification.index.5u0ywczfwws0'));
      });
    },
    syncRoutine: function syncRoutine() {
      var _this8 = this;
      (0, _wxApi.routineAsyncApi)().then(function (res) {
        _this8.$message.success(_this8.$t('systemSetting.notification.index.5u0ywczfwws0'));
      });
    }
  }
};