"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.balanceApi = balanceApi;
exports.captchaApi = captchaApi;
exports.foundsApi = foundsApi;
exports.getInfo = getInfo;
exports.getLoginPicApi = getLoginPicApi;
exports.groupPiApi = groupPiApi;
exports.infobyconditionApi = infobyconditionApi;
exports.levelDeleteApi = levelDeleteApi;
exports.levelInfoApi = levelInfoApi;
exports.levelListApi = levelListApi;
exports.levelSaveApi = levelSaveApi;
exports.levelUpdateApi = levelUpdateApi;
exports.levelUseApi = levelUseApi;
exports.login = login;
exports.logout = logout;
exports.systemUserLevelConfigApi = systemUserLevelConfigApi;
exports.systemUserLevelRuleApi = systemUserLevelRuleApi;
exports.systemUserLevelUpdateConfigApi = systemUserLevelUpdateConfigApi;
exports.systemUserLevelUpdateRuleApi = systemUserLevelUpdateRuleApi;
exports.tagAllListApi = tagAllListApi;
exports.tagDeleteApi = tagDeleteApi;
exports.tagInfoApi = tagInfoApi;
exports.tagListApi = tagListApi;
exports.tagPiApi = tagPiApi;
exports.tagSaveApi = tagSaveApi;
exports.tagUpdateApi = tagUpdateApi;
exports.topdetailApi = topdetailApi;
exports.updatePhoneApi = updatePhoneApi;
exports.updateSpreadApi = updateSpreadApi;
exports.userDeleteApi = userDeleteApi;
exports.userDetailApi = userDetailApi;
exports.userInfoApi = userInfoApi;
exports.userLevelUpdateApi = userLevelUpdateApi;
exports.userListApi = userListApi;
exports.userUpdateApi = userUpdateApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function login(data) {
  return (0, _request.default)({
    url: '/admin/platform/login',
    method: 'post',
    data: data
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/admin/platform/getAdminInfoByToken',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/admin/platform/logout',
    method: 'get'
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
function userListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/user/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 修改
 * @param pram
 */
function userUpdateApi(params, data) {
  return (0, _request.default)({
    url: "/admin/platform/user/update",
    method: 'post',
    params: params,
    data: data
  });
}

/**
 * 会员管理等级 修改
 * @param pram
 */
function userLevelUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/user/update/level",
    method: 'post',
    data: data
  });
}

/**
 * 会员管理 详情
 * @param pram
 */
function userInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/user/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 账户详情
 * @param pram
 */
function infobyconditionApi(params) {
  return (0, _request.default)({
    url: "/admin/user/infobycondition",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 账户详情top数据
 * @param pram
 */
function topdetailApi(params) {
  return (0, _request.default)({
    url: "/admin/user/topdetail",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 批量设置分组
 * @param pram
 */
function groupPiApi(params) {
  return (0, _request.default)({
    url: "/admin/user/group",
    method: 'post',
    params: params
  });
}

/**
 * 会员管理 批量设置标签
 * @param pram
 */
function tagPiApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/user/tag",
    method: 'post',
    data: data
  });
}

/**
 * 会员管理 积分余额
 * @param pram
 */
function foundsApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/user/operate/integer",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 积分余额
 * @param pram
 */
function balanceApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/user/operate/balance",
    method: 'get',
    params: params
  });
}

/**
 * 会员管理 删除
 * @param pram
 */
function userDeleteApi(params) {
  return (0, _request.default)({
    url: "/admin/user/delete",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 列表
 * @param pram
 */
function levelListApi() {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/list",
    method: 'get'
  });
}

/**
 * 会员等级 新增
 * @param pram
 */
function levelSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员等级 编辑
 *  @param pram
 */
function levelUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/update",
    method: 'post',
    data: data
  });
}

/**
 * 会员等级 详情
 * @param pram
 */
function levelInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/system/user/level/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员等级 删除
 * @param pram
 */
function levelDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 会员等级 是否显示
 * @param pram
 */
function levelUseApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/use",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 列表
 * @param pram
 */
function tagListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/user/tag/list",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 新增
 * @param pram
 */
function tagSaveApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/user/tag/save",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 编辑
 * @param pram
 */
function tagUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/user/tag/update",
    method: 'post',
    data: data
  });
}

/**
 * 会员标签 详情
 * @param pram
 */
function tagInfoApi(params) {
  return (0, _request.default)({
    url: "/admin/user/tag/info",
    method: 'get',
    params: params
  });
}

/**
 * 会员标签 删除
 * @param pram
 */
function tagDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/user/tag/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 用户标签全部列表
 */
function tagAllListApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/user/tag/all/list",
    method: 'get'
  });
}

/**
 *获取登录页图片
 */
function getLoginPicApi() {
  return (0, _request.default)({
    url: "/admin/platform/getLoginPic",
    method: 'get'
  });
}

/**
 * @description 验证码
 */
function captchaApi() {
  return (0, _request.default)({
    url: "/publicly/validate/code/get",
    method: 'get'
  });
}

/**
 * @description 修改上级推广人
 */
function updateSpreadApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/retail/store/update/user/spread",
    method: 'post',
    data: data
  });
}

/**
 * @description 修改手机号
 */
function updatePhoneApi(params) {
  return (0, _request.default)({
    url: "/admin/user/update/phone",
    method: 'get',
    params: params
  });
}

/**
 * @description 用户详情列表
 */
function userDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/user/detail/".concat(id),
    method: 'get'
  });
}

/**
 * @description 获取用户等级配置
 */
function systemUserLevelConfigApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/get/config",
    method: 'GET'
  });
}

/**
 * @description 编辑用户等级配置
 */
function systemUserLevelUpdateConfigApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/update/config",
    method: 'POST',
    data: data
  });
}

/**
 * @description 获取用户等级规则
 */
function systemUserLevelRuleApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/get/rule",
    method: 'GET'
  });
}

/**
 * @description 编辑用户等级规则
 */
function systemUserLevelUpdateRuleApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/system/user/level/update/rule",
    method: 'POST',
    data: data
  });
}