"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 权限判断函数
var signObj = {
  day: 0,
  experience: 0,
  integral: 0,
  id: 0,
  isExperience: true,
  isIntegral: true,
  show: true
};
var _default = exports.default = {
  name: 'index',
  data: function data() {
    return {
      signForm: {
        day: 0,
        experience: 0,
        id: 0,
        integral: 0,
        isExperience: true,
        isIntegral: true,
        signRuleDescription: ''
      },
      defaultDescription: '1.每天签到可以获得每天签到奖励',
      rules: {
        isIntegral: [{
          required: true,
          message: this.$t('marketing.sign.config.index.5trgn0bjrf40'),
          trigger: 'blur'
        }],
        experience: [{
          required: true,
          message: this.$t('marketing.sign.config.index.5trgn0bjrh00'),
          trigger: 'blur'
        }]
      },
      signConfigList: [],
      showTab: false
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:sign:get:config'])) this.getSignConfig();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    getSignConfig: function getSignConfig() {
      var _this = this;
      (0, _marketing.signeGetConfigApi)().then(function (res) {
        _this.signForm = res.baseSignConfig;
        _this.$set(_this, 'signConfigList', res.signConfigList);
        _this.$set(_this.signForm, 'signRuleDescription', res.signRuleDescription);
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _marketing.signBaseConfigApi)(_this2.signForm).then(function () {
            _this2.$message.success(_this2.$t('marketing.sign.config.index.5trgn0bjrjc0'));
            _this2.getSignConfig();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addSign: function addSign() {
      this.signConfigList.push(Object.assign({}, signObj));
    },
    editSign: function editSign(row) {
      this.$set(row, 'show', true);
    },
    delSign: function delSign(row) {
      var _this3 = this;
      this.$modalSure(this.$t('marketing.sign.config.index.5trgn0bjrko0')).then(function () {
        (0, _marketing.signeDelConfigApi)(row.id).then(function (data) {
          _this3.$message.success(_this3.$t('marketing.sign.config.index.5trgn0bjrmo0'));
          _this3.getSignConfig();
        });
      });
    },
    saveSign: function saveSign(row) {
      var _this4 = this;
      row.id ? (0, _marketing.signeEditConfigApi)(row).then(function () {
        _this4.$set(row, 'show', false);
        _this4.$message.success(_this4.$t('marketing.sign.config.index.5trgn0bjrjc0'));
        _this4.getSignConfig();
      }) : (0, _marketing.signAddConfigApi)(row).then(function () {
        _this4.$set(row, 'show', false);
        _this4.$message.success(_this4.$t('marketing.sign.config.index.5trgn0bjrjc0'));
        _this4.getSignConfig();
      });
    }
  }
};