"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_txt_tab',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {}
    };
  },
  created: function created() {
    this.defaults = this.configObj;
    this.configData = this.configObj[this.configNme];
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    radioChange: function radioChange(e) {
      if (this.configData.name !== 'itemStyle' && this.configData.name !== 'bgStyle' && this.configData.name !== 'contentStyle') {
        this.$emit('getConfig', {
          name: 'radio',
          values: e
        });
      }
    }
  }
};