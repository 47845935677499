"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Parser = _interopRequireDefault(require("@/components/FormGenerator/components/parser/Parser"));
var systemGroupDataApi = _interopRequireWildcard(require("@/api/systemGroupData.js"));
var systemFormConfigApi = _interopRequireWildcard(require("@/api/systemFormConfig.js"));
var _validate = require("@/utils/validate");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // name: "combineEdit"
  components: {
    parser: _Parser.default
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    isCreate: {
      type: Number,
      default: 0 // 0=create 1=edit
    },
    editData: {
      type: Object
    }
  },
  data: function data() {
    return {
      formConf: {
        fields: []
      },
      selfForm: {
        sort: 1,
        status: 1
      }
    };
  },
  mounted: function mounted() {
    this.handlerGetFormConfig();
    this.handlerInitEditData();
  },
  methods: {
    handlerInitEditData: function handlerInitEditData() {
      var _this$editData = this.editData,
        sort = _this$editData.sort,
        status = _this$editData.status;
      this.selfForm.sort = sort || this.selfForm.sort;
      this.selfForm.status = status || this.selfForm.status;
    },
    handlerGetFormConfig: function handlerGetFormConfig() {
      var _this = this;
      // 获取表单配置后生成table列
      var _pram = {
        id: this.formData.formId
      };
      systemFormConfigApi.getFormConfigInfo(_pram).then(function (data) {
        _this.formConf = JSON.parse(data.content);
      });
    },
    handlerSubmit: (0, _validate.Debounce)(function (formValue) {
      this.isCreate === 0 ? this.handlerSave(formValue) : this.handlerEdit(formValue);
    }),
    handlerSave: function handlerSave(formValue) {
      var _this2 = this;
      var _pram = this.buildFormPram(formValue);
      systemGroupDataApi.groupDataSave(_pram).then(function (data) {
        _this2.$message.success(_this2.$t('maintain.devconfig.combineEdit.5u0zk9kexh40'));
        _this2.$emit('hideDialog');
      });
    },
    handlerEdit: function handlerEdit(formValue) {
      var _this3 = this;
      var _pram = this.buildFormPram(formValue);
      systemGroupDataApi.groupDataEdit(_pram, this.editData.id).then(function (data) {
        _this3.$message.success(_this3.$t('maintain.devconfig.combineEdit.5u0zk9kexk40'));
        _this3.$emit('hideDialog');
      });
    },
    buildFormPram: function buildFormPram(formValue) {
      var _pram = {
        gid: this.formData.id,
        form: {
          fields: [],
          id: this.formData.formId,
          sort: this.selfForm.sort,
          status: this.selfForm.status
        }
      };
      var _fields = [];
      Object.keys(formValue).forEach(function (key) {
        _fields.push({
          name: key,
          title: key,
          value: formValue[key]
        });
      });
      _pram.form.fields = _fields;
      return _pram;
    }
  }
};