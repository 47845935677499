"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hasRole = _interopRequireDefault(require("./permission/hasRole"));
var _hasPermi = _interopRequireDefault(require("./permission/hasPermi"));
var _drag = _interopRequireDefault(require("./dialog/drag"));
var _dragWidth = _interopRequireDefault(require("./dialog/dragWidth"));
var _dragHeight = _interopRequireDefault(require("./dialog/dragHeight"));
var _copy = _interopRequireDefault(require("./copy/copy"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var install = function install(Vue) {
  Vue.directive('hasRole', _hasRole.default);
  Vue.directive('hasPermi', _hasPermi.default);
  Vue.directive('dialogDrag', _drag.default);
  Vue.directive('dialogDragWidth', _dragWidth.default);
  Vue.directive('dialogDragHeight', _dragHeight.default);
  Vue.directive('copy', _copy.default);
};
if (window.Vue) {
  window['hasRole'] = _hasRole.default;
  window['hasPermi'] = _hasPermi.default;
  Vue.use(install); // eslint-disable-line
}
var _default = exports.default = install;