"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var articleApi = _interopRequireWildcard(require("@/api/article.js"));
var _edit = _interopRequireDefault(require("./edit"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  // name: "list",
  components: {
    edit: _edit.default
  },
  data: function data() {
    return {
      constants: this.$constants,
      listPram: {
        author: null,
        cid: null,
        page: 1,
        title: '',
        limit: this.$constants.page.limit[0]
      },
      author: '',
      title: '',
      listData: {
        list: [],
        total: 0
      },
      editDialogConfig: {
        visible: false,
        data: {},
        isEdit: 0 // 0=add 1=edit
      },
      listLoading: true,
      categoryTreeData: [],
      categoryProps: {
        value: 'id',
        label: 'name',
        children: 'child',
        expandTrigger: 'hover',
        checkStrictly: true,
        emitPath: false
      }
    };
  },
  created: function created() {
    if (localStorage.getItem('articleClass')) {
      this.categoryTreeData = JSON.parse(localStorage.getItem('articleClass'));
    } else {
      if ((0, _permission.checkPermi)(['platform:article:category:list'])) this.handlerGetTreeList();
    }
    if ((0, _permission.checkPermi)(['platform:article:list'])) this.handlerGetListData(this.listPram);
  },
  methods: {
    checkPermi: _permission.checkPermi,
    //修改状态
    handleStatusChange: function handleStatusChange(row) {
      var _this = this;
      articleApi.articleSwitchApi(row.id).then(function (res) {
        _this.$message.success(_this.$t('content.article.list.5trhu3qpzfk0'));
        _this.handlerGetTreeList();
      });
    },
    handlerGetTreeList: function handlerGetTreeList() {
      var _this2 = this;
      articleApi.articleCategoryListApi().then(function (data) {
        _this2.categoryTreeData = data;
        var list = data.filter(function (item) {
          return item.status;
        });
        localStorage.setItem('articleClass', JSON.stringify(list));
      });
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this3 = this;
      this.listLoading = true;
      this.listPram.title = encodeURIComponent(this.title);
      this.listPram.author = encodeURIComponent(this.author);
      articleApi.ListArticle(pram).then(function (data) {
        _this3.listData = data;
        _this3.listLoading = false;
      });
    },
    handlerOpenEdit: function handlerOpenEdit(isEdit, editData) {
      // 0=add 1=edit
      if (isEdit === 1) {
        this.editDialogConfig.isEdit = 1;
        this.editDialogConfig.editData = editData;
      } else {
        this.editDialogConfig.isEdit = 0;
      }
      this.editDialogConfig.visible = true;
    },
    handlerHideDialog: function handlerHideDialog() {
      this.handlerGetListData(this.listPram);
      this.editDialogConfig.visible = false;
    },
    handlerDelete: function handlerDelete(rowData) {
      var _this4 = this;
      this.$modalSure(this.$t('content.article.list.5trhu3qpzgw0'), this.$t('content.article.list.5trhu3qpzi00')).then(function (result) {
        articleApi.DelArticle(rowData.id).then(function (data) {
          _this4.$message.success(_this4.$t('content.article.list.5trhu3qpzj40'));
          _this4.handlerGetListData(_this4.listPram);
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    }
  }
};