"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _videoChannel = require("@/api/videoChannel");
var _submitCatAudit = _interopRequireDefault(require("@/views/videoChannel/weChatcategoryAndBrand/submitCatAudit"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'categoryList',
  components: {
    SubmitCatAudit: _submitCatAudit.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: {
        // 列表数据对象
        data: [],
        total: 0
      },
      tableFrom: {
        // 列表参数
        page: 1,
        limit: 20,
        catName: null // 类目名称
      },
      forSubmitAuditCat: {
        dialog: {
          title: '上传微信类目资质',
          visible: false
        },
        catTitle: null,
        // 当前选择的三级目录
        params: {
          audit_req: {
            category_info: {
              certificate: [],
              //证件素材地址
              level1: null,
              level2: null,
              level3: null
            },
            license: '',
            scene_group_list: [1] // 类目使用场景,1:视频号 ，3:订单中心（非视频号订单中心，未明确开通此场景的商家请勿传值）。 组件开通流程中以及未接入场景时，请保持为空"scene_group_list":[]
          }
        }
      }
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:pay:component:cat:list'])) this.search();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    search: function search() {
      this.tableFrom.page = 1;
      this.tableFrom.limit = 20;
      this.getList(this.tableFrom);
    },
    // 列表
    getList: function getList(tablefrom) {
      var _this = this;
      this.listLoading = true;
      (0, _videoChannel.catListApi)(tablefrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList(this.tableFrom);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(this.tableFrom);
    },
    handleAudit: function handleAudit(row) {
      this.forSubmitAuditCat.catTitle = row.firstCatName + ' > ' + row.secondCatName + ' > ' + row.thirdCatName;
      this.forSubmitAuditCat.params.audit_req.category_info.level1 = row.firstCatId;
      this.forSubmitAuditCat.params.audit_req.category_info.level2 = row.secondCatId;
      this.forSubmitAuditCat.params.audit_req.category_info.level3 = row.thirdCatId;
      this.forSubmitAuditCat.dialog.title = '上传类目资质';
      this.forSubmitAuditCat.dialog.visible = true;
    },
    forSubmitAuditCatSuccess: function forSubmitAuditCatSuccess() {
      this.forSubmitAuditCat.dialog.visible = false;
      this.getList(this.tableFrom);
    }
  }
};