"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRoleById = getRoleById;
exports.menuListApi = menuListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 角色详情
 */
function getRoleById(pram) {
  return (0, _request.default)({
    url: "/admin/system/role/info/".concat(pram.roles),
    method: 'GET'
  });
}

/**
 * 菜单
 * @param pram
 */
function menuListApi() {
  return (0, _request.default)({
    url: '/admin/platform/getMenus',
    method: 'GET'
  });
}