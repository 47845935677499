"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_checked_tab',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    }
  },
  // created() {
  //   this.onClick(1)
  // },
  data: function data() {
    return {
      list: ['内容', '样式'],
      actived: 0,
      formData: {
        type: 0
      },
      defaults: {},
      configData: {}
    };
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        this.configData = nVal[this.configNme];
        this.actived = this.configData.tabVal;
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
    });
  },
  methods: {
    onClick: function onClick(index) {
      this.actived = index;
      this.configData.tabVal = this.actived;
      this.$emit('getConfig', {
        name: 'checkbox_tab',
        values: index
      });
    }
  }
};