var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configObj
    ? _c("div", [
        _c(
          "div",
          { staticClass: "indexList tui-skeleton", style: _vm.boxStyle },
          [
            _c("div", { staticClass: "title acea-row row-between-wrapper" }, [
              _c(
                "div",
                { staticClass: "text line1 tui-skeleton-rect acea-row" },
                [
                  _c("el-image", {
                    staticClass: "image",
                    attrs: { src: _vm.src },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "label", style: _vm.titleColor }, [
                    _vm._v(_vm._s(_vm.configObj.titleConfig.val)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "more tui-skeleton-rect", style: _vm.moreColor },
                [
                  _vm._v("\n        MORE\n        "),
                  _c("span", { staticClass: "iconfont icon-xuanze" }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tips mb10" }, [
              _vm._v("Good brand store"),
            ]),
            _vm._v(" "),
            _vm.listStyle === 0
              ? _c(
                  "div",
                  { staticClass: "merList", style: _vm.gridGap },
                  _vm._l(_vm.merList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pic tui-skeleton-rect",
                              style: _vm.contentStyle,
                            },
                            [
                              _c("el-image", {
                                staticClass: "picImg",
                                attrs: { src: item.coverImage },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-image", {
                            staticClass: "lines left",
                            attrs: {
                              src:
                                _vm.mediaDomain +
                                "/crmebimage/presets/lianjie.png",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.logoShow,
                                  expression: "logoShow",
                                },
                              ],
                              staticClass: "merlogo tui-skeleton-rect",
                            },
                            [
                              _c("el-image", {
                                attrs: { src: item.rectangleLogo },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-image", {
                            staticClass: "lines right",
                            attrs: {
                              src:
                                _vm.mediaDomain +
                                "/crmebimage/presets/lianjie.png",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "merName tui-skeleton-rect" },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.nameShow,
                                      expression: "nameShow",
                                    },
                                  ],
                                  staticClass: "neme",
                                  style: _vm.nameColor,
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.typeShow,
                                      expression: "typeShow",
                                    },
                                  ],
                                  staticClass: "label",
                                  style: _vm.labelColor,
                                },
                                [_vm._v("旗舰店")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.listStyle === 1
              ? [
                  _c(
                    "div",
                    { staticClass: "store-wrapper" },
                    [
                      _vm._l(_vm.merList, function (item, index) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "store-item street-noPad",
                              style: [
                                {
                                  "background-image":
                                    "url(" + item.streetBackImage + ")",
                                },
                                _vm.contentStyle,
                              ],
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "head street-backImage" },
                                [
                                  _c("div", { staticClass: "left-wrapper" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "merlogo street-merlogo",
                                        style: {
                                          "background-image":
                                            "url(" +
                                            _vm.mediaDomain +
                                            "/crmebimage/presets/shang.png)",
                                        },
                                      },
                                      [
                                        _c("el-image", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.logoShow,
                                              expression: "logoShow",
                                            },
                                          ],
                                          staticClass: "image",
                                          attrs: {
                                            src: item.rectangleLogo,
                                            mode: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "con-box" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "name line1 acea-row row-middle street-name",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.nameShow,
                                                  expression: "nameShow",
                                                },
                                              ],
                                              staticClass: "mer_name line1",
                                              style: _vm.nameColor,
                                            },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.typeShow,
                                                  expression: "typeShow",
                                                },
                                              ],
                                              staticClass: "merType mr10 label",
                                              style: _vm.labelColor,
                                            },
                                            [_vm._v("自营")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "pic-wrapper street-wrapper" },
                                _vm._l(item.proList, function (goods, indexn) {
                                  return _c(
                                    "div",
                                    { key: indexn, staticClass: "proList" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pic-item" },
                                        [
                                          _c("el-image", {
                                            attrs: {
                                              src: goods.image,
                                              mode: "aspectFill",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pic-name line2 street-pic",
                                        },
                                        [_vm._v(_vm._s(goods.name))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "street-price" },
                                        [_vm._v("￥" + _vm._s(goods.price))]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }