"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _distribution = require("@/api/distribution");
var _index = _interopRequireDefault(require("@/components/cards/index"));
var _permission = require("@/utils/permission");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'AccountsUser',
  components: {
    cardsData: _index.default
  },
  data: function data() {
    return {
      cardLists: [],
      timeVal: [],
      tableData: {
        data: [],
        total: 0
      },
      listLoading: false,
      tableFrom: {
        dateLimit: '',
        keywords: '',
        page: 1,
        limit: 20
      },
      fromList: this.$constants.fromList,
      dialogVisible: false,
      spreadData: {
        data: [],
        total: 0
      },
      spreadFrom: {
        page: 1,
        limit: 10,
        dateLimit: '',
        type: 0,
        keywords: '',
        uid: ''
      },
      keywords: '',
      timeValSpread: [],
      spreadLoading: false,
      uid: '',
      onName: '',
      titleName: ''
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:retail:store:people:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    seachList: function seachList() {
      this.getList(1);
    },
    // 清除
    clearSpread: function clearSpread(row) {
      var _this = this;
      this.$modalSure(this.$t('distribution.index.5trlrp989pw0') + row.nickname + '】的上级推广人吗').then(function () {
        (0, _distribution.spreadClearApi)(row.uid).then(function (res) {
          _this.$message.success(_this.$t('distribution.index.5trlrp989r80'));
          _this.getList(1);
        });
      });
    },
    onSpread: function onSpread(uid, n, p) {
      this.onName = n;
      this.titleName = p;
      this.uid = uid;
      this.dialogVisible = true;
      this.spreadFrom = {
        page: 1,
        limit: 10,
        dateLimit: '',
        type: 0,
        keywords: '',
        uid: uid
      };
      this.keywords = '';
      this.getListSpread();
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    // 选择时间
    selectChangeSpread: function selectChangeSpread(tab) {
      this.timeValSpread = [];
      this.spreadFrom.dateLimit = tab;
      this.spreadFrom.page = 1;
      this.onName === 'man' ? this.getListSpread() : this.getSpreadOrderList();
    },
    // 具体日期
    onchangeTimeSpread: function onchangeTimeSpread(e) {
      this.timeValSpread = e;
      this.spreadFrom.dateLimit = e ? this.timeValSpread.join(',') : '';
      this.spreadFrom.page = 1;
      this.onName === 'man' ? this.getListSpread() : this.getSpreadOrderList();
    },
    onChanges: function onChanges() {
      this.spreadFrom.page = 1;
      this.onName === 'man' ? this.getListSpread() : this.getSpreadOrderList();
    },
    // 推广人列表
    getListSpread: function getListSpread() {
      var _this2 = this;
      this.spreadLoading = true;
      this.spreadFrom.keywords = encodeURIComponent(this.keywords);
      (0, _distribution.spreadListApi)(this.spreadFrom).then(function (res) {
        _this2.spreadData.data = res.list;
        _this2.spreadData.total = res.total;
        _this2.spreadLoading = false;
      }).catch(function () {
        _this2.spreadLoading = false;
      });
    },
    pageChangeSpread: function pageChangeSpread(page) {
      this.spreadFrom.page = page;
      this.onName === 'man' ? this.getListSpread(this.uid) : this.getSpreadOrderList(this.uid);
    },
    handleSizeChangeSpread: function handleSizeChangeSpread(val) {
      this.spreadFrom.limit = val;
      this.onName === 'man' ? this.getListSpread(this.uid) : this.getSpreadOrderList(this.uid);
    },
    // 推广订单
    onSpreadOrder: function onSpreadOrder(uid, n, p) {
      this.uid = uid;
      this.onName = n;
      this.titleName = p;
      this.dialogVisible = true;
      this.spreadFrom = {
        page: 1,
        limit: 10,
        dateLimit: '',
        type: 0,
        keywords: '',
        uid: uid
      };
      this.keywords = '';
      this.getSpreadOrderList();
    },
    getSpreadOrderList: function getSpreadOrderList() {
      var _this3 = this;
      this.spreadLoading = true;
      this.spreadFrom.keywords = encodeURIComponent(this.keywords);
      (0, _distribution.spreadOrderListApi)(this.spreadFrom).then(function (res) {
        _this3.spreadData.data = res.list;
        _this3.spreadData.total = res.total;
        _this3.spreadLoading = false;
      }).catch(function () {
        _this3.spreadLoading = false;
      });
    },
    selectChange: function selectChange(tab) {
      this.tableFrom.dateLimit = tab;
      this.timeVal = [];
      // this.spreadStatistics()
      this.getList(1);
    },
    // 具体日期
    onchangeTime: function onchangeTime(e) {
      this.timeVal = e;
      this.tableFrom.dateLimit = e ? this.timeVal.join(',') : '';
      this.getList(1);
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.listLoading = true;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      (0, _distribution.promoterListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList(1);
    }
  }
};