"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _marketing = require("@/api/marketing");
var _permission = require("@/utils/permission");
var _merUseCategory = _interopRequireDefault(require("@/components/merUseCategory"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'groupList',
  components: {
    merchantName: _merUseCategory.default
  },
  data: function data() {
    return {
      isShow: 0,
      //1审核，2查看，3编辑
      loading: false,
      listLoading: false,
      tableData: {
        data: [],
        total: 0
      },
      proName: '',
      activityName: '',
      tableFrom: {
        page: 1,
        limit: 20,
        proName: '',
        activityName: '',
        merStars: 0,
        auditStatus: '1',
        merIds: '',
        activityStatus: '',
        proStatus: ''
      },
      headeNum: [{
        name: this.$t('marketing.group.list.index.5u1190xhflc0'),
        type: '1'
      }, {
        name: this.$t('marketing.group.list.index.5u1190xhfn40'),
        type: '2'
      }, {
        name: this.$t('marketing.group.list.index.5u1190xhfss0'),
        type: '3'
      }],
      multipleSelection: [],
      ruleForm: {
        reason: '',
        auditStatus: 'success',
        id: ''
      },
      rules: {
        auditStatus: [{
          required: true,
          message: this.$t('marketing.group.list.index.5u1190xhgx00'),
          trigger: 'change'
        }],
        reason: [{
          required: true,
          message: this.$t('marketing.group.list.index.5u1190xhgy80'),
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      loadingBtn: false,
      id: '',
      //拼团商品id
      merIds: [],
      //商户id
      groupInfo: {}
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:user:tag:list'])) this.getList(1);
    if (!localStorage.getItem('adminProductClassify')) this.$store.dispatch('product/getAdminProductClassify');
  },
  methods: {
    checkPermi: _permission.checkPermi,
    handleAudit: function handleAudit(row, n) {
      this.id = row.id;
      this.isShow = n;
      this.dialogVisible = true;
      this.groupInfo = row;
    },
    close: function close(refName) {
      this.dialogVisible = false;
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    reset: function reset(formName) {
      this.proName = '';
      this.activityName = '';
      this.merIds = [];
      this.tableFrom = {
        page: 1,
        limit: 20,
        proName: '',
        activityName: '',
        merStars: 0,
        auditStatus: '1',
        merIds: '',
        activityStatus: '',
        proStatus: ''
      };
      this.getList('');
    },
    //审核拒绝
    cancelForm: function cancelForm() {
      var _this = this;
      this.$modalPrompt('textarea', this.$t('marketing.group.list.index.5u1190xhh2c0')).then(function (V) {
        _this.ruleForm.reason = V;
        _this.submit();
      });
    },
    // 审核提交
    onSubmit: function onSubmit(type) {
      var _this2 = this;
      this.ruleForm.auditStatus = type;
      if (type === 'success') {
        this.$modalSure(this.$t('marketing.group.list.index.5u1190xhh8k0')).then(function () {
          _this2.submit();
        });
      } else {
        this.cancelForm();
      }
    },
    submit: function submit() {
      var _this3 = this;
      this.loadingBtn = true;
      this.ruleForm.id = this.id;
      (0, _marketing.groupProAuditApi)(this.ruleForm).then(function (res) {
        _this3.$message.success(_this3.$t('marketing.group.list.index.5u1190xhhb80'));
        _this3.dialogVisible = false;
        _this3.loadingBtn = false;
        _this3.getList();
      }).catch(function (res) {
        _this3.loadingBtn = false;
      });
    },
    //表格选中
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getMerId: function getMerId(id) {
      this.merIds = id;
      this.tableFrom.merIds = id.toString();
      this.getList();
    },
    // 列表
    getList: function getList(num) {
      var _this4 = this;
      this.tableFrom.page = num ? num : this.tableFrom.page;
      this.tableFrom.activityName = encodeURIComponent(this.activityName);
      this.tableFrom.proName = encodeURIComponent(this.proName);
      this.listLoading = true;
      (0, _marketing.groupProListApi)(this.tableFrom).then(function (res) {
        _this4.tableData.data = res.list;
        _this4.tableData.total = res.total;
        _this4.listLoading = false;
      }).catch(function (res) {
        _this4.listLoading = false;
      });
    },
    //批量删除
    batchDel: function batchDel() {
      var _this5 = this;
      var ids = this.multipleSelection.map(function (item) {
        return item.id;
      }).toString();
      this.$modalSure(this.$t('marketing.group.list.index.5u1190xhhd40')).then(function () {
        (0, _marketing.groupProDelApi)({
          ids: ids
        }).then(function () {
          _this5.$message.success(_this5.$t('marketing.group.list.index.5u1190xhhfc0'));
          _this5.getList();
        });
      });
    },
    // 删除
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      this.$modalSure(this.$t('marketing.group.list.index.5u1190xhhgo0')).then(function () {
        (0, _marketing.groupProDelApi)({
          ids: row.id
        }).then(function () {
          _this6.$message.success(_this6.$t('marketing.group.list.index.5u1190xhhfc0'));
          _this6.getList();
        });
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    }
  }
};