"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var merchant = _interopRequireWildcard(require("@/api/merchant"));
var _permission = require("@/utils/permission");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'index',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    // 计算是否滚动到最下面
    selectLoadMore: {
      bind: function bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        var SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          if (this.scrollHeight - this.scrollTop < this.clientHeight + 1) {
            binding.value();
          }
        });
      }
    }
  },
  data: function data() {
    return {
      merchantList: [],
      merId: null,
      loading: false,
      search: {
        limit: 10,
        page: 1,
        keywords: ''
      }
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:merchant:page:list'])) this.getMerList();
  },
  methods: {
    // 下拉加载更多
    selectLoadMore: function selectLoadMore() {
      this.search.page = this.search.page + 1;
      if (this.search.page > this.totalPage) return;
      this.getMerList(); // 请求接口
    },
    // 远程搜索
    remoteMethod: function remoteMethod(query) {
      var _this = this;
      this.loading = true;
      this.search.keywords = query;
      this.search.page = 1;
      this.merchantList = [];
      setTimeout(function () {
        _this.loading = false;
        _this.getMerList(); // 请求接口
      }, 200);
    },
    // 列表
    getMerList: function getMerList() {
      var _this2 = this;
      merchant.merchantListApi(this.search).then(function (res) {
        _this2.totalPage = res.totalPage;
        _this2.total = res.total;
        _this2.merchantList = _this2.merchantList.concat(res.list);
      });
    },
    onChangeMerId: function onChangeMerId() {
      this.$emit('getMerId', this.merId);
    }
  }
};