"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.brandAddApi = brandAddApi;
exports.brandDeleteApi = brandDeleteApi;
exports.brandListAllApi = brandListAllApi;
exports.brandListApi = brandListApi;
exports.brandShowApi = brandShowApi;
exports.brandUpdateApi = brandUpdateApi;
exports.copyConfigApi = copyConfigApi;
exports.copyProductApi = copyProductApi;
exports.guaranteeAddApi = guaranteeAddApi;
exports.guaranteeDeleteApi = guaranteeDeleteApi;
exports.guaranteeListApi = guaranteeListApi;
exports.guaranteeShowApi = guaranteeShowApi;
exports.guaranteeUpdateApi = guaranteeUpdateApi;
exports.importProductApi = importProductApi;
exports.offShellApi = offShellApi;
exports.onChangeSalesApi = onChangeSalesApi;
exports.orderExcelApi = orderExcelApi;
exports.productActivityListApi = productActivityListApi;
exports.productAuditApi = productAuditApi;
exports.productCategoryAddApi = productCategoryAddApi;
exports.productCategoryApi = productCategoryApi;
exports.productCategoryDeleteApi = productCategoryDeleteApi;
exports.productCategoryListApi = productCategoryListApi;
exports.productCategoryShowApi = productCategoryShowApi;
exports.productCategoryUpdateApi = productCategoryUpdateApi;
exports.productCouponListApi = productCouponListApi;
exports.productDeleteApi = productDeleteApi;
exports.productDetailApi = productDetailApi;
exports.productExcelApi = productExcelApi;
exports.productExportApi = productExportApi;
exports.productHeadersApi = productHeadersApi;
exports.productListbyidsApi = productListbyidsApi;
exports.productLstApi = productLstApi;
exports.putOnShellApi = putOnShellApi;
exports.replyCreatApi = replyCreatApi;
exports.replyDeleteApi = replyDeleteApi;
exports.replyEditApi = replyEditApi;
exports.replyInfoApi = replyInfoApi;
exports.replyListApi = replyListApi;
exports.updateProductApi = updateProductApi;
var _request = _interopRequireDefault(require("@/utils/request"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/**
 * 商品详情
 * @param pram
 */
function productDetailApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/info/".concat(id),
    method: 'GET'
  });
}

/**
 * 删除商品
 * @param pram
 */
function productDeleteApi(id, type) {
  return (0, _request.default)({
    url: "/admin/store/product/delete/".concat(id),
    method: 'get',
    params: {
      type: type
    }
  });
}

/**
 * 商品列表 表头数量
 */
function productHeadersApi() {
  return (0, _request.default)({
    url: '/admin/platform/product/tabs/headers',
    method: 'GET'
  });
}

/**
 * 商品列表
 * @param pram
 */
function productLstApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/product/list',
    method: 'GET',
    params: params
  });
}

/**
 * 商品审核
 * @param pram
 */
function productAuditApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/audit",
    method: 'post',
    data: data
  });
}

/**
 * 修改虚拟销量
 * @param pram
 */
function onChangeSalesApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/virtual/sales",
    method: 'post',
    data: data
  });
}

/**
 * 商品分类 分类缓存树
 * @param pram
 */
function productCategoryApi() {
  return (0, _request.default)({
    url: '/admin/platform/product/category/cache/tree',
    method: 'GET'
  });
}
/**
 * 商品分类 列表
 * @param pram
 */
function productCategoryListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/product/category/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品分类 新增
 * @param pram
 */
function productCategoryAddApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/product/category/add',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 编辑
 * @param pram
 */
function productCategoryUpdateApi(data) {
  return (0, _request.default)({
    url: '/admin/platform/product/category/update',
    method: 'post',
    data: data
  });
}
/**
 * 商品分类 删除
 * @param pram
 */
function productCategoryDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/category/delete/".concat(id),
    method: 'post'
  });
}
/**
 * 商品分类 修改分类显示状态
 * @param pram
 */
function productCategoryShowApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/category/update/show/".concat(id),
    method: 'post'
  });
}

/**
 * 商品上架
 * @param pram
 */
function putOnShellApi(id) {
  return (0, _request.default)({
    url: "/admin/store/product/putOnShell/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品强制下架
 * @param pram
 */
function offShellApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/force/down",
    method: 'post',
    data: data
  });
}

/**
 * 商品评论 列表
 * @param pram
 */
function replyListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/product/reply/list',
    method: 'GET',
    params: params
  });
}
/**
 * 商品评论 新增
 * @param pram
 */
function replyCreatApi(data) {
  return (0, _request.default)({
    url: '/admin/store/product/reply/save',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 编辑
 * @param pram
 */
function replyEditApi(data) {
  return (0, _request.default)({
    url: '/admin/store/product/reply/update',
    method: 'POST',
    data: data
  });
}
/**
 * 商品评论 详情
 * @param pram
 */
function replyInfoApi(id) {
  return (0, _request.default)({
    url: "/admin/store/product/reply/info/".concat(id),
    method: 'GET'
  });
}
/**
 * 商品评论 删除
 * @param pram
 */
function replyDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/reply/delete/".concat(id),
    method: 'GET'
  });
}

/**
 * 商品评论 导出
 * @param pram
 */
function productExportApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品复制 99Api
 * @param pram
 */
function importProductApi(params) {
  return (0, _request.default)({
    url: "/admin/store/product/importProduct",
    method: 'post',
    params: params
  });
}

/**
 * 商品复制 一号通
 * @param pram
 */
function copyProductApi(data) {
  return (0, _request.default)({
    url: "/admin/store/product/copy/product",
    method: 'post',
    data: data
  });
}

/**
 * 商品列表 导出
 * @param pram
 */
function productExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/product",
    method: 'get',
    params: params
  });
}

/**
 * 商品列表 获取复制商品配置
 * @param pram
 */
function copyConfigApi() {
  return (0, _request.default)({
    url: "/admin/store/product/copy/config",
    method: 'post'
  });
}

/**
 * 订单数据 导出
 * @param pram
 */
function orderExcelApi(params) {
  return (0, _request.default)({
    url: "/admin/export/excel/order",
    method: 'get',
    params: params
  });
}

/**
 * 品牌 列表
 * @param pram
 */
function brandListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/product/brand/list",
    method: 'get',
    params: params
  });
}

/**
 * 品牌缓存列表(全部)
 * @param pram
 */
function brandListAllApi() {
  return (0, _request.default)({
    url: "/admin/platform/product/brand/cache/list",
    method: 'get'
  });
}

/**
 * 品牌 新增
 * @param pram
 */
function brandAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/brand/add",
    method: 'post',
    data: data
  });
}

/**
 * 品牌 编辑
 * @param pram
 */
function brandUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/brand/update",
    method: 'post',
    data: data
  });
}

/**
 * 品牌 删除
 * @param pram
 */
function brandDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/brand/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 品牌 修改品牌显示状态
 * @param pram
 */
function brandShowApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/brand/update/show/".concat(id),
    method: 'post'
  });
}

/**
 * 保障服务 列表
 * @param pram
 */
function guaranteeListApi(params) {
  return (0, _request.default)({
    url: "/admin/platform/product/guarantee/list",
    method: 'get',
    params: params
  });
}

/**
 * 保障服务 新增
 * @param pram
 */
function guaranteeAddApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/guarantee/add",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务 编辑
 * @param pram
 */
function guaranteeUpdateApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/guarantee/update",
    method: 'post',
    data: data
  });
}

/**
 * 保障服务 删除
 * @param pram
 */
function guaranteeDeleteApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/guarantee/delete/".concat(id),
    method: 'post'
  });
}

/**
 * 保障服务 修改品牌显示状态
 * @param pram
 */
function guaranteeShowApi(id) {
  return (0, _request.default)({
    url: "/admin/platform/product/guarantee/update/show/".concat(id),
    method: 'post'
  });
}

/**
 * 修改商品
 * @param pram
 */
function updateProductApi(data) {
  return (0, _request.default)({
    url: "/admin/platform/product/update",
    method: 'post',
    data: data
  });
}

/**
 * 商品可用优惠券列表
 * @param pram
 */
function productCouponListApi() {
  return (0, _request.default)({
    url: '/admin/platform/coupon/product/usable/list',
    method: 'get'
  });
}

/**
 * 商品搜索分页列表（活动）
 * @param pram
 */
function productActivityListApi(params) {
  return (0, _request.default)({
    url: '/admin/platform/product/activity/search/page',
    method: 'get',
    params: params
  });
}

/**
 * 根据商品id集合查询商品列表
 * @param pram
 */
function productListbyidsApi(ids) {
  return (0, _request.default)({
    url: "/admin/platform/product/listbyids/".concat(ids),
    method: 'get'
  });
}