"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'c_coupon',
  props: {
    configObj: {
      type: Object
    },
    configNme: {
      type: String
    },
    number: {
      type: null
    }
  },
  data: function data() {
    return {
      defaults: {},
      configData: {},
      timeStamp: '',
      multiple: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.defaults = _this.configObj;
      _this.configData = _this.configObj[_this.configNme];
      console.log(_this.configObj, '123');
    });
  },
  computed: {
    checkboxes: function checkboxes() {
      return Array.from({
        length: this.defaults.numConfig.val
      }, function (_, index) {
        return {
          // main:[{}],
          value: index + 1,
          label: "Option ".concat(index + 1)
        };
      });
    }
  },
  watch: {
    configObj: {
      handler: function handler(nVal, oVal) {
        this.defaults = nVal;
        console.log(this.checkboxes);
        this.configData = nVal[this.configNme];
      },
      deep: true
    },
    number: function number(nVal) {
      this.timeStamp = nVal;
    }
  },
  methods: {
    sliderChange: function sliderChange(e, index) {
      // let storage = window.localStorage;
      // this.configData.activeValue = e ? e : storage.getItem(this.timeStamp);
      // this.$emit('getConfig', { name: 'select', values: e });
      // console.log(this.configData.activeValue)
    }
  }
};