var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divBox" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticClass: "table",
              attrs: {
                data: _vm.tableData.data,
                size: "small",
                "highlight-current-row": "",
                "header-cell-style": { fontWeight: "bold" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "60" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqtbo0"
                  ),
                  "min-width": "180",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqtwo0"
                  ),
                  "min-width": "120",
                  prop: "nickName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqtz80"
                  ),
                  "min-width": "120",
                  prop: "integral",
                  "sort-method": function (a, b) {
                    return a.integral - b.integral
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.type === 1 ? "+" : "-") +
                              _vm._s(scope.row.integral)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqu100"
                  ),
                  "min-width": "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.linkId))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqu2s0"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("integralLinkTypeFilter")(
                                scope.row.linkType
                              )
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqu5c0"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("integralStatusFilter")(scope.row.status)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqu740"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("filterEmpty")(scope.row.mark))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: _vm.$t(
                    "marketing.integral.integralLog.index.5trh54aqu8k0"
                  ),
                  "min-width": "150",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  "page-sizes": [20, 40, 60, 80],
                  "page-size": _vm.tableFrom.limit,
                  "current-page": _vm.tableFrom.page,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.tableData.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.pageChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }