"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _permission = require("@/utils/permission");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 权限判断函数
var _default = exports.default = {
  name: 'UserGroup',
  data: function data() {
    return {
      tableFrom: {
        page: 1,
        limit: 20
      },
      tableData: {
        data: [],
        total: 0
      },
      listLoading: true
    };
  },
  mounted: function mounted() {
    if ((0, _permission.checkPermi)(['platform:user:tag:list'])) this.getList();
  },
  methods: {
    checkPermi: _permission.checkPermi,
    onAdd: function onAdd(row) {
      var _this = this;
      this.$modalPrompt('text', this.$t('user.label.index.5tqnm7bst600'), row ? row.name : '').then(function (V) {
        row ? (0, _user.tagUpdateApi)({
          id: row.id,
          name: V
        }).then(function () {
          _this.$message.success(_this.$t('user.label.index.5tqnm7bstg00'));
          _this.getList();
        }) : (0, _user.tagSaveApi)({
          name: V
        }).then(function () {
          _this.$message.success(_this.$t('user.label.index.5tqnm7bstho0'));
          _this.getList();
        });
      });
    },
    // 列表
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _user.tagListApi)(this.tableFrom).then(function (res) {
        _this2.tableData.data = res.list;
        _this2.tableData.total = res.total;
        _this2.listLoading = false;
      }).catch(function (res) {
        _this2.listLoading = false;
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    // 删除
    handleDelete: function handleDelete(id, idx) {
      var _this3 = this;
      this.$modalSure(this.$t('user.label.index.5tqnm7bstk80')).then(function () {
        (0, _user.tagDeleteApi)(id).then(function () {
          _this3.$message.success(_this3.$t('user.label.index.5tqnm7bstm00'));
          _this3.tableData.data.splice(idx, 1);
        });
      });
    }
  }
};